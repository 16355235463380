/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";

export const seed = `CREATE TABLE movies (id int, title varChar(255), director varChar(255), release_year int);

-- Only write your code below this line




-- Only write your code above this line
SELECT * FROM movies;`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "title", "director", "release_year"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [0, "Frozen", "Chris Buck", 2013],
      [1, "Avengers Endgame", "Anthony Russo", 2019]
    ],
    "Wrong data inserted"
  );
  return true;
}
