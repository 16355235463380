/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";

export const seed = `CREATE TABLE products (id int, name varChar(255), price varChar(255), category int);
INSERT INTO products VALUES (0, "pillow", 29, 1);
INSERT INTO products VALUES (1, "bed sheet", 12, 1);
INSERT INTO products VALUES (2, "mattress", 240, 2);
INSERT INTO products VALUES (3, "sofa", 5200, 2);
INSERT INTO products VALUES (4, "chair", 310, 2);

-- Only write your code below this line
`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "name", "price"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [0, "pillow", "29"],
      [1, "bed sheet", "12"],
      [2, "mattress", "240"],
      [3, "sofa", "5200"],
      [4, "chair", "310"]
    ],
    "Wrong data inserted"
  );
  return true;
}
