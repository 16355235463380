/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `import random

# first_num and second_num assigned with random number from 0 to 9
first_num = random.randint(0,9)
second_num = random.randint(0,9)

# Write your code only below this line
`;
export const seedTest = ``;
export const testCases = `
assert answer_one == (first_num * second_num), "answer_one is not correct"
assert answer_two == ((first_num + second_num) * 3), "answer_two is not correct"`;

export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  window.console = oldConsole;
  return true;
}
