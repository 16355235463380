<template>
  <div>
    <b-row no-gutters>
      <b-col class="d-flex flex-column" sm="4">
        <div class="activity-body py-4 px-5">
          <div class="mb-3 d-flex justify-content-between">
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="activityIds[currentActivityIndex - 1]"
              v-if="currentActivityIndex != 0"
            >
              <b-icon icon="chevron-left" /> Prev
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="`/course/${this.courseId}`"
            >
              All Lessons
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="activityIds[currentActivityIndex + 1]"
            >
              Next <b-icon icon="chevron-right" />
            </b-button>
          </div>
          <component :is="activityCurriculum.content" :key="activityId" />
        </div>
      </b-col>
      <b-col sm="8">
        <div
          class="activity-actionbar d-flex justify-content-between align-items-center"
        >
          <h4 class="mx-3 mb-0">Editor</h4>
          <div>
            <span class="mr-2 text-muted" v-if="false">Saved!</span>
            <b-button class="mr-2" variant="secondary">
              Save
            </b-button>
            <b-button class="mr-2" variant="danger" @click="onClickReset">
              Reset
            </b-button>
            <b-button class="mr-2" variant="success" @click="onClickRun">
              Run
            </b-button>
          </div>
        </div>
        <b-row no-gutters>
          <b-col md="12">
            <div id="editor-container">
              <div id="editor"></div>
              <div id="editor-console">
                <database-table
                  :database="db"
                  v-for="(db, index) in resultDB"
                  :key="index"
                ></database-table>
                {{ consoleText }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-model="isResultModalVisible"
      id="result-modal"
      title="Result"
      @ok="onClickResultOk"
      :hide-footer="!isAnswerCorrect"
    >
      <p class="my-4 text-center">
        <span style="font-size: 3rem;">
          {{ isAnswerCorrect ? "✔️" : "❌" }}
        </span>
        <br />
        {{ isAnswerCorrect ? "Great!" : "Try again!" }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import Split from "split.js";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

import initSqlJs from "sql.js";

import * as activityServices from "@/services/acitivity.service";
import curriculum from "@/curriculum/sql";
import { mapGetters } from "vuex";
import DatabaseTable from "@/components/DatabaseTable.vue";

export default {
  name: "SqlActivityContainer",

  components: {
    DatabaseTable
  },

  data() {
    return {
      courseId: this.$route.params.courseId,
      activityId: this.$route.params.activityId,
      sql: null,
      db: null,
      editor: null,
      resultDB: [],
      activity: {},
      consoleText: "",
      isResultModalVisible: false,
      isAnswerCorrect: false,
      isSplit: false
    };
  },

  watch: {
    $route: "onMounted"
  },

  mounted() {
    this.onMounted();
  },

  computed: {
    ...mapGetters({
      isLicensed: "organisation/isLicensed",
      isSubscriptionActive: "organisation/isSubscriptionActive"
    }),

    activityCurriculum() {
      return curriculum.get(this.activityId);
    },

    activityIds() {
      return [...curriculum.keys()];
    },

    currentActivityIndex() {
      return this.activityIds.indexOf(this.activityId);
    }
  },

  methods: {
    async onMounted() {
      const loader = this.$loading.show();
      this.courseId = this.$route.params.courseId;
      this.activityId = this.$route.params.activityId;
      this.resultDB = [];

      await this.getActivity();
      this.consoleText = "";
      this.initSplit();
      this.initEditor();
      await this.initSQL();
      this.initPreSeed();
      loader.hide();
    },

    getActivity() {
      return activityServices
        .getActivity(this.activityId)
        .then(activity => {
          this.activity = activity;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    initEditor() {
      this.editor = ace.edit("editor", {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });
      this.editor.setValue(this.activityCurriculum.logics.seed, 1);
    },

    initSplit() {
      if (this.isSplit) {
        return;
      }
      Split(["#editor", "#editor-console"], {
        elementStyle: (dimension, size, gutterSize) => ({
          "flex-basis": `calc(${size}% - ${gutterSize}px)`
        }),
        gutterStyle: (dimension, gutterSize) => ({
          "flex-basis": `${gutterSize}px`
        }),
        direction: "horizontal",
        sizes: [70, 30]
      });
      this.isSplit = true;
    },

    async initSQL() {
      this.sql = await initSqlJs({
        // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
        // You can omit locateFile completely when running in node
        locateFile: file =>
          `https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.4.0/dist/${file}`
      });
    },

    initPreSeed() {
      const preSeed = this.activityCurriculum.logics.preSeed;
      if (preSeed != null) {
        this.initDB();
        this.resultDB = this.db.exec(preSeed);
      }
    },

    onClickRun() {
      this.resultDB = [];
      this.consoleText = "";

      try {
        // eslint-disable-next-line
        const self = this;
        this.resultDB = [];
        this.initDB();
        self.isAnswerCorrect = this.activityCurriculum.logics.testCorrectness(
          this.editor.getValue(),
          self
        );
        self.isResultModalVisible = true;
      } catch (error) {
        self.isResultModalVisible = true;
        this.isAnswerCorrect = false;
        this.consoleText = error;
      }
    },

    onClickResultOk() {
      const nextActivityId = this.activityIds[this.currentActivityIndex + 1];
      this.$router.push(nextActivityId);
    },

    onClickReset() {
      if (confirm("Confirm reset code?")) {
        this.editor.setValue(this.activityCurriculum.logics.seed, 1);
        this.resultDB = [];
        this.consoleText = "";
        this.initPreSeed();
      }
    },

    async initDB() {
      if (this.db != null) {
        this.db.close();
        this.db = null;
      }
      this.db = new this.sql.Database();
    }
  }
};
</script>

<style lang="less" scoped>
.activity {
  &-body {
    background-color: #fff;
    height: calc(100vh - 52px);
    overflow: auto;
  }

  &-header {
    height: 52px;
  }

  &-actionbar {
    height: 52px;
  }
}

#editor-container {
  height: calc(100vh - 110px);
  width: 100%;
  display: flex;
  flex-direction: row;
}

#editor {
  max-width: calc(100% - 200px);
  height: 100%;
  min-height: 100px;
  overflow: auto;
}

#editor-console {
  min-width: 200px;
  background-color: rgb(214, 214, 214);
  color: black;
  white-space: pre-wrap;
  overflow: auto;
}
</style>
