/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";
import { deviceTable } from "./devices";

export const preSeed = deviceTable;
export const seed = ``;

export function testCorrectness(code: string, self: any) {
  self.db.exec(preSeed);
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "name", "type", "price"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [0, "iPhone X", "phone", 999],
      [1, "Samsung Galaxy", "phone", 799],
      [2, "ThinkPad X1", "laptop", 0],
      [3, "Dell XPS", "laptop", 0],
      [4, "Huawei Mate X", "laptop", 0],
      [5, "Microsoft Surface", "laptop", 0],
      [6, "iPhone 12", "phone", 1499],
      [7, "Samsung Fold", "phone", 2100],
      [8, "Google Pixel", "phone", 799],
      [9, "iPad Mini 5", "tablet", 1129],
      [10, "Pocophone", "phone", 499],
      [11, "Xiaomi Mi 10", "phone", 599],
      [12, "HP Pavillion", "laptop", 0],
      [13, "HP Spectre", "laptop", 0],
      [14, "Macbook Pro", "laptop", 0],
      [15, "Realme", "phone", 399]
    ],
    "Wrong data updated"
  );
  return true;
}
