<template>
  <div>
    <h2>Operator Precedence</h2>

    <p>
      More than one math operators can be combined to perform a more complex
      calculation. Python math operation follows convention math precedence
      BODMAS that is
    </p>
    <ol>
      <li>Parentheses</li>
      <li>Exponents</li>
      <li>Multiplication, Division, and Modulus</li>
      <li>Addition and Subtraction</li>
    </ol>

    <div id="editor-math" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          8
          <br />
          12
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create variables <code>answer_one</code>, <code>answer_two</code>,
            and <code>answer_three</code>. Assign each with correct number based
            on given questions.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `print(2 + 1 * 4)
print((2 + 1) * 4)`;

export default {
  mounted() {
    this.initEditor("editor-math", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
