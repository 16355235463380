/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `const number = 17;

// Only write code below this line`;

const seedTest = `
  chaiAssert(number === 17, "Do not change the default code");
`;

export const testCases = `
  chaiAssert.isDefined(condition);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);

  assert(
    /condition\s*=\s*number\s*<\s*50\s*;/.test(code),
    "condition variable is not assigned correctly"
  );

  assert(
    /if\s*\(\s*condition\s*\)\s*{\s*console.log\(\s*number\s*\)\s*;\s*}/.test(
      code
    ),
    "if statement is not created correctly"
  );

  window.console = oldConsole;
  return true;
}
