import store from "@/store";

export function initFunction() {
  return (interpreter, scope) => {
    /* function highlightBlock(id) {
      store.state.game.blockId = id;
    }

    const highlightWrapper = function(id) {
      id = id ? id.toString() : "";
      return interpreter.createPrimitive(highlightBlock(id));
    };

    interpreter.setProperty(
      scope,
      "highlightBlock",
      interpreter.createNativeFunction(highlightWrapper)
    ); */

    const startProgramWrapper = function() {
      store.state.game.isRunning = true;
    };

    interpreter.setProperty(
      scope,
      "startProgram",
      interpreter.createNativeFunction(startProgramWrapper)
    );

    const endProgramWrapper = function() {
      store.state.game.isRunning = false;
    };

    interpreter.setProperty(
      scope,
      "endProgram",
      interpreter.createNativeFunction(endProgramWrapper)
    );

    const moveRightWrapper = function(callback) {
      store.state.game.isMovingRight = true;
      const checkState = function() {
        if (store.state.game.isMovingRight) {
          setTimeout(checkState, 10);
        } else {
          callback();
        }
      };
      checkState();
    };

    interpreter.setProperty(
      scope,
      "moveRight",
      interpreter.createAsyncFunction(moveRightWrapper)
    );

    const moveLeftWrapper = function(callback) {
      store.state.game.isMovingLeft = true;
      const checkState = function() {
        if (store.state.game.isMovingLeft) {
          setTimeout(checkState, 10);
        } else {
          callback();
        }
      };
      checkState();
    };

    interpreter.setProperty(
      scope,
      "moveLeft",
      interpreter.createAsyncFunction(moveLeftWrapper)
    );

    const moveUpWrapper = function(callback) {
      store.state.game.isMovingUp = true;
      const checkState = function() {
        if (store.state.game.isMovingUp) {
          setTimeout(checkState, 10);
        } else {
          callback();
        }
      };
      checkState();
    };

    interpreter.setProperty(
      scope,
      "moveUp",
      interpreter.createAsyncFunction(moveUpWrapper)
    );

    const moveDownWrapper = function(callback) {
      store.state.game.isMovingDown = true;
      const checkState = function() {
        if (store.state.game.isMovingDown) {
          setTimeout(checkState, 10);
        } else {
          callback();
        }
      };
      checkState();
    };

    interpreter.setProperty(
      scope,
      "moveDown",
      interpreter.createAsyncFunction(moveDownWrapper)
    );

    const attackWrapper = async function(direction, callback) {
      switch (direction) {
        case "ATTACK_UP":
          store.state.game.isAttackingUp = true;
          break;

        case "ATTACK_DOWN":
          store.state.game.isAttackingDown = true;
          break;

        case "ATTACK_LEFT":
          store.state.game.isAttackingLeft = true;
          break;

        case "ATTACK_RIGHT":
          store.state.game.isAttackingRight = true;
          break;

        default:
          break;
      }
      const checkState = function() {
        if (store.getters["game/isAttacking"]) {
          setTimeout(checkState, 10);
        } else {
          callback();
        }
      };
      checkState();
    };

    interpreter.setProperty(
      scope,
      "attack",
      interpreter.createAsyncFunction(attackWrapper)
    );

    interpreter.setProperty(
      scope,
      "moveDown",
      interpreter.createAsyncFunction(moveDownWrapper)
    );

    const hasSpriteWrapper = async function(type, direction, callback) {
      switch (direction) {
        case "DIRECTION_UP":
          callback(store.state.game.itemUp == type);
          break;

        case "DIRECTION_DOWN":
          callback(store.state.game.itemDown == type);
          break;

        case "DIRECTION_LEFT":
          callback(store.state.game.itemLeft == type);
          break;

        case "DIRECTION_RIGHT":
          callback(store.state.game.itemRight == type);
          break;

        default:
          callback(false);
          break;
      }
    };

    interpreter.setProperty(
      scope,
      "hasSprite",
      interpreter.createAsyncFunction(hasSpriteWrapper)
    );

    const hasPathWrapper = async function(direction, callback) {
      switch (direction) {
        case "PATH_UP":
          callback(store.state.game.itemUp != "WALL");
          break;

        case "PATH_DOWN":
          callback(store.state.game.itemDown != "WALL");
          break;

        case "PATH_LEFT":
          callback(store.state.game.itemLeft != "WALL");
          break;

        case "PATH_RIGHT":
          callback(store.state.game.itemRight != "WALL");
          break;

        default:
          callback(false);
          break;
      }
    };

    interpreter.setProperty(
      scope,
      "hasPath",
      interpreter.createAsyncFunction(hasPathWrapper)
    );

    const hasArrivedDestinationWrapper = async function(direction, callback) {
      callback(store.state.game.hasReachEndPoint);
    };

    interpreter.setProperty(
      scope,
      "hasArrivedDestination",
      interpreter.createAsyncFunction(hasArrivedDestinationWrapper)
    );
  };
}
