/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myNumberString === 'undefined') var myNumberString;
    if (typeof myNumber === 'undefined') var myNumber;
    try {returnResult(myNumberString, myNumber);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myNumberString: string, myNumber: number) => {
      result.myNumberString = myNumberString;
      result.myNumber = myNumber;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.myNumberString === undefined) {
    status.message = "myNumberString variable not created";
  } else if (result.myNumber === undefined) {
    status.message = "myNumber variable not created";
  } else if (result.myNumberString !== "100") {
    status.message = "myNumberString not assigned with correct value";
  } else if (result.myNumber !== 100) {
    status.message = "myNumber not assigned with correct value";
  } else if (
    !code.includes("alert(myNumberString)") ||
    !code.includes("alert(myNumber)")
  ) {
    status.message = "myNumberString or myNumber not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
