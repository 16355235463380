/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `alphabets = ["a", "b", "c", "d", "e"]

# Write code only below this line
`;
export const seedTest = `
assert alphabets == ["a", "b", "c", "d", "e"], "do not change default code"`;
export const testCases = `
assert alphabets == ["a", "b", "d"], "alphabets is not correct"`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  Sk.importMainWithBody("", false, seedCode + seedTest);

  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /print\s*\(\s*alphabets\s*\)/.test(code),
    "Print the value of alphabets"
  );

  window.console = oldConsole;
  return true;
}
