<template>
  <div class="pb-3">
    <h2>Project: BMI (Challenge 2)</h2>

    <p class="mt-3">
      Display different message to our user after they calculated their BMI. To
      do that we will need to apply conditionals.
    </p>
    <ul>
      <li>below 18.5 – “You're in the underweight range”</li>
      <li>between 18.5 and 24.9 – “You're in the healthy weight range”</li>
      <li>between 25 and 29.9 – “You're in the overweight range”</li>
      <li>Higher than 30 – “You're in the obese range”</li>
    </ul>

    <p class="text-md">
      Work on the challenge on your own before go through the solution below!
    </p>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-4-project-bmi-4.mp4?alt=media&token=f4eefe1f-4d34-4140-836f-2a13b024ee84"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3"></p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
