<template>
  <div>
    <h2>Define Functions</h2>

    <p>
      In Python a function is defined using the <code>def</code> keyword. We can
      have one or more statements in the function body. All the statements must
      have the same indentation level.
    </p>
    <p>Use the function name followed by parenthesis to call the function</p>

    <div id="editor-nested" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          1
          <br />
          3
          <br />
          5
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Define a function <code>list_even_numbers</code></li>
          <li>The function should print all the even numbers from 2 to 8</li>
          <li>Call <code>list_even_numbers</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `def list_odd_numbers():
    for i in range(1, 6, 2):
      print(i)

list_odd_numbers()`;

export default {
  mounted() {
    this.initEditor("editor-nested", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
