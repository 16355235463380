<template>
  <div>
    <h2>Increment / Decrement</h2>

    <p>
      The increment operator <code>++</code> and decrement operator
      <code>--</code> are used to decrease and increase variable by 1.
    </p>

    <div id="editor-math-increment" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 6
          <br />
          > 9
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use the increment operator to increase the
            <code>counter</code> variable
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-math-increment", `let a = 5;\na++;\nconsole.log(a);\n\nlet b = 10;\nb--;\nconsole.log(b);`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
