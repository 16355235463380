/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof number === 'undefined') var number ;
    try {returnResult(number);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (number: number) => {
      result.number = number;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");
  if (result.number === undefined) {
    status.message = "Variable number not created correctly";
  } else if (result.number !== 60) {
    status.message = "Variable number not assigned with correct value";
  } else if (
    !trimmedCode.includes("if(number>80){window.alert('Morethan80!');}")
  ) {
    status.message = "Wrong statement for number greater than 80";
  } else if (
    !trimmedCode.includes("elseif(number>50){window.alert('Morethan50!');}")
  ) {
    status.message = "Wrong statement for number greater than 50";
  } else if (
    !trimmedCode.includes("elseif(number>25){window.alert('Morethan25!');}")
  ) {
    status.message = "Wrong statement for number greater than 25";
  } else if (
    !trimmedCode.includes("else{window.alert('Lessthanorequal25!');}")
  ) {
    status.message = "Wrong statement for number less than or lower than 25";
  } else if (
    result.number > 50 &&
    result.number <= 80 &&
    result.printedText !== "More than 50!"
  ) {
    status.message = "Wrong statement printed for number greater than 50";
  } else if (
    result.number > 25 &&
    result.number <= 50 &&
    result.printedText !== "More than 25!"
  ) {
    status.message =
      "Wrong statement printed for number greater than 25 but less than 50";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
