/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isTrue;
    var isFalse;
    var isTwo;

    try { 
      isZero = countVowel("ckhjn");
      isOne = countVowel("test ttyklkl");
      isTwo = countVowel("hello");
    } catch (err) {
      window.alert(err);
    };

    try {returnResult(isZero, isOne, isTwo);} catch (err) { window.alert(err); };
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (isZero: number, isOne: number, isTwo: number) => {
      result.isZero = isZero;
      result.isOne = isOne;
      result.isTwo = isTwo;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function countVowel")) {
    status.message = "countVowel function is not created";
  } else if (result.isZero != 0 || result.isOne != 1 || result.isTwo != 2) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
