<template>
  <div ref="console" id="console" :style="customStyle">
    {{ output }}
  </div>
</template>

<script>
export default {
  name: "Console",

  props: {
    output: {
      type: String,
      default: ">>\n"
    },

    height: {
      type: String,
      default: "100%"
    }
  },

  updated() {
    const consoleEl = this.$refs["console"];
    consoleEl.scrollTop = consoleEl.scrollHeight;
  },

  computed: {
    customStyle() {
      return {
        height: this.height
      };
    }
  }
};
</script>

<style lang="less" scoped>
#console {
  padding: 10px;
  border: rgb(192, 192, 192) solid 1px;
  height: 100%;
  white-space: pre-line;
  overflow: scroll;
  color: white;
  background-color: #000;
}

/* Works on Firefox */
#console {
  scrollbar-width: thin;
  scrollbar-color: #fff rgba(0, 0, 0, 0);
}

/* Works on Chrome, Edge, and Safari */
#console::-webkit-scrollbar {
  width: 8px;
}

#console::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

#console::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0);
}
</style>
