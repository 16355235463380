<template>
  <div>
    <b-container class="my-5">
      <b-row>
        <b-col md="8">
          <router-view></router-view>
        </b-col>
        <b-col md="4">
          <b-card
            no-body
            header-bg-variant="dark"
            header-text-variant="white"
            header="Setting"
          >
            <b-list-group flush>
              <b-list-group-item to="/setting/profile">
                Profile
              </b-list-group-item>
              <b-list-group-item
                to="/setting/account"
                v-if="$store.state.user.role == 'teacher'"
              >
                Business Info
              </b-list-group-item>
              <b-list-group-item
                to="/setting/licenses"
                v-if="$store.state.user.role == 'teacher'"
              >
                Subscription
              </b-list-group-item>
              <b-list-group-item to="/setting/password">
                Password
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <p class="text-center my-3 ">
            <a class="text-muted" href="mailto:support@csplayground.io"
              >support@csplayground.io</a
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.form-container {
  width: 60%;
}
</style>
