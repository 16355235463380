import Introduction from "./Introduction.vue";
import * as IntroductionLogics from "./Introduction";

import QuickNote from "./QuickNote.vue";
import * as quickNoteLogics from "./QuickNote";

import Setup from "./Setup.vue";
import * as SetupLogics from "./Setup";

import KnowYourTools from "./KnowYourTools.vue";
import * as KnowYourToolsLogics from "./KnowYourTools";

import TalkToMe1 from "./TalkToMe1.vue";
import * as TalkToMe1Logics from "./TalkToMe1";

import TalkToMe2 from "./TalkToMe2.vue";
import * as TalkToMe2Logics from "./TalkToMe2";

import VariableIntroduction from "./VariableIntroduction.vue";
import * as variableIntroductionLogics from "./VariableIntroduction";

import VariableGet from "../foundation/VariableGet.vue";
import * as variableGetLogics from "../foundation/VariableGet";

import Exercise1 from "../foundation/Exercise1.vue";
import * as exercise1Logics from "../foundation/Exercise1";

import VariableDataType from "./VariableDataType.vue";
import * as variableDataTypeLogics from "./VariableDataType";

import Exercise2 from "../foundation/Exercise2.vue";
import * as exercise2Logics from "../foundation/Exercise2";

import Exercise3 from "../foundation/Exercise3.vue";
import * as exercise3Logics from "../foundation/Exercise3";

import VariableNaming from "./VariableNaming.vue";
import * as variableNamingLogics from "./VariableNaming";

import Exercise4 from "../foundation/Exercise4.vue";
import * as exercise4Logics from "../foundation/Exercise4";

import VariableReassign from "./VariableReassign.vue";
import * as variableReassignLogics from "./VariableReassign";

import Exercise5 from "../foundation/Exercise5.vue";
import * as exercise5Logics from "../foundation/Exercise5";

import Exercise6 from "../foundation/Exercise6.vue";
import * as exercise6Logics from "../foundation/Exercise6";

import Bmi1 from "./Bmi1.vue";
import * as Bmi1Logics from "./Bmi1";

import Bmi2 from "./Bmi2.vue";
import * as Bmi2Logics from "./Bmi2";

import Bmi3 from "./Bmi3.vue";
import * as Bmi3Logics from "./Bmi3";

import OperatorIntroduction from "./OperatorIntroduction.vue";
import * as operatorIntroductionLogics from "./OperatorIntroduction";

import Exercise7 from "../foundation/Exercise7.vue";
import * as exercise7Logics from "../foundation/Exercise7";

import Exercise8 from "../foundation/Exercise8.vue";
import * as exercise8Logics from "../foundation/Exercise8";

import OperatorCombine from "./OperatorCombine.vue";
import * as operatorCombineLogics from "./OperatorCombine";

import Exercise9 from "../foundation/Exercise9.vue";
import * as exercise9Logics from "../foundation/Exercise9";

import Exercise10 from "../foundation/Exercise10.vue";
import * as exercise10Logics from "../foundation/Exercise10";

import OperatorUpdate from "./OperatorUpdate.vue";
import * as operatorUpdateLogics from "./OperatorUpdate";

import Exercise11 from "../foundation/Exercise11.vue";
import * as exercise11Logics from "../foundation/Exercise11";

import AgeCalc from "./AgeCalc.vue";
import * as AgeCalcLogics from "./AgeCalc";

import ConditionalIntroduction from "./ConditionalIntroduction.vue";
import * as conditionalIntroductionLogics from "./ConditionalIntroduction";

import ConditionalExpression from "./ConditionalExpression.vue";
import * as conditionalExpressionLogics from "./ConditionalExpression";

import * as exercise12Logics from "../foundation/Exercise12";
import Exercise12 from "../foundation/Exercise12.vue";

import * as exercise13Logics from "../foundation/Exercise13";
import Exercise13 from "../foundation/Exercise13.vue";

import ConditionalIfElse from "./ConditionalIfElse.vue";
import * as conditionalIfElseLogics from "./ConditionalIfElse";

import ConditionalIfElseIf from "./ConditionalIfElseIf.vue";
import * as conditionalIfElseIfLogics from "./ConditionalIfElseIf";

import Exercise15 from "../foundation/Exercise15.vue";
import * as exercise15Logics from "../foundation/Exercise15";

import Exercise16 from "../foundation/Exercise16.vue";
import * as exercise16Logics from "../foundation/Exercise16";

import Bmi4 from "./Bmi4.vue";
import * as Bmi4Logics from "./Bmi4";

import GuessingGame from "./GuessingGame.vue";
import * as guessingGameLogics from "./GuessingGame";

import ConditionalCompoundAnd from "./ConditionalCompoundAnd.vue";
import * as conditionalCompoundAndLogics from "./ConditionalCompoundAnd";

import Exercise17 from "../foundation/Exercise17.vue";
import * as exercise17Logics from "../foundation/Exercise17";

import ConditionalCompoundOr from "./ConditionalCompoundOr.vue";
import * as conditionalCompoundOrLogics from "./ConditionalCompoundOr";

import Exercise18 from "../foundation/Exercise18.vue";
import * as exercise18Logics from "../foundation/Exercise18";

import Exercise19 from "../foundation/Exercise19.vue";
import * as exercise19Logics from "../foundation/Exercise19";

import ConditionalNot from "./ConditionalNot.vue";
import * as conditionalNotLogics from "./ConditionalNot";

import Exercise20 from "../foundation/Exercise20.vue";
import * as exercise20Logics from "../foundation/Exercise20";

import QuizApp from "./QuizApp.vue";
import * as quizAppLogics from "./QuizApp";

import QuizApp2 from "./QuizApp2.vue";
import * as quizApp2Logics from "./QuizApp2";

import QuizApp3 from "./QuizApp3.vue";
import * as quizApp3Logics from "./QuizApp3";

import BlocklyGameBird from "./BlocklyGameBird.vue";
import * as blocklyGameBirdLogics from "./BlocklyGameBird";

import LoopIntroduction from "./LoopIntroduction.vue";
import * as loopIntroductionLogics from "./LoopIntroduction";

import LoopVariable from "./LoopVariable.vue";
import * as loopVariableLogics from "./LoopVariable";

import Exercise21 from "../foundation/Exercise21.vue";
import * as exercise21Logics from "../foundation/Exercise21";

import Exercise22 from "../foundation/Exercise22.vue";
import * as exercise22Logics from "../foundation/Exercise22";

import Exercise23 from "../foundation/Exercise23.vue";
import * as exercise23Logics from "../foundation/Exercise23";

import BlocklyGameMaze from "./BlocklyGameMaze.vue";
import * as blocklyGameMazeLogics from "./BlocklyGameMaze";

import LoopBreak from "./LoopBreak.vue";
import * as loopBreakLogics from "./LoopBreak";

import Exercise24 from "../foundation/Exercise24.vue";
import * as exercise24Logics from "../foundation/Exercise24";

import LoopNested from "./LoopNested.vue";
import * as loopNestedLogics from "./LoopNested";

import Exercise25 from "../foundation/Exercise25.vue";
import * as exercise25Logics from "../foundation/Exercise25";

import Exercise26 from "../foundation/Exercise26.vue";
import * as exercise26Logics from "../foundation/Exercise26";

import LoopWhile from "./LoopWhile.vue";
import * as loopWhileLogics from "./LoopWhile";

import Exercise27 from "../foundation/Exercise27.vue";
import * as exercise27Logics from "../foundation/Exercise27";

import Exercise28 from "../foundation/Exercise28.vue";
import * as exercise28Logics from "../foundation/Exercise28";

import BlocklyGameTurtle from "./BlocklyGameTurtle.vue";
import * as blocklyGameTurtleLogics from "./BlocklyGameTurtle";

import ListIntroduction from "./ListIntroduction.vue";
import * as listIntroductionLogics from "./ListIntroduction";

import ListGet from "./ListGet.vue";
import * as listGetLogics from "./ListGet";

import ListSet from "./ListSet.vue";
import * as listSetLogics from "./ListSet";

import Exercise29 from "../foundation/Exercise29.vue";
import * as exercise29Logics from "../foundation/Exercise29";

import Exercise30 from "../foundation/Exercise30.vue";
import * as exercise30Logics from "../foundation/Exercise30";

import ListForLoop from "./ListForLoop.vue";
import * as listForLoopLogics from "./ListForLoop";

import ListForEachLoop from "./ListForEachLoop.vue";
import * as listForEachLoopLogics from "./ListForEachLoop";

import Exercise31 from "../foundation/Exercise31.vue";
import * as exercise31Logics from "../foundation/Exercise31";

import Exercise32 from "../foundation/Exercise32.vue";
import * as exercise32Logics from "../foundation/Exercise32";

import Exercise33 from "../foundation/Exercise33.vue";
import * as exercise33Logics from "../foundation/Exercise33";

import FunctionIntroduction from "./FunctionIntroduction.vue";
import * as functionIntroductionLogics from "./FunctionIntroduction";

import FunctionParam from "./FunctionParam.vue";
import * as functionParamLogics from "./FunctionParam";

import Exercise34 from "../foundation/Exercise34.vue";
import * as exercise34Logics from "../foundation/Exercise34";

import FunctionReturn from "./FunctionReturn.vue";
import * as functionReturnLogics from "./FunctionReturn";

import Exercise35 from "../foundation/Exercise35.vue";
import * as exercise35Logics from "../foundation/Exercise35";

import Exercise36 from "../foundation/Exercise36.vue";
import * as exercise36Logics from "../foundation/Exercise36";

import Exercise37 from "../foundation/Exercise37.vue";
import * as exercise37Logics from "../foundation/Exercise37";

import Exercise38 from "../foundation/Exercise38.vue";
import * as exercise38Logics from "../foundation/Exercise38";

import BlocklyGameMusic from "./BlocklyGameMusic.vue";
import * as blocklyGameMusicLogics from "./BlocklyGameMusic";

const curriculum = new Map();

curriculum.set("AtYLNfrxCEDA1uwJjJr7", {
  content: Introduction,
  logics: IntroductionLogics
});

curriculum.set("yrky3YpnNcLU4dbaq0T3", {
  content: QuickNote,
  logics: quickNoteLogics
});

curriculum.set("6hh2foteFK4vKNHk24xx", {
  content: Setup,
  logics: SetupLogics
});

curriculum.set("pfoYbsOzfzGn9ntinefL", {
  content: KnowYourTools,
  logics: KnowYourToolsLogics
});

curriculum.set("aBiEOeceJh75x2qreCXn", {
  content: TalkToMe1,
  logics: TalkToMe1Logics
});

curriculum.set("UHA6lvqbOBRpblyF240Z", {
  content: TalkToMe2,
  logics: TalkToMe2Logics
});

curriculum.set("9KTx0H7FXyFHESowC7E5", {
  content: VariableIntroduction,
  logics: variableIntroductionLogics
});

curriculum.set("rdojzRII4s2vgsMQrxtV", {
  content: VariableGet,
  logics: variableGetLogics
});

curriculum.set("OuYabRX5o9YxoGSrVtNQ", {
  content: Exercise1,
  logics: exercise1Logics
});

curriculum.set("zdBJB3EzPoHfPDvAS7im", {
  content: VariableDataType,
  logics: variableDataTypeLogics
});

curriculum.set("jEd0nAyxVBdyz4tK2jHv", {
  content: Exercise2,
  logics: exercise2Logics
});

curriculum.set("VFXX5coszIfmyiKchkcT", {
  content: Exercise3,
  logics: exercise3Logics
});

curriculum.set("RTbFQIRmFQHIDfQ34CGc", {
  content: VariableNaming,
  logics: variableNamingLogics
});

curriculum.set("CqzdNgavznwOq4xDG1Af", {
  content: Exercise4,
  logics: exercise4Logics
});

curriculum.set("ZXdMttr2SboPd8XtpxbX", {
  content: VariableReassign,
  logics: variableReassignLogics
});

curriculum.set("l7q2V73JI94F7tZs4jgm", {
  content: Exercise5,
  logics: exercise5Logics
});

curriculum.set("JY0uIBNYXANA5TBX6VLo", {
  content: Exercise6,
  logics: exercise6Logics
});

curriculum.set("qNWc6W7sRO0KpEayLT5c", {
  content: Bmi1,
  logics: Bmi1Logics
});

curriculum.set("3cOLWWMZ1ehhurjoLG4X", {
  content: Bmi2,
  logics: Bmi2Logics
});

curriculum.set("7amxW2a70JqiaSZmrXIC", {
  content: Bmi3,
  logics: Bmi3Logics
});

curriculum.set("c6ruyZDN8wEzuEpWlRiy", {
  content: OperatorIntroduction,
  logics: operatorIntroductionLogics
});

curriculum.set("EGIg0TWoTajXnK7XQdbn", {
  content: Exercise7,
  logics: exercise7Logics
});

curriculum.set("kOlp4HMqJ41GxyhOttCW", {
  content: Exercise8,
  logics: exercise8Logics
});

curriculum.set("PQbZksJ3cPsJR7fmcIJa", {
  content: OperatorCombine,
  logics: operatorCombineLogics
});

curriculum.set("U8ZtFgMWF4xJhwMqbBZr", {
  content: Exercise9,
  logics: exercise9Logics
});

curriculum.set("UMVYlyBPZDL8bKmXPvks", {
  content: Exercise10,
  logics: exercise10Logics
});

curriculum.set("wgv7DDDkBKK9x06E6tBY", {
  content: OperatorUpdate,
  logics: operatorUpdateLogics
});

curriculum.set("ZcUdZhV7vGZdXSSskKSt", {
  content: Exercise11,
  logics: exercise11Logics
});

curriculum.set("ckhOHvFe4szQlkuCobbl", {
  content: AgeCalc,
  logics: AgeCalcLogics
});

curriculum.set("cKhqjgvDPoh57Keo3b4U", {
  content: ConditionalIntroduction,
  logics: conditionalIntroductionLogics
});

curriculum.set("cKhqjgvDPoh57Keo3b4U", {
  content: ConditionalIntroduction,
  logics: conditionalIntroductionLogics
});

curriculum.set("PjHK0jvSGoiZODeLBqu8", {
  content: ConditionalExpression,
  logics: conditionalExpressionLogics
});

curriculum.set("AJG6GtjGk8BHwiXrZKdQ", {
  content: Exercise12,
  logics: exercise12Logics
});

curriculum.set("h9k8Vv7KJiwytLnJZhQ6", {
  content: Exercise13,
  logics: exercise13Logics
});

curriculum.set("orMKNzDbyWZ54eKz5y20", {
  content: ConditionalIfElse,
  logics: conditionalIfElseLogics
});

curriculum.set("yOHsflNjzqfs9ZCUVJS1", {
  content: ConditionalIfElseIf,
  logics: conditionalIfElseIfLogics
});

curriculum.set("xFYAjv3FEdGLfstQH4bc", {
  content: Exercise15,
  logics: exercise15Logics
});

curriculum.set("7G9rLdtx4PpxrpjS9gT6", {
  content: Exercise16,
  logics: exercise16Logics
});

curriculum.set("jle03y7NCV9HOL1GMVv7", {
  content: Bmi4,
  logics: Bmi4Logics
});

curriculum.set("uO9V1mp9f6pEFl6sRJf2", {
  content: GuessingGame,
  logics: guessingGameLogics
});

curriculum.set("jxEB61u2xvuRORnUQseq", {
  content: ConditionalCompoundAnd,
  logics: conditionalCompoundAndLogics
});

curriculum.set("jKzvZD3bqx2Y6OnFsKUH", {
  content: Exercise17,
  logics: exercise17Logics
});

curriculum.set("UjPYEkRxCwrkRlqI4ykp", {
  content: ConditionalCompoundOr,
  logics: conditionalCompoundOrLogics
});

curriculum.set("XZnQOt0DtHI3DHqcTmzk", {
  content: Exercise18,
  logics: exercise18Logics
});

curriculum.set("FD4PM9e2M60qVwoZ44hI", {
  content: Exercise19,
  logics: exercise19Logics
});

curriculum.set("DrQmRIjD7WL0gKOME91u", {
  content: ConditionalNot,
  logics: conditionalNotLogics
});

curriculum.set("XUjtleq0pMrsYV32sy9q", {
  content: Exercise20,
  logics: exercise20Logics
});

curriculum.set("NqV2dQHb6373dGJdNyft", {
  content: QuizApp,
  logics: quizAppLogics
});

curriculum.set("o0Vr4gFo3FFy8cYumdZU", {
  content: QuizApp2,
  logics: quizApp2Logics
});

curriculum.set("XRCjUBHDP6toHCPFyF9r", {
  content: QuizApp3,
  logics: quizApp3Logics
});

curriculum.set("qPIjCngLTRdK78H7mJzR", {
  content: BlocklyGameBird,
  logics: blocklyGameBirdLogics
});

curriculum.set("zVdVBdzUUYyvVrXMiopy", {
  content: LoopIntroduction,
  logics: loopIntroductionLogics
});

curriculum.set("fdlBC6k9S6MlEZ5RSy3F", {
  content: LoopVariable,
  logics: loopVariableLogics
});

curriculum.set("UXUoe1a9L7F5KkSTifli", {
  content: Exercise21,
  logics: exercise21Logics
});

curriculum.set("961KO33kOebQQzgyAMIf", {
  content: Exercise22,
  logics: exercise22Logics
});

curriculum.set("4c7SkegEo2AncRCGipoZ", {
  content: Exercise23,
  logics: exercise23Logics
});

curriculum.set("Z8tVO0gSh5olIgNQ5wDo", {
  content: BlocklyGameMaze,
  logics: blocklyGameMazeLogics
});

curriculum.set("sOhik0Ddoi1D5IE2xbAh", {
  content: LoopBreak,
  logics: loopBreakLogics
});

curriculum.set("rjrIwPgqR6dYxcz04ROz", {
  content: Exercise24,
  logics: exercise24Logics
});

curriculum.set("5hkhrbq9ykgMeT266vyt", {
  content: LoopNested,
  logics: loopNestedLogics
});

curriculum.set("Rs1li3gl9nff8T7k3T94", {
  content: Exercise25,
  logics: exercise25Logics
});

curriculum.set("jeAcAQRwNVz7Wev43JV3", {
  content: Exercise26,
  logics: exercise26Logics
});

curriculum.set("qkJbxDbBtdjyX72BO553", {
  content: LoopWhile,
  logics: loopWhileLogics
});

curriculum.set("c5ubpHCaGFeA4VNaW5lO", {
  content: Exercise27,
  logics: exercise27Logics
});

curriculum.set("YpVjg2mU0C3BW89vSqU9", {
  content: Exercise28,
  logics: exercise28Logics
});

curriculum.set("ZYS5p3EbCiyXGxFYTsnr", {
  content: BlocklyGameTurtle,
  logics: blocklyGameTurtleLogics
});

curriculum.set("Ulmd2zM2Z4CH797LJsCr", {
  content: ListIntroduction,
  logics: listIntroductionLogics
});

curriculum.set("2SaLqBPTdanT1OjNDRLN", {
  content: ListGet,
  logics: listGetLogics
});

curriculum.set("DZPEeEMdtoawJojAcKxN", {
  content: ListSet,
  logics: listSetLogics
});

curriculum.set("lSy4mlwNRcgBnnBEqmRY", {
  content: Exercise29,
  logics: exercise29Logics
});

curriculum.set("h93nAQ9M3DUA5Vlue8wg", {
  content: Exercise30,
  logics: exercise30Logics
});

curriculum.set("7MLw7mrQkIbUhdZhfswP", {
  content: ListForLoop,
  logics: listForLoopLogics
});

curriculum.set("H3kDlQIlafSavBP3PGiA", {
  content: ListForEachLoop,
  logics: listForEachLoopLogics
});

curriculum.set("lKVsoo6gAYBAkR23l6Sj", {
  content: Exercise31,
  logics: exercise31Logics
});

curriculum.set("edEGrHzc9C8C1Em4M2Qk", {
  content: Exercise32,
  logics: exercise32Logics
});

curriculum.set("mKt42hkwwc2bxPAudECn", {
  content: Exercise33,
  logics: exercise33Logics
});

curriculum.set("jVCc0ldsPHheDySihS7b", {
  content: FunctionIntroduction,
  logics: functionIntroductionLogics
});

curriculum.set("vXyxsZQJDlB89XHS1V1q", {
  content: FunctionParam,
  logics: functionParamLogics
});

curriculum.set("bV4i5YBbDLM5YKkSdSE0", {
  content: Exercise34,
  logics: exercise34Logics
});

curriculum.set("2amHcH8Qn4De2NVwORX3", {
  content: FunctionReturn,
  logics: functionReturnLogics
});

curriculum.set("NRyf74NteGXZTpN5aWvN", {
  content: Exercise35,
  logics: exercise35Logics
});

curriculum.set("0bqBNKubWs1nXsEi3hBx", {
  content: Exercise36,
  logics: exercise36Logics
});

curriculum.set("MeYVDqUB5BJrieXW0VJU", {
  content: Exercise37,
  logics: exercise37Logics
});

curriculum.set("9dXnqwifOAFrWhwizNKy", {
  content: Exercise38,
  logics: exercise38Logics
});

curriculum.set("MGruNfyBsmaTu9Lc8vBu", {
  content: BlocklyGameMusic,
  logics: blocklyGameMusicLogics
});

export default curriculum;
