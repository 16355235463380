/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `random_numbers = [2, 55, 13, 89, 26, 90, 54, 22]

# Write your code only below this line
`;

export const seedTest = `
assert random_numbers == [2, 55, 13, 89, 26, 90, 54, 22], "Do not change default code"
`;
export const testCases = `
assert total == 351, "Wrong total value"`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const userCodeStartingIndex = 3;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);
  assert(/for[\s\S]+:/.test(code), "Use a for loop statement");
  assert(/print\(\s*total\s*\)/.test(code), "Print the value of total");

  window.console = oldConsole;
  return true;
}
