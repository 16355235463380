/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `random_number = 20
result = None

# Write your code only below this line
`;

export const seedTest = ``;
export const testCases = `
if random_number > 25:
  assert result, "result set to wrong value when random_number is more than 25"
else:
  assert not result, "result set to wrong value when random_number is more than 25"
`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const userCodeStartingIndex = 4;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // Seed testing
  Sk.importMainWithBody("", false, seedCode + seedTest);
  assert(/random_number = 20/.test(seedCode), "Do not change default code");
  assert(/result = None/.test(seedCode), "Do not change default code");

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);
  assert(/if[\s\S]+:[\s\S]+else:/.test(code), "Use an if..else statement");

  // Other case testing
  const otherCase = `random_number = 50\nresult = None\n`;
  Sk.importMainWithBody("", false, otherCase + userCode + testCases);

  window.console = oldConsole;
  return true;
}
