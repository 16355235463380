import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";

// Boostrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Loading indicator
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading, {
  color: "orange"
});

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faPlusCircle,
  faTrash,
  faEllipsisV,
  faClone,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faPlusCircle,
  faEdit,
  faTrash,
  faEllipsisV,
  faClone,
  faChevronDown,
  faChevronUp
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.$analytics = firebase.analytics();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
