<template>
  <div>
    <b-container>
      <b-row class="mb-3">
        <b-col>
          <h2>My Account</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="bg-white p-3">
            <div class="form-container">
              <b-form @submit.prevent="updateOrganisation">
                <b-form-group label="Business name">
                  <b-form-input v-model="organisation.name" trim></b-form-input>
                </b-form-group>
                <b-form-group label="Contact">
                  <b-form-input
                    v-model="organisation.contact"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Email">
                  <b-form-input
                    v-model="organisation.email"
                    type="email"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Address">
                  <b-form-input
                    v-model="organisation.addressOne"
                    class="mb-2"
                    trim
                  ></b-form-input>
                  <b-form-input
                    v-model="organisation.addressTwo"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-button variant="dark" type="submit" :disabled="isSaving">
                  <b-spinner small v-if="isSaving"></b-spinner>
                  {{ isSaving ? "Loading..." : "Save" }}
                </b-button>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { organisationServices } from "@/services";

export default {
  name: "AccountSetting",

  data() {
    return {
      organisation: {},
      isSaving: false
    };
  },

  async created() {
    const loader = this.$loading.show();
    await this.getOrganisation();
    loader.hide();
  },

  methods: {
    getOrganisation() {
      return organisationServices
        .getOrganisation(this.$store.state.organisation.id)
        .then(organisation => {
          this.organisation = organisation;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    updateOrganisation() {
      this.isSaving = true;

      const updates = {
        name: this.organisation.name ?? "",
        contact: this.organisation.contact ?? "",
        email: this.organisation.email ?? "",
        addressOne: this.organisation.addressOne ?? "",
        addressTwo: this.organisation.addressTwo ?? ""
      };

      return organisationServices
        .updateOrganisation(this.$store.state.organisation.id, updates)
        .then(() => {
          this.isSaving = false;
          this.$bvToast.toast(`Setting saved successfully`, {
            title: "Account Setting",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: true
          });
        })
        .catch(error => {
          alert(error.message);
          this.isSaving = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.form-container {
  width: 60%;
}
</style>
