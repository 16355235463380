/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isFive;
    var isTen;
    var isSeventeen;
    var isThirtyFive;
    var isSixHundred;
    var isEightFiveOne;

    try { 
      window.alert = function() {};
      isFive = toWords(5);
      isTen = toWords(10);
      isSeventeen = toWords(17);
      isThirtyFive = toWords(35);
      isSixHundred = toWords(600);
      isEightFiveOne = toWords(851);
    } catch (err) {
      window.alert(err);
    };

    try {returnResult(isFive, isTen, isSeventeen, isThirtyFive, isSixHundred, isEightFiveOne);} catch (err) { window.alert(err); };
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      isFive: number,
      isTen: number,
      isSeventeen: number,
      isThirtyFive: number,
      isSixHundred: number,
      isEightFiveOne: number
    ) => {
      result.isFive = isFive;
      result.isTen = isTen;
      result.isSeventeen = isSeventeen;
      result.isThirtyFive = isThirtyFive;
      result.isSixHundred = isSixHundred;
      result.isEightFiveOne = isEightFiveOne;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function toWords")) {
    status.message = "toWords function is not created";
  } else if (
    result.isFive != "five" ||
    result.isTen != "ten" ||
    result.isSeventeen != "seventeen" ||
    result.isThirtyFive != "thirty-five" ||
    result.isSixHundred != "six hundred" ||
    result.isEightFiveOne != "eight hundred and fifty-one"
  ) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
