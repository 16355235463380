/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.printedText !== "505152535455565758596061626364656667686970") {
    status.message = "Loop is not executed correctly";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
