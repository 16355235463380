<template>
  <div>
    <h2>Object Introduction</h2>

    <p>
      An object can hold multiple data with <code>properties</code>. Property is
      a key-value pair where key is a string (property name) and value can hold
      any type of data. A key is used to identify the position where the value
      is stored.
    </p>

    <p>
      An object is created with the curly brackets <code>{ }</code>. An object
      can also be declared without any property
    </p>

    <div id="editor-object-intro" style="height: 200px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare an object named <code>someObject</code> with a key
            <code>year</code> and value of 2021
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let emptyProject = {};

let someObject = {
  someString: "Hello World!",
  someNum: 42,
  someBoolean: true,
  someArray: [],
  someFunction: function doSomething() {},
  someObject: {}
};
`

export default {
  mounted() {
    this.initEditor("editor-object-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
