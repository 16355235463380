import Introduction from "./Introduction.vue";
import * as IntroductionLogics from "./Introduction";

import Statement from "./Statement.vue";
import * as StatementLogics from "./Statement";

import Comment from "./Comment.vue";
import * as CommentLogics from "./Comment";

import Print from "./Print.vue";
import * as PrintLogics from "./Print";

import Variable from "./Variable.vue";
import * as VariableLogics from "./Variable";

import VariableNaming from "./VariableNaming.vue";
import * as VariableNamingLogics from "./VariableNaming";

import VariableDataType from "./VariableDataType.vue";
import * as VariableDataTypeLogics from "./VariableDataType";

import VariableAssign from "./VariableAssign.vue";
import * as VariableAssignLogics from "./VariableAssign";

import VariableInput from "./VariableInput.vue";
import * as VariableInputLogics from "./VariableInput";

import Exercise1 from "./Exercise1.vue";
import * as Exercise1Logics from "./Exercise1";

import Exercise2 from "./Exercise2.vue";
import * as Exercise2Logics from "./Exercise2";

import VariableCasting from "./VariableCasting.vue";
import * as VariableCastingLogics from "./VariableCasting";

import OperatorIntroduction from "./OperatorIntroduction.vue";
import * as OperatorIntroductionLogics from "./OperatorIntroduction";

import OperatorMath from "./OperatorMath.vue";
import * as OperatorMathLogics from "./OperatorMath";

import OperatorPrecedence from "./OperatorPrecedence.vue";
import * as OperatorPrecedenceLogics from "./OperatorPrecedence";

import OperatorCalculation from "./OperatorCalculation.vue";
import * as OperatorCalculationLogics from "./OperatorCalculation";

import Exercise3 from "./Exercise3.vue";
import * as Exercise3Logics from "./Exercise3";

import OperatorAssign from "./OperatorAssign.vue";
import * as OperatorAssignLogics from "./OperatorAssign";

import OperatorModule from "./OperatorModule.vue";
import * as OperatorModuleLogics from "./OperatorModule";

import OperatorString from "./OperatorString.vue";
import * as OperatorStringLogics from "./OperatorString";

import ListIntroduction from "./ListIntroduction.vue";
import * as ListIntroductionLogics from "./ListIntroduction";

import ListGetSet from "./ListGetSet.vue";
import * as ListGetSetLogics from "./ListGetSet";

import ListAdd from "./ListAdd.vue";
import * as ListAddLogics from "./ListAdd";

import ListDelete from "./ListDelete.vue";
import * as ListDeleteLogics from "./ListDelete";

import ListMethod from "./ListMethod.vue";
import * as ListMethodLogics from "./ListMethod";

import ListSlicing from "./ListSlicing.vue";
import * as ListSlicingLogics from "./ListSlicing";

import Exercise4 from "./Exercise4.vue";
import * as Exercise4Logics from "./Exercise4";

import ConditionalIntroduction from "./ConditionalIntroduction.vue";
import * as ConditionalIntroductionLogics from "./ConditionalIntroduction";

import ConditionalExpression from "./ConditionalExpression.vue";
import * as ConditionalExpressionLogics from "./ConditionalExpression";

import ConditionalIf from "./ConditionalIf.vue";
import * as ConditionalIfLogics from "./ConditionalIf";

import ConditionalIfElse from "./ConditionalIfElse.vue";
import * as ConditionalIfElseLogics from "./ConditionalIfElse";

import ConditionalIfElif from "./ConditionalIfElif.vue";
import * as ConditionalIfElifLogics from "./ConditionalIfElif";

import ConditionalCompound from "./ConditionalCompound.vue";
import * as ConditionalCompoundLogics from "./ConditionalCompound";

import Exercise5 from "./Exercise5.vue";
import * as Exercise5Logics from "./Exercise5";

import LoopIntroduction from "./LoopIntroduction.vue";
import * as LoopIntroductionLogics from "./LoopIntroduction";

import LoopFor from "./LoopFor.vue";
import * as LoopForLogics from "./LoopFor";

import LoopForRange from "./LoopForRange.vue";
import * as LoopForRangeLogics from "./LoopForRange";

import Exercise6 from "./Exercise6.vue";
import * as Exercise6Logics from "./Exercise6";

import Exercise7 from "./Exercise7.vue";
import * as Exercise7Logics from "./Exercise7";

import LoopBreak from "./LoopBreak.vue";
import * as LoopBreakLogics from "./LoopBreak";

import LoopContinue from "./LoopContinue.vue";
import * as LoopContinueLogics from "./LoopContinue";

import LoopNested from "./LoopNested.vue";
import * as LoopNestedLogics from "./LoopNested";

import Exercise8 from "./Exercise8.vue";
import * as Exercise8Logics from "./Exercise8";

import LoopWhile from "./LoopWhile.vue";
import * as LoopWhileLogics from "./LoopWhile";

import Exercise9 from "./Exercise9.vue";
import * as Exercise9Logics from "./Exercise9";

import FunctionIntroduction from "./FunctionIntroduction.vue";
import * as FunctionIntroductionLogics from "./FunctionIntroduction";

import FunctionNoParam from "./FunctionNoParam.vue";
import * as FunctionNoParamLogics from "./FunctionNoParam";

import FunctionParam from "./FunctionParam.vue";
import * as FunctionParamLogics from "./FunctionParam";

import Exercise10 from "./Exercise10.vue";
import * as Exercise10Logics from "./Exercise10";

import Exercise11 from "./Exercise11.vue";
import * as Exercise11Logics from "./Exercise11";

import FunctionGlobal from "./FunctionGlobal.vue";
import * as FunctionGlobalLogics from "./FunctionGlobal";

import DictionaryIntroduction from "./DictionaryIntroduction.vue";
import * as DictionaryIntroductionLogics from "./DictionaryIntroduction";

import DictionaryGet from "./DictionaryGet.vue";
import * as DictionaryGetLogics from "./DictionaryGet";

import DictionarySet from "./DictionarySet.vue";
import * as DictionarySetLogics from "./DictionarySet";

import DictionaryDelete from "./DictionaryDelete.vue";
import * as DictionaryDeleteLogics from "./DictionaryDelete";

const curriculum = new Map();

curriculum.set("vvKtXXB4W0YMOQXbE9xH", {
  content: Introduction,
  logics: IntroductionLogics
});

curriculum.set("y0fa3vH4ebCXqmDRImhj", {
  content: Statement,
  logics: StatementLogics
});

curriculum.set("rzuruShcbD7ITF0OqnZT", {
  content: Comment,
  logics: CommentLogics
});

curriculum.set("2To2kuRCKqxU9eFtFOID", {
  content: Print,
  logics: PrintLogics
});

curriculum.set("lTAxj9jZtINwMp3XXY7k", {
  content: Variable,
  logics: VariableLogics
});

curriculum.set("DcoRU3L01Qm6GTlb7ONA", {
  content: VariableNaming,
  logics: VariableNamingLogics
});

curriculum.set("FTz3Xdy3uUaS0UzmhzF3", {
  content: VariableDataType,
  logics: VariableDataTypeLogics
});

curriculum.set("CaJ3y4aLKAV73cWtRkX2", {
  content: VariableAssign,
  logics: VariableAssignLogics
});

curriculum.set("hHOeYRYdHiYLRNaUOSrk", {
  content: VariableInput,
  logics: VariableInputLogics
});

curriculum.set("JPq010yaecaZV1yl4lM6", {
  content: Exercise1,
  logics: Exercise1Logics
});

curriculum.set("oi4AyL6JTGxF1UYQS5KT", {
  content: Exercise2,
  logics: Exercise2Logics
});

curriculum.set("XbxeqTE1fEjkXt8odNVk", {
  content: VariableCasting,
  logics: VariableCastingLogics
});

curriculum.set("JDmUQNI6xfDH9rXIzS07", {
  content: OperatorIntroduction,
  logics: OperatorIntroductionLogics
});

curriculum.set("vuYPdhKfHhH83bI4TDOZ", {
  content: OperatorMath,
  logics: OperatorMathLogics
});

curriculum.set("gpz5OuERmXgwvq571HPz", {
  content: OperatorPrecedence,
  logics: OperatorPrecedenceLogics
});

curriculum.set("uxIP35uIDmhpj4P2ssfP", {
  content: OperatorCalculation,
  logics: OperatorCalculationLogics
});

curriculum.set("hXedQ9Vl40pN4mNKg3q0", {
  content: Exercise3,
  logics: Exercise3Logics
});

curriculum.set("LHwS9NvvKMMfawxH6ULk", {
  content: OperatorAssign,
  logics: OperatorAssignLogics
});

curriculum.set("PZQm2EUyUIv5gSbya0DD", {
  content: OperatorModule,
  logics: OperatorModuleLogics
});

curriculum.set("lQdY1h18u9qCqixbqzPV", {
  content: OperatorString,
  logics: OperatorStringLogics
});

curriculum.set("G95Ie5uc6k5sxWT0c48X", {
  content: ListIntroduction,
  logics: ListIntroductionLogics
});

curriculum.set("BogWv0NpCrN9ibQaHvJ5", {
  content: ListGetSet,
  logics: ListGetSetLogics
});

curriculum.set("9b9o0t4MNXWj8dOLdFPn", {
  content: ListAdd,
  logics: ListAddLogics
});

curriculum.set("L8tMCPux3Pwr4TLzAdpz", {
  content: ListDelete,
  logics: ListDeleteLogics
});

curriculum.set("W65TctA1CaI9Raf8XxNi", {
  content: ListMethod,
  logics: ListMethodLogics
});

curriculum.set("hz8WyPyu2EGdf666dUfA", {
  content: ListSlicing,
  logics: ListSlicingLogics
});

curriculum.set("1TF2IhMktVZVBoCUJlOB", {
  content: Exercise4,
  logics: Exercise4Logics
});

curriculum.set("NhGnPWnia3KO46b3ojJB", {
  content: ConditionalIntroduction,
  logics: ConditionalIntroductionLogics
});

curriculum.set("99W27fTWepAeHrBxE3Ba", {
  content: ConditionalExpression,
  logics: ConditionalExpressionLogics
});

curriculum.set("mnQTDNjlzCCQBv0O9iML", {
  content: ConditionalIf,
  logics: ConditionalIfLogics
});

curriculum.set("abqEGRRt4VJmvJquBoPK", {
  content: ConditionalIfElse,
  logics: ConditionalIfElseLogics
});

curriculum.set("cbsDSO0UTokDANkQ1iYH", {
  content: ConditionalIfElif,
  logics: ConditionalIfElifLogics
});

curriculum.set("n5QKEPPvIIHCz1Zt0yUJ", {
  content: ConditionalCompound,
  logics: ConditionalCompoundLogics
});

curriculum.set("RWUnUKjkPkSNG69Vsva9", {
  content: Exercise5,
  logics: Exercise5Logics
});

curriculum.set("9rQqiAlkeQKDz8kNRly4", {
  content: LoopIntroduction,
  logics: LoopIntroductionLogics
});

curriculum.set("u03GeaLFJYB0oSg5Kj9G", {
  content: LoopFor,
  logics: LoopForLogics
});

curriculum.set("gWvigdWhlg9bb15pOsUN", {
  content: LoopForRange,
  logics: LoopForRangeLogics
});

curriculum.set("VhKOU2Z2S4cEauAJYito", {
  content: Exercise6,
  logics: Exercise6Logics
});

curriculum.set("gOxce8wnQeEp2NxE6Suh", {
  content: Exercise7,
  logics: Exercise7Logics
});

curriculum.set("8ccD3fRSFwhXP7SNM0zJ", {
  content: LoopBreak,
  logics: LoopBreakLogics
});

curriculum.set("m3hiPAKHZAirKyH3s9be", {
  content: LoopContinue,
  logics: LoopContinueLogics
});

curriculum.set("BW9OMlWSuhn4SyrY8vLn", {
  content: LoopNested,
  logics: LoopNestedLogics
});

curriculum.set("Yiup8AIeNh9nVhFaPJKp", {
  content: Exercise8,
  logics: Exercise8Logics
});

curriculum.set("EzxpHI2Xg2igGAuX0oVC", {
  content: LoopWhile,
  logics: LoopWhileLogics
});

curriculum.set("iJ0VMXWdZ3PqabQwxPTj", {
  content: Exercise9,
  logics: Exercise9Logics
});

curriculum.set("MzlRt9GjudXnH5N2MJ6R", {
  content: FunctionIntroduction,
  logics: FunctionIntroductionLogics
});

curriculum.set("w7o9GizgtqFtXsQuWBjj", {
  content: FunctionNoParam,
  logics: FunctionNoParamLogics
});

curriculum.set("6CHMWSoA7PiRSLukeCvT", {
  content: FunctionParam,
  logics: FunctionParamLogics
});

curriculum.set("jnLNhiGS6Fi28ACl0ZP4", {
  content: Exercise10,
  logics: Exercise10Logics
});

curriculum.set("6DRzmIVn04kwUYo86kXs", {
  content: Exercise11,
  logics: Exercise11Logics
});

curriculum.set("vs3hBvbxZab5t4NYbYWu", {
  content: FunctionGlobal,
  logics: FunctionGlobalLogics
});

curriculum.set("6WqggMAPqd7eu2sTuybT", {
  content: DictionaryIntroduction,
  logics: DictionaryIntroductionLogics
});

curriculum.set("S6MEjXzj9KvGh03lQ22C", {
  content: DictionaryGet,
  logics: DictionaryGetLogics
});

curriculum.set("7JHu2MYJdHC1kGLAn89l", {
  content: DictionarySet,
  logics: DictionarySetLogics
});

curriculum.set("zuS1Em6wt5j9pnMhGNtB", {
  content: DictionaryDelete,
  logics: DictionaryDeleteLogics
});

export default curriculum;
