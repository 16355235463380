<template>
  <div>
    <h2>Compound Conditionals</h2>

    <p>
      Multiple condtions can be joined using logical operators.
    </p>

    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Operator</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>and</b-th>
          <b-td
            >Logical AND operator. Returns <code>true</code> only if both
            expressions evaluates to <code>true</code></b-td
          >
        </b-tr>
        <b-tr>
          <b-th>or</b-th>
          <b-td
            >Logical OR operator. Returns <code>true</code> if any expressions
            evaluates to <code>true</code></b-td
          >
        </b-tr>
        <b-tr>
          <b-th>not</b-th>
          <b-td>Logical NOT operator. Returns the inverse value</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4 class="mt-3">and operator</h4>
    <div id="editor-and" style="height: 130px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          False
          <br />
          False
          <br />
          False
          <br />
          True
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">or operator</h4>
    <div id="editor-or" style="height: 130px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          False
          <br />
          True
          <br />
          True
          <br />
          True
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">not operator</h4>
    <div id="editor-not" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          True
          <br />
          False
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use an if..else statement
            <ul>
              <li>
                if <code>number</code> greater than 10 and
                <code>animal</code> equals to "cat", set <code>result</code> to
                True
              </li>
              <li>Else, set <code>result</code> to False</li>
            </ul>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 50

print(number > 100 and number > 100)
print(number > 0 and number > 100)
print(number > 100 and number > 0)
print(number > 0 and number > 0)`;

const code2 = `number = 50

print(number > 100 or number > 100)
print(number > 0 or number > 100)
print(number > 100 or number > 0)
print(number > 0 or number > 0)`;

const code3 = `number = 50

print(not(number > 100))
print(not(number > 0))`;

export default {
  mounted() {
    this.initEditor("editor-and", code);
    this.initEditor("editor-or", code2);
    this.initEditor("editor-not", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
