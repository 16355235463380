/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var kmZero;
    var kmThree;
    var cmZero;
    var cmThreeHundred;
    try { 
      kmZero = convertMeter(0, "km");
      kmThree = convertMeter(3000, "km");
      cmZero = convertMeter(0, "cm");
      cmThreeHundred = convertMeter(3, "cm");
    } catch (err) {
      window.alert(err);
    };
    try {returnResult(kmZero, kmThree, cmZero, cmThreeHundred);} catch (err) { window.alert(err); };
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      kmZero: number,
      kmThree: number,
      cmZero: number,
      cmThreeHundred: number
    ) => {
      result.kmZero = kmZero;
      result.kmThree = kmThree;
      result.cmZero = cmZero;
      result.cmThreeHundred = cmThreeHundred;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function convertMeter")) {
    status.message = "convertMeter function is not created";
  } else if (
    result.kmZero != 0 ||
    result.kmThree != 3 ||
    result.cmZero != 0 ||
    result.cmThreeHundred != 300
  ) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
