/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = ``;
export const seedTest = ``;
export const testCases = `
assert isinstance(number, int), "number should be int data type"
assert isinstance(number_string, str), "number_string should be str data type"
assert number == 5, "number should equals to 5"
assert number_string == "5", "number_string should equal to a string of '5'"`;

export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  window.console = oldConsole;
  return true;
}
