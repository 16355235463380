<template>
  <div>
    <h2>If Else Statement</h2>

    <p>
      Use the else statement to specify a block of code to be executed if the
      condition is <code>False</code>.
    </p>
    <p>
      The else statement will execute as the condition number > 50 evaluates to
      <code>False</code>
    </p>

    <div id="editor-if" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          number is less than 50
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Write a if statement to check whether <code>random_number</code> is
            greater than 25. If true, set <code>result</code> to
            <code>True</code>, else set to <code>False</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 20
if number > 50:
  print("number is more than 50")
else:
  print("number is less than 50")
`;

const code2 = `number = 20
if number > 50:
  print("number is more than 50")
else:
  print("number is less than 50")
`;

const code3 = `number = 20
if number > 10:
print("number is more than 10")
`;

export default {
  mounted() {
    this.initEditor("editor-if", code);
    this.initEditor("editor-if-error", code2);
    this.initEditor("editor-if-error-2", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
