<template>
  <div>
    <h2>Exercise 1</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Insert only <code>id</code>, <code>title</code> and
            <code>genre</code> into the <code>games</code> table so that the
            table contain data as below
          </li>
        </ol>
      </b-card-text>
    </b-card>
    <b-table-simple
      class="text-center mt-3"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            title
          </b-th>
          <b-th>
            genre
          </b-th>
          <b-th>
            developer
          </b-th>
          <b-th>
            release_year
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Among Us</b-td>
          <b-td>multiplayer</b-td>
          <b-td>InnerSloth LLC</b-td>
          <b-td>2018</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Genshin Impact</b-td>
          <b-td>mmorpg</b-td>
          <b-td></b-td>
          <b-td></b-td>
        </b-tr>
        <b-tr>
          <b-td>2</b-td>
          <b-td>Pokemon Home</b-td>
          <b-td>single player</b-td>
          <b-td></b-td>
          <b-td></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  name: "CreateTable",

  mounted() {
    //
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
