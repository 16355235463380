<template>
  <div class="pb-3">
    <h2>Project: Age Calculator</h2>

    <p class="mt-3">
      In this project we will build a age calculator that will calculate a
      person's age based on his/her birthdate and a later date
    </p>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-5-project-age.mp4?alt=media&token=574e06e0-fd47-4b92-b962-88e45908c5fe"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3"></p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
