<template>
  <div>
    <b-container class="my-5">
      <b-row>
        <b-col sm="7">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Python Playground</h2>
            <div class="mb-3">
              <b-button class="mr-2" variant="success" @click="onClickRun">
                Run
              </b-button>
              <b-button variant="danger" @click="onClickReset">Reset</b-button>
            </div>
          </div>
          <div id="editor"></div>
        </b-col>

        <b-col sm="5">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Output</h2>
            <div>
              <b-button variant="dark" @click="onClickClear">Clear</b-button>
            </div>
          </div>
          <b-tabs content-class="mt-3">
            <b-tab title="Console" active>
              <div class="mt-2" id="editor-console">
                {{ consoleText }}
              </div>
            </b-tab>
            <b-tab title="Canvas">
              <div class="mt-2" id="mycanvas"></div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
import customConsole from "@/utils/custom-python-console";
import Sk from "skulpt";

export default {
  name: "JavascriptPlayground",

  data() {
    return {
      editor: null,
      consoleText: "",
      logs: []
    };
  },

  mounted() {
    this.initEditor();
  },

  methods: {
    initEditor() {
      this.editor = ace.edit("editor", {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });
    },

    onClickRun() {
      this.consoleText = "";
      try {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const oldConsole = window.console;
        window.console = customConsole(this);
        Sk.configure({
          output: this.printConsole,
          __future__: Sk.python3
        });
        (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";

        const myPromise = Sk.misceval.asyncToPromise(function() {
          return Sk.importMainWithBody("", false, self.editor.getValue(), true);
        });

        myPromise.then(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          function(mod) {
            window.console = customConsole(self);
          },
          function(err) {
            window.console = customConsole(self);
            console.log(err.toString());
          }
        );

        window.console = oldConsole;
      } catch (error) {
        self.isResultModalVisible = true;
        this.isAnswerCorrect = false;
        this.consoleText = error;
      }
    },

    printConsole(text) {
      this.logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
      console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
    },

    onClickReset() {
      if (confirm("Confirm reset code?")) {
        this.consoleText = "";
        this.editor.setValue("", 1);
      }
    },

    onClickClear() {
      this.consoleText = "";
    }
  }
};
</script>

<style scoped>
#editor {
  height: 80vh;
  overflow: auto;
}

#editor-console {
  height: 80vh;
  background-color: black;
  white-space: pre-wrap;
  overflow: auto;
}

#mycanvas {
  background-color: #ffffff;
  width: 400px;
  height: 400px;
  border: 1px solid #000000;
}
</style>
