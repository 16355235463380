export const plans = {
  production: [
    {
      name: "early_indi_29",
      priceId: "price_1ITOpqFMSvXm1ep5JgbgPXXW"
    },
    {
      name: "early_classroom_1",
      priceId: "price_1ITOpzFMSvXm1ep5tHERXSLv"
    },
    {
      name: "early_classroom_1",
      priceId: "price_1ITOpzFMSvXm1ep5Iuq5yi7X"
    },
    {
      name: "early_classroom_40",
      priceId: "price_1IaKXSFMSvXm1ep5zj6XvZOE",
      quantity: 40
    },
    {
      name: "early_classroom_100",
      priceId: "price_1IaKXaFMSvXm1ep5VuEoHJHT",
      quantity: 100
    }
  ],
  development: [
    {
      name: "early_indi_29",
      priceId: "price_1ISoIDFMSvXm1ep5r3N15v48"
    },
    {
      name: "early_classroom_1",
      priceId: "price_1ISoHDFMSvXm1ep5DrrRrHvu"
    },
    {
      name: "early_classroom_1",
      priceId: "price_1ISoHDFMSvXm1ep5lPe3zWSk"
    },
    {
      name: "early_classroom_40",
      priceId: "price_1IaI0gFMSvXm1ep5rkfiPLcA",
      quantity: 40
    },
    {
      name: "early_classroom_100",
      priceId: "price_1IaJNpFMSvXm1ep5KTw9xM0Y",
      quantity: 100
    }
  ]
};
