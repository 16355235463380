<template>
  <div>
    <h2>Array Methods</h2>

    <p>
      Below are some of the built-in array methods:
    </p>

    <h4>push</h4>
    <p>
      Adds one or more elements to the end of an array, and returns the new
      length of the array.
    </p>

    <div id="editor-array-push" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [1, 2, 3, 4]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">pop</h4>
    <p>
      Removes the last element from an array and returns that element.
    </p>

    <div id="editor-array-pop" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [1, 2]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">unshift</h4>
    <p>
      Adds one or more elements to the front of an array, and returns the new
      length of the array.
    </p>

    <div id="editor-array-unshift" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [4, 1, 2, 3]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">shift</h4>
    <p>
      Removes the first element from an array and returns that element.
    </p>

    <div id="editor-array-shift" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [2, 3]
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      <a
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array"
      >
        See other methods
      </a>
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use suitable method to add the string "lion" as last element of
            <code>animals</code>
          </li>
          <li>
            Use suitable method to add the string "bird" as first element of
            <code>animals</code>
          </li>
          <li>
            Remove the last element of <code>numbers</code> using suitable array
            method
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-array-push", `let someArray = [1, 2, 3];\nsomeArray.push(4);\nconsole.log(someArray);`);
    this.initEditor("editor-array-pop", `let someArray = [1, 2, 3];\nsomeArray.pop();\nconsole.log(someArray);`);
    this.initEditor("editor-array-unshift", `let someArray = [1, 2, 3];\nsomeArray.unshift(4);\nconsole.log(someArray);`);
    this.initEditor("editor-array-shift", `let someArray = [1, 2, 3];\nsomeArray.shift();\nconsole.log(someArray);`);

  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
