<template>
  <div>
    <h2>Parameter</h2>

    <p>
      We can also pass data into a function using <strong>parameters</strong>.
      The value passed as parameter also known as <code>arguments</code>.
    </p>

    <p>
      In below example, the function has two parameters: <code>number</code> and
      <code>multiple</code>
    </p>

    <div id="editor-function-para" style="height: 120px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 6
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare a function named <code>multiplyTen</code> with one
            parameter.
          </li>
          <li>
            When called with any number as argument, the function should assign
            <code>message</code> with a string "{number} multiple by 10 is
            {answer}", where

            <ul>
              <li>
                {number} should be replaced by value of argument
              </li>
              <li>
                {answer} should be replaced by value of argument times 10
              </li>
            </ul>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `function multiplyNumber(number, multiple) {
  console.log(number * multiple);
}

multiplyNumber(2, 3);
`

export default {
  mounted() {
    this.initEditor("editor-function-para", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
