<template>
  <div>
    <h2>Updating Variable Value</h2>

    <p>
      A variable can be updated with its existing value. In below example, the
      initial value of <code>a</code> is 5, the new value of <code>a</code> is
      assigned to its existing value plus 10 (which is 5 + 10).
    </p>
    <div id="editor-math-update" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 15
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      In JavaScript, assignment and operators can be combined.
    </p>

    <div id="editor-math-update-short" style="height: 150px;"></div>

    <p class="mt-3">List of all the combined operators:</p>

    <ul>
      <li>+=</li>
      <li>-=</li>
      <li>*=</li>
      <li>/=</li>
      <li>%=</li>
    </ul>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Reassign value of <code>a</code>, <code>b</code> and
            <code>c</code> so that
            <ul>
              <li><code>a</code> equals itself minus 3</li>
              <li><code>b</code> equals itself multiple 63</li>
              <li><code>c</code> equals 84 divide itself</li>
            </ul>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-math-update", `let a = 5;\na = a + 10;\nconsole.log(a);`);
    this.initEditor("editor-math-update-short", `let number = 10;\n\n// Instead of this\nnumber = number + 5;\n\n// It can also be written as\nnumber += 5;`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
