/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `import random
random_numbers = []

for x in range(random.randint(3, 9)):
  random_numbers.append(random.randint(1, 20))

# Write code only below this line
`;
export const seedTest = ``;
export const testCases = `
assert first_number == random_numbers[0], "first_number is not assigned with correct value"
assert last_number == random_numbers[len(random_numbers) - 1], "last_number is not assigned with correct value"`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 4; index++) {
    seedCode += splitCodes[index];
  }
  assert(/print\s*\(\s*bmi\s*\)/.test(code), "Print the value of bmi");

  Sk.importMainWithBody("", false, code + testCases);

  window.console = oldConsole;
  return true;
}
