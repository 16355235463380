<template>
  <div>
    <h2>Challenge 6 - Check Square Lengths</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">isSquare</span> that takes in an
            argument with a list of four numbers. Each number represents the
            length of a straight line.
          </li>
          <li>
            Function will return
            <span class="block block-logic">true</span> if a square can be
            formed with the lines. Else, return
            <span class="block block-logic">false</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Example input 1: [1, 1, 1, 1]
          <br />
          Example input 2: [5, 5, 5, 5]
          <br />
          Example input 3: [3, 3, 1, 3]
          <br />
          <br />
          Expected result:
          <br />
          >> true
          <br />
          >> true
          <br />
          >> false
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
