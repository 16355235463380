<template>
  <div>
    <h2>Get Value</h2>
    <p>
      The values in dictionaries can be access using their respective keys.
    </p>
    <p>
      Keys can be used either inside square brackets <code>[]</code> or with the
      <code>get()</code> method.
    </p>

    <div id="editor-dic-get" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Toyota
          <br />
          2012
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Getting not exist key</h4>
    <p>
      If we use the square brackets [], KeyError is raised in case a key is not
      found in the dictionary. On the other hand, the get() method returns None
      if the key is not found.
    </p>
    <div id="editor-dic-get-error" style="height: 130px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          KeyError: 'colour'
        </code>
      </b-card-text>
    </b-card>

    <div class="mt-3" id="editor-dic-get-error-2" style="height: 130px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          None
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Get the value stored in key <code>price</code> and assign it to
            <code>price</code> variable
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `car = {
    'brand': 'Toyota',
    'year': 2012
}

print(car['brand'])
print(car.get('year'))`;

const code2 = `car = {
    'brand': 'Toyota',
    'year': 2012
}

print(car['colour'])`;

const code3 = `car = {
    'brand': 'Toyota',
    'year': 2012
}

print(car.get('colour'))`;

export default {
  mounted() {
    this.initEditor("editor-dic-get", code);
    this.initEditor("editor-dic-get-error", code2);
    this.initEditor("editor-dic-get-error-2", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
