<template>
  <div class="pb-3">
    <h2>Project: Number Guessing Game</h2>

    <p class="mt-3">
      In this project, we will build a number-guessing game. A random number
      between 1 to 100 will be generated and the player will need to guess the
      number. Once a number is entered, the app should tell the user whether the
      guessed number is lower / higher or it is the correct value.
    </p>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-6-project-guessing.mp4?alt=media&token=bbe99c9f-24cd-429d-875d-26b887d81141"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3"></p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
