/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import customConsole from "@/utils/custom-python-console";
import { assert } from "chai";

export const logs: any[] = [];
function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export async function runCode(
  code: string,
  seed: string,
  tests: any,
  self: any
) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  let appendedSeedCode = "";
  let appendedCode = code;

  Sk.configure({
    output: printConsole,
    __future__: Sk.python3
  });
  (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "turtlecanvas";

  if (/import\s+turtle/.test(code)) {
    self.isCanvasVisible = true;
  }

  // Seed testing
  const splitSeed = seed.split("\n");
  const splitCodes = code.split("\n");
  for (let index = 0; index < splitSeed.length; index++) {
    appendedSeedCode += splitCodes[index] + "\n";
  }
  tests.seed.forEach((test: { value: any }) => {
    appendedSeedCode += "\n";
    appendedSeedCode += test.value;
  });

  await Sk.misceval.asyncToPromise(function() {
    return Sk.importMainWithBody("", false, appendedSeedCode, true);
  });

  // User code testing
  tests.user
    .filter((test: { testMode: string }) => {
      return test.testMode == "script";
    })
    .forEach((test: { value: any }) => {
      appendedCode += "\n";
      appendedCode += test.value;
    });

  await Sk.misceval.asyncToPromise(function() {
    return Sk.importMainWithBody("", false, appendedCode, true);
  });

  tests.user
    .filter((test: { testMode: string }) => {
      return test.testMode == "regex";
    })
    .forEach((test: { value: any; errorMessage: any }) => {
      const re = new RegExp(test.value);
      assert(re.test(code), test.errorMessage);
    });

  window.console = oldConsole;
  return true;
}
