<template>
  <div>
    <h2>Array Introduction</h2>

    <p>
      Array is a type of object in JavaScript. With array, we can store multiple
      value in a variable. An array is declared with square bracket and each
      item in an array is separated with a comma.
    </p>

    <div id="editor-array-intro" style="height: 80px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a variable <code>randomArray</code> and assign it with an
            array of three numbers.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-array-intro", `let emptyArray = [];\nlet numberArray = [21, 4, 63, 2];\nlet stringArray = ["hi", "hello"];`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
