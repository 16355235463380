<template>
  <div>
    <h2>Exercise 3</h2>

    <p>
      JavaScript variable can be reassign to any different data type. However,
      this is generally not a good practice.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Reassign the <code>someNumber</code> variable to a number of 50.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-variable-reassign", `let someNumber = 10;\nsomeNumber = 777;\nconsole.log(someNumber);`);
    this.initEditor("editor-variable-reassign-const", `const someNumber = 10;\nsomeNumber = 777;`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
