<template>
  <div>
    <h2>Challenge 4 - Convert Meters</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">convertMeter</span> that takes in
            two arguments. First argument is a number in unit meter to be
            convert. The second argument is a string that is either
            <span class="block block-string">km</span> or
            <span class="block block-string">cm</span>
          </li>
          <li>
            Function will return the convert value based on the second argument.
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <img
      class="img-fluid mt-3"
      src="@/assets/foundation-challenge/challenge3.png"
    />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1
          <br />
          >> 100
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
