<template>
  <div>
    <h2>Exercise 31</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">numbers</span> and assign it with
            an empty list
          </li>
          <li>
            Using a for loop, insert the number from 1 to 10 into the list in
            sequence using the set block
          </li>
          <li>Print <span class="block block-variable">numbers</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
