<template>
  <div>
    <b-container class="my-5">
      <h2 class="text-center mb-4">Subscription Plan</h2>
      <b-row align-h="center">
        <b-col md="3">
          <b-card class="text-center">
            <h3>Free</h3>
            <div class="subtitle">
              For small classes
            </div>
            <div>All courses</div>
            <div>All tools</div>
            <div>Unlimited classrooms</div>
            <div>Student progress tracking</div>
            <div>View students' solution</div>
            <div>5 students</div>
            <b-button
              v-b-modal.cancel-plan-modal
              v-if="isLicensed"
              variant="warning"
              class="mt-3"
              @click="onCancelSubscription"
            >
              Change Plan
            </b-button>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card class="text-center h-100">
            <h3>Classroom 40</h3>
            <div class="subtitle mb-3">
              $8/month
            </div>
            <div>Everything in free plan</div>
            <div>40 students</div>
            <b-button
              v-if="!isLicensed"
              variant="warning"
              class="mt-3"
              @click="onClickPurchase(3)"
            >
              Purchase
            </b-button>

            <b-button
              v-if="!isCurrentLicense(3) && isLicensed"
              v-b-modal.update-plan-modal
              variant="warning"
              class="mt-3"
              @click="onClickUpdatePlan(3)"
            >
              Change Plan
            </b-button>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card class="text-center h-100">
            <h3>Classroom 100</h3>
            <div class="subtitle mb-3">
              $16/month
            </div>
            <div>Everything in free plan</div>
            <div>100 students</div>
            <b-button v-if="!isLicensed" variant="warning" class="mt-3">
              Purchase
            </b-button>
            <b-button
              v-if="!isCurrentLicense(4) && isLicensed"
              v-b-modal.update-plan-modal
              variant="warning"
              class="mt-3"
              @click="onClickUpdatePlan(4)"
            >
              Change Plan
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      @ok="updatePlan"
      id="update-plan-modal"
      ok-title="Confirm"
      ok-variant="warning"
      title="BootstrapVue"
    >
      <template #modal-title> Update Plan </template>
      <template
        >Confirm update plan? We will prorate the price we charge next month to
        make up for any price changes. A downgrade will generate a credit to be
        applied to the next invoice.</template
      >
    </b-modal>

    <b-modal
      @ok="cancelSubscription"
      id="cancel-plan-modal"
      ok-title="Confirm"
      ok-variant="warning"
      title="BootstrapVue"
    >
      <template #modal-title> Cancel Plan </template>
      <template
        >Confirm cancel plan? Subscription will take effect
        immediately.</template
      >
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { functions } from "@/config/firebase";
import { loadStripe } from "@stripe/stripe-js";
import { organisationServices } from "@/services";
import { mapGetters } from "vuex";
import { plans } from "@/constants/plans";

export default {
  name: "Plans",

  data() {
    return {
      plans: plans[process.env.VUE_APP_MODE],
      selectedPlanIndex: null,
      quantity: 5,
      isLoading: false
    };
  },

  mounted() {
    this.getCurrentLicensesCount();
  },

  computed: {
    ...mapGetters({
      isLicensed: "organisation/isLicensed"
    })
  },

  methods: {
    async onClickPurchase(planIndex) {
      const loader = this.$loading.show();
      const plan = this.plans[planIndex];
      const quantity = plan.quantity;

      const organisation = await organisationServices.getOrganisation(
        this.$store.state.organisation.id
      );
      const usedLicenses =
        organisation.availableLicenses - organisation.remainingLicenses;

      if (this.$store.state.organisation.id === null) {
        this.$bvToast.toast("Organisation account not found", {
          title: "Checkout failed",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
        loader.hide();
        return;
      }

      if (quantity < usedLicenses) {
        this.$bvToast.toast(
          "Please remove students from classroom before changing plan",
          {
            title: "Checkout failed",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          }
        );
        loader.hide();
        return;
      }

      // Logging analytics
      this.$analytics.logEvent("begin_checkout", {
        currency: "USD",
        items: [{ name: plan.name }]
      });

      // Create/get stripe customer
      let customerId = null;
      if (this.$store.state.user.stripeCustomerId == null) {
        const createCustomer = functions.httpsCallable(
          `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-createCustomer`
        );
        customerId = await createCustomer({
          email: this.$store.state.user.email
        }).then(res => {
          return res.data;
        });
      } else {
        customerId = this.$store.state.user.stripeCustomerId;
      }

      // Get stripe checkout session
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
      const getCheckoutSession = functions.httpsCallable(
        `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-getStripeCheckoutSession`
      );

      getCheckoutSession({
        plan: plan,
        quantity: 1,
        customerId: customerId
      })
        .then(async res => {
          const session = res.data;
          return stripe.redirectToCheckout({
            sessionId: session.id
          });
        })
        .catch(error => {
          loader.hide();
          this.$bvToast.toast(error.message, {
            title: "Checkout failed",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });
    },

    onClickUpdatePlan(planIndex) {
      this.selectedPlanIndex = planIndex;
    },

    async cancelSubscription() {
      const loader = this.$loading.show();
      const organisation = await organisationServices.getOrganisation(
        this.$store.state.organisation.id
      );
      const usedLicenses =
        organisation.availableLicenses - organisation.remainingLicenses;

      if (5 < usedLicenses) {
        this.$bvToast.toast(
          "Please remove students from classroom before changing plan",
          {
            title: "Update failed",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          }
        );
        loader.hide();
        return;
      }

      const cancelSubscription = functions.httpsCallable(
        `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-cancelSubscription`
      );
      await cancelSubscription({
        subscriptionId: this.$store.state.organisation.subscriptionId
      }).then(res => {
        return res.data;
      });

      loader.hide();

      this.$bvToast.toast("Subscription canceled", {
        title: "Change plan",
        autoHideDelay: 5000,
        variant: "warning",
        appendToast: true
      });
    },

    async updatePlan() {
      const planIndex = this.selectedPlanIndex;
      const loader = this.$loading.show();
      const organisation = await organisationServices.getOrganisation(
        this.$store.state.organisation.id
      );
      const plan = this.plans[planIndex];
      const usedLicenses =
        organisation.availableLicenses - organisation.remainingLicenses;

      if (planIndex != 0 && plan.quantity < usedLicenses) {
        this.$bvToast.toast(
          "Please remove students from classroom before changing plan",
          {
            title: "Update failed",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          }
        );
        loader.hide();
        return;
      }

      const getSubscription = functions.httpsCallable(
        `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-getSubscription`
      );
      const subscription = await getSubscription({
        subscriptionId: this.$store.state.organisation.subscriptionId
      }).then(res => {
        return res.data;
      });

      const updateSubscription = functions.httpsCallable(
        `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-updateSubscription`
      );
      await updateSubscription({
        subscriptionId: this.$store.state.organisation.subscriptionId,
        params: {
          items: [
            {
              id: subscription.items.data[0].id,
              price: plan.priceId,
              quantity: 1
            }
          ]
        }
      })
        .then(res => {
          return res.data;
        })
        .catch(error => {
          alert(error.message);
        });

      const getCustomerPortalURL = functions.httpsCallable(
        `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-getCustomerPortalSession`
      );

      await getCustomerPortalURL({
        customerId: this.$store.state.user.stripeCustomerId
      })
        .then(res => {
          const url = res.data;
          window.open(url);
          loader.hide();
        })
        .catch(error => {
          alert(error);
          loader.hide();
        });

      loader.hide();
    },

    getCurrentLicensesCount() {
      if (this.isLicensed && !this.isTeacherPlan) {
        this.quantity = this.$store.state.organisation.availableLicenses;
      }
    },

    isCurrentLicense(planIndex) {
      return this.plans[planIndex].name == this.$store.state.organisation.plan;
    }
  }
};
</script>

<style></style>
