/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof answer === 'undefined') var answer;
    try {returnResult(answer);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (answer: number) => {
      result.answer = answer;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.answer === undefined) {
    status.message = "answer variable not created correctly";
  } else if (result.answer != 235) {
    status.message = "answer value is not correct";
  } else if (!code.includes("alert(answer)")) {
    status.message = "answer not printed";
  } else if (
    !code.includes("answer = numbers[2] * 5") &&
    !code.includes("answer = 5 * numbers[2]") &&
    !code.includes("numbers.slice")
  ) {
    status.message = "get element from numbers to perform calculation";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
