<template>
  <div>
    <h2>Exercise 6</h2>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <code>even_numbers</code> and assign it as empty
            list.
          </li>
          <li>
            Use a for loop to append all the even numbers from 10 to 30 into the
            list
          </li>
          <li>Print <code>even_numbers</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `numbers = []

# Prints out the numbers 0,1,2,3,4
for x in range(5):
    print(x)

# Prints out 3,4,5
for x in range(3, 6):
    print(x)

# Prints out 3,5,7
for x in range(3, 8, 2):
    print(x)`;

export default {
  mounted() {
    this.initEditor("editor-for-range", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
