<template>
  <div>
    <h2>Declare Variable</h2>
    <p>
      Most useful programs need to track a value as it changes over the course
      of the program, undergoing different operations as called for by your
      program's intended tasks. The easiest way to go about that in your program
      is to assign a value to a symbolic container, called a variable
    </p>
    <p>
      In JavaScript, the <code>let</code> keyword is used to declare a variable
      with restricted scope and the <code>const</code> keyword to declare a
      variable that cannot be reassigned. A variable must have a unique name.
      You can assign a value to a variable using equal to (=) operator. A
      variable can also be declared with assignement.
    </p>

    <div id="declare-1" style="height: 100px;"></div>

    <p class="mt-3">
      Before 2015, using the <code>var</code> keyword was the only way to
      declare a JavaScript variable. The different between <code>var</code>,
      <code>let</code> and <code>const</code> probably too complicated to be
      explained in this tutorial, you can read this
      <a
        target="_blank"
        href="https://www.freecodecamp.org/news/var-let-and-const-whats-the-difference/"
      >
        article</a
      >
      if you like to learn more.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare a variable <code>luckyNumber</code> and assign it a value of
            7 using the <code>let</code> keyword
          </li>
          <li>
            Declare a variable <code>fruit</code> and assign it a value of
            "apple" using the <code>const</code> keyword
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("declare-1");
  },

  methods: {
    initEditor(id) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: `let number = 10;\nconst animal = "cat";\n\n// variable without assignment\nlet someVariable;`
      });
    }
  }
};
</script>

<style></style>
