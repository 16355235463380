/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `some_numbers = []

# Write your code only below this line
`;

export const seedTest = `
assert some_numbers == [], "Do not change default code"`;
export const testCases = `
assert some_numbers == [5, 6, 5, 6, 5, 6], "some_numbers not append with correct value"`;
export let logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  logs = [];
  const userCodeStartingIndex = 3;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // Seed testing
  Sk.importMainWithBody("", false, seedCode + seedTest);

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);
  assert(/for[\s\S]+:[\s\S]+for[\s\S]+:/.test(code), "Use nested for loops");

  window.console = oldConsole;
  return true;
}
