/* eslint-disable @typescript-eslint/no-explicit-any */
export const seed = `CREATE TABLE books (id int PRIMARY KEY, title varChar(255), author_id int, publish_date date);
INSERT INTO books VALUES (0, 'Skin in the Game', 2, '28-12-2017');
INSERT INTO books VALUES (1, 'Thinking Fast and Slow', 0, '25-10-2011');
INSERT INTO books VALUES (2, 'Outliers', 1, '18-11-2008');
INSERT INTO books VALUES (3, 'The Black Swan', 2, '17-04-2007');
SELECT * FROM books;

CREATE TABLE authors (id int PRIMARY KEY, first_name varChar(255), last_name varChar(255));
INSERT INTO authors VALUES (0, 'Daniel', 'Kahneman');
INSERT INTO authors VALUES (1, 'Malcolm', 'Gladwell');
INSERT INTO authors VALUES (2, 'Nassim', 'Nicholas Taleb');
SELECT * FROM authors;`;

export const testCases = "";

export function testCorrectness(code: string, self: any) {
  self.resultDB = self.db.exec(code);
  return true;
}
