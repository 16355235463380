<template>
  <div>
    <h2>Loops Variable</h2>

    <p>
      Each for loop needs a variable that works as a counter, and it can be
      accessed and used in the loop. You can declare the loop variable with any
      valid variable name.
    </p>
    <p>
      In the example below, <code>i</code> is the variable created with a value
      of 2. Every time the loop executes, it will be increased by 2 until it
      reaches 8.
    </p>
    <div id="editor-loop-intro" style="height: 110px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [2, 4, 6, 8]
          <br />
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a for loop to push all even numbers between 20 to 50 in to the
            array <code>numbers</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let numbers = [];
for (let i = 2; i <= 8; i += 2) {
  numbers.push(i);
}
console.log(numbers);
`

export default {
  mounted() {
    this.initEditor("editor-loop-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
