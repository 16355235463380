<template>
  <div>
    <h2>Variable Naming</h2>
    <p>
      Most programming languages use the
      <strong>Camel Case</strong> convention to name variables. The first word
      starts with all lower case while the next word begins with a capital
      letter. For example:
    </p>

    <div id="declare-1" class="mb-3" style="height: 80px;"></div>

    <p>
      Although you can name your variable anything, the variable name created
      should be meaningful and allows a programmer to easily identify what is
      stored in that variable. For example, if you want to store the number of
      students in a classroom an example would be
      <code>classroomStudentCount</code>
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Change all the variable name to follow Camel Case convention
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("declare-1", "let number = 10;");
  },

  methods: {
    initEditor(id) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: `let myName;\nlet myFavouriteColour;\nlet studentList;\nlet isGoodStudent;`
      });
    }
  }
};
</script>

<style></style>
