<template>
  <div>
    <h2>Delete Property</h2>

    <p>A key can be deleted using the <code>delete</code> keyword</p>

    <div id="editor-object-delete" style="height: 150px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > { someNum: 42 }
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Delete the colour property of <code>car</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let someObject = {
  someString: "Hello World!",
  someNum: 42,
};

delete someObject.someString;
console.log(someObject);
`

export default {
  mounted() {
    this.initEditor("editor-object-delete", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
