import Blockly from "blockly";

export const blockDefinition = [
  {
    type: "movement_right",
    message0: "moveRight",
    previousStatement: null,
    nextStatement: null,
    colour: 230,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "movement_left",
    message0: "moveLeft",
    previousStatement: null,
    nextStatement: null,
    colour: 230,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "movement_up",
    message0: "moveUp",
    previousStatement: null,
    nextStatement: null,
    colour: 230,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "movement_down",
    message0: "moveDown",
    previousStatement: null,
    nextStatement: null,
    colour: 230,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "boolean_sprite_ahead",
    message0: "%1 at %2",
    args0: [
      {
        type: "field_dropdown",
        name: "SPRITE_TYPE",
        options: [
          ["green slime", "GREENSLIME"],
          ["goblin", "GOBLIN"],
          ["zombie", "ZOMBIE"],
          ["wall", "WALL"]
        ]
      },
      {
        type: "field_dropdown",
        name: "DIRECTION",
        options: [
          ["up", "DIRECTION_UP"],
          ["down", "DIRECTION_DOWN"],
          ["left", "DIRECTION_LEFT"],
          ["right", "DIRECTION_RIGHT"]
        ]
      }
    ],
    output: "Boolean",
    colour: 210,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "action_attack",
    message0: "attack %1",
    args0: [
      {
        type: "field_dropdown",
        name: "ATTACK_DIRECTION",
        options: [
          ["right", "ATTACK_RIGHT"],
          ["left", "ATTACK_LEFT"],
          ["up", "ATTACK_UP"],
          ["down", "ATTACK_DOWN"]
        ]
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 0,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "boolean_path",
    message0: "has path %1",
    args0: [
      {
        type: "field_dropdown",
        name: "PATH",
        options: [
          ["up", "PATH_UP"],
          ["down", "PATH_DOWN"],
          ["left", "PATH_LEFT"],
          ["right", "PATH_RIGHT"]
        ]
      }
    ],
    output: "Boolean",
    colour: 210,
    tooltip: "",
    helpUrl: ""
  },
  {
    type: "boolean_arrive_destination",
    message0: "arrived destination",
    output: null,
    colour: 210,
    tooltip: "",
    helpUrl: ""
  }
];

export function defineCustomBlocks() {
  Blockly.defineBlocksWithJsonArray(blockDefinition);
}
