<template>
  <div>
    <b-form @submit.prevent="updateClassroom">
      <b-row>
        <b-col md="6">
          <b-form-group label="Accept new student">
            <b-form-checkbox v-model="localClassroom.isOpenToStudent" switch>
              {{ switchText }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label="Classroom name">
            <b-form-input
              type="text"
              v-model="localClassroom.name"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            variant="dark"
            class="mr-2"
            type="submit"
            :disabled="isSaving"
          >
            <b-spinner small v-if="isSaving"></b-spinner>
            {{ isSaving ? "Loading..." : "Save" }}
          </b-button>
          <b-button variant="danger" @click="onClickDeleteClassroom">
            Delete Classroom
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { classroomServices } from "@/services";

export default {
  name: "TeacherClassroomSettingTab",

  props: {
    classroom: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  data() {
    return {
      classroomId: this.$route.params.id,
      localClassroom: Object.assign({}, this.classroom),
      isSaving: false
    };
  },

  computed: {
    switchText() {
      return this.classroom.isOpenToStudent ? "Open" : "Closed";
    }
  },

  methods: {
    updateClassroom() {
      this.isSaving = true;
      return classroomServices
        .updateClassroom(this.classroomId, {
          name: this.localClassroom.name,
          isOpenToStudent: this.localClassroom.isOpenToStudent
        })
        .then(() => {
          this.isSaving = false;
          this.$bvToast.toast(`Class setting updated`, {
            title: "Classroom Setting",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: true
          });
        })
        .catch(error => {
          alert(error.message);
          this.isSaving = false;
        });
    },

    onClickDeleteClassroom() {
      if (confirm("Confirm delete classroom?")) {
        return classroomServices
          .deleteClassroom(this.classroomId)
          .then(() => {
            this.$router.push("/");
          })
          .catch(error => {
            alert(error.message);
          });
      }
    }
  }
};
</script>

<style></style>
