<template>
  <div>
    <h2>Get/Set Elements</h2>

    <p>
      Array elements are numbered counting from zero. The positions of elements
      also referred as index numbers.
    </p>
    <h4>Get element</h4>
    <p>
      We can get an element in an array by its index number in square brackets.
    </p>
    <div id="editor-list-get" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          4
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Set element
    </h4>
    <p>Element in array can also be replaced</p>
    <div id="editor-list-set" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [4, 22, 77]
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a variable <code>some_number</code> and assign it with the
            first element of <code>some_array</code>
          </li>
          <li>Replace the third element in <code>some_array</code> to 3</li>
          <li>Print the value of <code>some_array</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number_list = [4, 22, 1]
print(number_list[0])`;

const code2 = `number_list = [4, 22, 1]
number_list[2] = 77
print(number_list)`;

const code3 = `number_list = [4, 22, 1]
number_list[3] = 77
print(number_list)`;

export default {
  mounted() {
    this.initEditor("editor-list-get", code);
    this.initEditor("editor-list-set", code2);
    this.initEditor("editor-list-add", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
