/* eslint-disable @typescript-eslint/no-unused-vars */
import Blockly from "blockly";

export function initializeStub() {
  Blockly.JavaScript["movement_right"] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const code = "moveRight();\n";
    return code;
  };

  Blockly.JavaScript["movement_left"] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const code = "moveLeft();\n";
    return code;
  };

  Blockly.JavaScript["movement_up"] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const code = "moveUp();\n";
    return code;
  };

  Blockly.JavaScript["movement_down"] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const code = "moveDown();\n";
    return code;
  };

  Blockly.JavaScript["action_attack"] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const dropdownAttackDirection = block.getFieldValue("ATTACK_DIRECTION");
    const code = `attack('${dropdownAttackDirection}');\n`;
    return code;
  };

  Blockly.JavaScript["boolean_sprite_ahead"] = function(block) {
    const dropdownSpriteType = block.getFieldValue("SPRITE_TYPE");
    const dropdownDirection = block.getFieldValue("DIRECTION");
    // TODO: Assemble JavaScript into code variable.
    const code = `hasSprite('${dropdownSpriteType}', '${dropdownDirection}')`;
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_EQUALITY];
  };

  Blockly.JavaScript["boolean_path"] = function(block) {
    const dropdownPath = block.getFieldValue("PATH");
    // TODO: Assemble JavaScript into code variable.
    const code = `hasPath('${dropdownPath}')`;
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_EQUALITY];
  };

  Blockly.JavaScript["boolean_arrive_destination"] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    const code = "hasArrivedDestination()";
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_EQUALITY];
  };
}
