<template>
  <div class="pb-3">
    <h2>Project: BMI Calculator (Challenge 1)</h2>

    <p class="mt-3">
      1) Design your app and make it look interesting. Do it your own way!
    </p>
    <p>
      2) Most people know their height by centimeters instead of meters. Change
      the program to take in value as centimeters and convert it to meters
      before calculation. 100 centimeter (cm) = 1 meter (m).
    </p>

    <p class="text-md">
      Work on the challenge on your own before go through the solution below!
    </p>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-4-project-bmi-3.mp4?alt=media&token=0de5390b-e34a-4aef-8cf6-b9042703ef40"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3"></p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
