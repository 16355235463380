<template>
  <div class="home">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col md="4">
          <div id="content">
            <div class="p-4">
              <h1 class="title">Lossless Text Compression</h1>
              <p>
                An interactive tool learn how lossless text compression can be
                use to reduce file size. In this activity, we will assume every
                character made up of 1 byte.
              </p>
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Text sample"
                label-for="input-sm"
              >
                <b-form-select v-model="selectedSample" size="sm">
                  <b-form-select-option :value="0">
                    Let It Be
                  </b-form-select-option>
                  <b-form-select-option :value="1">
                    Shake It Off
                  </b-form-select-option>
                  <b-form-select-option :value="2">
                    aaaaaaaaaa
                  </b-form-select-option>
                  <b-form-select-option :value="-1">
                    Custom text
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group
                v-if="this.selectedSample == -1"
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Custom content"
                label-for="input-sm"
              >
                <textarea v-model="customText" cols="30" rows="5"></textarea>
              </b-form-group>

              <div>
                <b-button variant="primary" @click="onClickUseText">
                  Use text
                </b-button>
              </div>

              <div class="mt-3">
                <div>Original size: {{ selectedText.length }} bytes</div>
                <div>Reduced text size: {{ reducedText.length }} bytes</div>
                <div>Dictionary size: {{ dictionaryText.length }} bytes</div>
                <div>
                  Total size after compression:
                  {{ totalSizeAfter }} bytes
                </div>
                <div>Percentage reduction: {{ percentReduction }}%</div>
              </div>

              <p class="mt-3"></p>
            </div>
          </div>
        </b-col>

        <b-col md="4">
          <div id="text-container">
            <textarea
              class="p-3 box-size"
              v-model="reducedText"
              cols="30"
              rows="10"
              disabled
            ></textarea>
          </div>
        </b-col>

        <b-col md="4">
          <div class="dictionary-container p-2">
            <h3 class="my-0">Dictionary</h3>
          </div>
          <div id="dictionary"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const sample = [
  `When I find myself in times of trouble, Mother Mary comes to me
Speaking words of wisdom, let it be
And in my hour of darkness she is standing right in front of me
Speaking words of wisdom, let it be
Let it be, let it be, let it be, let it be
Whisper words of wisdom, let it be
And when the broken hearted people living in the world agree
There will be an answer, let it be
For though they may be parted, there is still a chance that they will see
There will be an answer, let it be
Let it be, let it be, let it be, let it be
There will be an answer, let it be
Let it be, let it be, let it be, let it be
Whisper words of wisdom, let it be
Let it be, let it be, let it be, let it be
Whisper words of wisdom, let it be, be
And when the night is cloudy there is still a light that shines on me
Shinin' until tomorrow, let it be
I wake up to the sound of music, Mother Mary comes to me
Speaking words of wisdom, let it be
And let it be, let it be, let it be, let it be
Whisper words of wisdom, let it be
And let it be, let it be, let it be, let it be
Whisper words of wisdom, let it be`,
  `I stay out too late
Got nothing in my brain
That's what people say, mm, mm
That's what people say, mm, mm
I go on too many dates
But I can't make 'em stay
At least that's what people say, mm, mm
That's what people say, mm, mm
But I keep cruising
Can't stop, won't stop moving
It's like I got this music in my mind
Saying it's gonna be alright
'Cause the players gonna play, play, play, play, play
And the haters gonna hate, hate, hate, hate, hate
Baby, I'm just gonna shake, shake, shake, shake, shake
I shake it off, I shake it off (Whoo-hoo-hoo)
Heartbreakers gonna break, break, break, break, break
And the fakers gonna fake, fake, fake, fake, fake
Baby, I'm just gonna shake, shake, shake, shake, shake
I shake it off, I shake it off (Whoo-hoo-hoo)`,
  `aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa`
];

export default {
  name: "Home",

  data() {
    return {
      editor: null,
      selectedSample: 0,
      selectedText: "",
      customText: "",
      reducedText: "",
      dictionaryText: ""
    };
  },

  mounted() {
    this.onMounted();
  },

  computed: {
    totalSizeAfter() {
      return this.reducedText.length + this.dictionaryText.length;
    },

    percentReduction() {
      const percent =
        100 - (this.totalSizeAfter / this.selectedText.length) * 100;
      return percent.toFixed(2);
    }
  },

  methods: {
    onMounted() {
      this.initEditor();
    },

    initEditor() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      this.editor = ace.edit("dictionary", {
        mode: "ace/mode/text",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });

      this.editor.session.gutterRenderer = {
        getWidth: function(session, lastLineNumber, config) {
          return lastLineNumber.toString().length * config.characterWidth;
        },
        getText: function(session, row) {
          return String.fromCharCode(row + 9728);
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.editor.on("change", function(delta) {
        self.onEditorChange();
      });
    },

    onEditorChange() {
      const dicText = this.editor.getValue();
      const dicLines = dicText.split("\n");
      this.dictionaryText = dicText;

      let tempReduced = this.selectedText;
      for (let index = 0; index < dicLines.length; index++) {
        const line = dicLines[index];
        const replacement = String.fromCharCode(index + 9728);
        if (line == "") {
          continue;
        }
        tempReduced = tempReduced.replaceAll(line, replacement);
      }
      this.reducedText = tempReduced;
    },

    onClickUseText() {
      if (this.selectedSample == -1) {
        this.selectedText = this.customText;
        this.reducedText = this.customText;
        this.onEditorChange();
      } else {
        this.selectedText = sample[this.selectedSample];
        this.reducedText = sample[this.selectedSample];
        this.onEditorChange();
      }
    }
  }
};
</script>

<style lang="less" scoped>
#content {
  height: calc(100vh - 52px);
  overflow: auto;
  background-color: white;
}

#text-container {
  height: calc(100vh - 52px);
  width: 100%;
  overflow: hidden;
}

textarea {
  width: 100%;
  height: 100%;
}

#ace-editor {
  height: calc(100vh - 52px);
  width: 100%;
  overflow: auto;
}

#dictionary {
  height: calc(100vh - 52px - 2.5rem);
  width: 100%;
  overflow: auto;
}

.dictionary-container {
  background-color: rgb(224, 224, 224);
}

.title {
  font-size: 2em;
  font-weight: 600;
}

.box-size {
  box-sizing: border-box;
}
</style>
