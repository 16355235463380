<template>
  <div>
    <h2>Data Type</h2>

    <p>
      Python is a dynamic type programming language. Variables in Python are not
      directly associated with any particular value type, and any variable can
      be assigned (and re-assigned) values of all types. Here are some of the
      built-in Python data types:
    </p>
    <ul>
      <li>Numeric: int, float, complex</li>
      <li>String: str</li>
      <li>Sequence: list, tuple, range</li>
      <li>Binary: bytes, bytearray, memoryview</li>
      <li>Map: dict</li>
      <li>Boolean: bool</li>
      <li>Set: set, frozenset</li>
    </ul>

    <h4 class="mt-3">String</h4>
    <p>
      A string represents sequence of characters. It can be represented using
      single quote <code>''</code> or double quote <code>""</code>.
    </p>
    <div id="editor-type-string" style="height: 60px;"></div>
    <p class="mt-2">
      A multiline string can be assigned by using three quotes.
    </p>
    <div id="editor-type-string-multiline" style="height: 80px;"></div>

    <h4 class="mt-3">Numeric</h4>
    <p>
      Numeric data types are use to hold numeric value (numbers). There are
      three numeric data types:
    </p>
    <ul>
      <li>int - integers</li>
      <li>float - number with decimals (up to 15 decimals accuracy)</li>
      <li>complex - complex numbers</li>
    </ul>
    <div id="editor-type-numeric" style="height: 80px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <code>random_number</code> with float data type
          </li>
          <li>Create a variable <code>quote</code> with str data type</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `some_string = 'hello'
another_string = "hello"`;
const code3 = `a = """Sentence one,
sentence two,
sentence three."""`;
const code2 = `some_int = 3
some_float = 3.142
some_complex = 1 + 2j`;

export default {
  mounted() {
    this.initEditor("editor-type-string", code);
    this.initEditor("editor-type-string-multiline", code3);
    this.initEditor("editor-type-numeric", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
