<template>
  <div>
    <h2>Loops Introduction</h2>

    <p>
      Loops are used to instruct the computer to do something over and over
      again. This is what makes a computer so powerful in carrying out a
      repetitive task. A computer can repeat a line of code a million times in a
      second! There are two main types of loops we can use in most programming
      languages - For Loop and While Loop
    </p>

    <h4 class="mt-3">For Loops</h4>
    <p>
      A for loop will carry out instructions for a given number of times.
    </p>
    <div id="editor-loop-intro" style="height: 100px;"></div>

    <p class="mt-3">We can also use a for loop to iterate over a sequence</p>
    <div id="editor-loop-intro-2" style="height: 80px;"></div>

    <h4 class="mt-3">While Loops</h4>
    <p>
      A while loop iterates through a block of code as long as the given
      condition is true.
    </p>
    <div id="editor-loop-intro-3" style="height: 100px;"></div>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Let's started to learn more about loops!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `numbers = []

# Prints out the numbers 0,1,2,3,4
for x in range(5):
    print(x)`;

const code2 = `# Prints out 0,1,2,3,4
count = 0
while count < 5:
    print(count)`;

export default {
  mounted() {
    this.initEditor("editor-loop-intro", code);
    this.initEditor("editor-loop-intro-2", code2);
    this.initEditor("editor-loop-intro-3", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
