/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";

export const seed = `


-- Only write code above this line
INSERT INTO cars VALUES (0, 'Tesla', 2020);
SELECT * FROM cars;`;

export const testCases = `
SELECT * FROM cars;
`;

export function testCorrectness(code: string, self: any) {
  self.resultDB = self.db.exec(code);
  const result = self.db.exec(testCases);

  assert(
    /id\s+int\s*,\s*brand\s+varChar\(255\)\s*,\s*year\s+int/.test(code),
    "column name or data type not set correctly"
  );

  assert.deepEqual(
    result[0].columns,
    ["id", "brand", "year"],
    "Wrong table columns"
  );
  return true;
}
