<template>
  <div>
    <h2>Conditional Expressions</h2>

    <p>
      A <strong>conditional expression</strong> evaluates to a boolean value of
      either <code>true</code> or <code>false</code>. Comparison operators below
      are use to create conditional expressions.
    </p>

    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Operator</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>==</b-th>
          <b-td>equal to</b-td>
        </b-tr>
        <b-tr>
          <b-th>===</b-th>
          <b-td>equal value and equal type</b-td>
        </b-tr>
        <b-tr>
          <b-th>!=</b-th>
          <b-td>not equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>!===</b-th>
          <b-td>not equal value and not equal type</b-td>
        </b-tr>
        <b-tr>
          <b-th>&gt;</b-th>
          <b-td>greater than</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;</b-th>
          <b-td>less than</b-td>
        </b-tr>
        <b-tr>
          <b-th>&gt;=</b-th>
          <b-td>greater than or equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;=</b-th>
          <b-td>less than or equal</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Example</h4>
    <p>Operators are used to compare two value and return a boolean</p>
    <div id="editor-cond-intro" style="height: 180px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Change the value of 0 so that all the expressions will evaluate to
            <code>true</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let a = 10;
let b = "apple";

console.log(a == 10); // true
console.log(a != 30); // true
console.log(a > 20); // false
console.log(a < 20); // true
console.log(b == "apple"); // true
`

export default {
  mounted() {
    this.initEditor("editor-cond-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
