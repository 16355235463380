/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `print("Do not print this!")
print("Print this!")`;
export const seedTest = ``;
export const testCases = ``;

function printConsole(text: any) {
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /\s*#\s*print\("Do not print this!"\)\s+print\("Print this!"\)/.test(code),
    "Use # to comment out the first print statement"
  );

  window.console = oldConsole;
  return true;
}
