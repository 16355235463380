<template>
  <div class="home">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col id="content-container" md="4">
          <div id="content">
            <div class="p-4">
              <h1 class="title">Number System</h1>
              <p>
                Understand the conversion between binary, decimal, and
                hexadecimal number system.
              </p>
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Number"
                label-for="input-sm"
              >
                <b-input-group size="sm">
                  <textarea
                    v-model="number"
                    rows="3"
                    style="width: 100%;"
                  ></textarea>
                </b-input-group>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="From"
                label-for="input-sm"
              >
                <b-form-select v-model="from" size="sm">
                  <b-form-select-option
                    v-for="option in systemOptions"
                    :key="option.value"
                    :value="option.value"
                    :disabled="isDisabled('from', option.value)"
                  >
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="To"
                label-for="input-sm"
              >
                <b-form-select v-model="to" size="sm">
                  <b-form-select-option
                    v-for="option in systemOptions"
                    :key="option.value"
                    :value="option.value"
                    :disabled="isDisabled('to', option.value)"
                  >
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <div class="mt-3">
                <b-button variant="primary" @click="onClickConvert">
                  Convert
                </b-button>
              </div>
              <div class="mt-3">
                Result:
              </div>
              <textarea v-model="result" id="result" rows="3"></textarea>
            </div>
          </div>
        </b-col>

        <b-col md="8">
          <div id="sim">
            <div class="p-4">
              <h2 class="title mb-3">Place values</h2>

              <b-button
                :variant="buttonVariant('binary')"
                size="lg"
                class="mr-2"
                @click="onClickNumberSystem('binary')"
              >
                Binary
              </b-button>
              <b-button
                :variant="buttonVariant('decimal')"
                size="lg"
                class="mr-2"
                @click="onClickNumberSystem('decimal')"
              >
                Decimal
              </b-button>
              <b-button
                :variant="buttonVariant('hexadecimal')"
                size="lg"
                class="mr-2"
                @click="onClickNumberSystem('hexadecimal')"
              >
                Hexadecimal
              </b-button>

              <b-row class="mt-5">
                <b-col v-for="index in [0, 1, 2, 3, 4, 5]" :key="index">
                  <b-form-select
                    size="lg"
                    v-model="digits[index]"
                    :options="options"
                  ></b-form-select>
                  <div class="place-value">
                    {{ placeValues[index] }}
                  </div>
                  <div class="place-value">
                    {{ "x " + parseInt(digits[index], radix) }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="result mt-5">Number: {{ digits.join("") }}</div>
                  <div class="result">
                    Value: {{ parseInt(digits.join(""), radix) }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import _ from "lodash";
import "ace-builds/webpack-resolver";

export default {
  name: "Home",

  data() {
    return {
      number: "",
      from: 2,
      to: 10,
      result: "",
      numberSystem: "binary",
      systemOptions: [
        {
          name: "Binary",
          value: 2
        },
        {
          name: "Decimal",
          value: 10
        },
        {
          name: "Hexadecimal",
          value: 16
        }
      ],
      digits: ["0", "0", "0", "0", "0", "0"],
      binaryDigits: ["0", "1"],
      decimalDigits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      hexDigits: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F"
      ]
    };
  },

  mounted() {
    this.onMounted();
  },

  computed: {
    radix() {
      let value = 10;
      switch (this.numberSystem) {
        case "binary":
          value = 2;
          break;

        case "decimal":
          value = 10;
          break;

        case "hexadecimal":
          value = 16;
          break;

        default:
          break;
      }
      return value;
    },

    options() {
      switch (this.numberSystem) {
        case "binary":
          return this.binaryDigits;

        case "decimal":
          return this.decimalDigits;

        case "hexadecimal":
          return this.hexDigits;

        default:
          return [];
      }
    },

    placeValues() {
      const values = [];
      let value = 1;
      let multiple = 1;
      switch (this.numberSystem) {
        case "binary":
          multiple = 2;
          break;

        case "decimal":
          multiple = 10;
          break;

        case "hexadecimal":
          multiple = 16;
          break;

        default:
          break;
      }

      values.unshift(1);
      for (let index = 0; index < 5; index++) {
        value *= multiple;
        values.unshift(value);
      }
      return values;
    }
  },

  methods: {
    onMounted() {
      //
    },

    onClickConvert() {
      const trimmedNumber = this.number.trim();
      const parsedNumber = parseInt(trimmedNumber, this.from);
      this.result = parsedNumber.toString(this.to);
    },

    onClickNumberSystem(system) {
      this.resetDigits();
      this.numberSystem = system;
    },

    isDisabled(input, value) {
      if (input == "from") {
        return this.to == value;
      }

      if (input == "to") {
        return this.from == value;
      }
    },

    buttonVariant(system) {
      return system == this.numberSystem ? "dark" : "outline-dark";
    },

    resetDigits() {
      this.digits = ["0", "0", "0", "0", "0", "0"];
    }
  }
};
</script>

<style lang="less" scoped>
#content-container {
  height: calc(100vh - 52px);
}

#content {
  background-color: #ffffff;
  height: calc(100vh - 52px);
  overflow: auto;
  border-right: 1px solid rgb(209, 209, 209);
}

#footer {
  font-size: 14px;
  display: flex;
  align-items: center;
  position: absolute;
  color: white;
  background-color: rgb(65, 65, 65);
  height: 40px;
  width: 100%;
  bottom: 0;
  white-space: pre-wrap;
}

.footer-link {
  color: white;
}

#ace-editor {
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.title {
  font-size: 2em;
  font-weight: 600;
}

#result {
  width: 100%;
}

.place-value {
  margin-top: 15px;
  font-size: 1.5em;
  text-align: center;
}

.result {
  font-size: 1.5em;
}
</style>
