<template>
  <div>
    <h2>Tenary Operator</h2>

    <p>
      The tenary operator is the only JavaScript operator that has three
      operands. It allow us to execute expression based on a conditional
      expression.
    </p>

    <p>
      Syntax of tenary operator
    </p>
    <p><code>condition ? exp1 : exp2</code></p>
    <p>
      exp1 will be evaluated if condition is true, else exp2 will be evaluated
    </p>

    <div id="editor-cond-tenary" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "No"
          <br />
          > "Yes"
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-2">Using tenary operator makes our code cleaner.</p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Rewrite the assignment of <code>result</code> using tenary operator
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let a = 50;

let moreThanFifty = 10 > a ? "Yes" : "No";
console.log(moreThanFifty);

moreThanFifty = 100 > a ? "Yes" : "No";
console.log(moreThanFifty);
`

export default {
  mounted() {
    this.initEditor("editor-cond-tenary", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
