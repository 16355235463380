/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof number === 'undefined') var number ;
    try {returnResult(number);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (number: number) => {
      result.number = number;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");
  const isEven = result.number % 2 == 0;
  if (
    !trimmedCode.includes(
      "if(number%2==0){window.alert('Itisanevennumber!');}"
    ) &&
    !trimmedCode.includes("if(0==number%2){window.alert('Itisanevennumber!');}")
  ) {
    status.message = "If statement is not setup correctly";
  } else if (isEven && !result.printedText.includes("It is an even number!")) {
    status.message = "Print statement not correct";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
