<template>
  <div>
    <h2>Not Operator</h2>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-20-conditional-not.mp4?alt=media&token=4c600896-e7be-4caa-9a86-d0ab9150a73a"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      We can use the <strong>not</strong> operator to flip the boolean evaluated
      by a conditional expression.
    </p>

    <img class="img-fluid" src="@/assets/foundation/conditional_not.png" />

    <p>For example:</p>

    <img
      class="img-fluid"
      src="@/assets/foundation/conditional_not_example.png"
    />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> false
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Fill in the blanks with any suitable value so that all the
            conditional expressions will be evaluated as
            <span class="block block-logic">true</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> true
          <br />
          >> true
          <br />
          >> true
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "FoundationVariable",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
