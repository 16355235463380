<template>
  <div>
    <h2>Update Statement</h2>
    <p>
      A common task is to update existing data, which can be done using an
      <code>UPDATE</code> statement. Similar to the
      <code>INSERT</code> statement, you have to specify exactly which table,
      columns, and rows to update. In addition, the data you are updating has to
      match the data type of the columns in the table schema.
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            first_name
          </b-th>
          <b-th>
            last_name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Alice</b-td>
          <b-td>Vasilii</b-td>
          <b-td>13</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Fred</b-td>
          <b-td>Davidson</b-td>
          <b-td>14</b-td>
        </b-tr>
        <b-tr>
          <b-td>2</b-td>
          <b-td>Melissa</b-td>
          <b-td>Welch</b-td>
          <b-td>12</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div id="editor-update" style="height: 50px;"></div>

    <p class="mt-3">
      Beware that all the records that satisfy <code>WHERE</code> clause
      conditions will be updated.
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            first_name
          </b-th>
          <b-th>
            last_name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Amelia</b-td>
          <b-td>Clarkson</b-td>
          <b-td>13</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Fred</b-td>
          <b-td>Davidson</b-td>
          <b-td>14</b-td>
        </b-tr>
        <b-tr>
          <b-td>2</b-td>
          <b-td>Melissa</b-td>
          <b-td>Welch</b-td>
          <b-td>12</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Update the device name "iPhone 12" to "iPhone 11" and the price to
            1399
          </li>
          <li>Select all the data in <code>devices</code> table</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
const code = `UPDATE students SET first_name = "Amelia", last_name = "Clarkson" WHERE id = 1;`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-update", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
