<template>
  <div>
    <h2>Delete Element</h2>

    <h4>Pop</h4>
    <p>
      <code>pop()</code> method can be used to delete an element in a list. We
      can provide the index to be removed as argument. If the index is not
      provided, the <code>pop()</code>
      method will removes the last element.
    </p>
    <div id="editor-list-pop" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [4, 1, 48]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Del
    </h4>
    <p><code>del</code> keyword also deletes an element at specific index</p>
    <div id="editor-list-del" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [4, 1, 48, 92]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Remove
    </h4>
    <p><code>remove()</code> method deletes an element</p>
    <div id="editor-list-remove" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [4, 1, 48, 92]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Clear
    </h4>
    <p><code>clear()</code> method empties the list</p>
    <div id="editor-list-clear" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          []
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Remove "c" and "e" from <code>alphabets</code></li>
          <li>Print <code>alphabets</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number_list = [4, 22, 1, 48, 92]
number_list.pop(1)      # removes 22
number_list.pop()       # removes 92
print(number_list)`;

const code2 = `number_list = [4, 22, 1, 48, 92]
number_list.remove(22)     
print(number_list)`;

const code3 = `number_list = [4, 22, 1, 48, 92]
number_list.clear()     
print(number_list)`;

const code4 = `number_list = [4, 22, 1, 48, 92]
del number_list[1]      # removes 22
print(number_list)`;

export default {
  mounted() {
    this.initEditor("editor-list-pop", code);
    this.initEditor("editor-list-del", code4);
    this.initEditor("editor-list-remove", code2);
    this.initEditor("editor-list-clear", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
