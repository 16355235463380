<template>
  <div>
    <h2>Else If Statement</h2>

    <p>
      Else if statement adds another condition to check when the first if
      statement is false.
    </p>

    <p>
      The first condition number > 50 evaluates to <code>false</code>, hence
      second condition will be evaluated. Since the second condition number > 25
      evaluates to <code>true</code>, the code block is executed
    </p>

    <div id="editor-cond-else-if" style="height: 250px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "greater than 20 but less than 50"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <p>Write an if..else if...else statement so that</p>
        <ol class="activity-list">
          <li>
            if <code>randomNumber</code> is equal or more than 1000,
            <code>message</code> will be assigned with "More than or equal 1000"
          </li>
          <li>
            Else if <code>randomNumber</code> is equal or more than 100, assign
            <code>message</code>
            with "More than or equal 100"
          </li>
          <li>
            Else, <code>message</code> should be assigned with "Less than 100"
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let number = 25;

if (number >= 50) {
  // false, so not executed
  console.log("more than 50");
} else if (number > 20) {
  // true, executed
  console.log("greater than 20 but less than 50");
} else {
  // skipped as one of above conditions is true
  console.log("less than 20");
}`

export default {
  mounted() {
    this.initEditor("editor-cond-else-if", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
