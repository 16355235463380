/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof numberOne  === 'undefined') var numberOne ;
    if (typeof numberTwo === 'undefined') var numberTwo;
    if (typeof total === 'undefined') var total;
    try {returnResult(numberOne, numberTwo, total);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      console.log(result.printedText);
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      numberOne: number,
      numberTwo: number,
      total: number
    ) => {
      result.numberOne = numberOne;
      result.numberTwo = numberTwo;
      result.total = total;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.numberOne === undefined) {
    status.message = "numberOne not created";
  } else if (result.numberTwo === undefined) {
    status.message = "numberTwo not created";
  } else if (result.total === undefined) {
    status.message = "total not created";
  } else if (result.numberOne !== 15) {
    status.message = "numberOne not assigned with correct value";
  } else if (result.numberTwo !== 18) {
    status.message = "numberTwo not assigned with correct value";
  } else if (result.total !== 270) {
    status.message = "total value is not correct";
  } else if (
    !code.includes("total = numberOne * numberTwo") &&
    !code.includes("total = numberTwo * numberOne")
  ) {
    status.message =
      "total should be assigned as the multiplication of numberOne and numberTwo";
  } else if (!code.includes("alert(total)")) {
    status.message = "total not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
