/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let numbers = [];

// Only write code below this line
`;

const seedTest = `
  chaiAssert.deepEqual(numbers, [], "Do not change default code");
`;

export const testCases = `
  let answer = [4, 5, 4, 5, 4, 5];
  chaiAssert.deepEqual(numbers, answer, "Wrong numbers inserted into array");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /for\s*\([\s\S]+\)\s*{[\s\S]*for\s*\([\s\S]+\)\s*{[\s\S]+}[\s\S]*}/.test(
      code
    ),
    "use nested for loops to complete this activity"
  );

  window.console = oldConsole;
  return true;
}
