/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = ``;
export const seedTest = ``;
export const testCases = `
assert callable(list_even_numbers), "list_even_numbers function not found"`;
export let logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  logs = [];
  const userCodeStartingIndex = 3;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({
    output: printConsole,
    __future__: Sk.python3,
    execLimit: 3000
  });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);
  assert.deepEqual(logs, ["2", "4", "6", "8"], "Numbers not printed correctly");

  window.console = oldConsole;
  return true;
}
