const challengeFoundationCurriculum = new Map();

import Challenge1 from "./Challenge1.vue";
import * as challenge1Logics from "./Challenge1";

import Challenge2 from "./Challenge2.vue";
import * as challenge2Logics from "./Challenge2";

import Challenge3 from "./Challenge3.vue";
import * as challenge3Logics from "./Challenge3";

import Challenge4 from "./Challenge4.vue";
import * as challenge4Logics from "./Challenge4";

import Challenge5 from "./Challenge5.vue";
import * as challenge5Logics from "./Challenge5";

import Challenge6 from "./Challenge6.vue";
import * as challenge6Logics from "./Challenge6";

import Challenge7 from "./Challenge7.vue";
import * as challenge7Logics from "./Challenge7";

import Challenge8 from "./Challenge8.vue";
import * as challenge8Logics from "./Challenge8";

import Challenge9 from "./Challenge9.vue";
import * as challenge9Logics from "./Challenge9";

challengeFoundationCurriculum.set("2WAmZr1vy3vWrfbWLBS0", {
  content: Challenge1,
  logics: challenge1Logics
});

challengeFoundationCurriculum.set("hV29N4UsVJt54YpWVCwL", {
  content: Challenge2,
  logics: challenge2Logics
});

challengeFoundationCurriculum.set("aPAF1ehB6vfMZOYLUaCi", {
  content: Challenge3,
  logics: challenge3Logics
});

challengeFoundationCurriculum.set("wgmSQEiSrFck3bwpJbNj", {
  content: Challenge4,
  logics: challenge4Logics
});

challengeFoundationCurriculum.set("pBhRi42EYh8k62rQgYlF", {
  content: Challenge5,
  logics: challenge5Logics
});

challengeFoundationCurriculum.set("NkfXy2GofP0ZFAm5rzC8", {
  content: Challenge6,
  logics: challenge6Logics
});

challengeFoundationCurriculum.set("wZyOOBRJ9rjSfzIq8yCq", {
  content: Challenge7,
  logics: challenge7Logics
});

challengeFoundationCurriculum.set("eBPkUiIcwLvePQZej3ez", {
  content: Challenge8,
  logics: challenge8Logics
});

challengeFoundationCurriculum.set("ShtKItMGSpN1ViJhcHv9", {
  content: Challenge9,
  logics: challenge9Logics
});

export default challengeFoundationCurriculum;
