<template>
  <div>
    <h2>Conditional Introduction</h2>

    <p>
      Very often a program needs to execute different code based on different
      conditions. We can do things conditionally in our programs using if
      statements combined with conditional expressions.
    </p>
    <p>
      An if statement tells the program to execute a block of code if a
      condition expression is true. The syntax of if statement:
    </p>
    <p>
      <code>
        if expression:
        <br />
        <span class="ml-4">statement(s)</span>
      </code>
    </p>

    <div id="editor-if" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          First if statement
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start learning about conditionals!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 10

if number > 5:
  print("First if statement")
  
if number == 5:
  print("Second if statement")`;

const code2 = `even_numbers = [2, 4, 6, 8, 10]

# Replace 2nd to 4th elements
even_numbers[1:3] = [30, 40, 50]
print(even_numbers)

# Delete 1st to 2nd elements
del even_numbers[:1]
print(even_numbers)`;

export default {
  mounted() {
    this.initEditor("editor-if", code);
    this.initEditor("editor-list-slice-update", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
