import Introduction from "./Introduction.vue";
import * as IntroductionLogics from "./Introduction";

const curriculum = new Map();

curriculum.set("8EeOQ7AgtfMhWgH6QCB7", {
  content: Introduction,
  logics: IntroductionLogics
});

export default curriculum;
