<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-table
          :current-page="currentPage"
          :per-page="perPage"
          :items="students"
          :fields="fields"
        >
          <template #cell(no)="row">
            {{ row.index + 1 + (currentPage - 1) * perPage }}
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" @click="alert(row)" class="mr-2">
              Reset Password
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              @click="onClickDeleteStudent(row.item)"
            >
              Remove
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col md="12" class="d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="students.length"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  classroomServices,
  organisationServices,
  userServices
} from "@/services";
export default {
  name: "TeacherClassroomStudentTab",

  props: {
    classroom: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  data() {
    return {
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      students: [],
      fields: [
        { key: "no", sortable: true },
        { key: "name", sortable: true },
        { key: "email" },
        {
          key: "actions",
          label: "Actions",
          tdClass: "actions-column"
        }
      ]
    };
  },

  created() {
    this.getClassroomStudents();
  },

  methods: {
    getClassroomStudents() {
      return classroomServices
        .getClassroomStudents(this.classroom.id)
        .then(students => {
          this.students = students;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    async onClickDeleteStudent(student) {
      if (confirm(`Confirm delete ${student.name}?`)) {
        const loader = this.$loading.show();
        await classroomServices.deleteStudent(this.classroom.id, student.id);
        await userServices.deleteClassroom(this.classroom.id, student.id);
        await organisationServices.increaseRemainingLicense(
          this.classroom.organisationId
        );
        loader.hide();
        this.getClassroomStudents();
      }
    }
  }
};
</script>

<style lang="less"></style>
