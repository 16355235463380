<template>
  <div class="p-5">
    <b-container>
      <div v-if="$store.state.user.role == 'teacher'">
        <b-row>
          <b-col md="12">
            <div class="d-flex justify-content-between align-items-center">
              <h2>📖 My Courses</h2>
              <div>
                <b-button
                  variant="dark"
                  size="sm"
                  v-b-modal.modal-create-course
                >
                  Create course
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col
            class="mb-3"
            v-for="course in organisationCourses"
            :key="course.id"
            md="6"
          >
            <course-card
              :course="course"
              @card-click="onClickOrganisationCourseCard(course)"
            ></course-card>
          </b-col>
        </b-row>

        <b-row v-if="!isLoading && organisationCourses.length == 0">
          <b-col>
            <p>
              🚀
              <a href="#" v-b-modal.modal-create-course>Create</a> your own
              self-paced courses!
            </p>
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-3" v-if="isLoading">
        <b-col md="6" class="mb-3">
          <course-card-skeleton />
        </b-col>
        <b-col md="6" class="mb-3">
          <course-card-skeleton />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <h2>📚 All Courses</h2>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="mb-3" v-for="course in courses" :key="course.id" md="6">
          <course-card
            :course="course"
            @card-click="onClickCourseCard(course)"
          ></course-card>
        </b-col>
      </b-row>

      <b-row class="mt-3" v-if="isLoading">
        <b-col md="6" class="mb-3">
          <course-card-skeleton />
        </b-col>
        <b-col md="6" class="mb-3">
          <course-card-skeleton />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-create-course"
      ref="modal"
      title="Create course"
      ok-variant="dark"
      @show="resetCreateCourseModal"
      @hidden="resetCreateCourseModal"
      @ok="handleOkCreateCourse"
      :ok-disabled="isCreatingCourse"
      :no-close-on-backdrop="isCreatingCourse"
      :hide-header-close="isCreatingCourse"
      :no-close-on-esc="isCreatingCourse"
      :cancel-disabled="isCreatingCourse"
    >
      <form ref="addCourseForm" @submit.stop.prevent="handleSubmitCreateClass">
        <b-form-group
          label="Name"
          invalid-feedback="Name is required"
          :state="newCourseNameState"
        >
          <b-form-input
            type="text"
            v-model="newCourse.name"
            :disabled="isCreatingCourse"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Language"
          invalid-feedback="Language is required"
          :state="newCourseOptionState"
        >
          <b-form-select
            v-model="newCourse.language"
            :options="courseOptions"
            :disabled="isCreatingCourse"
          ></b-form-select>
        </b-form-group>
      </form>
      <template #modal-ok>
        <b-spinner small v-if="isCreatingCourse"></b-spinner>
        {{ isCreatingCourse ? "Loading..." : "Create course" }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as courseServices from "@/services/course.service";
import CourseCard from "@/components/CourseCard.vue";
import CourseCardSkeleton from "@/components/CourseCardSkeleton.vue";
import { CourseBuilder } from "@/models/builders/course.builder";

export default {
  name: "Courses",

  components: {
    CourseCard,
    CourseCardSkeleton
  },

  data() {
    return {
      courses: [],
      organisationCourses: [],
      newCourse: {
        name: null,
        language: "python"
      },
      courseOptions: [{ value: "python", text: "Python" }],
      isCreatingCourse: false,
      isLoading: false,
      newCourseNameState: null,
      newCourseOptionState: null
    };
  },

  async created() {
    this.isLoading = true;
    await this.getCourses();
    await this.getCoursesByOrganisation();
    this.isLoading = false;
  },

  methods: {
    getCourses() {
      return courseServices
        .getCourses()
        .then(courses => {
          this.courses = courses;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getCoursesByOrganisation() {
      return courseServices
        .getCoursesByOrganisation(this.$store.state.organisation.id)
        .then(courses => {
          this.organisationCourses = courses;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onClickOrganisationCourseCard(course) {
      this.$router.push(`/course/${course.id}/edit`);
    },

    onClickCourseCard(course) {
      this.$router.push(`/course/${course.id}`);
    },

    handleOkCreateCourse(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitCreateCourse();
    },

    async handleSubmitCreateCourse() {
      this.isCreatingCourse = true;
      const course = new CourseBuilder()
        .setName(this.newCourse.name)
        .setDescription("Course description...")
        .setType("user-created")
        .setStatus("")
        .setChapterOrder({})
        .setActivityOrder({})
        .setLanguage(this.newCourse.language)
        .setUserId(this.$store.state.user.id)
        .setOrganisationId(this.$store.state.organisation.id)
        .setCreatedAtAsCurrent()
        .build();
      try {
        const newRef = await courseServices.createCourse(course);
        this.$router.push(`/course/${newRef.id}/edit`);
      } catch (error) {
        this.isCreatingCourse = false;
      }
    },

    resetCreateCourseModal() {
      this.selectedOption = null;
    }
  }
};
</script>

<style></style>
