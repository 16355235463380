<template>
  <div>
    <h2>Introduction to List</h2>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-30-list-intro.mp4?alt=media&token=8b197f79-8dea-4b49-9eec-3b2a9f330bab"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      A variable lets you store one value, such as a number, a string, or a
      boolean. What if you want to store a collection of numbers together?
    </p>
    <p>
      <strong>List</strong> is a data type that stores more than one value in a
      variable. Each element in a list can be access based on its
      <strong>index</strong> (position of the element in a list).
    </p>
    <p>
      In below example, we create a variable and store a list with 3 numbers
    </p>
    <img class="img-fluid" src="@/assets/foundation/list_intro.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">fruitList</span>
          </li>
          <li>
            Assign it with a list of string in following sequence:
            <span class="block block-string">apple</span>,
            <span class="block block-string">orange</span>,
            <span class="block block-string">pear</span>
          </li>
          <li>
            Print
            <span class="block block-variable">fruitList</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> ["apple","orange","pear"]
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "ListIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
