/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof firstNum === 'undefined') var firstNum;
    if (typeof secondNum === 'undefined') var secondNum ;
    try {returnResult(firstNum, secondNum);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (firstNum: number, secondNum: number) => {
      result.firstNum = firstNum;
      result.secondNum = secondNum;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };
  const trimmedCode = code.replace(/\s/g, "");

  if (result.firstNum === undefined) {
    status.message = "firstNum is not created";
  } else if (result.secondNum === undefined) {
    status.message = "secondNum is not created";
  } else if (
    !trimmedCode.includes(
      "if(firstNum>10&&secondNum>10){window.alert('Botharemorethan10!');}"
    ) &&
    !trimmedCode.includes(
      "if(secondNum>10&&firstNum>10){window.alert('Botharemorethan10!');}"
    )
  ) {
    status.message = "If statement is not setup correctly";
  } else if (
    !trimmedCode.includes(
      "else{window.alert('Eitheroneorbothnotmorethan10!');}"
    )
  ) {
    status.message = "Else statement is not setup correctly";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
