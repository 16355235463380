<template>
  <div class="pb-3">
    <h2>Quick Note</h2>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-0.5-course-intro.mp4?alt=media&token=f82c3054-280b-429d-bf6f-1eac2f6579ca"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "AppInventorIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/captions/quick-note-en.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less"></style>
