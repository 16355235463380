/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let partOne = "There are ";
let partTwo = " students in school.";
let studentCount = 120;

// Only write code below this line
`;

const seedTest = `
  chaiAssert(partOne === "There are ", "do no change code above the line");
  chaiAssert(partTwo === " students in school.", "do no change code above the line");
  chaiAssert(studentCount === 120, "do no change code above the line");
`;

export const testCases = `
  chaiAssert.strictEqual(sentence, "There are 120 students in school.", "sentence");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 3; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);
  assert(
    /sentence\s*=\s*partOne\s*\+\s*studentCount\s*\+\s*partTwo\s*;/.test(code),
    "Define sentence using variables defined."
  );

  window.console = oldConsole;
  return true;
}
