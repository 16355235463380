/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myAge === 'undefined') var myAge;
    try {returnResult(myAge);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myAge: number) => {
      result.myAge = myAge;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");

  if (result.myAge === undefined) {
    status.message = "myAge variable is not set correctly";
  } else if (result.myAge < 18) {
    status.message = "myAge variable is not assigned with correct value";
  } else if (
    !trimmedCode.includes("if(myAge>=18){window.alert('Iamoldenoughtodrive!')")
  ) {
    status.message = "If statement not setup correctly";
  } else if (result.printedText != "I am old enough to drive!") {
    status.message = "Print statement not correct";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
