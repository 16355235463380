/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myAge === 'undefined') var myAge ;
    try {returnResult(myAge);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myAge: number) => {
      result.myAge = myAge;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");
  if (result.myAge === undefined) {
    status.message = "Variable myAge not created correctly";
  } else if (result.myAge !== 20) {
    status.message = "Variable myAge not assigned with correct value";
  } else if (
    !trimmedCode.includes(
      "if(myAge>50){window.alert('Iamolderthan50yearsold!')"
    )
  ) {
    status.message =
      "Check your if condition & statement for myAge greater than 50";
  } else if (
    !trimmedCode.includes(
      "elseif(myAge>10){window.alert('Iamolderthan10yearsold!')"
    )
  ) {
    status.message =
      "Check your else if condition & statement for myAge between 11 to 50";
  } else if (
    result.myAge > 50 &&
    result.printedText !== "I am older than 50 years old!"
  ) {
    status.message = "Wrong printed message for myAge greater than 50";
  } else if (
    result.myAge > 10 &&
    result.myAge < 50 &&
    result.printedText != "I am older than 10 years old!"
  ) {
    status.message =
      "Wrong printed message for myAge greater than 10 but less than 50";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
