/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let numbers = [];

// Only write code below this line`;

const seedTest = `
  chaiAssert.deepEqual(numbers, [], "Wrong numbers inserted into array");
`;

export const testCases = `
  chaiAssert.deepEqual(numbers, [1,2,3,4,5,6,7,8,9,10], "Wrong numbers inserted into array");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /function generateNumbers\(\)\s*{[\s\S]+}/.test(code),
    "declare generateNumbers function"
  );

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
