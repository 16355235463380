<template>
  <div>
    <div class="d-flex game-header justify-content-between align-items-center">
      <div>
        <a href="https://csplayground.io">
          <img
            src="@/assets/csplayground-logo.png"
            alt="codehive logo"
            height="30"
          />
        </a>
      </div>
      <div>
        <b-button
          class="mx-1"
          v-for="i in 10"
          :key="i"
          size="sm"
          :variant="level == i ? 'dark' : 'outline-dark'"
          pill
          :to="`/hour-of-code/blockly-games-dungeon-crawler?level=${i}`"
        >
          {{ i }}
        </b-button>
      </div>
      <div></div>
    </div>
    <div class="d-flex">
      <div class="game-content">
        <div id="gameContainer"></div>
        <div class="instruction">
          <h3>Goal</h3>
          <div v-if="level == 1">Get to the staircase.</div>
          <div v-if="level == 2">
            Collect the sword. Use for loop to repeat an action for a certain
            number of times.
          </div>
          <div v-if="level == 3">
            Get to the stair. Use for loop to repeat an action for a certain
            number of times.
          </div>
          <div v-if="level == 4">
            Get to the starcase. Use attack block to kill slimes that get into
            your way. You can only attack twice.
          </div>
          <div v-if="level == 5">
            Get to the starcase. Use the while loop to repeat actions until you
            reach your goal. You can only attack once.
          </div>
          <div v-if="level == 6">
            Get to the starcase. Use both for loop and while loop to reduce
            number of blocks needed. You can only attack once.
          </div>
          <div v-if="level == 7">
            Goblins appeared! It takes three hits to kill a goblin. Kill all the
            enemies and get to the starcase. You cannot attack when there is no
            enemy.
          </div>
          <div v-if="level == 8">
            Kill all the enemies and get to the starcase. You cannot attack when
            there is no enemy.
          </div>
          <div v-if="level == 9">
            Kill all the enemies and get to the starcase. You cannot attack when
            there is no enemy.
          </div>
          <div v-if="level == 10">
            Big zombie appeared! Number of attack required to kill the zombie is
            unknown. Kill all the enemies and get to the treasure. You cannot
            attack when there is no enemy.
          </div>
        </div>
      </div>
      <div class="workspace">
        <div
          class="activity-actionbar d-flex justify-content-between align-items-center"
        >
          <div class="d-flex  align-items-center">
            <h4 class="mx-3 mb-0">Workspace</h4>
            <div>Remaining blocks: {{ remainingBlock }}</div>
          </div>
          <div>
            <b-button class="mr-2" variant="danger" @click="onClickReset">
              Reset
            </b-button>
            <b-button class="mr-2" variant="success" @click="onClickRun">
              <b-spinner small v-if="isRunning"></b-spinner>
              {{ isRunning ? "Running..." : "Run" }}
            </b-button>
          </div>
        </div>
        <div id="blocklyDiv"></div>
      </div>
    </div>

    <b-modal
      v-model="resultModalVisible"
      id="result-modal"
      title="Result"
      @ok="onClickResultOk"
      :hide-footer="!isAnswerCorrect"
    >
      <p class="my-4 text-center">
        <span style="font-size: 3rem;">
          {{ isAnswerCorrect ? "✔️" : "❌" }}
        </span>
        <br />
        {{ isAnswerCorrect ? "Great! Let's work on next level." : "Try again" }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import Blockly from "blockly";
import Interpreter from "js-interpreter";
import { initializeStub } from "@/games/blockly/block-stub";
import { defineCustomBlocks } from "@/games/blockly/block-definition";
import { initFunction } from "@/games/blockly/function-wrapper";
import toolbox from "@/games/scenes/toolbox";

export default {
  data() {
    return {
      gameInstance: null,
      workspace: null,
      interpreter: null,
      isRunning: false,
      level: 1,
      gameContainerId: "gameContainer",
      resultModalVisible: false,
      isAnswerCorrect: false,
      remainingBlock: null,
      pid: 0,
      maxBlocks: [10, 5, 10, 8, 15, 20, 30, 35, 30, 25],
      correctSlashCount: [0, 0, 0, 2, 1, 1, 13, 7, 7, 28],
      correctKillCount: [0, 0, 0, 0, 0, 0, 5, 3, 3, 7]
    };
  },

  watch: {
    $route: "onMounted",

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasProgramStarted(newVal, oldVal) {
      if (!newVal) {
        this.checkResult();
      }
    }
  },

  async mounted() {
    this.onMounted();
  },

  computed: {
    hasProgramStarted() {
      return this.$store.state.game.hasProgramStarted;
    }
  },

  methods: {
    async onMounted() {
      this.level = this.$route.query.level
        ? parseInt(this.$route.query.level)
        : 1;
      const loader = this.$loading.show();
      await this.initGame();
      this.initBlockly();
      loader.hide();
    },

    async initGame() {
      if (this.gameInstance) {
        this.resetGame();
      }
      this.$store.commit("game/resetState");
      const game = await import("@/games/scenes/hoc-dungeon/game");
      this.$nextTick(() => {
        this.gameInstance = game.launch(this.gameContainerId, this.level - 1);
      });
    },

    initBlockly() {
      defineCustomBlocks();
      initializeStub();
      this.removeBlocklyInjection();
      this.injectBlockly();
    },

    injectBlockly() {
      this.workspace = Blockly.inject(document.getElementById("blocklyDiv"), {
        trashcan: true,
        maxBlocks: this.maxBlocks[this.level - 1],
        zoom: {
          controls: true,
          startScale: 1.0,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2
        },
        toolbox: toolbox[this.level - 1]
      });
      this.remainingBlock = this.maxBlocks[this.level - 1];
      this.workspace.addChangeListener(() => {
        this.remainingBlock = this.workspace.remainingCapacity();
      });
    },

    resetWorkspace() {
      this.removeBlocklyInjection();
      this.injectBlockly();
    },

    resetGame() {
      document.getElementById("gameContainer").innerHTML = "";
      this.gameInstance.destroy(true);
    },

    removeBlocklyInjection() {
      document.getElementById("blocklyDiv").innerHTML = "";
    },

    onClickRun() {
      this.isAnswerCorrect = false;
      this.restartGame();
      this.$store.commit("game/startGame");

      // Run Blockly code
      window.LoopTrap = 1000;
      Blockly.JavaScript.INFINITE_LOOP_TRAP =
        'if(--loopTrap == 0) throw "Infinite loop.";\n';
      const workspaceCode = Blockly.JavaScript.workspaceToCode(this.workspace);
      const code =
        "var loopTrap = 1000;\n startProgram();\n" +
        workspaceCode +
        "endProgram();\n";
      console.log(code);
      this.interpreter = new Interpreter(code, initFunction());

      const runCode = () => {
        if (this.interpreter) {
          const hasMore = this.interpreter.run();
          if (hasMore) {
            this.pid = setTimeout(runCode, 100);
          }
        }
      };
      runCode();
    },

    checkResult() {
      const isSlashCountCorrect =
        this.correctSlashCount[this.level - 1] ==
        this.$store.state.game.slashCount;

      const isKillCountCorrect =
        this.correctKillCount[this.level - 1] ==
        this.$store.state.game.killCount;

      if (
        this.$store.state.game.hasReachEndPoint &&
        isSlashCountCorrect &&
        isKillCountCorrect
      ) {
        this.isAnswerCorrect = true;
      }
      this.showResultModal();
    },

    showResultModal() {
      this.resultModalVisible = true;
    },

    onClickReset() {
      this.restartGame();
    },

    onClickResultOk() {
      this.$router.push(
        `/hour-of-code/blockly-games-dungeon-crawler?level=${this.level + 1}`
      );
    },

    restartGame() {
      clearTimeout(this.pid);
      this.$store.commit("game/resetState");
      this.gameInstance.scene.getScenes()[0].scene.restart();
    }
  }
};
</script>

<style lang="less" scoped>
.activity {
  &-actionbar {
    height: 52px;
  }
}

.instruction {
  padding: 1.5rem;
}

.game-header {
  height: 52px;
  background-color: white;
}

.game-content {
  width: 400px;
  height: calc(100vh - 52px);
}

#gameContainer {
  width: 400px;
  height: 400px;
}

.workspace {
  width: 100%;
  height: calc(100vh - 52px);
}

#blocklyDiv {
  height: calc(100vh - 104px);
  width: 100%;
}
</style>
