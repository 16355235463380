<template>
  <div>
    <h2>Create Table</h2>
    <p>
      Data in a relational database is stored as <strong>tables</strong>. Each
      tables has fixed number of columns and any number of row.
    </p>
    <p>
      In SQL, we use the <code>CREATE TABLE</code> keywords, followed by the
      table name, column name(s) and data types to create a new table in our
      database.
    </p>
    <div id="editor-create" style="height: 50px;"></div>

    <b-table-simple
      class="text-center mt-3"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>

    <p>
      In example above, we created an empty table with 3 columns - id, name and
      age.
    </p>

    <h4 class="mt-3">Data Types</h4>
    <p>
      Different database uses different data type naming. Be sure to check the
      documentation of specific database you use. Here are some of the common
      data types use by most relational database.
    </p>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Data type</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>int</b-th>
          <b-td>Integer number (no decimal)</b-td>
        </b-tr>
        <b-tr>
          <b-th>numeric</b-th>
          <b-td>Exact numerical (number with decimal)</b-td>
        </b-tr>
        <b-tr>
          <b-th>varChar(n)</b-th>
          <b-td>
            String with a length of <em>n</em>. Can be from 0 to 65535
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a table <code>cars</code> with 3 columns
            <ul>
              <li>id - int</li>
              <li>brand - varChar(255)</li>
              <li>year - int</li>
            </ul>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `CREATE TABLE student (id int, name varChar(255), age int);
`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-create", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
