<template>
  <div>
    <h2>Get / Set Element</h2>

    <p>
      Array elements are numbered counting from zero. The positions of elements
      also referred as index numbers.
    </p>

    <h4>Get element</h4>
    <p>
      We can get an element in an array by its index number in square brackets.
    </p>

    <div id="editor-array-get" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 4
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Replace element</h4>
    <p class="mt-3">Element in array can also be replaced</p>
    <div id="editor-array-replace" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [21, 999, 63, 2]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Add element</h4>
    <p class="mt-3">Adding element based on index</p>
    <div id="editor-array-add" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [21, 4, 63, 2, 999]
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a variable <code>someNumber</code> and assign it with the
            first element of <code>someArray</code>
          </li>
          <li>Replace the third element in <code>someArray</code> to 3</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-array-get", `let numberArray = [21, 4, 63, 2];\nlet selected = numberArray[1];\nconsole.log(selected);`);
    this.initEditor("editor-array-replace", `let numberArray = [21, 4, 63, 2];\nnumberArray[1] = 999;\nconsole.log(numberArray);`);
        this.initEditor("editor-array-add", `let numberArray = [21, 4, 63, 2];\nnumberArray[4] = 999;\nconsole.log(numberArray);`);

  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
