/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = ``;
export const seedTest = ``;
export const testCases = ``;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /print\s*\(\s*3\s*\*\s*5\s*-\s*4\s*\)/.test(code),
    "Print the value of 3 times 5 minus 4"
  );

  assert(
    /print\s*\(\s*12\s*%\s*3\s*\)/.test(code),
    "Print the remainder of 12 divided by 3"
  );

  window.console = oldConsole;
  return true;
}
