<template>
  <div>
    <b-container class="my-5" v-if="!isLoading">
      <b-row class="mb-3">
        <div class="mb-2">
          <b-button class="text-muted" variant="link" to="/">
            <b-icon icon="chevron-left" /> Back
          </b-button>
        </div>
        <b-col md="12">
          <h2>{{ classroom.name }}</h2>
        </b-col>
        <b-col md="12">
          <div class="classroom-code text-muted">
            Class code: {{ classroom.classCode }}
          </div>
          <div class="classroom-code text-muted">
            Invitation Link: {{ invitationLink }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <b-card no-body>
            <b-tabs card>
              <b-tab title="Courses" active title-link-class="text-dark">
                <b-card-text>
                  <teacher-classroom-course-tab
                    :classroom="classroom"
                    :courses="courses"
                    @update-courses="onCoursesUpdated"
                  />
                </b-card-text>
              </b-tab>
              <b-tab title="Progress" title-link-class="text-dark">
                <b-card-text>
                  <teacher-classroom-progress-tab
                    :classroom="classroom"
                    :courses="courses"
                  />
                </b-card-text>
              </b-tab>
              <b-tab title="Students" title-link-class="text-dark">
                <b-card-text>
                  <teacher-classroom-student-tab :classroom="classroom" />
                </b-card-text>
              </b-tab>
              <b-tab title="Setting" title-link-class="text-dark">
                <b-card-text>
                  <teacher-classroom-setting-tab :classroom="classroom" />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { classroomServices, courseServices } from "@/services";
import TeacherClassroomCourseTab from "./TeacherClassroomCourseTab.vue";
import TeacherClassroomStudentTab from "./TeacherClassroomStudentTab.vue";
import TeacherClassroomSettingTab from "./TeacherClassroomSettingTab.vue";
import TeacherClassroomProgressTab from "./TeacherClassroomProgressTab.vue";

export default {
  name: "TeacherClassroom",

  components: {
    TeacherClassroomCourseTab,
    TeacherClassroomStudentTab,
    TeacherClassroomSettingTab,
    TeacherClassroomProgressTab
  },

  data() {
    return {
      classroomId: this.$route.params.id,
      classroom: {},
      courses: [],
      isLoading: true
    };
  },

  async created() {
    const loader = this.$loading.show();
    await this.getClassroom();
    await this.getCourses();
    loader.hide();
    this.isLoading = false;
  },

  computed: {
    courseOptions() {
      return this.courses.map(course => {
        return {
          text: course.name,
          value: course.id
        };
      });
    },

    invitationLink() {
      return `${process.env.VUE_APP_BASE_URL}/invite/${this.classroomId}`;
    }
  },

  methods: {
    getClassroom() {
      return classroomServices
        .getClassroom(this.classroomId)
        .then(classroom => {
          this.classroom = classroom;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getCourses() {
      return courseServices
        .getCoursesByClassroom(this.classroom.id)
        .then(courses => {
          this.courses = courses;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    async onCoursesUpdated() {
      const loader = this.$loading.show();
      await this.getCourses();
      this.$bvToast.toast(`Course removed from class`, {
        title: "Classroom Setting",
        autoHideDelay: 5000,
        variant: "danger",
        appendToast: true
      });
      loader.hide();
      return;
    }
  }
};
</script>

<style lang="less" scoped>
.classroom-code {
  font-size: 1.2rem !important;
}
</style>
