const level1 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
  <category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
</xml>`;

const level2 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level3 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level4 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level5 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_path">
      <field name="PATH">PATH_UP</field>
    </block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
    <block type="logic_negate"></block>
    <block type="boolean_arrive_destination">
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_whileUntil">
      <field name="MODE">WHILE</field>
    </block>
</category>
</xml>`;

const level6 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_path">
      <field name="PATH">PATH_UP</field>
    </block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
    <block type="logic_negate"></block>
    <block type="boolean_arrive_destination">
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_whileUntil">
      <field name="MODE">WHILE</field>
    </block>
    <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level7 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_path">
      <field name="PATH">PATH_UP</field>
    </block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
    <block type="logic_negate"></block>
    <block type="boolean_arrive_destination">
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_whileUntil">
      <field name="MODE">WHILE</field>
    </block>
    <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level8 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_path">
      <field name="PATH">PATH_UP</field>
    </block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
    <block type="logic_negate"></block>
    <block type="boolean_arrive_destination">
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_whileUntil">
      <field name="MODE">WHILE</field>
    </block>
    <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level9 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_path">
      <field name="PATH">PATH_UP</field>
    </block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
    <block type="logic_negate"></block>
    <block type="boolean_arrive_destination">
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_whileUntil">
      <field name="MODE">WHILE</field>
    </block>
    <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

const level10 = `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
<category colour="#5b67a5" name="Movement">
    <block type="movement_right"></block>
    <block type="movement_left"></block>
    <block type="movement_up"></block>
    <block type="movement_down"></block>
  </category>
  <category name="Logic" colour="#5b80a5">
    <block type="controls_if"></block>
    <block type="boolean_path">
      <field name="PATH">PATH_UP</field>
    </block>
    <block type="boolean_sprite_ahead">
      <field name="SPRITE_TYPE">GREENSLIME</field>
    </block>
    <block type="logic_negate"></block>
    <block type="boolean_arrive_destination">
    </block>
  </category>
  <category name="Actions" colour="#a55b5b">
  <block type="action_attack"></block>
  </category>
<category name="Loops" colour="#5ba55b">
  <block type="controls_whileUntil">
      <field name="MODE">WHILE</field>
    </block>
    <block type="controls_for">
    <field name="VAR" id="2zuUr/a1-vB_Af;}@jXg">i</field>
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">3</field>
      </block>
    </value>
    <value name="BY">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
  </block>
</category>
</xml>`;

export default [
  level1,
  level2,
  level3,
  level4,
  level5,
  level6,
  level7,
  level8,
  level9,
  level10
];
