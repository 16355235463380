<template>
  <div>
    <b-container class="my-5" v-if="!isLoading">
      <b-row class="mb-5">
        <b-col>
          <h1>{{ course.name }}</h1>
          <p>
            {{ course.description }}
          </p>
        </b-col>
        <b-col md="5">
          <b-img :src="course.imageURL" fluid></b-img>
        </b-col>
      </b-row>

      <b-row v-if="!isStudentInClass">
        <b-col>
          <b-card>
            <b-card-text class="text-center">
              😢 Oops. You do not have access to this course
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mb-3" v-for="chapter in chapters" :key="chapter.id" v-else>
        <b-col>
          <b-card
            header-tag="header"
            footer-tag="footer"
            header-bg-variant="white"
            footer-bg-variant="white"
          >
            <template #header>
              <h4 class="mb-0">{{ chapter.name }}</h4>
            </template>

            <b-card-text
              class="d-flex align-items-center flex-wrap"
              style="row-gap: 15px;"
            >
              <progress-icon
                v-for="activity in chapterActivities(chapter.id)"
                :key="activity.id"
                :activity="activity"
                @click-icon="onClickIcon"
                :isCompleted="isActivityDone(activity.id)"
                class="mr-2"
              ></progress-icon>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  courseServices,
  chapterServices,
  activityServices,
  classroomServices
} from "@/services";
import ProgressIcon from "@/components/ProgressIcon";
import _ from "lodash";

export default {
  name: "StudentClassroomCourse",

  data() {
    return {
      classroomId: this.$route.params.classroomId,
      courseId: this.$route.params.courseId,
      course: {},
      classroom: {},
      activityStatus: {},
      chapters: [],
      activities: [],
      classrooms: [],
      isLoading: true
    };
  },

  components: {
    ProgressIcon
  },

  async created() {
    const loader = this.$loading.show();
    await this.getStudentStatus();
    await this.getClassroom();
    await this.getCourse();
    await this.getChapters();
    await this.getActivities();
    loader.hide();
    this.isLoading = false;
  },

  computed: {
    isStudentInClass() {
      return this.classroom.studentIds.includes(this.$store.state.user.id);
    }
  },

  methods: {
    getCourse() {
      return courseServices.getCourse(this.courseId).then(course => {
        this.course = course;
      });
    },

    getClassroom() {
      return classroomServices
        .getClassroom(this.classroomId)
        .then(classroom => {
          this.classroom = classroom;
        });
    },

    getChapters() {
      return chapterServices
        .getChapters(this.courseId)
        .then(chapters => {
          if (this.course.type == "user-created") {
            chapters = this.sortChapters(chapters);
          }
          this.chapters = _.sortBy(chapters, ["order"]);
        })
        .catch(error => {
          alert(error);
        });
    },

    sortChapters(chapters) {
      chapters = chapters.map(chapter => {
        return {
          ...chapter,
          order: this.course.chapterOrder[chapter.id]
        };
      });
      return _.orderBy(chapters, ["order"]);
    },

    getStudentStatus() {
      return activityServices
        .getStudentStatus(this.classroomId, this.$store.state.user.id)
        .then(activityStatus => {
          this.activityStatus = activityStatus;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getActivities() {
      return activityServices
        .getActivities(this.courseId)
        .then(activities => {
          if (this.course.type == "user-created") {
            activities = this.sortActivities(activities);
          }
          this.activities = activities;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    sortActivities(activities) {
      activities = activities.map(activity => {
        return {
          ...activity,
          order: this.course.activityOrder[activity.id]
        };
      });
      return _.orderBy(activities, ["order"]);
    },

    isActivityDone(activityId) {
      let isDone = false;

      if (
        this.activityStatus == null ||
        this.activityStatus[this.courseId] == null
      ) {
        return false;
      }

      if (this.activityStatus[this.courseId][activityId] == "done")
        isDone = true;
      return isDone;
    },

    onClickIcon(activity) {
      this.$router.push(
        `/classroom/${this.classroomId}/course/${this.courseId}/activity/${activity.id}`
      );
    },

    chapterActivities(chapterId) {
      return this.activities.filter(activity => {
        return activity.chapterId == chapterId;
      });
    }
  }
};
</script>

<style></style>
