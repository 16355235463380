/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `a = 15
b = 0

# Only change code below this line;
exp_one = a <= 0
exp_two = b != 0
exp_three = -10 > 0
exp_four = 30 < 0
`;

export const seedTest = `
assert a == 15, "Do not change default code"
assert b == 0, "Do not change default code"`;
export const testCases = `
assert exp_one, "exp_one not evaluated to True"
assert exp_two, "exp_two not evaluated to True"
assert exp_three, "exp_three not evaluated to True"
assert exp_four, "exp_four not evaluated to True"`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index] + "\n";
  }
  Sk.importMainWithBody("", false, seedCode + seedTest);

  Sk.importMainWithBody("", false, code + testCases);

  window.console = oldConsole;
  return true;
}
