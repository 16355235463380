<template>
  <div>
    <h2>While Loop</h2>

    <video-player
      poster="/images/loops.png"
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-29-loop-while.mp4?alt=media&token=2d4f7451-83bc-49fe-a572-02bcd4a164dc"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      A while loop iterates through a block of code as long the given condition
      is true.
    </p>
    <img class="img-fluid" src="@/assets/foundation/while_loop.png" />
    <p>
      In above example, as long as
      <span class="block block-variable">counter</span> variable is less than 5,
      the while loop will continue iterate.
    </p>
    <p>
      In each loop, we add 1 to the
      <span class="block block-variable">counter</span>. When it
      <span class="block block-variable">counter</span> equals to 5, the while
      loop will stop.
    </p>
    <p>
      When using a while loop, if there are no changes in the condition, the
      while loop will loop forever! This is called an infinite loop. When that
      happens, your program will crash!
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">numberCounter</span> and set it
            to a value of 1
          </li>
          <li>
            Using a while loop, print value
            <span class="block block-variable">numberCounter</span> while it is
            less than 6
          </li>
          <li>
            Make sure to increase the value of
            <span class="block block-variable">numberCounter</span> before the
            loop ends!
          </li>
        </ol>

        <p class="mb-0 mt-2">
          *Use either &lt; or ≤ operator to solve this activity
        </p>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1
          <br />
          >> 2
          <br />
          >> 3
          <br />
          >> 4
          <br />
          >> 5
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "FoundationVariable",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
