<template>
  <div>
    <h2>Exercise 4</h2>
    <p>AND and OR operator can be used to combine multiple conditions</p>
    <h4>AND Operator</h4>
    <p>
      The AND operator allows the existence of multiple conditions in an SQL
      statement's WHERE clause.
    </p>

    <h4>OR Operator</h4>
    <p>
      The OR operator is used to combine multiple conditions in an SQL
      statement's WHERE clause.
    </p>

    <div id="editor-and-or" style="height: 50px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Select all the data from <code>devices</code> table with "phone"
            <code>type</code> that have <code>price</code> less than 1000
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
const code = `SELECT * FROM students WHERE age > 13 AND class = "diamond";`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-and-or", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
