/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof firstNumber  === 'undefined') var firstNumber ;
    if (typeof answer === 'undefined') var answer;
    try {returnResult(firstNumber, answer);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      console.log(result.printedText);
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (firstNumber: number, answer: number) => {
      result.firstNumber = firstNumber;
      result.answer = answer;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.firstNumber === undefined) {
    status.message = "firstNumber is not created";
  } else if (result.answer === undefined) {
    status.message = "answer is not created";
  } else if (result.firstNumber !== 150) {
    status.message = "firstNumber not assigned with correct value";
  } else if (result.answer !== 91) {
    status.message = "answer value is not correct";
  } else if (
    !code.includes("answer = (firstNumber + 20) - 79") &&
    !code.includes("answer = firstNumber + (20 - 79)")
  ) {
    status.message = "use operators to calculate answer";
  } else if (!code.includes("alert(answer)")) {
    status.message = "answer not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
