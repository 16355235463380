<template>
  <div>
    <h2>Challenge 8 - Number to Words</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">toWords</span> that takes in a
            number argument greater than zero and less than 1000
          </li>
          <li>
            Function will return a string expressing the number in words
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Example input 1: 16
          <br />
          Example input 1: 52
          <br />
          Example input 2: 999
          <br />
          <br />
          Expected result:
          <br />
          >> "sixteen"
          <br />
          >> "fifty-two"
          <br />
          >> "nine hundred and ninety-nine"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
