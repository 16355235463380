<template>
  <div>
    <h2>List Introduction</h2>

    <p>
      List is one of the built-in data type used to store collections of data.
      It stores a sequence of data that are ordered, changable and allow
      duplicated values.
    </p>
    <p>
      A list is created by placing all the items (elements) inside square
      brackets [], separated by commas.
    </p>
    <div id="editor-list" style="height: 110px;"></div>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create variable <code>odd_numbers</code> and assign it with a list
            with 1, 3, 5, and 7
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `empty_list = []
number_list = [1, 2, 3]
string_list = ["car", "bike", "bus"]
mixed_list = [1, "car", 2]`;

const code2 = `print("Number of apples: " + 32)`;
const code3 = `print("Number of apples: " + str(32))`;

export default {
  mounted() {
    this.initEditor("editor-list", code);
    this.initEditor("editor-join-error", code2);
    this.initEditor("editor-join-fix", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
