<template>
  <div>
    <h2>Conditional Introduction</h2>

    <p>
      Very often a program needs to execute different code based on different
      conditions. We can do things conditionally in our programs using if
      statements combined with conditional expressions.
    </p>

    <p>
      An if statement tells the program to execute a block of code if a
      condition expression is <code>true</code>.
    </p>

    <div id="editor-cond-intro" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "The number is 5"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start learning about conditionals!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let number = 5;
if (number == 5) {
  console.log("The number is 5");
}
`

export default {
  mounted() {
    this.initEditor("editor-cond-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
