<template>
  <div>
    <h2>Exercise 13</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare a function named <code>oddNumberGenerator</code> with two
            parameters.
          </li>
          <li>
            Return an array of all the odd numbers between the two arguments
            provided.
          </li>
          <li>
            Assume first argument value is always less than second argument
          </li>
          <li>Return empty array if no odd number found</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `function divide(a, b) {
  return a / b;
}

let result = divide(9, 3);
console.log(result);
`

const codeReturn = `function isEven(number) {
  if (number % 2 == 0) {
    return true;
  }

  // This line will not be reached if number is even
  return false;
}

console.log(isEven(10));
`

export default {
  mounted() {
    this.initEditor("editor-function-return", code);
    this.initEditor("editor-function-return-2", codeReturn);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
