/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof numbers === 'undefined') var numbers;
    if (typeof randomNumber === 'undefined') var randomNumber;
    try {returnResult(numbers, randomNumber);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (numbers: number[], randomNumber: number) => {
      result.numbers = numbers;
      result.randomNumber = randomNumber;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const index = result.randomNumber + (result.randomNumber - 1);
  const print = result.printedText.substring(index);

  let answer = "";
  for (let i = 1; i <= result.randomNumber; i++) {
    answer += i;
  }

  console.log(print);
  console.log(answer);
  if (result.numbers === undefined) {
    status.message = "Variable not setup correctly";
  } else if (print != answer) {
    status.message = "wrong values printed";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
