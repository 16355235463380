export const deviceTable = `
  CREATE TABLE devices (id int, name varChar(255), type varChar(255), price numeric);
  INSERT INTO devices VALUES (0, "iPhone X", "phone", 999);
  INSERT INTO devices VALUES (1, "Samsung Galaxy", "phone", 799);
  INSERT INTO devices VALUES (2, "ThinkPad X1", "laptop", 1999);
  INSERT INTO devices VALUES (3, "Dell XPS", "laptop", 1899);
  INSERT INTO devices VALUES (4, "Huawei Mate X", "laptop", 1689);
  INSERT INTO devices VALUES (5, "Microsoft Surface", "laptop", 1459);
  INSERT INTO devices VALUES (6, "iPhone 12", "phone", 1499);
  INSERT INTO devices VALUES (7, "Samsung Fold", "phone", 2100);
  INSERT INTO devices VALUES (8, "Google Pixel", "phone", 799);
  INSERT INTO devices VALUES (9, "iPad Mini 5", "tablet", 1129);
  INSERT INTO devices VALUES (10, "Pocophone", "phone", 499);
  INSERT INTO devices VALUES (11, "Xiaomi Mi 10", "phone", 599);
  INSERT INTO devices VALUES (12, "HP Pavillion", "laptop", 779);
  INSERT INTO devices VALUES (13, "HP Spectre", "laptop", 889);
  INSERT INTO devices VALUES (14, "Macbook Pro", "laptop", 1399);
  INSERT INTO devices VALUES (15, "Realme", "phone", 399);
  SELECT * from devices;
`;
