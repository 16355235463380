import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";

// Layouts
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";

import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import BlocklyActivityDemo from "../views/BlocklyActivityDemo.vue";
import Invite from "../views/Invite.vue";
import Plans from "../views/Plans.vue";
import PaymentStatus from "../views/PaymentStatus.vue";

import Setting from "../views/setting/Setting.vue";
import Profile from "../views/setting/Profile.vue";
import Account from "../views/setting/Account.vue";
import License from "../views/setting/License.vue";
import Billing from "../views/setting/Billing.vue";
import Password from "../views/setting/Password.vue";

import Classroom from "../views/classrooms/Classroom.vue";

import Courses from "../views/courses/Courses.vue";
import Course from "../views/courses/Course.vue";
import CreateCourse from "../views/courses/CreateCourse.vue";
import StudentClassroomCourse from "../views/courses/StudentClassroomCourse.vue";

import Activity from "../views/activities/Activity.vue";
import EditActivity from "../views/activities/EditActivity.vue";
import ClassroomActivity from "../views/activities/ClassroomActivity.vue";
import TeacherViewActivity from "../views/activities/TeacherViewActivity.vue";

// Playgrounds
import Playground from "../views/playgrounds/Playground.vue";
import BlocklyPlayground from "../views/playgrounds/BlocklyPlayground.vue";
import JavascriptPlayground from "../views/playgrounds/JavascriptPlayground.vue";
import PythonPlayground from "../views/playgrounds/PythonPlayground.vue";
import SqlPlayground from "../views/playgrounds/SqlPlayground.vue";

// Tools
import Tools from "@/views/tools/Tools.vue";
import BinaryImageTool from "@/views/tools/BinaryImageTool.vue";
import BinarySoundTool from "@/views/tools/BinarySoundTool.vue";
import BinaryTextTool from "@/views/tools/BinaryTextTool.vue";
import TextCompressionTool from "@/views/tools/TextCompressionTool.vue";
import NumberSystemTool from "@/views/tools/NumberSystemTool.vue";

// Hour of Code
import Dungeon from "../views/hourofcode/dungeon/Dungeon.vue";

import * as authServices from "@/services/auth.service";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      layout: DefaultLayout
    },
    component: Dashboard
  },
  {
    path: "/hour-of-code/blockly-games-dungeon-crawler",
    name: "Dungeon",
    meta: {
      layout: EmptyLayout,
      isAuthExempted: true
    },
    component: Dungeon
  },
  {
    path: "/setting",
    name: "Setting",
    redirect: "/setting/profile",
    meta: {
      layout: DefaultLayout
    },
    component: Setting,
    children: [
      {
        path: "profile",
        name: "Profile",
        meta: {
          layout: DefaultLayout
        },
        component: Profile
      },
      {
        path: "account",
        name: "Account",
        meta: {
          layout: DefaultLayout
        },
        component: Account
      },
      {
        path: "licenses",
        name: "Licenses",
        meta: {
          layout: DefaultLayout
        },
        component: License
      },
      {
        path: "billing",
        name: "Billing",
        meta: {
          layout: DefaultLayout
        },
        component: Billing
      },
      {
        path: "password",
        name: "Password",
        meta: {
          layout: DefaultLayout
        },
        component: Password
      }
    ]
  },
  {
    path: "/plans",
    name: "Plans",
    meta: {
      layout: DefaultLayout
    },
    component: Plans
  },
  {
    path: "/playground",
    name: "Playground",
    meta: {
      layout: DefaultLayout,
      isAuthExempted: true
    },
    component: Playground,
    children: [
      {
        path: "blockly",
        name: "BlocklyPlayground",
        meta: {
          layout: DefaultLayout,
          isAuthExempted: true
        },
        component: BlocklyPlayground
      },
      {
        path: "javascript",
        name: "JavascriptPlayground",
        meta: {
          layout: DefaultLayout,
          isAuthExempted: true
        },
        component: JavascriptPlayground
      },
      {
        path: "python",
        name: "PythonPlayground",
        meta: {
          layout: DefaultLayout,
          isAuthExempted: true
        },
        component: PythonPlayground
      },
      {
        path: "sql",
        name: "SqlPlayground",
        meta: {
          layout: DefaultLayout,
          isAuthExempted: true
        },
        component: SqlPlayground
      }
    ]
  },
  {
    path: "/payment-status",
    name: "PaymentStatus",
    meta: {
      layout: DefaultLayout
    },
    component: PaymentStatus
  },
  {
    path: "/tool/number-system",
    name: "NumberSystemTool",
    meta: {
      layout: DefaultLayout
    },
    component: NumberSystemTool
  },
  {
    path: "/tool/binary-representation-of-images",
    name: "BinaryImageTool",
    meta: {
      layout: DefaultLayout
    },
    component: BinaryImageTool
  },
  {
    path: "/tool/binary-representation-of-sound",
    name: "BinarySoundTool",
    meta: {
      layout: DefaultLayout
    },
    component: BinarySoundTool
  },
  {
    path: "/tool/binary-representation-of-text",
    name: "BinaryTextTool",
    meta: {
      layout: DefaultLayout
    },
    component: BinaryTextTool
  },
  {
    path: "/tool/lossless-text-compression",
    name: "TextCompressionTool",
    meta: {
      isPremium: true,
      layout: DefaultLayout
    },
    component: TextCompressionTool
  },
  {
    path: "/activity/binary-representation-of-sound",
    name: "BinaryImageSound",
    meta: {
      isPremium: true,
      layout: DefaultLayout
    },
    component: BinarySoundTool
  },
  {
    path: "/tools",
    name: "Tools",
    meta: {
      layout: DefaultLayout
    },
    component: Tools
  },

  {
    path: "/blockly-activity-demo",
    name: "BlocklyActivityDemo",
    meta: {
      layout: EmptyLayout,
      isAuthExempted: true
    },
    component: BlocklyActivityDemo
  },
  {
    path: "/invite/:classroomId",
    name: "Invite",
    meta: {
      layout: DefaultLayout
    },
    component: Invite
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: EmptyLayout,
      isAuthExempted: true,
      isDeniedAfterLogin: true
    },
    component: Login
  },
  {
    path: "/signup",
    name: "Signup",
    meta: {
      layout: EmptyLayout,
      isAuthExempted: true,
      isDeniedAfterLogin: true
    },
    component: Signup
  },
  {
    path: "/classroom/:id/edit",
    name: "Classroom",
    meta: {
      layout: DefaultLayout
    },
    component: Classroom
  },
  {
    path: "/courses",
    name: "Courses",
    meta: {
      layout: DefaultLayout
    },
    component: Courses
  },
  {
    path: "/course/:id",
    name: "Course",
    meta: {
      layout: DefaultLayout
    },
    component: Course
  },
  {
    path: "/course/:id/edit",
    name: "CreateCourse",
    meta: {
      layout: DefaultLayout
    },
    component: CreateCourse
  },
  {
    path: "/classroom/:classroomId/course/:courseId",
    name: "StudentClasroomCourse",
    meta: {
      layout: DefaultLayout
    },
    component: StudentClassroomCourse
  },
  {
    path: "/classroom/:classroomId/course/:courseId/activity/:activityId",
    name: "ClassroomActivity",
    meta: {
      layout: DefaultLayout
    },
    component: ClassroomActivity
  },
  {
    path:
      "/view/classroom/:classroomId/student/:studentId/course/:courseId/activity/:activityId",
    name: "TeacherViewBlocklyActivity",
    meta: {
      layout: DefaultLayout
    },
    component: TeacherViewActivity
  },
  {
    path: "/course/:courseId/activity/:activityId",
    name: "Activity",
    meta: {
      layout: DefaultLayout
    },
    component: Activity
  },
  {
    path: "/course/:courseId/activity/:activityId/edit",
    name: "EditActivity",
    meta: {
      layout: DefaultLayout
    },
    component: EditActivity
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const isAuthExempted = to.matched.some(record => record.meta.isAuthExempted);
  const isDeniedAfterLogin = to.matched.some(
    record => record.meta.isDeniedAfterLogin
  );

  const currentUser = await authServices.getCurrentUser();
  const isStateUpdated = store.getters["user/state"].id != null;

  if (currentUser) {
    if (!isStateUpdated) {
      await store.dispatch("login", currentUser.uid);
    }
    isDeniedAfterLogin ? next("/") : next();
  } else if (to.name == "Invite") {
    next(`/login?cl=${to.params.classroomId}`);
  } else if (isAuthExempted) {
    next();
  } else {
    next("/login");
  }
});

export default router;
