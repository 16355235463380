<template>
  <div>
    <h2>Assignment Operators</h2>

    <h4 class="mt-3">Updating Variable Value</h4>
    <p>
      A variable can be updated with its existing value. In below example, the
      initial value of a is 5, the new value of a is assigned to its existing
      value plus 10 (which is 5 + 10).
    </p>
    <div id="editor-update" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          5
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Combined Operators</h4>
    <p>
      Assignment operators are used to assign values to variables.
    </p>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Operator</b-th>
          <b-th>Same as</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>x += 1</b-th>
          <b-td>x = x + 1</b-td>
        </b-tr>
        <b-tr>
          <b-th>x -= 1</b-th>
          <b-td>x = x - 1</b-td>
        </b-tr>
        <b-tr>
          <b-th>x *= 1</b-th>
          <b-td>x = x * 1</b-td>
        </b-tr>
        <b-tr>
          <b-th>x /= 1</b-th>
          <b-td>x = x / 1</b-td>
        </b-tr>
        <b-tr>
          <b-th>x %= 1</b-th>
          <b-td>x = x % 1</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Reassign <code>first_num</code> with a value three times its current
            value
          </li>
          <li>Print <code>first_num</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `a = 2
a = a + 3
print(a)`;

export default {
  mounted() {
    this.initEditor("editor-update", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
