/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isZero;
    var isFive;
    var isEightEight;

    try { 
      window.alert = function() {};
      isZero = toDecimal("00000");
      isFive = toDecimal("00101");
      isEightEight = toDecimal("1011000");
    } catch (err) {
      window.alert(err);
    };

    try {returnResult(isZero, isFive, isEightEight);} catch (err) { window.alert(err); };
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      isZero: number,
      isFive: number,
      isEightEight: number
    ) => {
      result.isZero = isZero;
      result.isFive = isFive;
      result.isEightEight = isEightEight;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function toDecimal")) {
    status.message = "toDecimal function is not created";
  } else if (
    result.isZero != 0 ||
    result.isFive != 5 ||
    result.isEightEight != 88
  ) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
