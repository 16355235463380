<template>
  <div>
    <h2>Modules</h2>

    <p>
      A Python module is a Python file that contains functions, classes or
      variables. There are many built-in Python modules available to help to
      write your code more efficiently without reinventing the wheel. You can
      find all the modules
      <a href="https://docs.python.org/3/library/index.html" target="_blank"
        >here</a
      >.
    </p>
    <p>To use a module, we use the <code>import</code> statement</p>
    <div id="editor-module-import" style="height: 60px;"></div>

    <h4 class="mt-3">Math Module</h4>
    <p>
      This module provides access to the mathematical functions. Below are
      examples of useful functions or constants provided by
      <code>math</code> module. You can find the full list
      <a href="https://docs.python.org/3/library/math.html" target="_blank">
        here </a
      >.
    </p>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Usage</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>math.sqrt(x)</b-th>
          <b-td>return the square root of x</b-td>
        </b-tr>
        <b-tr>
          <b-th>math.fabs(x)</b-th>
          <b-td>return the absolute value of x</b-td>
        </b-tr>
        <b-tr>
          <b-th>math.sin(x)</b-th>
          <b-td>return the sine of x radians </b-td>
        </b-tr>
        <b-tr>
          <b-th>math.cos(x)</b-th>
          <b-td>return the cosine of x radians </b-td>
        </b-tr>
        <b-tr>
          <b-th>math.tan(x)</b-th>
          <b-td>return the tangent of x radians </b-td>
        </b-tr>
        <b-tr>
          <b-th>math.pi</b-th>
          <b-td>mathematical constant π</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div id="editor-module-math" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          5
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create variable <code>root</code> and assign it with the square root
            of 289
          </li>
          <li>Print <code>root</code></li>
        </ol>
        <br />
        * Use math module to calculate the value of
        <code>root</code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `import math
import statistics`;

const code2 = `import math
print(math.fabs(-5))`;

export default {
  mounted() {
    this.initEditor("editor-module-import", code);
    this.initEditor("editor-module-math", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
