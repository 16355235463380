/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `weight = 60
height = 1.75

# Write your code only below this line
`;
export const seedTest = ``;
export const testCases = `
assert weight == 60, "do not change this initial code"
assert height == 1.75, "do not change this initial code"
assert bmi == (weight/height/height), "wrong value for bmi"`;

export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(/print\s*\(\s*bmi\s*\)/.test(code), "Print the value of bmi");

  window.console = oldConsole;
  return true;
}
