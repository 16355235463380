/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isTwoHundred = 0;
    try { isTwoHundred = plusHundred(100);} catch (err) {};
    try {returnResult(isTwoHundred);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (isTwoHundred: number) => {
      result.isTwoHundred = isTwoHundred;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function plusHundred(number)")) {
    status.message = "plusHundred function is not created correctly";
  } else if (result.isTwoHundred != 200) {
    status.message = "Logic for function is not correct";
  } else if (!code.includes("answer = plusHundred(15)")) {
    status.message =
      "Answer variable not assigned to result of function correctly";
  } else if (!code.includes("alert(answer)")) {
    status.message = "Answer not printed correctly";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
