<template>
  <div>
    <h2>Data Type - Number, String &amp; Boolean</h2>

    <h4>Number</h4>
    <p>The number type represents both integer and decimal numbers.</p>
    <div id="editor-datatype-number" style="height: 60px;"></div>

    <h4 class="mt-3">String</h4>
    <p>
      A string is a sequence of characters used to represent text. A string in
      JavaScript must be surrounded by quotes. There are 3 types of quotes we
      can use:
    </p>
    <ol>
      <li>Single quotes: 'This is a string'</li>
      <li>Double quotes: "This is a string"</li>
      <li>Backticks: `This is a string`</li>
    </ol>
    <p>
      Single quotes and doubles quotes are practically the same in JavaScript.
      Backticks has additional functionality that allows us to embed variable
      and expression into a string by wrapping them around ${ }.
    </p>
    <div id="editor-datatype-string" style="height: 80px;"></div>

    <h4 class="mt-3">Boolean</h4>
    <p>
      Boolean is a logical data type that can either be <code>true</code> or
      <code>false</code>. It is used selection statement such as if statement to
      decide which code to execute.
    </p>
    <div id="editor-datatype-boolean" style="height: 60px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <code>someNumber</code> and assign it with a
            number of 42
          </li>
          <li>
            Create a variable <code>someString</code> and assign it with a
            string of "forty-two"
          </li>
          <li>
            Create a variable <code>isCorrect</code> and assign it with boolean
            of <code>true</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-datatype-number", `let numberInteger = 10;\nlet numberDecimal = 3.142;`);
    this.initEditor("editor-datatype-string", `let stringOne = "hello";\nlet stringTwo = 'hello';\nlet stringThree = \`hello\`;\n`);
    this.initEditor("editor-datatype-boolean", `let trueBoolean = true;\nlet falseBoolean = false;`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
