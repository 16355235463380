/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myName === 'undefined') var myName;
    if (typeof favouriteAnimal === 'undefined') var favouriteAnimal;
    try {returnResult(myName, favouriteAnimal);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myName: string, favouriteAnimal: string) => {
      result.myName = myName;
      result.favouriteAnimal = favouriteAnimal;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.myName === undefined) {
    status.message = "myName variable not created";
  } else if (result.favouriteAnimal === undefined) {
    status.message = "favouriteAnimal variable not created";
  } else if (result.myName == 0 || result.myName == "") {
    status.message = "Assign a proper value to myName";
  } else if (result.favouriteAnimal == 0 || result.favouriteAnimal == "") {
    status.message = "Assign a proper value to favouriteAnimal";
  } else if (!code.includes("alert(myName)")) {
    status.message = "myName not printed";
  } else if (!code.includes("alert(favouriteAnimal)")) {
    status.message = "favouriteAnimal not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
