<template>
  <div>
    <b-container>
      <b-row class="mb-3">
        <b-col>
          <h2>Billing</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="bg-white p-3">
            <div class="form-container"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.form-container {
  width: 60%;
}
</style>
