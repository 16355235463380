<template>
  <div>
    <b-container>
      <b-row class="mb-3">
        <b-col>
          <h2>My Profile</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="bg-white p-3">
            <div class="form-container">
              <div class="mb-2">Account type: {{ $store.state.user.role }}</div>
              <b-form @submit.prevent="onSave">
                <b-form-group label="Email">
                  <b-form-input
                    :value="$store.state.user.email"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Name">
                  <b-form-input type="text" v-model="user.name"></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="dark" :disabled="isSaving">
                  <b-spinner small v-if="isSaving"></b-spinner>
                  {{ isSaving ? "Loading..." : "Save" }}
                </b-button>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { userServices } from "@/services";
export default {
  name: "SettingProfile",

  data() {
    return {
      user: {},
      isSaving: false
    };
  },

  async created() {
    const loader = this.$loading.show();
    await this.getUser();
    loader.hide();
  },

  methods: {
    getUser() {
      return userServices
        .getUser(this.$store.state.user.id)
        .then(user => {
          this.user = user;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onSave() {
      this.isSaving = true;

      return userServices
        .updateUser(this.$store.state.user.id, {
          name: this.user.name
        })
        .then(() => {
          this.isSaving = false;
          return this.$bvToast.toast(`Profile saved`, {
            title: "Profile Setting",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: true
          });
        })
        .catch(error => {
          alert(error.message);
          this.isSaving = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.form-container {
  width: 60%;
}
</style>
