<template>
  <div>
    <h2>Print</h2>

    <p>
      We have seen the <code>print()</code> function used in many of our
      previous activities. This function is used to output data to screen.
    </p>
    <div id="editor-print" style="height: 40px;"></div>

    <p class="mt-2">
      We can pass two or more different values in the print function separated
      by commas.
    </p>

    <div id="editor-print-comma" style="height: 100px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Hello World!
          <br />
          The answer to everything is 42
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Print a string of "Bonjour!"
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `print("Hello World!")`;
const code2 = `print("Hello", "World!")

number = 42
print("The answer to everything is ", number)`;

export default {
  mounted() {
    this.initEditor("editor-print", code);
    this.initEditor("editor-print-comma", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
