<template>
  <div>
    <h2>For...in Loop</h2>

    <p>
      for...in is a special form of for loop to iterate over all keys in an
      object.
    </p>

    <div id="editor-object-forin" style="height: 200px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > ["a", "b", "c"]
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare a variable <code>values</code> and assign it with an array
            of values of <code>car</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let someObject = {
  a: 1,
  b: 2,
  c: 3
};

let keys = [];
for (let key in someObject) {
  keys.push(key);
}
console.log(keys);
`

export default {
  mounted() {
    this.initEditor("editor-object-forin", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
