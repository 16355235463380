import Introduction from "./Introduction.vue";
import * as IntroductionLogics from "./Introduction";

import CreateTable from "./CreateTable.vue";
import * as CreateTableLogics from "./CreateTable";

import InsertTable from "./InsertTable.vue";
import * as InsertTableLogics from "./InsertTable";

import Exercise1 from "./Exercise1.vue";
import * as Exercise1Logics from "./Exercise1";

import SelectTable from "./SelectTable.vue";
import * as SelectTableLogics from "./SelectTable";

import Exercise2 from "./Exercise2.vue";
import * as Exercise2Logics from "./Exercise2";

import WhereClause from "./WhereClause.vue";
import * as WhereClauseLogics from "./WhereClause";

import Exercise3 from "./Exercise3.vue";
import * as Exercise3Logics from "./Exercise3";

import Exercise4 from "./Exercise4.vue";
import * as Exercise4Logics from "./Exercise4";

import UpdateTable from "./UpdateTable.vue";
import * as UpdateTableLogics from "./UpdateTable";

import Exercise5 from "./Exercise5.vue";
import * as Exercise5Logics from "./Exercise5";

import DeleteTable from "./DeleteTable.vue";
import * as DeleteTableLogics from "./DeleteTable";

import OrderBy from "./OrderBy.vue";
import * as OrderByLogics from "./OrderBy";

import Limit from "./Limit.vue";
import * as LimitLogics from "./Limit";
const curriculum = new Map();

curriculum.set("ZiBOOfNRtDVTzCVxqjGg", {
  content: Introduction,
  logics: IntroductionLogics
});

curriculum.set("Eb95qqjP3XjVN6uosmDF", {
  content: CreateTable,
  logics: CreateTableLogics
});

curriculum.set("C7b64GyOokOd3AxDWdYX", {
  content: InsertTable,
  logics: InsertTableLogics
});

curriculum.set("ulznltRe3VKB3ONKmeX8", {
  content: Exercise1,
  logics: Exercise1Logics
});

curriculum.set("BIeLbNRMXosPTVfxI5m4", {
  content: SelectTable,
  logics: SelectTableLogics
});

curriculum.set("tpWz1A2RqHJldmIL1oR5", {
  content: Exercise2,
  logics: Exercise2Logics
});

curriculum.set("GqqD7cVeC3tUJgj7NJzs", {
  content: WhereClause,
  logics: WhereClauseLogics
});

curriculum.set("8RVLHseaVMnlzyHwy4lC", {
  content: Exercise3,
  logics: Exercise3Logics
});

curriculum.set("rzJDks53HeqSlsNwzhIX", {
  content: Exercise4,
  logics: Exercise4Logics
});

curriculum.set("CguKrR4VcEOBoOevEuOO", {
  content: UpdateTable,
  logics: UpdateTableLogics
});

curriculum.set("Yxsm523QjgIf7KHxj37k", {
  content: Exercise5,
  logics: Exercise5Logics
});

curriculum.set("UIlO49eWYT9qfACalxAr", {
  content: DeleteTable,
  logics: DeleteTableLogics
});

curriculum.set("O90kettVVmXjS7TQIhLS", {
  content: OrderBy,
  logics: OrderByLogics
});

curriculum.set("OVqwEoONJvFn7Vtt9288", {
  content: Limit,
  logics: LimitLogics
});

export default curriculum;
