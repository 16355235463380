<template>
  <div>
    <h2>Function with Return</h2>
    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-37-function-return.mp4?alt=media&token=bf12610d-df93-4538-830e-26545dbc2301"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      A function can also return a value into the calling code.
    </p>
    <img class="img-fluid" src="@/assets/foundation/function_three_1.png" />

    <p>
      Print the value of
      <span class="block block-variable">answer</span> and we will get
    </p>

    <b-card class="my-3" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> 150
        </code>
      </b-card-text>
    </b-card>

    <p>
      In the above example, the function
      <span class="block block-function">addHundred</span> has a parameter
      <span class="block block-variable">number</span>. This function will add
      100 to the argument and return the result.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function
            <span class="block block-function">multiplyTen</span> with a
            <span class="block block-variable">number</span> parameter
          </li>
          <li>
            The function will take the
            <span class="block block-variable">number</span> multiply 10 and
            return the result
          </li>
          <li>
            Create a variable
            <span class="block block-variable">answer</span> and assign it with
            <span class="block block-function">multiplyTen</span>
            function with an argument of 9
          </li>
          <li>Print <span class="block block-variable">answer</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Example result:
          <br />
          >> 90
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "ListIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
