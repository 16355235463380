<template>
  <div>
    <b-container fluid class="pb-3">
      <div class="d-flex justify-content-between align-items-center px-3 py-3">
        <div class="hover-underline clickable" @click="onClickBack">
          <b-icon icon="chevron-left" /> Back
        </div>
        <div>
          <input
            class="editable editable-h2 w-100 mb-2 text-center"
            type="text"
            value="Course title"
            v-model="activity.name"
            @change="onChangeActivityName"
          />
        </div>
        <div>
          <b-button
            class="mr-2"
            variant="secondary"
            :to="`/course/${activity.courseId}/activity/${activity.id}`"
            target="_blank"
          >
            View
          </b-button>
          <b-button variant="dark" @click="onClickSave" :disabled="isSaving">
            <b-spinner small v-if="isSaving"></b-spinner>
            {{ isSaving ? "Saving..." : "Save" }}
          </b-button>
        </div>
      </div>
      <b-row no-gutters>
        <b-col class="px-2 pl-md-3 d-flex flex-column" md="6">
          <div id="editorjs"></div>
        </b-col>
        <b-col class="pl-md-2 pr-md-3" md="6">
          <div id="editor"></div>
          <div class="unit-test-card">
            <h4 class="my-3">Test Script</h4>
            <b-card no-body>
              <b-tabs card>
                <b-tab title="After seed">
                  <b-card-text>
                    <b-dropdown
                      text="Add"
                      size="sm"
                      variant="dark"
                      class="mb-2"
                    >
                      <b-dropdown-item
                        @click="showAddTestModal('seed', 'script')"
                      >
                        Test script
                      </b-dropdown-item>
                      <!-- <b-dropdown-item>Regex test</b-dropdown-item> -->
                    </b-dropdown>

                    <div
                      class="test-line d-flex justify-content-between align-items-center py-2"
                      v-for="(test, index) in activity.tests.seed || []"
                      :key="index"
                    >
                      <div>
                        {{ test.name }}
                      </div>
                      <div>
                        <font-awesome-icon
                          @click="onClickEditTest('seed', test, index)"
                          class="clickable text-grey-300 mr-2"
                          icon="edit"
                        />
                        <font-awesome-icon
                          @click="onClickDeleteTest('seed', index)"
                          class="clickable text-grey-300"
                          icon="trash"
                        />
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="After user code" active>
                  <b-card-text>
                    <b-dropdown
                      text="Add"
                      size="sm"
                      variant="dark"
                      class="mb-2"
                    >
                      <b-dropdown-item
                        @click="showAddTestModal('user', 'script')"
                      >
                        Script
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="showAddTestModal('user', 'regex')"
                        >Regex test</b-dropdown-item
                      >
                    </b-dropdown>

                    <div
                      class="test-line d-flex justify-content-between align-items-center py-2"
                      v-for="(test, index) in activity.tests.user || []"
                      :key="index"
                    >
                      <div>
                        {{ test.name }}
                      </div>
                      <div>
                        <font-awesome-icon
                          @click="onClickEditTest('user', test, index)"
                          class="clickable text-grey-300 mr-2"
                          icon="edit"
                        />
                        <font-awesome-icon
                          @click="onClickDeleteTest('user', index)"
                          class="clickable text-grey-300"
                          icon="trash"
                        />
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-add-test"
      ref="modal"
      title="Add Script"
      ok-variant="dark"
      v-model="isAddTestModalVisible"
      @hidden="resetAddTest"
      @ok="handleOkAddTest"
      @shown="onModalShown"
      :ok-disabled="isAddingTest"
      :no-close-on-backdrop="isAddingTest"
      :hide-header-close="isAddingTest"
      :no-close-on-esc="isAddingTest"
      :cancel-disabled="isAddingTest"
    >
      <form ref="addCourseForm" @submit.stop.prevent="handleSubmitAddTest">
        <p>Add script for submission correctness test</p>
        <b-form-group label="Name" invalid-feedback="Name is required">
          <b-form-input
            type="text"
            v-model="testName"
            :disabled="isAddingTest"
            required
          ></b-form-input>
        </b-form-group>
        <div id="editor-test"></div>
      </form>
      <template #modal-ok>
        <b-spinner small v-if="isAddingTest"></b-spinner>
        <span v-if="isAddTest">
          {{ isAddingTest ? "Loading..." : "Add" }}
        </span>
        <span v-else>
          {{ isAddingTest ? "Loading..." : "Save" }}
        </span>
      </template>
    </b-modal>

    <b-modal
      id="modal-add-test"
      ref="modal"
      title="Add Script"
      ok-variant="dark"
      v-model="isAddRegexTestModalVisible"
      @hidden="resetAddTest"
      @ok="handleOkAddTest"
      @shown="onModalShown"
      :ok-disabled="isAddingTest"
      :no-close-on-backdrop="isAddingTest"
      :hide-header-close="isAddingTest"
      :no-close-on-esc="isAddingTest"
      :cancel-disabled="isAddingTest"
    >
      <form ref="addCourseForm" @submit.stop.prevent="handleSubmitAddTest">
        <p>Add script for submission correctness test</p>
        <b-form-group label="Name" invalid-feedback="Name is required">
          <b-form-input
            type="text"
            v-model="testName"
            :disabled="isAddingTest"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Regex" invalid-feedback="Name is required">
          <b-input-group prepend="/" append="/">
            <b-form-input
              type="text"
              v-model="testRegex"
              :disabled="isAddingTest"
              required
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Error message">
          <b-form-input
            type="text"
            v-model="testErrorMessage"
            :disabled="isAddingTest"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-ok>
        <b-spinner small v-if="isAddingTest"></b-spinner>
        <span v-if="isAddTest">
          {{ isAddingTest ? "Loading..." : "Add" }}
        </span>
        <span v-else>
          {{ isAddingTest ? "Loading..." : "Save" }}
        </span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import CodeTool from "@editorjs/code";
import List from "@editorjs/list";
import InlineCode from "@editorjs/inline-code";
import ImageTool from "@editorjs/image";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import YoutubeEmbed from "editorjs-youtube-embed";

import { activityServices } from "@/services";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  name: "EditActivity",

  data() {
    return {
      activityId: this.$route.params.activityId,
      activity: {
        seed: "",
        content: "",
        tests: {
          seed: [],
          user: []
        }
      },
      testType: null,
      testName: null,
      testMode: null,
      testErrorMessage: "",
      testRegex: "",
      selectedTest: {},
      editorjs: null,
      aceEditor: null,
      aceEditorTest: null,
      loader: null,
      isAddTestModalVisible: false,
      isAddRegexTestModalVisible: false,
      isSaving: false,
      isAddingTest: false
    };
  },

  async mounted() {
    this.loader = this.$loading.show();
    await this.getActivity();
    await this.initEditorJs();
    await this.initAceEditor();
    this.loader.hide();
  },

  computed: {
    isAddTest() {
      return this.selectedTest.index == null;
    }
  },

  methods: {
    initEditorJs() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      const seedData = {
        blocks: [
          {
            type: "header",
            data: {
              text: "Lesson title",
              level: 2
            }
          },
          {
            type: "paragraph",
            data: {
              text: "Write your lesson here..."
            }
          }
        ]
      };
      const data =
        this.activity.content == "" || this.activity.content == null
          ? seedData
          : this.activity.content;

      this.editorjs = new EditorJS({
        holderId: "editorjs",
        inlineToolbar: true,
        data: data,
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: "Enter a header",
              inlineToolbar: true,
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 1
            }
          },
          code: CodeTool,
          list: {
            class: List,
            inlineToolbar: true
          },
          inlineCode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+M"
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                vimeo: true
              }
            }
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile(file) {
                  return self.uploadActivityFile(file);
                }
              }
            }
          },
          youtubeEmbed: YoutubeEmbed,
          delimiter: Delimiter
        }
      });
      return;
    },

    initAceEditor() {
      const seedCode =
        this.activity.seed == "" || this.activity.seed == null
          ? "# Seed code"
          : this.activity.seed;

      this.aceEditor = ace.edit("editor", {
        mode: `ace/mode/${this.activity.language}`,
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });

      this.aceEditor.setValue(seedCode, 1);
      return;
    },

    getActivity() {
      return activityServices.getActivity(this.activityId).then(activity => {
        this.activity = activity;
      });
    },

    async onClickSave() {
      this.isSaving = true;

      const content = await this.editorjs.save();
      const seed = this.aceEditor.getValue();
      this.activity.content = content;

      await activityServices
        .updateActivity(this.activityId, {
          name: this.activity.name,
          content: content,
          seed: seed,
          tests: this.activity.tests
        })
        .catch(error => {
          this.isSaving = false;
          this.$bvToast.toast(error.message, {
            title: "Update activity",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });

      this.isSaving = false;
      this.$bvToast.toast("Lesson saved", {
        title: "Update lesson",
        autoHideDelay: 5000,
        variant: "success",
        appendToast: true
      });
    },

    onChangeActivityName() {
      return activityServices
        .updateActivity(this.activityId, {
          name: this.activity.name
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: "Update activity",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });
    },

    async uploadActivityFile(file) {
      const imageURL = await activityServices
        .updateFile(this.activity, file)
        .catch(error => {
          alert(error.message);
        });

      return {
        success: 1,
        file: {
          url: imageURL
        }
      };
    },

    showAddTestModal(type, testMode) {
      if (testMode == "script") {
        this.isAddTestModalVisible = true;
      } else {
        this.isAddRegexTestModalVisible = true;
      }

      this.testType = type;
      this.testMode = testMode;
    },

    onClickDeleteTest(type, index) {
      if (confirm("Confirm delete script?")) {
        this.activity.tests[type].splice(index, 1);
      }
    },

    onClickEditTest(type, test, index) {
      this.selectedTest = test;
      this.selectedTest.index = index;

      this.testType = type;
      this.testName = this.selectedTest.name;
      this.testMode = this.selectedTest.testMode;

      if (test.testMode == "script") {
        this.isAddTestModalVisible = true;
      } else {
        this.isAddRegexTestModalVisible = true;
        this.testRegex = this.selectedTest.value;
        this.testErrorMessage = this.selectedTest.errorMessage;
      }
    },

    onModalShown() {
      if (this.testMode == "script") {
        this.aceEditorTest = ace.edit("editor-test", {
          mode: `ace/mode/${this.activity.language}`,
          selectionStyle: "text",
          theme: "ace/theme/eclipse"
        });
        if (!this.isAddTest) {
          this.aceEditorTest.setValue(this.selectedTest.value, 1);
        }
      }
    },

    handleOkAddTest(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitAddTest();
    },

    handleSubmitAddTest() {
      this.isAddingTest = true;

      if (this.testMode == "script") {
        this.setScriptTest();
      } else {
        this.setRegexTest();
      }

      this.isAddingTest = false;
      this.isAddTestModalVisible = false;
      this.isAddRegexTestModalVisible = false;
    },

    setScriptTest() {
      if (this.isAddTest) {
        this.activity.tests[this.testType].push({
          type: this.testType,
          name: this.testName,
          testMode: "script",
          value: this.aceEditorTest.getValue()
        });
      } else {
        this.activity.tests[this.testType][this.selectedTest.index] = {
          type: this.testType,
          name: this.testName,
          testMode: "script",
          value: this.aceEditorTest.getValue()
        };
      }
    },

    setRegexTest() {
      if (this.isAddTest) {
        this.activity.tests[this.testType].push({
          type: this.testType,
          name: this.testName,
          testMode: "regex",
          errorMessage: this.testErrorMessage,
          value: this.testRegex
        });
      } else {
        this.activity.tests[this.testType][this.selectedTest.index] = {
          type: this.testType,
          name: this.testName,
          testMode: "regex",
          errorMessage: this.testErrorMessage,
          value: this.testRegex
        };
      }
    },

    resetAddTest() {
      this.testName = null;
      this.testType = null;
      this.testMode = null;
      this.testRegex = "";
      this.testErrorMessage = "";
      this.selectedTest = {};
    },

    onClickBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped>
#editorjs {
  background-color: white;
  padding: 1rem 2rem 1rem 2rem;
  height: calc(100vh - 66px);
  border-radius: 5px;
  border: 2px solid rgb(0, 0, 0);
  overflow: auto;
}

#editor {
  background-color: white;
  padding: 1rem 2rem 1rem 2rem;
  height: calc(50vh - 66px);
}

#editor-test {
  background-color: white;
  padding: 1rem 2rem 1rem 2rem;
  height: calc(50vh - 66px);
}

.test-line {
  border-bottom: 1px solid rgb(233, 233, 233);
  border-top: 1px solid rgb(233, 233, 233);
}
</style>
