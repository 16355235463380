<template>
  <div>
    <h2>Exercise 8</h2>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use nested for loops to append following numbers into variable
            <code>some_numbers</code>
            <br /><br />
            [1, 1, 1, 1, 2, 2, 2, 2]
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
