<template>
  <div>
    <h2>Math Object</h2>

    <p>
      JavaScript has built in Math object that has many properties and methods
      for common mathematical constants and functions. In this tutorial, we will
      not go into details how object works, it deserves its own chapter.
    </p>

    <h4>Constants</h4>
    <p>Common mathematical constants</p>
    <div id="editor-math-constant" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 2.718
          <br />
          > 3.14159
          <br />
          > 0.693
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Methods</h4>
    <p>
      Adding a number and a string will return a string.
    </p>
    <div id="editor-math-method" style="height: 230px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 10
          <br />
          > 0.6165970339760092
          <br />
          > 2
        </code>
      </b-card-text>
    </b-card>
    <p class="mt-3">
      Learn other constants and methods available
      <a
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math"
        target="_blank"
        >here</a
      >
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a variable <code>randomNumber</code> and assign it with a
            random number using Math object method.
          </li>
          <li>
            Define a variable <code>roundedNumber</code> and assign it with the
            rounded value of <code>x</code>.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-math-constant", `console.log(Math.E);\nconsole.log(Math.PI);\nconsole.log(Math.LN2);`);
    this.initEditor("editor-math-method", `// Return the absolute value\nlet a = Math.abs(-10);\n\n// Return a random number between 0 and 1\nlet b = Math.random();\n\n// Return the number rounded to nearest integer\nlet c = Math.round(2.345);\n\nconsole.log(a);\nconsole.log(b);\nconsole.log(c);`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
