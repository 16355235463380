/* eslint-disable @typescript-eslint/no-explicit-any */
export function getArrayElements(object: Record<string, any>) {
  if (object == null || object.properties == null) {
    return [];
  }
  const keys: string[] = Object.keys(object.properties);
  const array: any[] = [];
  keys.forEach(key => {
    array.push(object.properties[key]);
  });
  return array;
}

export const consoleModifier = `var console = (function(oldCons) {
  return {
    log: function(text) {
      self.consoleText += '> ';
      self.consoleText += text;
      self.consoleText += '\\n';
    },
    warn: function(text) {
      self.consoleText += '> ';
      self.consoleText += text;
      self.consoleText += '\\n';
    },
    error: function(text) {
      self.consoleText += '> ';
      self.consoleText += text;
      self.consoleText += '\\n';
    },
    info: function(text) {
      self.consoleText += '> ';
      self.consoleText += text;
      self.consoleText += '\\n';
    }
  };
})(window.console); window.console = console;`;
