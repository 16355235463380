<template>
  <div>
    <h2>Exercise 32</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <span class="block block-variable">numbers</span>
          </li>
          <li>
            Assign <span class="block block-variable">numbers</span> with a list
            of numbers 15, 3, 48, 22 in correct sequence
          </li>
          <li>
            Use a for loop, replace each number in
            <span class="block block-variable">numbers</span> with a number
            equal to each number multiply by 3
          </li>
          <li>Print <span class="block block-variable">numbers</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> [45,9,144,66]
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
