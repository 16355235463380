<template>
  <div>
    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th v-for="header in database.columns" :key="header">{{
            header
          }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr v-for="(row, rowIndex) in database.values" :key="rowIndex">
          <b-td
            v-for="(cell, cellIndex) in row"
            :key="`${rowIndex}-${cellIndex}`"
            >{{ cell }}</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  name: "DatabaseTable",

  props: {
    database: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style></style>
