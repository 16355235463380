/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;

const seedTest = `
`;

export const testCases = `
  chaiAssert.deepEqual(oddNumberGenerator(10, 11), [], "function should return [] true when argument is 10, 11");

  chaiAssert.deepEqual(oddNumberGenerator(3, 6), [5], "function should return [5] true when argument is 3, 6");

  chaiAssert.deepEqual(oddNumberGenerator(10, 20), [11, 13, 15, 17, 19], "function should return [11, 13, 15, 17, 19] true when argument is 10, 20");

`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  const seedCode = "";
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /function oddNumberGenerator\([\s\S]+\)\s*{[\s\S]+}/.test(code),
    "oddNumberGenerator function not declared"
  );

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
