<template>
  <div>
    <h2>Exercise 4</h2>
    <p>
      Method <code>len()</code>can be used to count the number of elements in a
      list.
    </p>
    <div id="editor-exercise-4" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          4
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Assign <code>first_number</code> as the first element of
            <code>random_numbers</code>
          </li>
          <li>
            Assign <code>last_number</code> as the last element of
            <code>random_numbers</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `some_list = [2, 3, 5, 6]
print(len(some_list))`;

export default {
  mounted() {
    this.initEditor("editor-exercise-4", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
