<template>
  <div>
    <h2>Nested Loops</h2>

    <p>
      Nested loops refer to a loop within a loop. The inner loop will completes
      its iteration before the next iteration of outer loop take place.
    </p>
    <div id="editor-loop-nested" style="height: 130px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "i = 0, j = 10"
          <br />
          > "i = 0, j = 11"
          <br />
          > "i = 1, j = 10"
          <br />
          > "i = 1, j = 11"
          <br />
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a nested loop to push numbers into <code>numbers</code> as below
          </li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [4, 5, 4, 5, 4, 5]
          <br />
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `for (let i = 0; i < 2; i++) {
  for (let j = 10; j < 12; j++) {
    console.log("i = " + i + ", j = " + j);
  }
}
`

export default {
  mounted() {
    this.initEditor("editor-loop-nested", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
