/* eslint-disable @typescript-eslint/no-explicit-any */
import customConsole from "@/utils/custom-console";

export const seed = ``;

export const testCases = "";

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code);
  window.console = oldConsole;
  return true;
}
