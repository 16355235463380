/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = ``;
export const seedTest = ``;
export const testCases = `
assert callable(is_divisible), "is_divisible function not found"

answer = is_divisible(10, 2)
assert answer == True, "is_divisible does not return correct value"

answer = is_divisible(10, 3)
assert answer == False, "is_divisible does not return correct value"`;
export let logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  logs = [];
  const userCodeStartingIndex = 3;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({
    output: printConsole,
    __future__: Sk.python3,
    execLimit: 3000
  });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);

  window.console = oldConsole;
  return true;
}
