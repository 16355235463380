<template>
  <div>
    <h2>Breaking Loop</h2>

    <video-player
      poster="/images/loops.png"
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-27-loop-break.mp4?alt=media&token=9ae80f10-58de-4992-9723-c4556a885e0d"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      During the loop iteration, we can use a
      <span class="block block-loop">break</span> to jump out of a loop or
      <span class="block block-loop">continue</span> statement to skip one
      iteration in the loop.
    </p>

    <p>Example of using <span class="block block-loop">break</span></p>
    <img class="img-fluid" src="@/assets/foundation/loop_break.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      Example of using <span class="block block-loop">continue</span>
    </p>
    <img class="img-fluid" src="@/assets/foundation/loop_continue.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1
          <br />
          >> 3
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Print only the numbers that are divisible by 3</li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 60
          <br />
          >> 63
          <br />
          .
          <br />
          .
          <br />
          >> 90
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "FoundationVariable",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
