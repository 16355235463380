/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";
import { deviceTable } from "./devices";

export const preSeed = deviceTable;
export const seed = ``;

export function testCorrectness(code: string, self: any) {
  self.db.exec(preSeed);
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "name", "type", "price"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [7, "Samsung Fold", "phone", 2100],
      [2, "ThinkPad X1", "laptop", 1999],
      [3, "Dell XPS", "laptop", 1899]
    ],
    "Wrong data selected"
  );
  return true;
}
