<template>
  <div>
    <h2>If Elif Else Statement</h2>

    <p>
      <code>elif</code> stands for else if. We can use if...elif to check for
      multiple conditions. A if statement can have multipl elif statement.
    </p>

    <div id="editor-elif" style="height: 140px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          number is less than or equal 25
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Write a if...elif..else statement
            <ul>
              <li>
                if <code>random_number</code> is more than 80, set
                <code>result</code> to 1
              </li>
              <li>
                if <code>random_number</code> is between 30 to 80, set
                <code>result</code> to 0
              </li>
              <li>Else, set <code>result</code> to -1</li>
            </ul>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 20
if number > 50:
  print("number is more than 50")
elif number > 25:
  print("number is more than 25")
else:
  print("number is less than or equal 25")
`;

export default {
  mounted() {
    this.initEditor("editor-elif", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
