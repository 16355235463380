<template>
  <div>
    <h2>Functions with Parameters</h2>

    <p>
      We can also pass data into a function using <strong>parameter</strong>.
      You can define multiple parameters for a function. The value passed as
      parameter also known as argument. Parameter is optional in function
      defination.
    </p>

    <div id="editor-function-param" style="height: 170px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Hello Mike
          <br />
          8
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Return Statement</h4>
    <p>
      The return statement is used to exit a function and return the value of
      expression.
    </p>
    <div id="editor-function-return" style="height: 120px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          4
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a function <code>multiply_ten</code> with one parameter
          </li>
          <li>
            The function will return the value of parameter multipled by 10
          </li>
          <li>
            Reassign <code>answer</code> as the value return by
            <code>multiply_ten</code> with argument 8
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `def say_greeting(name):
    print("Hello", name)

say_greeting("Mike")

def print_sum(a, b):
    print(a + b)

print_sum(5, 3)`;

const code2 = `def plus_two(x):
    return x + 2

sum = plus_two(2)
print(sum)`;

export default {
  mounted() {
    this.initEditor("editor-function-param", code);
    this.initEditor("editor-function-return", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
