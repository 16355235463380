<template>
  <div>
    <h2>Iterate List Using For Loop</h2>
    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-33-list-for.mp4?alt=media&token=6be320a7-f937-4297-9b96-dfc7f17ba6c4"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>
    <p class="mt-3">
      A for loop can be used to iterate through all the items in a list.
    </p>

    <img class="img-fluid" src="@/assets/foundation/list_for_loop.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "apple"
          <br />
          >> "orange"
          <br />
          >> "melon"
          <br />
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      We may not know the number of elements in a list at any certain point,
      hence we can use the <span class="block block-list">length</span> block to
      get the number of elements instead
    </p>

    <img
      class="img-fluid"
      src="@/assets/foundation/list_for_loop_with_length.png"
    />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Print out each element in
            <span class="block block-variable">colours</span> list
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "red"
          <br />
          >> "green"
          <br />
          >> "blue"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "ListIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
