<template>
  <div>
    <h2>Delete Value</h2>

    <p>
      <code>pop()</code> method can be used to remove an element in a
      dictionary. This method will removes an element with with provided
      <code>key</code> and returns the value.
    </p>
    <p>
      We can also use the <code>del</code> keyword to remove individual element.
    </p>

    <div id="editor-dic-pop" style="height: 180px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          2011
          <br />
          {'brand': 'Toyota'}
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Delete the <code>price</code> key</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `car = {
    'brand': 'Toyota',
    'year': 2011,
    'price': 35000
}

print(car.pop('year'))
del car['price']
print(car)`;

export default {
  mounted() {
    this.initEditor("editor-dic-pop", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
