<template>
  <div class="pb-3">
    <h2>Project: BMI Calculator (Intro)</h2>
    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-4-project-bmi.mp4?alt=media&token=ae7b3ecd-e7a4-4589-a8d6-f5d569ddb77d"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      BMI stands for Body mass index, it is a convenient way to find out if a
      person is underweight, normal weight, overweight, or obese. A person BMI
      can be calculated by the formula below
    </p>

    <img class="img-fluid" src="@/assets/app-inventor/bmi-formula.png" />

    <p>Try calculate your BMI value using pen and paper!</p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
