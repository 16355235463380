/* eslint-disable @typescript-eslint/no-explicit-any */
export default function customConsole(self: any): any {
  return {
    log: (...data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        self.consoleText += data[i];
      }
      self.consoleText += "\n";
    },

    warn: (...data: any[]) => {
      self.consoleText += data;
      self.consoleText += "\n";
    },

    info: (...data: any[]) => {
      self.consoleText += data;
      self.consoleText += "\n";
    },

    error: (...data: any[]) => {
      self.consoleText += data;
      self.consoleText += "\n";
    }
  };
}
