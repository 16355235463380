<template>
  <div class="py-5">
    <b-container>
      <div class="mb-3 d-flex justify-content-between">
        <div class="hover-underline clickable" @click="onClickBack">
          <b-icon icon="chevron-left" /> back
        </div>
      </div>
      <b-row class="mb-5">
        <b-col>
          <input
            class="editable editable-h2 w-100 mb-2"
            type="text"
            value="Course title"
            v-model="course.name"
            @change="onChangeDetails('name')"
          />
          <textarea
            class="editable w-100"
            rows="5"
            v-model="course.description"
            @change="onChangeDetails('description')"
          >
          </textarea>
          <div>
            <b-button class="mr-2" variant="dark" :to="`/course/${courseId}`"
              >View Course</b-button
            >
            <b-button variant="dark" v-b-modal.modal-assign-class>
              Assign to class
            </b-button>
          </div>
        </b-col>
        <b-col md="5">
          <b-img :src="courseImage" fluid></b-img>
          <div class="mt-2 d-flex align-items-center">
            <b-form-file
              accept="image/*"
              placeholder="600x400"
              plain
              v-model="newImage"
              @input="updateCourseImage"
            ></b-form-file>
          </div>
        </b-col>
      </b-row>

      <transition-group name="list" tag="div">
        <b-card
          header-tag="header"
          footer-tag="footer"
          header-bg-variant="white"
          footer-bg-variant="white"
          v-for="(chapter, index) in chapters"
          :key="chapter.id"
          class="mb-3"
        >
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <input
                class="editable editable-h4 bg-white"
                type="text"
                v-model="chapter.name"
                @change="onChangeChapterName(chapter)"
              />
              <div>
                <font-awesome-icon
                  class="clickable text-grey-300 mr-3"
                  icon="chevron-up"
                  @click="onClickSwapChapter(index, -1)"
                />
                <font-awesome-icon
                  class="clickable text-grey-300 mr-3"
                  icon="chevron-down"
                  @click="onClickSwapChapter(index, 1)"
                />
                <font-awesome-icon
                  @click="onClickDeleteChapter(chapter.id)"
                  class="clickable text-grey-300"
                  icon="trash"
                />
              </div>
            </div>
          </template>
          <b-card-text>
            <draggable
              v-model="activitiesByChapter[chapter.id]"
              :group="chapter.id"
              @end="onDragEnd(chapter)"
            >
              <div
                v-for="activity in activitiesByChapter[chapter.id]"
                :key="activity.id"
                class="lesson d-flex justify-content-between align-items-centers py-2"
              >
                <div
                  @click="onClickEditActivity(activity.id)"
                  class="clickable hover-underline"
                >
                  {{ activity.name }}
                </div>
                <div class="actions">
                  <font-awesome-icon
                    @click="onClickEditActivity(activity.id)"
                    class="clickable text-grey-300"
                    icon="edit"
                  />
                  <font-awesome-icon
                    @click="onClickDeleteActivity(activity.id)"
                    class="clickable text-grey-300"
                    icon="trash"
                  />
                </div>
              </div>
            </draggable>
            <div
              @click="onClickAddActivity(chapter)"
              class="clickable hover-underline d-inline-block py-2"
            >
              <b-icon icon="plus-circle" class="mr-2" />
              Add lesson
            </div>
          </b-card-text>
        </b-card>
      </transition-group>

      <div
        class="clickable hover-underline d-inline-block py-2"
        @click="onClickAddSection"
      >
        <b-icon icon="plus-circle" class="mr-2" />
        Add section
      </div>
      <div class="d-flex justify-content-center mt-3">
        <div class="clickable hover-underline" @click="onClickDeleteCourse">
          <font-awesome-icon class="mr-2" icon="trash" />
          Delete Course
        </div>
      </div>
    </b-container>

    <b-modal
      id="modal-assign-class"
      ref="modal"
      title="Assign to Class"
      ok-variant="dark"
      @hidden="resetAssignClassModal"
      @ok="handleOkAssignClass"
      :ok-disabled="isAssigningClass"
      :no-close-on-backdrop="isAssigningClass"
      :hide-header-close="isAssigningClass"
      :no-close-on-esc="isAssigningClass"
      :cancel-disabled="isAssigningClass"
    >
      <form
        ref="assignClassForm"
        @submit.stop.prevent="handleSubmitAssignClass"
      >
        <b-form-group
          :state="assignClassModalState"
          label="Class"
          invalid-feedback="Class is required"
        >
          <b-form-select
            v-model="selectedClass"
            :options="classroomOptions"
            required
          ></b-form-select>
        </b-form-group>
      </form>
      <template #modal-ok>
        <b-spinner small v-if="isAssigningClass"></b-spinner>
        {{ isAssigningClass ? "Loading..." : "Assign" }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import draggable from "vuedraggable";
import {
  activityServices,
  chapterServices,
  classroomServices,
  courseServices
} from "@/services";
export default {
  name: "EditCourse",

  components: {
    draggable
  },

  data() {
    return {
      courseId: this.$route.params.id,
      course: {
        name: null,
        description: null,
        imageURL: null,
        image: null
      },
      newImage: null,
      chapters: [],
      activities: [],
      activitiesByChapter: {},
      loader: null,
      message: "Course description...",
      classrooms: [],
      selectedClass: {},
      assignClassModalState: null,
      isAssigningClass: false
    };
  },

  async created() {
    this.loader = this.$loading.show();
    await this.getCourse();
    await this.getChapters();
    await this.getActivities();
    await this.getClassrooms();
    this.groupActivitiesByChapter();
    this.loader.hide();
  },

  computed: {
    courseImage() {
      return this.course.imageURL != ""
        ? this.course.imageURL
        : "/images/course-imageplaceholder.jpg";
    },

    classroomOptions() {
      return this.classrooms.map(classroom => {
        return {
          text: classroom.name,
          value: {
            id: classroom.id,
            name: classroom.name
          }
        };
      });
    }
  },

  methods: {
    getCourse() {
      return courseServices
        .getCourse(this.courseId)
        .then(course => {
          this.course = course;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getClassrooms() {
      return classroomServices
        .getTeacherClassrooms(this.$store.state.user.id)
        .then(classrooms => {
          this.classrooms = classrooms;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getChapters() {
      return chapterServices
        .getChapters(this.courseId)
        .then(chapters => {
          this.chapters = this.sortChapters(chapters);
        })
        .catch(error => {
          alert(error.message);
        });
    },

    sortChapters(chapters) {
      chapters = chapters.map(chapter => {
        return {
          ...chapter,
          order: this.course.chapterOrder[chapter.id]
        };
      });
      return _.orderBy(chapters, ["order"]);
    },

    getActivities() {
      return activityServices
        .getActivities(this.courseId)
        .then(activities => {
          this.activities = this.sortActivities(activities);
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: "Update course",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });
    },

    sortActivities(activities) {
      activities = activities.map(activity => {
        return {
          ...activity,
          order: this.course.activityOrder[activity.id]
        };
      });
      return _.orderBy(activities, ["order"]);
    },

    groupActivitiesByChapter() {
      this.chapters.forEach(chapter => {
        const chapterActivities = this.activities.filter(activity => {
          return activity.chapterId == chapter.id;
        });
        this.$set(this.activitiesByChapter, chapter.id, chapterActivities);
      });
    },

    async onChangeDetails(field) {
      await courseServices
        .updateCourse(this.courseId, {
          [field]: this.course[field]
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: "Update course",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });

      this.$bvToast.toast("Course details updated", {
        title: "Update course",
        autoHideDelay: 5000,
        variant: "success",
        appendToast: true
      });
    },

    async onChangeChapterName(chapter) {
      await chapterServices
        .updateChapter(chapter.id, {
          name: chapter.name
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: "Update section",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });

      this.$bvToast.toast("Section details updated", {
        title: "Update course",
        autoHideDelay: 5000,
        variant: "success",
        appendToast: true
      });
    },

    async onClickSwapChapter(index, movement) {
      if (index + movement < 0 || index + movement >= this.chapters.length) {
        return;
      }
      const temp = this.chapters[index];
      this.chapters.splice(index, 1, this.chapters[index + movement]);
      this.chapters.splice(index + movement, 1, temp);

      await this.updateChapterOrder();
      this.$bvToast.toast("Section order updated", {
        title: "Update course",
        autoHideDelay: 5000,
        variant: "success",
        appendToast: true
      });
    },

    async updateChapterOrder() {
      const chapterOrder = {};
      for (let index = 0; index < this.chapters.length; index++) {
        chapterOrder[this.chapters[index].id] = index + 1;
      }

      return courseServices.updateCourse(this.courseId, {
        chapterOrder: chapterOrder
      });
    },

    getActivitiesInChapter(chapterId) {
      return this.activitiesByChapter[chapterId];
    },

    async onClickAddActivity(chapter) {
      this.loader = this.$loading.show();

      const activityOrder =
        this.activitiesByChapter[chapter.id] == null
          ? 1
          : this.activitiesByChapter[chapter.id].length + 1;
      const activityRef = await activityServices
        .addActivity({
          chapterId: chapter.id,
          courseId: chapter.courseId,
          name: "New lesson",
          tests: {
            seed: [],
            user: []
          },
          language: this.course.language,
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.organisation.id
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: "Add section",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });

      const activityOrderRef = "activityOrder." + activityRef.id;
      await courseServices.updateCourse(this.courseId, {
        [activityOrderRef]: chapter.order * 10 + activityOrder
      });
      await this.getCourse();

      await this.getActivities();
      this.groupActivitiesByChapter();
      this.loader.hide();
    },

    async onClickAddSection() {
      this.loader = this.$loading.show();
      const chapterRef = await chapterServices
        .addChapter({
          courseId: this.courseId,
          name: "New section",
          order: this.chapters.length + 1,
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.organisation.id
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: "Add section",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          });
        });

      // Update chapter orders
      const chapterOrderRef = "chapterOrder." + chapterRef.id;
      await courseServices.updateCourse(this.courseId, {
        [chapterOrderRef]: this.chapters.length + 1
      });
      await this.getCourse();

      // Fetch latest chapters
      await this.getChapters();
      this.loader.hide();
    },

    onClickEditActivity(activityId) {
      this.$router.push(`/course/${this.courseId}/activity/${activityId}/edit`);
    },

    async updateCourseImage() {
      if (this.newImage == null) {
        return;
      }

      const fileSize = this.newImage.size / 1024 / 1024;
      if (fileSize > 1) {
        alert("Max 1Mb image file size");
        this.newImage = null;
        return;
      }

      this.loader = this.$loading.show();
      const imageURL = await courseServices.uploadFile(
        this.courseId,
        this.newImage
      );

      await courseServices.updateCourse(this.courseId, {
        imageURL: imageURL
      });

      this.newImage = null;
      this.course.imageURL = imageURL;
      this.loader.hide();
    },

    async onClickDeleteActivity(activityId) {
      if (confirm("Confirm delete lesson?")) {
        this.loader = this.$loading.show();
        await activityServices.deleteActivity(activityId);
        await this.getActivities();
        this.groupActivitiesByChapter();
        this.loader.hide();
      }
    },

    async onClickDeleteChapter(chapterId) {
      if (confirm("Confirm delete section?")) {
        this.loader = this.$loading.show();
        await chapterServices.deleteChapter(chapterId);
        await this.updateChapterOrder();
        await this.getChapters();
        this.loader.hide();
      }
    },

    async onDragEnd() {
      const activityOrder = {};
      this.chapters.forEach(chapter => {
        const activities = this.activitiesByChapter[chapter.id];
        for (let index = 0; index < activities.length; index++) {
          activityOrder[activities[index].id] = chapter.order * 10 + index + 1;
        }
      });

      await courseServices.updateCourse(this.courseId, {
        activityOrder: activityOrder
      });

      this.$bvToast.toast("Lesson order updated", {
        title: "Update course",
        autoHideDelay: 5000,
        variant: "success",
        appendToast: true
      });
    },

    async onClickDeleteCourse() {
      if (confirm("Confirm delete course?")) {
        this.loader = this.$loading.show();
        await courseServices.deleteCourse(this.courseId);
        this.$router.push("/courses");
        this.loader.hide();
      }
    },

    resetAssignClassModal() {
      this.selectedClass = {};
    },

    handleOkAssignClass(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitAssignClass();
    },

    async handleSubmitAssignClass() {
      const valid = this.$refs.assignClassForm.checkValidity();
      this.assignClassModalState = valid;
      if (!valid) {
        return;
      }
      this.isAssigningClass = true;

      try {
        await classroomServices.addCourse(this.selectedClass.id, this.course);

        this.$bvModal.hide("modal-assign-class");
        this.$bvToast.toast(
          `Course ${this.course.name} assigned to ${this.selectedClass.name}`,
          {
            title: "Assign Courses",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: true
          }
        );
      } catch (error) {
        alert(error.message);
      }
    },

    onClickBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped></style>
