/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isSix = [];
    try { isSix = oddNumberGenerator(6); } 
    catch (err) {
      isSix = [];
    } 
    try { returnResult(isSix); } catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (isSix: number[]) => {
      result.isSix = isSix;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function oddNumberGenerator(n)")) {
    status.message = "oddNumberGenerator function is not created correctly";
  } else if (
    result.isSix.properties[0] != 1 ||
    result.isSix.properties[1] != 3 ||
    result.isSix.properties[2] != 5
  ) {
    status.message = "Logic for function is not correct";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
