<template>
  <div>
    <h2>Compound Conditions</h2>

    <p>
      Multiple condtions can be joined using logical operators.
    </p>

    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Operator</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>&amp;&amp;</b-th>
          <b-td
            >Logical AND operator. Returns <code>true</code> only if both
            expressions evaluates to <code>true</code></b-td
          >
        </b-tr>
        <b-tr>
          <b-th>||</b-th>
          <b-td
            >Logical OR operator. Returns <code>true</code> if any expressions
            evaluates to <code>true</code></b-td
          >
        </b-tr>
        <b-tr>
          <b-th>!</b-th>
          <b-td>Logical NOT operator. Returns the inverse value</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4 class="mt-4">AND Operator</h4>
    <div id="editor-cond-and" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > true
          <br />
          > false
          <br />
          > false
          <br />
          > false
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">OR Operator</h4>
    <div id="editor-cond-or" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > true
          <br />
          > true
          <br />
          > true
          <br />
          > false
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">NOT Operator</h4>
    <div id="editor-cond-not" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > false
          <br />
          > true
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Using an if...else statement with AND operators, check whether
            <code>firstNumber</code> and <code>secondNumber</code> are more than
            50. If so, assign <code>result</code> with "Yes", else assign "No"
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const codeAnd = `console.log(true && true);
console.log(false && true);
console.log(true && false);
console.log(false && false);
`
const codeOr = `console.log(true || true);
console.log(false || true);
console.log(true || false);
console.log(false || false);
`
const codeNot = `console.log(!true);
console.log(!false);
`

export default {
  mounted() {
    this.initEditor("editor-cond-and", codeAnd);
    this.initEditor("editor-cond-or", codeOr);
    this.initEditor("editor-cond-not", codeNot);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
