<template>
  <div class="pb-3">
    <h2>Project: Talk to Me</h2>
    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-3-project-talktome.mp4?alt=media&token=a169e658-3daf-440c-a2a9-8980789dd72d"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3"></p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
