<template>
  <div>
    <h2>Exercise 10</h2>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a function <code>is_divisible</code> with two parameters
          </li>
          <li>
            The function will check if the first parameter is divisible by the
            second parameter.
          </li>
          <li>
            If divisible, the function will return <code>True</code>. Else,
            return <code>False</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          # Example
          <br />
          >>> print(is_divisible(9, 3))
          <br />
          True
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `def say_greeting(name):
    print("Hello", name)

say_greeting("Mike")

def print_sum(a, b):
    print(a + b)

print_sum(5, 3)`;

const code2 = `def plus_two(x):
    return x + 2

sum = plus_two(2)
print(sum)`;

export default {
  mounted() {
    this.initEditor("editor-function-param", code);
    this.initEditor("editor-function-return", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
