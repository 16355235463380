<template>
  <div>
    <h2>For Loop - Range</h2>
    <p>
      Use built-in <code>range()</code> function to iterate through sequence of
      numbers. The function has three parameters.
    </p>
    <p>
      <code>range(start, stop, step)</code>
    </p>

    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Paramter</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>start</b-th>
          <b-td>starting integer of sequence</b-td>
        </b-tr>
        <b-tr>
          <b-th>stop</b-th>
          <b-td>integer specifying at which position to stop</b-td>
        </b-tr>
        <b-tr>
          <b-th>step</b-th>
          <b-td>increment between each integer</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div id="editor-for-range" style="height: 240px;"></div>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Find the sum of numbers in <code>random_numbers</code>, assign it to
            variable <code>total</code>
          </li>
          <li>Print <code>total</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `numbers = []

# Prints out the numbers 0,1,2,3,4
for x in range(5):
    print(x)

# Prints out 3,4,5
for x in range(3, 6):
    print(x)

# Prints out 3,5,7
for x in range(3, 8, 2):
    print(x)`;

export default {
  mounted() {
    this.initEditor("editor-for-range", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
