<template>
  <div class="pb-3">
    <h2>Project: Quiz App</h2>

    <p class="mt-3">
      In this project, we will build a math quiz application. Through this
      project, you will learn and understand the different type of variables,
      create content with message dialog and understand basic logical and
      conditional operations. First try to build the main page ui as below, you
      can design it anyway you want!
    </p>

    <img class="img-fluid" src="@/assets/app-inventor/quiz-ui.png" />

    <p class="mt-3">How to build the first UI</p>
    <video-player
      class="mt-3"
      poster="/images/quiz.png"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-21-project-quiz.mp4?alt=media&token=c2311518-bafc-4b61-8ca8-daeff5e9cd29"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
