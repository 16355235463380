<template>
  <div>
    <h2>Variable</h2>

    <p>
      A variable is a named location used to store data in the memory. It is
      helpful to think of variables as a container that holds data that can be
      changed later in the program. For example,
    </p>
    <div id="editor-variable" style="height: 80px;"></div>

    <h4 class="mt-3">Reassign variable</h4>
    <p>Variable value can be reassign to any type after it is created</p>
    <div id="editor-variable-reassign" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          15
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable name <code>favourite_number</code>
            and assign it with a value of 7
          </li>
          <li>Print the value of <code>favourite_number</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 15
favourite_animal = "cat"
is_good = True`;

const code2 = `number = "fifteen"
number = 15
print(number)`;

export default {
  mounted() {
    this.initEditor("editor-variable", code);
    this.initEditor("editor-variable-reassign", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
