<template>
  <div>
    <h2>Variable Casting</h2>
    <p>
      Casting means changing data from one type to another type. Built-in Python
      functions can be used to perform casting.
    </p>
    <ul>
      <li>int() - constructs an integer number</li>
      <li>float() - constructs a float number</li>
      <li>str() - constructs a string</li>
    </ul>
    <div id="editor-casting" style="height: 120px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          20
          <br />
          20.0
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <code>some_number</code> and assign it with an
            integer by casting <code>some_text</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `a = '20'
b = int(a)
c = float(a)

print(b)
print(c)`;

export default {
  mounted() {
    this.initEditor("editor-casting", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
