<template>
  <div>
    <h2>Functions Introduction</h2>

    <p>
      Even a simple computer program contains hundreds if not thousands of lines
      of code. Most of the time, part of these codes are repeated. Instead of
      typing out the same instructions over and over, we can group them into
      chunks called function or procedure.
    </p>

    <p>
      The <code>def</code> keyword is used to declare a function. A function can
      be called by its name. When a function is called, all the code within its
      block will be executed.
    </p>

    <div id="editor-nested" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Hello
          <br />
          Hi
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Let's start learning about functions!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `def say_hello():
    print("Hello")
    print("Hi")

say_hello()`;

export default {
  mounted() {
    this.initEditor("editor-nested", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
