import { render, staticRenderFns } from "./Exercise37.vue?vue&type=template&id=cf3b279e&scoped=true&"
import script from "./Exercise37.vue?vue&type=script&lang=js&"
export * from "./Exercise37.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf3b279e",
  null
  
)

export default component.exports