<template>
  <div>
    <b-container class="my-5">
      <b-row>
        <b-col sm="7">
          <div class="d-flex justify-content-between align-items-center">
            <h2>SQL Playground</h2>
            <div class="mb-3">
              <b-button class="mr-2" variant="success" @click="onClickRun">
                Run
              </b-button>
              <b-button variant="danger" @click="onClickReset">Reset</b-button>
            </div>
          </div>
          <div id="editor"></div>
        </b-col>

        <b-col sm="5">
          <div>
            <h2>Tables</h2>
          </div>
          <div class="mt-3" id="editor-console">
            <database-table
              :database="db"
              v-for="(db, index) in resultDB"
              :key="index"
            ></database-table>
            {{ consoleText }}
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
import initSqlJs from "sql.js";
import DatabaseTable from "@/components/DatabaseTable.vue";

export default {
  name: "SqlPlayground",

  components: {
    DatabaseTable
  },

  data() {
    return {
      sql: null,
      db: null,
      editor: null,
      resultDB: [],
      consoleText: ""
    };
  },

  async mounted() {
    this.initEditor();
    await this.initSQL();
  },

  methods: {
    initEditor() {
      this.editor = ace.edit("editor", {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });
    },

    async initSQL() {
      this.sql = await initSqlJs({
        // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
        // You can omit locateFile completely when running in node
        locateFile: file =>
          `https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.4.0/dist/${file}`
      });
    },

    async initDB() {
      if (this.db != null) {
        this.db.close();
        this.db = null;
      }
      this.db = new this.sql.Database();
    },

    onClickRun() {
      try {
        this.resultDB = [];
        this.initDB();
        const result = this.db.exec(this.editor.getValue());
        this.resultDB = result;
      } catch (error) {
        this.consoleText = error;
      }
    },

    onClickReset() {
      if (confirm("Confirm reset code?")) {
        this.editor.setValue("", 1);
        this.resultDB = [];
        this.consoleText = "";
      }
    }
  }
};
</script>

<style scoped>
#editor {
  height: 80vh;
  overflow: auto;
}

#editor-console {
  height: 80vh;
  background-color: #fff;
  white-space: pre-wrap;
  overflow: auto;
  color: black;
}
</style>
