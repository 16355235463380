/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    try { 
      var head = coinFlip(51); 
      var fifty = coinFlip(50);
      var tail = coinFlip(49);
    } 
    catch (err) {
      var head = "";
      var tail = "";
      var fifty = "";
    } 
    try { returnResult(head, tail, fifty); } catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (head: string, tail: string, fifty: string) => {
      result.head = head;
      result.tail = tail;
      result.fifty = fifty;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function coinFlip(number)")) {
    status.message = "coinFlip function is not created correctly";
  } else if (result.head != "head") {
    status.message = "Logic for head is not correct";
  } else if (result.tail != "tail" || result.fifty != "tail") {
    status.message = "Logic for tail is not correct";
  } else if (!code.includes("alert(coinFlip(randomNumber))")) {
    status.message = "coinFlip not called with with the correct argument";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
