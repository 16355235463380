<template>
  <div>
    <h2>If Statement</h2>

    <p>
      If statement evaluates an given expression and if the expression is
      <code>True</code>, a block of code will be executed.
    </p>
    <p>
      Blocks of code are denoted by line indentation. The number of spaces in
      the indentation is variable, but all statements within the block must be
      indented the same amount.
    </p>

    <div id="editor-if" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          number is more than 10
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Wrong sytax</h4>
    <p>Below examples will generate error</p>
    <div id="editor-if-error" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          IndentationError: unexpected indent at line 4
        </code>
      </b-card-text>
    </b-card>

    <div class="mt-3" id="editor-if-error-2" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          IndentationError: expected an indented block at line 3
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Write a if statement to check whether <code>random_number</code> is
            greater than 10. If true, set <code>result</code> to
            <code>True</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 20
if number > 10:
  print("number is more than 10")
`;

const code2 = `number = 20
if number > 10:
  print("number is more than 10")
   print("second print statement")  # Different number of spaces
`;

const code3 = `number = 20
if number > 10:
print("number is more than 10")
`;

export default {
  mounted() {
    this.initEditor("editor-if", code);
    this.initEditor("editor-if-error", code2);
    this.initEditor("editor-if-error-2", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
