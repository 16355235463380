<template>
  <div>
    <b-row class="justify-content-center align-items-center mt-5">
      <b-col md="6">
        <b-card class="py-3">
          <b-card-text class="text-center">
            <h2>{{ paymentStatus }}</h2>
            <b-button to="/">
              Back to Dashboard
            </b-button>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Invite",

  data() {
    return {
      status: this.$route.query.status,
      sessionId: this.$route.query.session_id
    };
  },

  computed: {
    paymentStatus() {
      return this.status == "success"
        ? "✔️ Payment Successful"
        : "❌ Payment Cancelled";
    }
  },

  methods: {}
};
</script>

<style></style>
