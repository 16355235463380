<template>
  <div>
    <h2>Challenge 5 - Count Vowels</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">countVowel</span> that takes in
            an string argument.
          </li>
          <li>
            Function will return the number of vowels in the argument.
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Input: "Hello"
          <br />
          Expected result:
          <br />
          >> 2
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
