/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `some_text = "32"

# Write code only below this line
`;
export const seedTest = ``;
export const testCases = `
assert isinstance(some_number, int), "some_number should be int data type"
assert isinstance(some_text, str), "some_text should be str data type"
assert some_text == "32", "Do not change the default code"
assert some_number == 32, "some_number should equal to 32"`;

export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /int\(\s*some_text\s*\)/.test(code),
    "Use int() to cast some_text into integer"
  );

  window.console = oldConsole;
  return true;
}
