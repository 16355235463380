<template>
  <div
    ref="draggableContainer"
    id="draggable-container"
    @mousedown="dragMouseDown"
    :class="{ visible: localIsVisible }"
  >
    <div id="draggable-header">
      <span class="clickable hover-underline" @click="onClickClose">Close</span>
    </div>
    <slot name="main"></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableDiv",

  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      localIsVisible: this.isVisible,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    };
  },

  methods: {
    dragMouseDown: function(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },

    elementDrag: function(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },

    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },

    onClickClose() {
      this.localIsVisible = false;
    }
  }
};
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
  top: 100px;
  left: calc(50% - 200px);
  cursor: move;
  width: 400px;
  height: 400px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgb(231, 231, 231);
  background-color: white;
  border: 2px solid #000000;
  text-align: center;
  visibility: hidden;
}

.visible {
  visibility: visible !important;
}
</style>
