<template>
  <div>
    <h2>Bracket Notation</h2>

    <p>
      Aside from dot notation, value of object can also be access using bracket
      notation. Dot notation requires the key to be valid variable name. Using
      bracket notation, any string is allowed.
    </p>

    <div id="editor-object-bracket" style="height: 200px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use bracket notation to change the maxSpeed of <code>car</code> to
            100
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let someObject = {
  someString: "Hello World!",
  someNum: 42,
  "other num": 23
};

console.log(someObject["someNum"]);
console.log(someObject["other num"]);
someObject["someString"] = "Hi!";
`

export default {
  mounted() {
    this.initEditor("editor-object-bracket", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
