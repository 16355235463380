/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isOne = 0;
    var isTen = 0;
    var isFive = 0;
    var isTwenty = 0;
    var isZero = 10;
    try { 
      isOne = getLargest([1]);
      isTen = getLargest([1, 2, 3, 5, 10]);
      isFive = getLargest([5, 1, 1, 1, 1, 3]);
      isTwenty = getLargest([1, 2, 3, 20, 4, 7, 6]);
      isZero = getLargest([]);
    } catch (err) {};
    try {returnResult(isOne, isTen, isFive, isTwenty, isZero);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      isOne: number,
      isTen: number,
      isFive: number,
      isTwenty: number,
      isZero: number
    ) => {
      result.isOne = isOne;
      result.isTen = isTen;
      result.isFive = isFive;
      result.isTwenty = isTwenty;
      result.isZero = isZero;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function getLargest")) {
    status.message = "getLargest function is not created";
  } else if (
    result.isOne != 1 ||
    result.isTen != 10 ||
    result.isFive != 5 ||
    result.isTwenty != 20 ||
    result.isZero != 0
  ) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
