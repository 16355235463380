<template>
  <div>
    <h2>Exercise 11</h2>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a function <code>generate_even_numbers</code> with one
            parameters
          </li>
          <li>
            The function will return a list of even numbers starting from 2.
          </li>
          <li>
            The length of list generated based on the parameter value
          </li>
        </ol>
        <br />
        * assume argument is always greater than 0
      </b-card-text>
    </b-card>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          # Example
          <br />
          >>> print(generate_even_numbers(3))
          <br />
          [2, 4, 6]
          <br /><br />
          >>> print(generate_even_numbers(6))
          <br />
          [2, 4, 6, 8, 10, 12]
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `def say_greeting(name):
    print("Hello", name)

say_greeting("Mike")

def print_sum(a, b):
    print(a + b)

print_sum(5, 3)`;

const code2 = `def plus_two(x):
    return x + 2

sum = plus_two(2)
print(sum)`;

export default {
  mounted() {
    this.initEditor("editor-function-param", code);
    this.initEditor("editor-function-return", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
