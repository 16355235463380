<template>
  <div>
    <h2>Functions Introduction</h2>

    <p>
      Even a simple computer program contains hundreds if not thousands of lines
      of code. Most of the time, part of these codes are repeated. Instead of
      typing out the same instructions over and over, we can group them into
      chunks called function or procedure.
    </p>

    <p>
      The <code>function</code> keyword is used to declare a function. A
      function can be called by its name. When a function is called, all the
      code within its block will be executed.
    </p>

    <div id="editor-function-intro" style="height: 120px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "Hello"
          <br />
          > "Hi"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare a function named <code>generateNumbers</code>. The function
            should push 1 to 10 into <code>numbers</code> array
          </li>
          <li>Call the function once</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `function sayGreetings() {
  console.log("Hello");
  console.log("Hi");
}

sayGreetings();
`

export default {
  mounted() {
    this.initEditor("editor-function-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
