<template>
  <div>
    <h2>If/Else Statement</h2>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-16-conditional-else.mp4?alt=media&token=e9c6c736-631d-4da2-8166-0e8f62740cba"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Use if to specify a block of code to be executed, if a specified condition
      is true. Use else to specify a block of code to be executed, if the same
      condition is false
    </p>
    <img class="img-fluid" src="@/assets/foundation/conditional_2.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">favouriteFruit</span> and set it
            to
            <span class="block block-string">orange</span>
          </li>
          <li>
            Use a if statement to check whether
            <span class="block block-variable">favouriteFruit</span> is
            <span class="block block-string">apple</span>, if true, print a
            message <span class="block block-string">I love apple too!</span>.
          </li>
          <li>
            Else, print
            <span class="block block-string">Not my favourite fruit!</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "Not my favourite fruit!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "FoundationVariable",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
