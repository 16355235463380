<template>
  <div>
    <h2>Data Type</h2>
    <p>
      JavaScript is a loosely typed and dynamic language. Variables in
      JavaScript are not directly associated with any particular value type, and
      any variable can be assigned (and re-assigned) values of all types.
    </p>
    <p>
      There are nine data types defined in JavaScript:
    </p>

    <ul>
      <li>undefined</li>
      <li>Boolean</li>
      <li>Number</li>
      <li>String</li>
      <li>BigInt</li>
      <li>Symbol</li>
      <li>Object</li>
      <li>Function</li>
      <li>null</li>
    </ul>

    <p>
      BigInt and Symbol are not commonly encountered. BigInt is used to
      represent big number as Number can not represent value larger than
      9007199254740991. The symbol type is used to create unique identifiers for
      objects.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to for the next activity
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    //
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
