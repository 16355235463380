<template>
  <div>
    <h2>Other Methods</h2>

    <h4>reverse</h4>
    <p>
      The reverse() method reverses an array.
    </p>

    <div id="editor-array-reverse" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [3, 2, 1]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">splice</h4>
    <p>
      The splice() method changes the contents of an array by removing or
      replacing existing elements and/or adding new elements. Splice methods has
      three parameters, separated by comma <code>,</code> :
    </p>

    <ul>
      <li>First parameter: The index at which to start changing the array.</li>
      <li>
        Second parameter (optional): An integer indicating the number of
        elements in the array to remove from start
      </li>
      <li>
        Third paramter (optional): The elements to add to the array, beginning
        from start.
      </li>
    </ul>

    <p>
      If only first parameter provided, all element after the index will be
      removed
    </p>
    <div id="editor-array-splice-1" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > ['Jan']
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      Two element from index 1 will be removed
    </p>
    <div id="editor-array-splice-2" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > ['Jan', 'May']
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      No element removed and 'March' will be added after index 1
    </p>
    <div id="editor-array-splice-3" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > ['Jan', 'Feb', 'March', 'April', 'May']
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      <a
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice"
      >
        Learn more about splice
      </a>
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Reverse the array
          </li>
          <li>
            Then, remove "Tue", "Wed", and "Thu" from the array using splice
            method
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const spliceCode1 = `let months = ['Jan', 'Feb', 'April', 'May'];
months.splice(1);
console.log(months);`;

const spliceCode2 = `let months = ['Jan', 'Feb', 'April', 'May'];
months.splice(1, 2);
console.log(months);
`;

const spliceCode3 = `let months = ['Jan', 'Feb', 'April', 'May'];
months.splice(1, 0, 'March');
console.log(months);
`;

export default {
  mounted() {
    this.initEditor("editor-array-reverse", `let someArray = [1, 2, 3];\nsomeArray.reverse();\nconsole.log(someArray);`);
    this.initEditor("editor-array-splice-1", spliceCode1);
    this.initEditor("editor-array-splice-2", spliceCode2);
    this.initEditor("editor-array-splice-3", spliceCode3);

  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
