<template>
  <div>
    <b-container>
      <b-row class="mb-3">
        <b-col>
          <h2>Password</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="bg-white p-3">
            <div class="form-container">
              <b-form @submit.prevent="onClickSavePassword">
                <b-form-group
                  label="New password"
                  :state="passwordState"
                  :invalid-feedback="invalidFeedback"
                >
                  <b-form-input
                    :state="passwordState"
                    type="password"
                    v-model="password"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Confirm new password"
                  :state="passwordState"
                  :invalid-feedback="invalidFeedback"
                >
                  <b-form-input
                    :state="passwordState"
                    type="password"
                    v-model="confirmPassword"
                  ></b-form-input>
                </b-form-group>

                <b-button variant="dark" type="submit">
                  <b-spinner small v-if="isSavingPassword"></b-spinner>
                  {{ isSavingPassword ? "Loading..." : "Save" }}
                </b-button>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { authServices } from "@/services";

export default {
  name: "SettingPassword",

  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordState: null,
      invalidFeedback: "",
      isSavingPassword: false
    };
  },

  methods: {
    onClickSavePassword() {
      this.passwordState = null;

      if (this.password != this.confirmPassword) {
        this.invalidFeedback = "Password not same";
        this.passwordState = false;
        return;
      }

      this.isSavingPassword = true;

      authServices
        .getCurrentUser()
        .then(currentUser => {
          return currentUser.updatePassword(this.password);
        })
        .then(() => {
          this.$bvToast.toast("Password saved successfully", {
            title: "Password Setting",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: true
          });
          this.isSavingPassword = false;
        })
        .catch(error => {
          this.passwordState = false;
          this.invalidFeedback = error.message;
          this.isSavingPassword = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.form-container {
  width: 60%;
}
</style>
