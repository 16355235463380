/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `hours = 17

# Write code only below this line
`;
export const seedTest = ``;
export const testCases = ``;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /print\(\s*"Cats sleep "\s*\+\s*str\(\s*hours\s*\)\s*\+\s*" hours per day."\s*\)/.test(
      code
    ),
    "Print statement not correct"
  );

  window.console = oldConsole;
  return true;
}
