/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `studentName = "Alice"
Cat_Age = 3
laptoptype = "Macbook Pro"`;
export const seedTest = ``;
export const testCases = ``;

export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /student_name/.test(code),
    "studentName is not renamed with snake case"
  );
  assert(/cat_age/.test(code), "Cat_Age is not renamed with snake case");
  assert(/laptop_type/.test(code), "laptoptype is not renamed with snake case");

  window.console = oldConsole;
  return true;
}
