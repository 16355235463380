<template>
  <div>
    <b-row
      class="justify-content-center align-items-center mt-5"
      v-if="$store.state.user.role == 'student'"
    >
      <b-col md="6">
        <b-card class="py-3">
          <b-card-text class="text-center">
            <h2>Join Class</h2>
            <h3>{{ classroom.name }}</h3>
            <p v-if="isJoined">
              You already joined this class
            </p>
            <p v-else>{{ description }}</p>
            <b-button
              v-if="classroom.isOpenToStudent && !isJoined"
              class="mt-3"
              size="lg"
              variant="dark"
              :disabled="isJoining"
              @click="onClickJoinClass"
            >
              <b-spinner small v-if="isJoining"></b-spinner>
              {{ isJoining ? "Loading..." : "Join Class" }}
            </b-button>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      class="justify-content-center align-items-center mt-5"
      v-if="classroom.id != null && $store.state.user.role == 'teacher'"
    >
      <b-col md="6">
        <b-card class="py-3">
          <b-card-text class="text-center">
            <h2>Join Class</h2>
            <p>{{ classroom.name }}</p>
            <p>🙅 Only student account can join a classroom</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  classroomServices,
  organisationServices,
  userServices
} from "@/services";

export default {
  name: "Invite",

  data() {
    return {
      classroomId: this.$route.params.classroomId,
      classroom: {
        studentIds: []
      },
      description: "",
      isJoining: false,
      isJoined: false
    };
  },

  async created() {
    const loader = this.$loading.show();
    await this.getClassroom();
    this.getDescription();
    this.checkJoined();
    loader.hide();
  },

  methods: {
    getClassroom() {
      return classroomServices
        .getClassroom(this.classroomId)
        .then(classroom => {
          if (classroom == null) {
            this.description = "Classroom id does not exist";
            return;
          }
          this.classroom = classroom;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getDescription() {
      if (this.classroom.id == null) {
        this.description = "Classroom id does not exist";
        return;
      }

      this.description = this.classroom.isOpenToStudent
        ? "You are joining this class as a student"
        : "This class is not accepting new student 🙅";
    },

    checkJoined() {
      this.isJoined = this.classroom.studentIds.includes(
        this.$store.state.user.id
      );
    },

    async onClickJoinClass() {
      this.isJoining = true;

      if (this.classroom == null) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`Class of this id does not exist`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      if (!this.classroom.isOpenToStudent) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`This class is not open for enrollment`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      if (this.classroom.studentIds.includes(this.$store.state.user.id)) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`You have already joined this class`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      const organisation = await organisationServices.getOrganisation(
        this.classroom.organisationId
      );

      if (organisation == null) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(
          `Organisation for this classroom not found`,
          {
            title: "Join Class",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          }
        );
      }

      if (organisation.remainingLicenses <= 0) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`Organisation is out of student licenses`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      try {
        await classroomServices.addStudent(
          this.classroomId,
          this.$store.state.user.id
        );

        await userServices.addClassroom(
          this.classroomId,
          this.$store.state.user.id
        );

        await organisationServices.reduceRemainingLicense(
          this.classroom.organisationId
        );

        await organisationServices.addStudent(
          this.classroom.organisationId,
          this.$store.state.user.id,
          this.classroomId
        );

        this.$router.push("/");
      } catch (error) {
        alert(error.message);
        this.isJoining = false;
      }
    }
  }
};
</script>

<style></style>
