<template>
  <div>
    <h2>Get/Set Object Value</h2>

    <h4>Get</h4>
    <p>
      Value stored in each key in an object can be access by using the dot
      notation. If a key does not exits, <code>undefined will be returned</code>
    </p>

    <div id="editor-object-get" style="height: 150px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 42
          <br />
          > undefined
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Set</h4>
    <p>
      Similary, you can set value to a new key or existing key using dot
      notation.
    </p>

    <div id="editor-object-set" style="height: 150px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > { someNum: 5, otherNum: 10 }
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Defined a variable <code>carColour</code> and assign it with the
            value of colour in <code>car</code> object
          </li>
          <li>Change the maxSpeed of <code>car</code> to 250</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let someObject = {
  someString: "Hello World!",
  someNum: 42,
};

console.log(someObject.someNum);
console.log(someObject.otherNum);
`
const codeSet = `let someObject = {
  someNum: 42,
};

someObject.someNum = 5;
someObject.otherNum = 10;
console.log(someObject);
`
export default {
  mounted() {
    this.initEditor("editor-object-get", code);
    this.initEditor("editor-object-set", codeSet);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
