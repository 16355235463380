// Components
import VariableIntroduction from "./VariableIntroduction.vue";
import VariableDataType from "./VariableDataType.vue";
import VariableNaming from "./VariableNaming.vue";
import VariableGet from "./VariableGet.vue";
import VariableReassign from "./VariableReassign.vue";

import OperatorIntroduction from "./OperatorIntroduction.vue";
import OperatorCombine from "./OperatorCombine.vue";
import OperatorUpdate from "./OperatorUpdate.vue";

import ConditionalIntroduction from "./ConditionalIntroduction.vue";
import ConditionalExpression from "./ConditionalExpression.vue";
import ConditionalIfElse from "./ConditionalIfElse.vue";
import ConditionalIfElseIf from "./ConditionalIfElseIf.vue";
import ConditionalCompoundAnd from "./ConditionalCompoundAnd.vue";
import ConditionalCompoundOr from "./ConditionalCompoundOr.vue";
import ConditionalNot from "./ConditionalNot.vue";

import LoopIntroduction from "./LoopIntroduction.vue";
import LoopVariable from "./LoopVariable.vue";
import LoopBreak from "./LoopBreak.vue";
import LoopNested from "./LoopNested.vue";
import LoopWhile from "./LoopWhile.vue";

import ListIntroduction from "./ListIntroduction.vue";
import ListGet from "./ListGet.vue";
import ListSet from "./ListSet.vue";
import ListForLoop from "./ListForLoop.vue";
import ListForEachLoop from "./ListForEachLoop.vue";

import FunctionIntroduction from "./FunctionIntroduction.vue";
import FunctionParam from "./FunctionParam.vue";
import FunctionReturn from "./FunctionReturn.vue";

import Exercise1 from "./Exercise1.vue";
import Exercise2 from "./Exercise2.vue";
import Exercise3 from "./Exercise3.vue";
import Exercise4 from "./Exercise4.vue";
import Exercise5 from "./Exercise5.vue";
import Exercise6 from "./Exercise6.vue";
import Exercise7 from "./Exercise7.vue";
import Exercise8 from "./Exercise8.vue";
import Exercise9 from "./Exercise9.vue";
import Exercise10 from "./Exercise10.vue";
import Exercise11 from "./Exercise11.vue";
import Exercise12 from "./Exercise12.vue";
import Exercise13 from "./Exercise13.vue";
import Exercise14 from "./Exercise14.vue";
import Exercise15 from "./Exercise15.vue";
import Exercise16 from "./Exercise16.vue";
import Exercise17 from "./Exercise17.vue";
import Exercise18 from "./Exercise18.vue";
import Exercise19 from "./Exercise19.vue";
import Exercise20 from "./Exercise20.vue";
import Exercise21 from "./Exercise21.vue";
import Exercise22 from "./Exercise22.vue";
import Exercise23 from "./Exercise23.vue";
import Exercise24 from "./Exercise24.vue";
import Exercise25 from "./Exercise25.vue";
import Exercise26 from "./Exercise26.vue";
import Exercise27 from "./Exercise27.vue";
import Exercise28 from "./Exercise28.vue";
import Exercise29 from "./Exercise29.vue";
import Exercise30 from "./Exercise30.vue";
import Exercise31 from "./Exercise31.vue";
import Exercise32 from "./Exercise32.vue";
import Exercise33 from "./Exercise33.vue";
import Exercise34 from "./Exercise34.vue";
import Exercise35 from "./Exercise35.vue";
import Exercise36 from "./Exercise36.vue";
import Exercise37 from "./Exercise37.vue";
import Exercise38 from "./Exercise38.vue";

// Logics
import * as variableIntroductionLogics from "./VariableIntroduction";
import * as variableGetLogics from "./VariableGet";
import * as variableDataTypeLogics from "./VariableDataType";
import * as variableNamingLogics from "./VariableNaming";
import * as variableReassignLogics from "./VariableReassign";

import * as operatorIntroductionLogics from "./OperatorIntroduction";
import * as operatorCombineLogics from "./OperatorCombine";
import * as operatorUpdateLogics from "./OperatorUpdate";

import * as conditionalIntroductionLogics from "./ConditionalIntroduction";
import * as conditionalExpressionLogics from "./ConditionalExpression";
import * as conditionalIfElseLogics from "./ConditionalIfElse";
import * as conditionalIfElseIfLogics from "./ConditionalIfElseIf";
import * as conditionalCompoundAndLogics from "./ConditionalCompoundAnd";
import * as conditionalCompoundOrLogics from "./ConditionalCompoundOr";
import * as conditionalNotLogics from "./ConditionalNot";

import * as loopIntroductionLogics from "./LoopIntroduction";
import * as loopVariableLogics from "./LoopVariable";
import * as loopBreakLogics from "./LoopBreak";
import * as loopNestedLogics from "./LoopNested";
import * as loopWhileLogics from "./LoopWhile";

import * as listIntroductionLogics from "./ListIntroduction";
import * as listGetLogics from "./ListGet";
import * as listSetLogics from "./ListSet";
import * as listForLoopLogics from "./ListForLoop";
import * as listForEachLoopLogics from "./ListForEachLoop";

import * as functionIntroductionLogics from "./FunctionIntroduction";
import * as functionParamLogics from "./FunctionParam";
import * as functionReturnLogics from "./FunctionReturn";

import * as exercise1Logics from "./Exercise1";
import * as exercise2Logics from "./Exercise2";
import * as exercise3Logics from "./Exercise3";
import * as exercise4Logics from "./Exercise4";
import * as exercise5Logics from "./Exercise5";
import * as exercise6Logics from "./Exercise6";
import * as exercise7Logics from "./Exercise7";
import * as exercise8Logics from "./Exercise8";
import * as exercise9Logics from "./Exercise9";
import * as exercise10Logics from "./Exercise10";
import * as exercise11Logics from "./Exercise11";
import * as exercise12Logics from "./Exercise12";
import * as exercise13Logics from "./Exercise13";
import * as exercise14Logics from "./Exercise14";
import * as exercise15Logics from "./Exercise15";
import * as exercise16Logics from "./Exercise16";
import * as exercise17Logics from "./Exercise17";
import * as exercise18Logics from "./Exercise18";
import * as exercise19Logics from "./Exercise19";
import * as exercise20Logics from "./Exercise20";
import * as exercise21Logics from "./Exercise21";
import * as exercise22Logics from "./Exercise22";
import * as exercise23Logics from "./Exercise23";
import * as exercise24Logics from "./Exercise24";
import * as exercise25Logics from "./Exercise25";
import * as exercise26Logics from "./Exercise26";
import * as exercise27Logics from "./Exercise27";
import * as exercise28Logics from "./Exercise28";
import * as exercise29Logics from "./Exercise29";
import * as exercise30Logics from "./Exercise30";
import * as exercise31Logics from "./Exercise31";
import * as exercise32Logics from "./Exercise32";
import * as exercise33Logics from "./Exercise33";
import * as exercise34Logics from "./Exercise34";
import * as exercise35Logics from "./Exercise35";
import * as exercise36Logics from "./Exercise36";
import * as exercise37Logics from "./Exercise37";
import * as exercise38Logics from "./Exercise38";

const curriculum = new Map();
curriculum.set("wVAsds7Gxh5ZAtaWdQSl", {
  content: VariableIntroduction,
  logics: variableIntroductionLogics
});

curriculum.set("AfOpUe0ylUOEt4YtkMMx", {
  content: VariableGet,
  logics: variableGetLogics
});

curriculum.set("cosTmT3WBMmXIIHVzx51", {
  content: Exercise1,
  logics: exercise1Logics
});

curriculum.set("VfL4fqiDDMtuEJvweD64", {
  content: VariableDataType,
  logics: variableDataTypeLogics
});

curriculum.set("kj7r2qqiiaojizbfuy0", {
  content: Exercise2,
  logics: exercise2Logics
});

curriculum.set("kj7srdzkntm0qopv9ts", {
  content: Exercise3,
  logics: exercise3Logics
});

curriculum.set("kj7ssfju1ke8hmiy0pp", {
  content: VariableNaming,
  logics: variableNamingLogics
});

curriculum.set("07Dm3Fdn2QnGRc4Wgk8W", {
  content: Exercise4,
  logics: exercise4Logics
});

curriculum.set("RiRLpolfaL3qIR2wLhB8", {
  content: VariableReassign,
  logics: variableReassignLogics
});

curriculum.set("aZOD6DebcBBrKAZKoUyp", {
  content: Exercise5,
  logics: exercise5Logics
});

curriculum.set("B3u7L7VjroGS2jLC98Wj", {
  content: Exercise6,
  logics: exercise6Logics
});

curriculum.set("zIeo15srgCyOuQO0V8SH", {
  content: OperatorIntroduction,
  logics: operatorIntroductionLogics
});

curriculum.set("PGUI0OLVmbfYgRMtHKJ1", {
  content: Exercise7,
  logics: exercise7Logics
});

curriculum.set("s8IeKWfWMbsbYPCx4iOC", {
  content: Exercise8,
  logics: exercise8Logics
});

curriculum.set("Qmsy18xeMALJ7PZ3bVkx", {
  content: OperatorCombine,
  logics: operatorCombineLogics
});

curriculum.set("J8zRb6KEUwXaZeJa6Tih", {
  content: Exercise9,
  logics: exercise9Logics
});

curriculum.set("jF0T0DOQbdf3tKDEU6Ze", {
  content: Exercise10,
  logics: exercise10Logics
});

curriculum.set("8cuJ2A7XS2ao19VkuWOz", {
  content: OperatorUpdate,
  logics: operatorUpdateLogics
});

curriculum.set("7uKKUNyTERQYGX0oaD2l", {
  content: Exercise11,
  logics: exercise11Logics
});

curriculum.set("PaDHz2A6UiHmIunwTVOU", {
  content: ConditionalIntroduction,
  logics: conditionalIntroductionLogics
});

curriculum.set("Ttfbhsx9g1SVUMIkd6pD", {
  content: ConditionalExpression,
  logics: conditionalExpressionLogics
});

curriculum.set("RnTNmzvc9g2wPh8XlRI0", {
  content: Exercise12,
  logics: exercise12Logics
});

curriculum.set("5CCOzjgGIfuUI5goaLMA", {
  content: Exercise13,
  logics: exercise13Logics
});

curriculum.set("qr8SFT0Lad2ZWOzDtlmg", {
  content: Exercise14,
  logics: exercise14Logics
});

curriculum.set("kjbl601cclzrj8gwq40", {
  content: ConditionalIfElse,
  logics: conditionalIfElseLogics
});

curriculum.set("kjbld2gfif1dbpk0kyg", {
  content: ConditionalIfElseIf,
  logics: conditionalIfElseIfLogics
});

curriculum.set("ptdMLfMKjhPL3yHVNyMj", {
  content: Exercise15,
  logics: exercise15Logics
});

curriculum.set("G2nLK1lwaD9caVWogju7", {
  content: Exercise16,
  logics: exercise16Logics
});

curriculum.set("KqvczBESgXzqNQPAA6FP", {
  content: ConditionalCompoundAnd,
  logics: conditionalCompoundAndLogics
});

curriculum.set("lYeuT6P3Tm6Zx18ze8Nw", {
  content: Exercise17,
  logics: exercise17Logics
});

curriculum.set("ilw9s5OQk2zRq8Cjgspo", {
  content: ConditionalCompoundOr,
  logics: conditionalCompoundOrLogics
});

curriculum.set("txKJelCZ9bmmRBXLtHUt", {
  content: Exercise18,
  logics: exercise18Logics
});

curriculum.set("PFHMRo8un1bgOCMm6rV4", {
  content: Exercise19,
  logics: exercise19Logics
});

curriculum.set("lWUF69tVAro3snkgbe0s", {
  content: ConditionalNot,
  logics: conditionalNotLogics
});

curriculum.set("URsyE4mFXKek2NYT9dz0", {
  content: Exercise20,
  logics: exercise20Logics
});

curriculum.set("34grrjVFKPTsLZv3f8Dg", {
  content: LoopIntroduction,
  logics: loopIntroductionLogics
});

curriculum.set("93fR18vUjVrtFju2paTF", {
  content: LoopVariable,
  logics: loopVariableLogics
});

curriculum.set("P6Im56GefNESKTEvnn2e", {
  content: Exercise21,
  logics: exercise21Logics
});

curriculum.set("BLmx3zhzTMzd6lgL5wXZ", {
  content: Exercise22,
  logics: exercise22Logics
});

curriculum.set("KQ6L05sCSRHDup3vFqXb", {
  content: Exercise23,
  logics: exercise23Logics
});

curriculum.set("nV8iXpScA6FlX9it9osy", {
  content: LoopBreak,
  logics: loopBreakLogics
});

curriculum.set("SBwqmiYvPDnSKx55LqKK", {
  content: Exercise24,
  logics: exercise24Logics
});

curriculum.set("2ghgBcrUZaECZf3vVERT", {
  content: LoopNested,
  logics: loopNestedLogics
});

curriculum.set("OQ94v5NOUyDkj2riDmTB", {
  content: Exercise25,
  logics: exercise25Logics
});

curriculum.set("PByVToQ9kfE9BTddOxRp", {
  content: Exercise26,
  logics: exercise26Logics
});

curriculum.set("ZaEgE6dqPAiYcpfbUrHK", {
  content: LoopWhile,
  logics: loopWhileLogics
});

curriculum.set("ttMIEsabPJEm5qjn3Nzf", {
  content: Exercise27,
  logics: exercise27Logics
});

curriculum.set("3seXyq4gpGqKeUK0kBtn", {
  content: Exercise28,
  logics: exercise28Logics
});

curriculum.set("mdPtI67MAuQHcAMENBGP", {
  content: ListIntroduction,
  logics: listIntroductionLogics
});

curriculum.set("u4o0d4oxYQIHsHOJDmwg", {
  content: ListGet,
  logics: listGetLogics
});

curriculum.set("LeS0Q3JLJBg58rMaDMIW", {
  content: ListSet,
  logics: listSetLogics
});

curriculum.set("ozLQt4kuQnHw2uNS23AT", {
  content: Exercise29,
  logics: exercise29Logics
});

curriculum.set("QGl5Uib8aVJVZNvjtGhr", {
  content: Exercise30,
  logics: exercise30Logics
});

curriculum.set("oN0JTQ6vZvhPR9gUbV3h", {
  content: ListForLoop,
  logics: listForLoopLogics
});

curriculum.set("vnACuH728xkj6GDCH3Aw", {
  content: ListForEachLoop,
  logics: listForEachLoopLogics
});

curriculum.set("SUZFz88Bf9iGTQrNJl9R", {
  content: Exercise31,
  logics: exercise31Logics
});

curriculum.set("rsx5IlrpeOcDH8D4tSbk", {
  content: Exercise32,
  logics: exercise32Logics
});

curriculum.set("D4gjLueyWvk3GLTpF406", {
  content: Exercise33,
  logics: exercise33Logics
});

curriculum.set("bBgXtktVbSpILTQMi2sx", {
  content: FunctionIntroduction,
  logics: functionIntroductionLogics
});

curriculum.set("pLCDr0qoAqo4rt0DXly9", {
  content: FunctionParam,
  logics: functionParamLogics
});

curriculum.set("ldXowylth2Ilcm5FYX2y", {
  content: Exercise34,
  logics: exercise34Logics
});

curriculum.set("Th840s4voz2FDkIg7273", {
  content: FunctionReturn,
  logics: functionReturnLogics
});

curriculum.set("mHcRfURk3tJbggqzaBhi", {
  content: Exercise35,
  logics: exercise35Logics
});

curriculum.set("vFXTMgBFy37kBfr2p4E5", {
  content: Exercise36,
  logics: exercise36Logics
});

curriculum.set("eHLI9cLVFNPCeGzx57vj", {
  content: Exercise37,
  logics: exercise37Logics
});

curriculum.set("MSIC4HVQTn3l4KYGYIbJ", {
  content: Exercise38,
  logics: exercise38Logics
});

export default curriculum;
