/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `lucky_number = 4 +
2 +
1`;
export const seedTest = ``;
export const testCases = `
assert lucky_number == 7, "lucky_number should equals to 7"`;

function printConsole(text: any) {
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(
    /lucky_number = 4 \+\s*\\\s*2 \+\s*\\\s*1/.test(code),
    "Use forward slash \\ for multiline statement"
  );

  window.console = oldConsole;
  return true;
}
