<template>
  <div>
    <h2>Global Variable</h2>
    <p>
      In Python, variable defined outside of a function or in global scope is
      known as a global variable.
    </p>
    <p>
      We can access the variable value but not be able to modify it.
    </p>

    <div id="editor-global-get" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          7
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Error modifying global variable</h4>
    <div id="editor-global-set" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          UnboundLocalError: local variable 'number' referenced before
          assignment
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Global keyword</h4>
    <p>
      To modify a variable out of a function's scope, we need to use the
      <code>global</code> keyword.
    </p>
    <div id="editor-global-modify" style="height: 130px;"></div>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Define a function <code>set_x</code> with one parameter</li>
          <li>
            The function will reassign variable <code>x</code> as value of
            function argument
          </li>
          <li>Call <code>set_x</code> function with argument 100</li>
          <li>Print <code>x</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 7
def print_number():
    print(number)

print_number()`;

const code2 = `number = 7
def print_number():
    number = number + 1

print_number()`;

const code3 = `number = 7
def print_number():
    global number
    number = number + 1
    print(number)

print_number()`;

export default {
  mounted() {
    this.initEditor("editor-global-get", code);
    this.initEditor("editor-global-set", code2);
    this.initEditor("editor-global-modify", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
