<template>
  <div>
    <h2>Select Statement</h2>
    <p>
      The <code>SELECT</code> statement is used to get data from a database. In
      example below, <code>SELECT</code> returns the <code>name</code> and
      <code>class</code> columns from the students table.
    </p>
    <div id="editor-select" style="height: 100px;"></div>

    <b-table-simple
      class="text-center mt-3"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            name
          </b-th>
          <b-th>
            cgpa
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>John</b-td>
          <b-td>3.23</b-td>
        </b-tr>
        <b-tr>
          <b-td>Melisa</b-td>
          <b-td>3.52</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Select all</h4>
    <p>Use the <code>*</code> symbol to select all columns in a table.</p>
    <div id="editor-select-all" style="height: 50px;"></div>

    <b-table-simple
      class="text-center mt-3"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            name
          </b-th>
          <b-th>
            class
          </b-th>
          <b-th>
            age
          </b-th>
          <b-th>
            cgpa
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>John</b-td>
          <b-td>Ruby</b-td>
          <b-td>16</b-td>
          <b-td>3.23</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Melisa</b-td>
          <b-td>Ruby</b-td>
          <b-td>16</b-td>
          <b-td>3.52</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Select all data from the <code>devices</code> table</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `CREATE TABLE students (id int, name varChar(255), class varChar(255), age int, cgpa numeric);
INSERT INTO students VALUES (0, "John", "Ruby", 16, 3.23);
INSERT INTO students VALUES (1, "Melisa", "Ruby", 16, 3.52);

SELECT name, cgpa FROM students;`;

const codeAll = `SELECT * FROM students;`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-select", code);
    this.initEditor("editor-select-all", codeAll);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
