<template>
  <div>
    <h2>Math Operators</h2>

    <p>
      Arithmetic operators are used to perform operation on numbers.
    </p>
    <ul>
      <li>Addtion +</li>
      <li>Substraction -</li>
      <li>Multiplication *</li>
      <li>Division /</li>
      <li>Modulus %</li>
      <li>Exponential **</li>
    </ul>
    <div id="editor-math" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          2
          <br />
          2
          <br />
          4
          <br />
          3
          <br />
          8
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Modulus %</h4>
    <p>
      Modulus operator will return the remainder of dividing two numbers.
    </p>
    <div id="editor-modulus" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          0
          <br />
          1
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Print the value of 3 multiply by 5 minus 4
          </li>
          <li>Print the remainder of 12 divided by 3</li>
        </ol>
      </b-card-text>
    </b-card>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          11
          <br />
          0
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `print(1 + 1)
print(5 - 3)
print(2 * 2)
print(9 / 3)
print(2 ** 3)`;
const code2 = `print(9 % 3)
print(10 % 3)`;
const code3 = `a = 2 + 5`;

export default {
  mounted() {
    this.initEditor("editor-math", code);
    this.initEditor("editor-modulus", code2);
    this.initEditor("editor-floor", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
