<template>
  <div>
    <h2>Introduction to HTML</h2>
    <p>
      HTML stands for Hypertext Markup Language. It is the standard language
      used to convey information over the internet. Together with CSS and
      JavaScript, we will be able to create interactive web pages.
    </p>
    <p>What is the difference between HTML, CSS, and JavaScript?</p>

    <ul>
      <li>HTML - Defines the content of the page</li>
      <li>CSS - Defines the style of the HTML elements</li>
      <li>JavaScript - Programming language used to interact with the HTML</li>
    </ul>

    <div id="html-intro" style="height: 60px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start your web development journey with HTML
            &amp; CSS!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `<h1>Hi there!</h1>`;

export default {
  mounted() {
    this.initEditor("html-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/html",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
