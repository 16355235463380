<template>
  <div>
    <component :is="classroomComponent" />
  </div>
</template>

<script>
import TeacherClassroom from "@/views/classrooms/TeacherClassroom";

export default {
  name: "Classroom",

  computed: {
    classroomComponent() {
      let component = null;
      switch (this.$store.state.user.role) {
        case "teacher":
          component = TeacherClassroom;
          break;

        case "student":
          break;

        default:
          break;
      }
      return component;
    }
  }
};
</script>

<style></style>
