<template>
  <div>
    <h2>Challenge 9 - Caesar Cipher</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">encrypt</span> that takes two
            arguments.

            <ul>
              <li>
                First argument is a string of plaintext or ciphertext.
              </li>
              <li>
                Second argument is the encryption key which is a number range
                from 1 to 25.
              </li>
            </ul>
          </li>
          <li>
            Function will return the encrypted string
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <img
      class="img-fluid mt-3"
      src="@/assets/foundation-challenge/challenge9.png"
    />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "jvkpun pz mbu"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
