/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `question_zero = 3 - 3
question_one = 3 + 7 % 2
question_two = 2 * (3 + 2)
question_three = 2 + 2 / 2

# Write your code only below this line
# Example: number_zero = 0`;
export const seedTest = ``;
export const testCases = `
assert answer_one == 4, "answer_one is not correct"
assert answer_two == 10, "answer_two is not correct"
assert answer_three == 3, "answer_three is not correct"`;

export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });
  Sk.importMainWithBody("", false, code + testCases);

  assert(/answer_one\s*=\s*4/.test(code), "Assign answer_one with number");
  assert(/answer_two\s*=\s*10/.test(code), "Assign answer_two with number");
  assert(/answer_three\s*=\s*3/.test(code), "Assign answer_three with number");

  window.console = oldConsole;
  return true;
}
