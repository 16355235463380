<template>
  <div>
    <b-container class="my-5">
      <b-row>
        <b-col sm="8">
          <div class="d-flex justify-content-between align-items-center">
            <h2>JavaScript Playground</h2>
            <div class="mb-3">
              <b-button class="mr-2" variant="success" @click="onClickRun">
                Run
              </b-button>
              <b-button variant="danger" @click="onClickReset">Reset</b-button>
            </div>
          </div>
          <div id="editor"></div>
        </b-col>

        <b-col sm="4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Console</h2>
            <div>
              <b-button variant="dark" @click="onClickClear">Clear</b-button>
            </div>
          </div>
          <div class="mt-2" id="editor-console">
            {{ consoleText }}
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
import customConsole from "@/utils/custom-console";

export default {
  name: "JavascriptPlayground",

  data() {
    return {
      editor: null,
      consoleText: ""
    };
  },

  mounted() {
    this.initEditor();
  },

  methods: {
    initEditor() {
      this.editor = ace.edit("editor", {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });
    },

    onClickRun() {
      this.consoleText = "";
      try {
        const oldConsole = window.console;
        window.console = customConsole(this);
        eval(this.editor.getValue());
        window.console = oldConsole;
      } catch (error) {
        self.isResultModalVisible = true;
        this.isAnswerCorrect = false;
        this.consoleText = error;
      }
    },

    onClickReset() {
      if (confirm("Confirm reset code?")) {
        this.consoleText = "";
        this.editor.setValue("", 1);
      }
    },

    onClickClear() {
      this.consoleText = "";
    }
  }
};
</script>

<style scoped>
#editor {
  height: 80vh;
  overflow: auto;
}

#editor-console {
  height: 80vh;
  background-color: black;
  white-space: pre-wrap;
  overflow: auto;
}
</style>
