<template>
  <div class="p-5">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col>
          <h1>Tools</h1>
        </b-col>
      </b-row>

      <h3 class="subtitle">Data Representation</h3>
      <b-row class="mt-3">
        <b-col
          md="4"
          class="mb-3"
          v-for="tool in filteredTools"
          :key="tool.name"
        >
          <tool-card :tool="tool" @card-click="onClickCard(tool)"></tool-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ToolCard from "@/components/ToolCard.vue";
import { mapGetters } from "vuex";
import { tools } from "@/constants/tools";

export default {
  name: "Tools",

  components: {
    ToolCard
  },

  data() {
    return {
      tools: tools
    };
  },

  computed: {
    ...mapGetters({
      isSubscriptionActive: "organisation/isSubscriptionActive",
      isLicensed: "organisation/isLicensed"
    }),

    filteredTools() {
      return this.$store.state.user.role == "student"
        ? tools.filter(tool => {
            return !tool.isPremium;
          })
        : tools;
    }
  },

  methods: {
    onClickCard(tool) {
      if (
        tool.isPremium &&
        !this.isSubscriptionActive &&
        this.$store.state.user.role == "teacher"
      ) {
        this.$bvToast.toast("Upgrade your plan to access premium tools", {
          title: "Premium tools",
          autoHideDelay: 5000,
          variant: "warning",
          appendToast: true
        });
        return;
      }

      this.$router.push(tool.route);
    }
  }
};
</script>

<style></style>
