<template>
  <div>
    <h2>Introduction to Function</h2>
    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-35-function-intro.mp4?alt=media&token=2d0e2526-a7b2-4667-b9df-d7c01e450721"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Even a simple computer program contains hundreds if not thousands of lines
      of code. Most of the time, part of these codes are repeated. Instead of
      typing out the same instructions over and over, we can group them into
      chunks called function or procedure.
    </p>
    <img class="img-fluid" src="@/assets/foundation/function_one.png" />
    <p>
      Above we have a function call
      <span class="block block-variable">saySomething</span>. Whenever we run
      this function, all the code within
      <span class="block block-variable">saySomething</span> will be executed.
      In the example below, the function was called twice so code in
      <span class="block block-variable">saySomething</span> will execute for 2
      times
    </p>
    <img class="img-fluid" src="@/assets/foundation/function_one_2.png" />
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "Hi!
          <br />
          >> "How are you?"
          <br />
          >> "Hi!
          <br />
          >> "How are you?"
        </code>
      </b-card-text>
    </b-card>
    <p class="mt-3">
      We can reuse the function as many times as we want without writing the
      same code again and again.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function
            <span class="block block-variable">introduceMyself</span>
          </li>
          <li>
            The function will print 3 messages in following sequence when called
            <ul>
              <li>Hi!</li>
              <li>I am a bee.</li>
              <li>I like coding.</li>
            </ul>
          </li>
          <li>
            Call
            <span class="block block-variable">introduceMyself</span> function
            once
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "Hi!"
          <br />
          >> "I am a bee."
          <br />
          >> "I like coding."
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "ListIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
