<template>
  <div>
    <h2>Exercise 3</h2>

    <p>
      BMI is a measurement of a person's leanness or corpulence based on their
      height and weight. The formula for calculating BMI is as follow:
    </p>
    <p>
      BMI = weight ÷ height ÷ height
    </p>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <code>bmi</code> and assign it with bmi value
            calculated based on the weight and height value.
          </li>
          <li>Print <code>bmi</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    //
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
