<template>
  <div>
    <h2>Reassign Variable</h2>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-11-reassign.mp4?alt=media&token=8af82126-d585-4bcb-b9f0-fd48a97166b6"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Once a variable is created, we cannot create a variable with the same
      name.
    </p>
    <p>
      At a certain point in our program, we can use the set block to reassign
      value to our variables. This will replace the old value with the new
      value. In the example below, the variable
      <span class="block block-variable">number</span> is first assigned with
      the number 10, then it was reassigned with the number 99.
    </p>
    <img class="img-fluid" src="@/assets/foundation/variable_reassign.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Reassign variable
            <span class="block block-variable">pocketMoney</span> to 30
          </li>
          <li>Print <span class="block block-variable">pocketMoney</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 30
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "AppInventorVariableReassign",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
