<template>
  <div>
    <b-container class="my-5">
      <b-row>
        <b-col sm="8">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Blockly Playground</h2>
            <div>
              <b-button class="mr-2" variant="success" @click="runCode">
                Run
              </b-button>
              <b-button variant="danger" @click="resetWorkspace"
                >Reset</b-button
              >
              <b-dropdown text="Code" class="m-md-2">
                <b-dropdown-item @click="viewCode('js')">
                  JavaScript
                </b-dropdown-item>
                <b-dropdown-item @click="viewCode('python')">
                  Python
                </b-dropdown-item>
                <b-dropdown-item @click="viewCode('dart')">
                  Dart
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-3" id="blocklyDiv"></div>
        </b-col>

        <b-col sm="4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Console</h2>
            <div>
              <b-button variant="dark" @click="resetConsole">Clear</b-button>
            </div>
          </div>
          <console class="mt-3" :output="output" :height="'80vh'" />
        </b-col>
      </b-row>
    </b-container>

    <b-modal :title="generatedCodeLanguage" v-model="viewCodeModal">
      <template>
        <div class="view-code-content">
          {{ generatedCode }}
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Blockly from "blockly";
import "blockly/python";
import "blockly/dart";

import Interpreter from "js-interpreter";
import Console from "@/components/Console.vue";
import formatter from "@/utils/console-formatter";
import * as toolbox from "@/config/blockly-toolbox";

export default {
  name: "Playground",

  components: {
    Console
  },

  data() {
    return {
      workspace: null,
      interpreter: null,
      code: "",
      output: "",
      result: {},
      resultMessage: "",
      generatedCode: "",
      generatedCodeLanguage: "",
      viewCodeModal: false
    };
  },

  mounted() {
    this.injectBlockly();
  },

  methods: {
    injectBlockly() {
      this.workspace = Blockly.inject(document.getElementById("blocklyDiv"), {
        trashcan: true,
        toolbox: toolbox.playground,
        zoom: {
          controls: true,
          startScale: 1.0,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2
        }
      });
    },

    viewCode(language) {
      switch (language) {
        case "js":
          this.generatedCodeLanguage = "JavaScript";
          this.generatedCode = Blockly.JavaScript.workspaceToCode(
            this.workspace
          );
          break;

        case "python":
          this.generatedCodeLanguage = "Python";
          this.generatedCode = Blockly.Python.workspaceToCode(this.workspace);
          break;

        case "dart":
          this.generatedCodeLanguage = "Dart";
          this.generatedCode = Blockly.Dart.workspaceToCode(this.workspace);
          break;

        default:
          break;
      }
      this.viewCodeModal = true;
    },

    runCode() {
      const workspaceCode = Blockly.JavaScript.workspaceToCode(this.workspace);
      const code = this.appendCode(workspaceCode);
      this.interpreter = new Interpreter(code, this.getInitApi());
      this.stepInterpreter();
    },

    stepInterpreter() {
      const maxSteps = 100000;
      let count = 0;
      while (count <= maxSteps) {
        if (!this.interpreter.step()) {
          break;
        }
        count += 1;
      }
      if (count >= maxSteps) {
        this.appendOutput(
          "Your code is taking too long to run. Check if you have infinite loop in your code!"
        );
      }
    },

    appendCode(code) {
      return code;
    },

    getInitApi() {
      return (interpreter, scope) => {
        // eslint-disable-next-line
        const self = this;
        const alertWrapper = function(obj) {
          const formattedText = formatter(obj);
          return self.appendOutput(formattedText);
        };

        interpreter.setProperty(
          scope,
          "alert",
          interpreter.createNativeFunction(alertWrapper)
        );

        const promptWrapper = function(text) {
          return prompt(text);
        };
        interpreter.setProperty(
          scope,
          "prompt",
          interpreter.createNativeFunction(promptWrapper)
        );
      };
    },

    appendOutput(text) {
      this.output += ">> " + text + "\n";
    },

    generateCode() {
      this.code = Blockly.JavaScript.workspaceToCode(this.workspace);
      this.showCode = true;
    },

    resetWorkspace() {
      if (confirm("Are you sure you want to reset playground?")) {
        this.result = {};
        document.getElementById("blocklyDiv").innerHTML = "";
        this.code = "";
        this.injectBlockly();
      }
    },

    resetConsole() {
      this.output = "";
    },

    resetCodeResult() {
      this.result = {};
    }
  }
};
</script>

<style lang="less" scoped>
#blocklyDiv {
  height: 80vh;
  width: 100%;
}

.view-code-content {
  white-space: pre-wrap;
}
</style>
