import Introduction from "./Introduction.vue";
import * as IntroductionLogics from "./Introduction";

import IntroductionConsole from "./IntroductionConsole.vue";
import * as IntroductionConsoleLogics from "./IntroductionConsole";

import VariableDeclare from "./VariableDeclare.vue";
import * as VariableDeclareLogics from "./VariableDeclare";

import VariableDeclareNaming from "./VariableDeclareNaming.vue";
import * as VariableDeclareNamingLogics from "./VariableDeclareNaming";

import VariableDataType from "./VariableDataType.vue";
import * as VariableDataTypeLogics from "./VariableDataType";

import VariableDataUndefinedType from "./VariableDataTypeUndefined.vue";
import * as VariableDataTypeUndefinedLogics from "./VariableDataTypeUndefined";

import VariableDataNumberType from "./VariableDataTypeNumber.vue";
import * as VariableDataTypeNumberLogics from "./VariableDataTypeNumber";

import VariableReassign from "./VariableDataTypeReassign.vue";
import * as VariableReassignLogics from "./VariableDataTypeReassign";

import Exercise1 from "./Exercise1.vue";
import * as Exercise1Logics from "./Exercise1";

import Exercise2 from "./Exercise2.vue";
import * as Exercise2Logics from "./Exercise2";

import Exercise3 from "./Exercise3.vue";
import * as Exercise3Logics from "./Exercise3";

import VariableDataTypeReassignToVariable from "./VariableDataTypeReassignToVariable.vue";
import * as VariableDataTypeReassignToVariableLogics from "./VariableDataTypeReassignToVariable";

import MathIntroduction from "./MathIntroduction.vue";
import * as MathIntroductionLogics from "./MathIntroduction";

import MathAssignment from "./MathAssignment.vue";
import * as MathAssignmentLogics from "./MathAssignment";

import MathUpdateVariable from "./MathUpdateVariable.vue";
import * as MathUpdateVariableLogics from "./MathUpdateVariable";

import MathStringOperator from "./MathStringOperator.vue";
import * as MathStringOperatorLogics from "./MathStringOperator";

import Exercise4 from "./Exercise4.vue";
import * as Exercise4Logics from "./Exercise4";

import MathIncrement from "./MathIncrement.vue";
import * as MathIncrementLogics from "./MathIncrement";

import MathObject from "./MathObject.vue";
import * as MathObjectLogics from "./MathObject";

import Exercise5 from "./Exercise5.vue";
import * as Exercise5Logics from "./Exercise5";

import ArrayIntroduction from "./ArrayIntroduction.vue";
import * as ArrayIntroductionLogics from "./ArrayIntroduction";

import ArrayGetSet from "./ArrayGetSet.vue";
import * as ArrayGetSetLogics from "./ArrayGetSet";

import ArrayLength from "./ArrayLength.vue";
import * as ArrayLengthLogics from "./ArrayLength";

import ArrayMethod from "./ArrayMethod.vue";
import * as ArrayMethodLogics from "./ArrayMethod";

import ArrayOtherMethod from "./ArrayOtherMethod.vue";
import * as ArrayOtherMethodLogics from "./ArrayOtherMethod";

import ConditionalIntroduction from "./ConditionalIntroduction.vue";
import * as ConditionalIntroductionLogics from "./ConditionalIntroduction";

import ConditionalOperator from "./ConditionalOperator.vue";
import * as ConditionalOperatorLogics from "./ConditionalOperator";

import ConditionalIf from "./ConditionalIf.vue";
import * as ConditionalIfLogics from "./ConditionalIf";

import ConditionalElse from "./ConditionalElse.vue";
import * as ConditionalElseLogics from "./ConditionalElse";

import Exercise6 from "./Exercise6.vue";
import * as Exercise6Logics from "./Exercise6";

import ConditionalElseIf from "./ConditionalElseIf.vue";
import * as ConditionalElseIfLogics from "./ConditionalElseIf";

import ConditionalTenary from "./ConditionalTenary.vue";
import * as ConditionalTenaryLogics from "./ConditionalTenary";

import ConditionalSwitch from "./ConditionalSwitch.vue";
import * as ConditionalSwitchLogics from "./ConditionalSwitch";

import ConditionalCompound from "./ConditionalCompound.vue";
import * as ConditionalCompoundLogics from "./ConditionalCompound";

import Exercise7 from "./Exercise7.vue";
import * as Exercise7Logics from "./Exercise7";

import LoopIntroduction from "./LoopIntroduction.vue";
import * as LoopIntroductionLogics from "./LoopIntroduction";

import LoopVariable from "./LoopVariable.vue";
import * as LoopVariableLogics from "./LoopVariable";

import Exercise8 from "./Exercise8.vue";
import * as Exercise8Logics from "./Exercise8";

import Exercise9 from "./Exercise9.vue";
import * as Exercise9Logics from "./Exercise9";

import LoopBreak from "./LoopBreak.vue";
import * as LoopBreakLogics from "./LoopBreak";

import LoopContinue from "./LoopContinue.vue";
import * as LoopContinueLogics from "./LoopContinue";

import LoopNested from "./LoopNested.vue";
import * as LoopNestedLogics from "./LoopNested";

import Exercise10 from "./Exercise10.vue";
import * as Exercise10Logics from "./Exercise10";

import LoopWhile from "./LoopWhile.vue";
import * as LoopWhileLogics from "./LoopWhile";

import Exercise11 from "./Exercise11.vue";
import * as Exercise11Logics from "./Exercise11";

import Exercise12 from "./Exercise12.vue";
import * as Exercise12Logics from "./Exercise12";

import FunctionIntroduction from "./FunctionIntroduction.vue";
import * as FunctionIntroductionLogics from "./FunctionIntroduction";

import FunctionParameter from "./FunctionParameter.vue";
import * as FunctionParameterLogics from "./FunctionParameter";

import FunctionReturn from "./FunctionReturn.vue";
import * as FunctionReturnLogics from "./FunctionReturn";

import Exercise13 from "./Exercise13.vue";
import * as Exercise13Logics from "./Exercise13";

import Exercise14 from "./Exercise14.vue";
import * as Exercise14Logics from "./Exercise14";

import ObjectIntroduction from "./ObjectIntroduction.vue";
import * as ObjectIntroductionLogics from "./ObjectIntroduction";

import ObjectGetSet from "./ObjectGetSet.vue";
import * as ObjectGetSetLogics from "./ObjectGetSet";

import ObjectDelete from "./ObjectDelete.vue";
import * as ObjectDeleteLogics from "./ObjectDelete";

import ObjectBracket from "./ObjectBracket.vue";
import * as ObjectBracketLogics from "./ObjectBracket";

import ObjectKeys from "./ObjectKeys.vue";
import * as ObjectKeysLogics from "./ObjectKeys";

import ObjectForIn from "./ObjectForIn.vue";
import * as ObjectForInLogics from "./ObjectForIn";

const curriculum = new Map();

curriculum.set("C1ICVrzi4mqsrUic9izg", {
  content: Introduction,
  logics: IntroductionLogics
});

curriculum.set("gkwjrPLyNxZRVFR8mCHG", {
  content: IntroductionConsole,
  logics: IntroductionConsoleLogics
});

curriculum.set("WnVKsp9YFIjmh4s6NlRH", {
  content: VariableDeclare,
  logics: VariableDeclareLogics
});

curriculum.set("9osX2LASdllKFDxFQXXu", {
  content: VariableDeclareNaming,
  logics: VariableDeclareNamingLogics
});

curriculum.set("YZAVTk0tuXj6YuFC5kRM", {
  content: VariableDataType,
  logics: VariableDataTypeLogics
});

curriculum.set("FB3MqbY1az8ojQx3RIOK", {
  content: VariableDataUndefinedType,
  logics: VariableDataTypeUndefinedLogics
});

curriculum.set("7O2TY2x0YV2jC9dGTMUX", {
  content: VariableDataNumberType,
  logics: VariableDataTypeNumberLogics
});

curriculum.set("Kg4oyld2N1BQaGyPWGtM", {
  content: VariableReassign,
  logics: VariableReassignLogics
});

curriculum.set("JrpUWilTCRdR6b3QqOSA", {
  content: Exercise1,
  logics: Exercise1Logics
});

curriculum.set("QXBiiSlLKbBL0Cr2xXq8", {
  content: Exercise2,
  logics: Exercise2Logics
});

curriculum.set("WPF1F8APfaJDfVFEWrd8", {
  content: Exercise3,
  logics: Exercise3Logics
});

curriculum.set("F4Tyvs1nOtV8YSUiOVFd", {
  content: VariableDataTypeReassignToVariable,
  logics: VariableDataTypeReassignToVariableLogics
});

curriculum.set("vODu8IaSkZjnU1M6KoKl", {
  content: MathIntroduction,
  logics: MathIntroductionLogics
});

curriculum.set("KL44y0T5So82avjOhnAY", {
  content: MathAssignment,
  logics: MathAssignmentLogics
});

curriculum.set("ZIUu1VlsGpdu8IV8LVWV", {
  content: MathUpdateVariable,
  logics: MathUpdateVariableLogics
});

curriculum.set("LVWvAtfPLv7jS8Gg7kQy", {
  content: MathIncrement,
  logics: MathIncrementLogics
});

curriculum.set("rwZsFDBXKXXR0R0v13TF", {
  content: Exercise4,
  logics: Exercise4Logics
});

curriculum.set("rjOgeeYV0ppktP0ahXeA", {
  content: MathStringOperator,
  logics: MathStringOperatorLogics
});

curriculum.set("IVOIjPfin3gJrlXf7IPK", {
  content: MathObject,
  logics: MathObjectLogics
});

curriculum.set("jHeVvESRLoLeFwjgV5sC", {
  content: Exercise5,
  logics: Exercise5Logics
});

curriculum.set("Tw3VtsZWZxIPYN1GLQXE", {
  content: ArrayIntroduction,
  logics: ArrayIntroductionLogics
});

curriculum.set("SZ8uAv4sR7RjQIlEMwt1", {
  content: ArrayGetSet,
  logics: ArrayGetSetLogics
});

curriculum.set("gc2k9OOqvVkXBK4S36lK", {
  content: ArrayLength,
  logics: ArrayLengthLogics
});

curriculum.set("didc35YbhgwzyEYnrCxC", {
  content: ArrayMethod,
  logics: ArrayMethodLogics
});

curriculum.set("JIwQpepui3L2FGLaZ2ba", {
  content: ArrayOtherMethod,
  logics: ArrayOtherMethodLogics
});

curriculum.set("tJSYthioUqjgrK90JR2T", {
  content: ConditionalIntroduction,
  logics: ConditionalIntroductionLogics
});

curriculum.set("2PPScrMFJzMb531K9JnO", {
  content: ConditionalOperator,
  logics: ConditionalOperatorLogics
});

curriculum.set("Ysw2KzthV3BVrdlwYQY8", {
  content: ConditionalIf,
  logics: ConditionalIfLogics
});

curriculum.set("t0lCikzus1VofdfcYeDf", {
  content: ConditionalElse,
  logics: ConditionalElseLogics
});

curriculum.set("xDKRpiarfJVBaMiw5inp", {
  content: Exercise6,
  logics: Exercise6Logics
});

curriculum.set("EpqFMo6w4YKexDa6Juzo", {
  content: ConditionalElseIf,
  logics: ConditionalElseIfLogics
});

curriculum.set("CFqyluaFEVu5NCeYgoZp", {
  content: ConditionalTenary,
  logics: ConditionalTenaryLogics
});

curriculum.set("K0cc3qOkEl5dU1CsTVgm", {
  content: ConditionalSwitch,
  logics: ConditionalSwitchLogics
});

curriculum.set("rGwtQdXPaCKgLtJZ8NAe", {
  content: ConditionalCompound,
  logics: ConditionalCompoundLogics
});

curriculum.set("ViEpRR1SBGxNSZPTfW14", {
  content: Exercise7,
  logics: Exercise7Logics
});

curriculum.set("gYyHQQ0Ao8EgtfNtvHcN", {
  content: LoopIntroduction,
  logics: LoopIntroductionLogics
});

curriculum.set("5TibIuTpKz20stvJPNVy", {
  content: LoopVariable,
  logics: LoopVariableLogics
});

curriculum.set("Bom7OPHln8PQLEV9MXvc", {
  content: Exercise8,
  logics: Exercise8Logics
});

curriculum.set("aUhOCFpRD2GYxVV0IQtf", {
  content: Exercise9,
  logics: Exercise9Logics
});

curriculum.set("9gwBLR67xZxV9GayurJf", {
  content: LoopBreak,
  logics: LoopBreakLogics
});

curriculum.set("YfSfmG1Dr7maxqKk4uTJ", {
  content: LoopContinue,
  logics: LoopContinueLogics
});

curriculum.set("bKlVEyumnTR3Kcppinym", {
  content: LoopNested,
  logics: LoopNestedLogics
});

curriculum.set("JjZmVZIBFy6HHSPMTiBk", {
  content: Exercise10,
  logics: Exercise10Logics
});

curriculum.set("CxhRla2MGkmj57pmc82c", {
  content: LoopWhile,
  logics: LoopWhileLogics
});

curriculum.set("K1wH9Hr3OF398X4twMYn", {
  content: Exercise11,
  logics: Exercise11Logics
});

curriculum.set("oYKVmXxWkcOsYt43aJGK", {
  content: Exercise12,
  logics: Exercise12Logics
});

curriculum.set("l0WmHaBmtJHpFkQs1SgN", {
  content: FunctionIntroduction,
  logics: FunctionIntroductionLogics
});

curriculum.set("I97YTLlPLBrnpMnzNGfv", {
  content: FunctionParameter,
  logics: FunctionParameterLogics
});

curriculum.set("qvxUpwZ2hAz8V7VEnjgD", {
  content: FunctionReturn,
  logics: FunctionReturnLogics
});

curriculum.set("sFAoluGOFjAPozEvH2ZP", {
  content: Exercise13,
  logics: Exercise13Logics
});

curriculum.set("yHQ6CsoUu5gvFZprRsK0", {
  content: Exercise14,
  logics: Exercise14Logics
});

curriculum.set("XxRzd0VfnPDOB6d3yctR", {
  content: ObjectIntroduction,
  logics: ObjectIntroductionLogics
});

curriculum.set("NeLl8KaW8SMP3QqDfxBm", {
  content: ObjectGetSet,
  logics: ObjectGetSetLogics
});

curriculum.set("W02IR3eRuyvcaDOakR9T", {
  content: ObjectBracket,
  logics: ObjectBracketLogics
});

curriculum.set("vUPET6JLXOHCvynbZIqq", {
  content: ObjectDelete,
  logics: ObjectDeleteLogics
});

curriculum.set("2SaphdueuxJYacuxcSwg", {
  content: ObjectKeys,
  logics: ObjectKeysLogics
});

curriculum.set("HSt1bCgHTVXIGZJFWAYo", {
  content: ObjectForIn,
  logics: ObjectForInLogics
});

export default curriculum;
