<template>
  <div>
    <h2>Reassign Variable</h2>

    <p>
      Variable with the same name cannot be declared more than once. Once a
      variable is declared with the <code>let</code> or
      <code>var</code> keyword, we can reassign the variable with different
      value. To reassign a variable value, we use the = operator without the
      declare keyword.
    </p>
    <div id="editor-variable-reassign" style="height: 80px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 777
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">const keyword</h4>
    <p>
      A variable declared with
      <code>const</code> keyword can not be reassigned
    </p>
    <div id="editor-variable-reassign-const" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > TypeError: Assignment to constant variable.
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Reassign the variable <code>specialNumber</code> to 65.</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-variable-reassign", `let someNumber = 10;\nsomeNumber = 777;\nconsole.log(someNumber);`);
    this.initEditor("editor-variable-reassign-const", `const someNumber = 10;\nsomeNumber = 777;`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
