/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `import random
random_number = random.randint(1, 120)
result = None

# Write your code only below this line
`;

export const seedTest = ``;
export const testCases = `
if random_number > 80:
  assert result == 1, "result set to wrong value when random_number is greater than 50"
elif random_number >= 30:
  assert result == 0, "result set to wrong value when random_number is between 30 to 80"
else:
  assert result == -1, "result set to wrong value when random_number is less than 50"
`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const userCodeStartingIndex = 5;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // Seed testing
  Sk.importMainWithBody("", false, seedCode + seedTest);
  assert(
    /random_number = random.randint\(1, 120\)/.test(seedCode),
    "Do not change default code"
  );
  assert(/result = None/.test(seedCode), "Do not change default code");

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);
  assert(
    /if[\s\S]+:[\s\S]+elif[\s\S]+:[\s\S]+else:/.test(code),
    "Use an if..else statement"
  );

  // Other case testing
  Sk.importMainWithBody(
    "",
    false,
    "random_number = 100\nresult = None\n" + userCode + testCases
  );

  Sk.importMainWithBody(
    "",
    false,
    "random_number = 80\nresult = None\n" + userCode + testCases
  );

  Sk.importMainWithBody(
    "",
    false,
    "random_number = 40\nresult = None\n" + userCode + testCases
  );

  Sk.importMainWithBody(
    "",
    false,
    "random_number = 30\nresult = None\n" + userCode + testCases
  );

  Sk.importMainWithBody(
    "",
    false,
    "random_number = 10\nresult = None\n" + userCode + testCases
  );

  window.console = oldConsole;
  return true;
}
