<template>
  <div>
    <h2>Exercise 9</h2>
    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a while loop to sum up all the natural numbers from 1 upwards
            and find the first sum value that is more than 1000
          </li>
          <li>Assign that value to the <code>sum</code> variable</li>
          <li>Print <code>sum</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `counter = 0

while counter < 5:
    print(counter)
    counter += 1`;

export default {
  mounted() {
    this.initEditor("editor-nested", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
