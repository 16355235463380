/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `for i in range(50):

    # Write your code only below this line



    # Write your code only above this line
    print(i)`;

export const seedTest = ``;
export const testCases = ``;
export let logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  logs = [];
  const userCodeStartingIndex = 3;
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < userCodeStartingIndex; index++) {
    seedCode += splitCodes[index] + "\n";
  }

  let userCode = "";
  for (
    let index = userCodeStartingIndex;
    index < self.editor.session.getLength();
    index++
  ) {
    userCode += splitCodes[index] + "\n";
  }

  // User code testing
  Sk.importMainWithBody("", false, code + testCases);
  assert(/for i in range\(50\):/.test(code), "Do not change default code");
  assert(/print\(\s*i\s*\)/.test(code), "Do not change default code");
  assert.deepEqual(
    logs,
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    "loop is break incorrectly"
  );

  window.console = oldConsole;
  return true;
}
