/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myMoney  === 'undefined') var myMoney ;
    try {returnResult(myMoney);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      console.log(result.printedText);
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myMoney: number) => {
      result.myMoney = myMoney;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.myMoney === undefined) {
    status.message = "myMoney is not created";
  } else if (result.myMoney !== 170) {
    status.message = "myMoney not assigned with correct value";
  } else if (code.includes("myMoney = 170")) {
    status.message = "Use operators to calculate answer";
  } else if (!code.includes("alert(myMoney)")) {
    status.message = "answer not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
