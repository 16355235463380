<template>
  <div>
    <h2>Exercise 5</h2>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use an if..else statement
            <ul>
              <li>
                if <code>number_one</code> is greater than 10 or
                <code>number_two</code> is equal or less than 25, set
                <code>result</code> to True
              </li>
              <li>Else, set <code>result</code> to False</li>
            </ul>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 50

print(number > 100 and number > 100)
print(number > 0 and number > 100)
print(number > 100 and number > 0)
print(number > 0 and number > 0)`;

const code2 = `number = 50

print(number > 100 or number > 100)
print(number > 0 or number > 100)
print(number > 100 or number > 0)
print(number > 0 or number > 0)`;

const code3 = `number = 50

print(not(number > 100))
print(not(number > 0))`;

export default {
  mounted() {
    this.initEditor("editor-and", code);
    this.initEditor("editor-or", code2);
    this.initEditor("editor-not", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
