/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";
import { deviceTable } from "./devices";

export const preSeed = deviceTable;
export const seed = ``;

export function testCorrectness(code: string, self: any) {
  self.db.exec(preSeed);
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(result[0].columns, ["name", "price"], "Wrong table columns");

  assert.deepEqual(
    result[0].values,
    [
      ["iPhone X", 999],
      ["iPad Mini 5", 1129]
    ],
    "Wrong data selected"
  );
  return true;
}
