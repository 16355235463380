<template>
  <div>
    <h2>Else Statement</h2>

    <p>
      Use the else statement to specify a block of code to be executed if the
      condition is false.
    </p>

    <p>
      The else statement will execute as the condition <code>number</code> > 50
      evaluates to <code>false</code>
    </p>

    <div id="editor-cond-else" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "less than 50"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Write an if/else statement so that <code>message</code> will be
            assigned with "It is apple" if <code>fruit</code> equals to "apple"
          </li>
          <li>
            Else, <code>message</code> should be assigned with "It is not"
          </li>
        </ol>
        <br />
        * Use only == operator
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let number = 20;

if (number > 50) {
  console.log("more than 50");
} else {
  console.log("less than 50");
}`

export default {
  mounted() {
    this.initEditor("editor-cond-else", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
