<template>
  <div>
    <h2>Console</h2>
    <p>
      Programming code might contain syntax errors, or logical errors. Often,
      when programming code contains errors, nothing will happen. There are no
      error messages, and you will get no indications where to search for
      errors. Searching for (and fixing) errors in programming code is called
      code debugging.
    </p>
    <p>
      All modern browsers have a built-in JavaScript debugger. Built-in
      debuggers can be turned on and off, forcing errors to be reported to the
      user. You can use console.log() to display JavaScript values in the
      debugger window:
    </p>

    <div id="console-1" style="height: 50px;">
      console.log("Hi there!");
    </div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "Hi there!"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            use <code>console.log()</code> and print the string "Hello World!"
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
export default {
  mounted() {
    this.initEditor("console-1");
  },

  methods: {
    initEditor(id) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true
      });
    }
  }
};
</script>

<style></style>
