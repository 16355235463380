<template>
  <div>
    <h2>Data Type - Undefined &amp; Null</h2>

    <h4>undefined</h4>
    <p>
      The meaning of undefined is “value is not assigned”. If a variable is
      declared, but not assigned, then its value is undefined:
    </p>

    <div id="editor-datatype-undefined" style="height: 80px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > undefined
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">null</h4>
    <p>Null in JavaScript means nothing.</p>

    <div id="editor-datatype-null" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > null
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      undefined and null might seem like the same but they are not. Null is an
      assigned value. If a variable is assigned with null, means it is assigned
      with <em>nothing</em>.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Define a variable <code>undefinedVariable</code> without assignment
          </li>
          <li>
            Define a variable <code>nullVariable</code> and assign it with null
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-datatype-undefined", `let someVariable;\nconsole.log(someVariable);`);
    this.initEditor("editor-datatype-null", `let someVariable = null;\nconsole.log(someVariable);`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
