<template>
  <div>
    <b-container>
      <b-row class="mb-3">
        <b-col>
          <h2>Subscription</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="bg-white p-3">
            <div class="form-container">
              <b-form-group label="Licnese Plans">
                <b-form-input
                  :value="organisation.plan"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Subscription Starts">
                <b-form-input
                  :value="subscriptionStart"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Subscription Ends">
                <b-form-input :value="subscriptionEnd" disabled></b-form-input>
              </b-form-group>
              <b-form-group label="Available student licenses">
                <b-form-input
                  :value="organisation.availableLicenses"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Remaining student licenses">
                <b-form-input
                  :value="organisation.remainingLicenses"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-button variant="warning" to="/plans">
                Change Plan
              </b-button>
              <b-button
                class="ml-2"
                variant="secondary"
                @click="onClickManagePlan"
              >
                Manage Billing
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { functions } from "@/config/firebase";
import { organisationServices } from "@/services";
import { mapGetters } from "vuex";

export default {
  name: "AccountSetting",

  data() {
    return {
      organisation: {}
    };
  },

  async created() {
    const loader = this.$loading.show();
    await this.getOrganisation();
    loader.hide();
  },

  computed: {
    ...mapGetters({
      isLicensed: "organisation/isLicensed"
    }),

    subscriptionStart() {
      return this.organisation.subscriptionStart
        ? this.organisation.subscriptionStart.toString()
        : "";
    },

    subscriptionEnd() {
      return this.organisation.subscriptionEnd
        ? this.organisation.subscriptionEnd.toString()
        : "";
    }
  },

  methods: {
    getOrganisation() {
      return organisationServices
        .getOrganisation(this.$store.state.organisation.id)
        .then(organisation => {
          this.organisation = organisation;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onClickManagePlan() {
      const loader = this.$loading.show();
      const getCustomerPortalURL = functions.httpsCallable(
        `${process.env.VUE_APP_PAYMENT_FUNCTIONS_PREFIX}-getCustomerPortalSession`
      );

      getCustomerPortalURL({
        customerId: this.$store.state.user.stripeCustomerId
      })
        .then(res => {
          const url = res.data;
          window.open(url);
          loader.hide();
        })
        .catch(error => {
          alert(error);
          loader.hide();
        });
    }
  }
};
</script>

<style lang="less" scoped>
.form-container {
  width: 60%;
}
</style>
