<template>
  <div>
    <h2>Exercise 3</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Show the <code>name</code> and <code>price</code> from the
            <code>devices</code> table with price between 900 and 1200
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  name: "CreateTable",

  mounted() {
    //
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
