<template>
  <div class="pb-3">
    <h2>Blockly Game: Turtle</h2>

    <p class="mt-3">
      Use your knowledge about functions to create music!
    </p>

    <iframe src="https://blockly.games/music?lang=en" frameborder="0"></iframe>

    <p class="mt-3"></p>
  </div>
</template>

<script>
export default {
  name: "TalkToMe"
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}

iframe {
  width: 100%;
  height: 80vh;
}
</style>
