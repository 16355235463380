import { render, staticRenderFns } from "./TextCompressionTool.vue?vue&type=template&id=0146d6a2&scoped=true&"
import script from "./TextCompressionTool.vue?vue&type=script&lang=js&"
export * from "./TextCompressionTool.vue?vue&type=script&lang=js&"
import style0 from "./TextCompressionTool.vue?vue&type=style&index=0&id=0146d6a2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0146d6a2",
  null
  
)

export default component.exports