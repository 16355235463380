<template>
  <div>
    <h2>Calculation with Variables</h2>

    <p>
      Math expression can be assigned to a variable. The expression will first
      be evaluated and the value assigned to variable.
    </p>

    <div id="editor-exp" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          6
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Calculations</h4>
    <p>Variables can be used as operand in math expression.</p>
    <div id="editor-exp-math" style="height: 90px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          6
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create variable <code>answer_one</code> and assign it with
            <code>first_num</code> multiply <code>second_num</code>
          </li>
          <li>
            Create variable <code>answer_two</code> and assign it with the sum
            of <code>first_num</code> and <code>second_num</code>
            multiplied by 3
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `some_number = 2 + 4
print(some_number)`;
const code2 = `a = 4
b = 2
c = a + b + 3
print(c)`;

export default {
  mounted() {
    this.initEditor("editor-exp", code);
    this.initEditor("editor-exp-math", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
