<template>
  <div>
    <h2>Limit Clause</h2>
    <p>
      <code>LIMIT</code> clause can be use with <code>SELECT</code> statement to
      contrain the number of rows return by the query.
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            first_name
          </b-th>
          <b-th>
            last_name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Alice</b-td>
          <b-td>Vasilii</b-td>
          <b-td>13</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Fred</b-td>
          <b-td>Davidson</b-td>
          <b-td>14</b-td>
        </b-tr>
        <b-tr>
          <b-td>2</b-td>
          <b-td>Melissa</b-td>
          <b-td>Welch</b-td>
          <b-td>12</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div id="editor-limit" style="height: 50px;"></div>

    <p class="mt-3">
      Query above will return one row that satisfy the
      <code>SELECT</code> statement.
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            first_name
          </b-th>
          <b-th>
            last_name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Alice</b-td>
          <b-td>Vasilii</b-td>
          <b-td>13</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Select the top 3 most expensive devices in
            <code>devices</code> table and sort them in decending order
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";
const code = `SELECT * FROM students LIMIT 1;`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-limit", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
