/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import customConsole from "@/utils/custom-python-console";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Sk from "skulpt";
import { assert } from "chai";

export const seed = `import random
random_number = random.randint(0, 50)
result = False

# Write your code only below this line
`;

export const seedTest = ``;
export const testCases = `
if random_number > 10:
  assert result, "result set to wrong value"
else:
  assert not result, "result set to wrong value"
`;
export const logs: any[] = [];

function printConsole(text: any) {
  logs.push(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
  console.log(Sk.ffi.remapToJs(text).replace(/\n/g, ""));
}

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);

  Sk.configure({ output: printConsole, __future__: Sk.python3 });

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index] + "\n";
  }
  Sk.importMainWithBody("", false, seedCode + seedTest);
  assert(
    /random_number = random.randint\(0, 50\)/.test(seedCode),
    "Do not change default code"
  );

  Sk.importMainWithBody("", false, code + testCases);
  assert(/if[\s\S]+:/.test(code), "Use an if statement");

  window.console = oldConsole;
  return true;
}
