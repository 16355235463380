<template>
  <div>
    <h2>Challenge 7 - Binary to Decimal</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">toDecimal</span> that takes in a
            string of binary number
          </li>
          <li>
            Function will return a decimal number with equilvalent value
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Example input 1: "00101"
          <br />
          Example input 2: "10001111"
          <br />
          <br />
          Expected result:
          <br />
          >> 5
          <br />
          >> 143
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
