<template>
  <div>
    <h2>Introduction to JavaScript</h2>
    <p>
      JavaScript was initially created to “make web pages alive”. The programs
      in this language are called scripts. They can be written right in a web
      page’s HTML and run automatically as the page loads. Today, JavaScript has
      a unique position as the most widely-adopted browser language with full
      integration in HTML/CSS.
    </p>
    <p>
      In this course, you'll learn fundamental programming concepts in
      JavaScript. You'll start with basic data structures like numbers and
      strings. Then you'll learn to work with arrays, objects, functions, loops,
      if/else statements, and more.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start your JavaScript journey!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
