<template>
  <div class="pb-3">
    <h2>Project: Quiz App 2</h2>

    <p class="mt-3">
      Let's build our first question!
    </p>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-22-project-quiz-2.mp4?alt=media&token=200f9fdc-4912-47f3-ba17-fa22358aa221"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <h3 class="mt-3">
      Solution
    </h3>

    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-23-project-quiz-3.mp4?alt=media&token=3fad80f6-4ace-4557-9b33-024039e1a52e"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "TalkToMe",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
