/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let firstNumber = 80;
let secondNumber = 60;
let result;

function checkResult(){
// Only write code below this line





// Only write code above this line
  return
}
`;

const seedTest = `
  chaiAssert(firstNumber === 80, "Do not change the default code");
  chaiAssert(secondNumber === 60, "Do not change the default code");
  chaiAssert(result === undefined, "Do not change the default code");
`;

export const testCases = `
  firstNumber = 80;
  secondNumber = 80;
  checkResult();
  chaiAssert(result === "Yes", "Wrong result value when both numbers are more than 50");

  firstNumber = 20;
  secondNumber = 80;
  checkResult();
  chaiAssert(result === "No", "Wrong result value when firstNumber is more than 50");

  firstNumber = 80;
  secondNumber = 20;
  checkResult();
  chaiAssert(result === "No", "Wrong result value when secondNumber is more than 50");

  firstNumber = 20;
  secondNumber = 20;
  checkResult();
  chaiAssert(result === "No", "Wrong result value when both numbers are not more than 50");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 3; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /if\s*\([\s\S]+&&[\s\S]+\)\s*{[\s\S]+}\s*else\s*{[\s\S]+}/.test(code),
    "use if...else statement with AND operator"
  );

  window.console = oldConsole;
  return true;
}
