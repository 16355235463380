/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `const number = 80;

// Only write code below this line
`;

const seedTest = `
  chaiAssert(number === 80, "Do not change the default code");
`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /if\s*\(\s*number\s*>\s*50\s*\)\s*{\s*console\.log\(\s*number\s*\)\s*;\s*}/.test(
      code
    ),
    "if statement is incorrect"
  );

  window.console = oldConsole;
  return true;
}
