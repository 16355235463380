<template>
  <div>
    <h2>Dictionaries Introduction</h2>

    <p>
      Dictionary is a data type that stores data using key-value pairs. A key is
      used to identify the position where the value is stored. Keys must be of
      immutable type such string, number or tuple.
    </p>

    <p>
      An object is created with the curly brackets <code>{ }</code>. An object
      can also be declared without any property.
    </p>

    <div id="editor-dic" style="height: 110px;"></div>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Let's start learning about dictionaries!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `some_dictionary = {
    'fruit': 'apple',
    1: 'some number',
    numbers: [2, 4, 6]
}`;

export default {
  mounted() {
    this.initEditor("editor-dic", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
