<template>
  <div>
    <h2>Continue</h2>

    <p>
      A continue statement can be used to skip an interation of the loop.
    </p>

    <div id="editor-continue" style="height: 100px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          1
          <br />
          2
          <br />
          4
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Skip the iteration when <code>i</code> equals to 3</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `for i in [1, 2, 3, 4]:
    if i == 3:
        continue
    print(i)`;

export default {
  mounted() {
    this.initEditor("editor-continue", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
