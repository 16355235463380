<template>
  <div>
    <h2>Array Properties</h2>

    <p>
      Since array is an object, there are built-in properties and methods. We
      can get the number of elements in an array with
      <code>length</code> property.
    </p>

    <div id="editor-array-length" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 4
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Log the number of elements in <code>someArray</code> using the
            .length property
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-array-length", `let numberArray = [21, 4, 63, 2];\nconsole.log(numberArray.length);`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
