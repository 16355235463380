/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    try {returnResult();} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = () => {
      //
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function sayMyName(name2)")) {
    status.message = "Functions is not created correctly";
  } else if (!code.includes("window.alert('My name is ' + String(name2))")) {
    status.message = "Statement not printed correctly";
  } else if (!code.includes("sayMyName('")) {
    status.message = "Function not called";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
