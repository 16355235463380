/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof numberCounter === 'undefined') var numberCounter ;
    try {returnResult(numberCounter);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (numberCounter: number) => {
      result.numberCounter = numberCounter;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.numberCounter === undefined) {
    status.message = "numberCounter not created correctly";
  } else if (
    !code.includes("while (numberCounter <= 5)") &&
    !code.includes("while (numberCounter < 6)")
  ) {
    status.message = "Check your while loop conditional experession";
  } else if (!code.includes("alert(numberCounter)")) {
    status.message = "numberCounter not printed";
  } else if (result.printedText != "12345") {
    status.message = "Number not printed in correct sequence";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
