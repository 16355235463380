<template>
  <div>
    <h2>Exercise 35</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function called
            <span class="block block-function">plusHundred</span> that takes a
            parameter called
            <span class="block block-variable">number</span> and return the
            result
          </li>
          <li>
            The function should do following
            <ul>
              <li>
                Add 100 to <span class="block block-variable">number</span>
              </li>
              <li>Return the result</li>
            </ul>
          </li>
          <li>
            Create a variable
            <span class="block block-variable">answer</span> and set it as the
            function <span class="block block-function">plusHundred</span> with
            a parameter of 15
          </li>
          <li>
            Print the value of <span class="block block-variable">answer</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 115
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
