<template>
  <div>
    <h2>Join String</h2>

    <p>
      The <code>+</code> operator can be used to join together two strings. This
      can be very useful in building custom messages.
    </p>
    <div id="editor-join-string" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Hello Ken
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">Joining different types</h4>
    <p class="mt-2">
      Strings and integers/floats cannot be joined.
    </p>

    <div id="editor-join-error" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          TypeError: cannot concatenate 'str' and 'int' objects
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">To fix the error, we can cast the int into str.</p>

    <div id="editor-join-fix" style="height: 60px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Number of apples: 32
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Join and print the following</li>
          <ul>
            <li>"Cats sleep "</li>
            <li><code>hours</code></li>
            <li>" hours per day."</li>
          </ul>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `name = "Ken"
print("Hello " + name)`;

const code2 = `print("Number of apples: " + 32)`;
const code3 = `print("Number of apples: " + str(32))`;

export default {
  mounted() {
    this.initEditor("editor-join-string", code);
    this.initEditor("editor-join-error", code2);
    this.initEditor("editor-join-fix", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
