/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myFavouriteFruit === 'undefined') var myFavouriteFruit;
    if (typeof isApple === 'undefined') var isApple;
    try {returnResult(myFavouriteFruit, isApple);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myFavouriteFruit: string, isApple: boolean) => {
      result.myFavouriteFruit = myFavouriteFruit;
      result.isApple = isApple;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");

  if (result.myFavouriteFruit === undefined) {
    status.message = "myFavouriteFruit variable is not set correctly";
  } else if (result.isApple === undefined) {
    status.message = "isApple variable is not set correctly";
  } else if (result.myFavouriteFruit !== "apple") {
    status.message =
      "myFavouriteFruit variable is not assigned with correct value";
  } else if (
    !code.includes("isApple = myFavouriteFruit == 'apple'") &&
    !code.includes("isApple = 'apple' == myFavouriteFruit")
  ) {
    status.message =
      "isApple variable is not assigned with correct conditional expression";
  } else if (
    !trimmedCode.includes(`if(isApple){window.alert('Iloveappletoo!');}`)
  ) {
    status.message = "If statement not set up correctly";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
