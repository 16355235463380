/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";

export const seed = `CREATE TABLE devices (id int, name varChar(255), type varChar(255), price numeric);
INSERT INTO devices VALUES (0, "iPhone", "phone", 999);
INSERT INTO devices VALUES (1, "Samsung Galaxy", "phone", 799);
INSERT INTO devices VALUES (2, "ThinkPad X1", "laptop", 1999);
INSERT INTO devices VALUES (3, "Dell XPS", "laptop", 1899);

-- Only write your code below this line
`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "name", "type", "price"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [0, "iPhone", "phone", 999],
      [1, "Samsung Galaxy", "phone", 799],
      [2, "ThinkPad X1", "laptop", 1999],
      [3, "Dell XPS", "laptop", 1899]
    ],
    "Wrong data inserted"
  );
  return true;
}
