<template>
  <div>
    <h2>Return</h2>

    <p>
      A function can also return a value into the calling code.
    </p>

    <div id="editor-function-return" style="height: 120px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 3
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      When a function execution reaches <code>return</code>, the rest of the
      code will not be executed.
    </p>

    <div id="editor-function-return-2" style="height: 180px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > true
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Declare a function named <code>isMoreThanFifty</code> with one
            parameter that takes in an number array.
          </li>
          <li>
            When called with an array as argument, the function should return
            <code>true</code> if the sum of elements in array is more than 50.
            Else, return <code>false</code>
          </li>
          <li>Assume array has at least one element</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `function divide(a, b) {
  return a / b;
}

let result = divide(9, 3);
console.log(result);
`

const codeReturn = `function isEven(number) {
  if (number % 2 == 0) {
    return true;
  }

  // This line will not be reached if number is even
  return false;
}

console.log(isEven(10));
`

export default {
  mounted() {
    this.initEditor("editor-function-return", code);
    this.initEditor("editor-function-return-2", codeReturn);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
