<template>
  <div>
    <h2>Operators Introduction</h2>

    <p>
      Operators are special symbols in Python that carry out arithmetic or
      logical computation. The value that the operator operates on is called the
      operand.
    </p>
    <p>
      In example below, the operator <code>+</code> is used to sum up two
      numbers. The number 2 and 5 are the operands.
    </p>
    <div id="editor-plus" style="height: 60px;"></div>

    <p class="mt-3">Operators can be divided into different groups:</p>
    <ul>
      <li>Arithmetic operators</li>
      <li>Assignment operators</li>
      <li>Comparison operators</li>
      <li>Logical operators</li>
      <li>Identity operators</li>
      <li>Membership operators</li>
      <li>Bitwise operators</li>
    </ul>
    <p>
      In this chapter, we will look into arithmetic operators which is used to
      perform mathematic operations and assignment operators for assigning value
      to variables.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start your learning about operators!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `a = 2 + 5
`;

export default {
  mounted() {
    this.initEditor("editor-plus", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
