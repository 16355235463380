<template>
  <div>
    <h2>Challenge 3 - Count Even Numbers</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function named
            <span class="block block-function">countEven</span> that takes in a
            list of numbers as argument
          </li>
          <li>
            Function will return the number of even numbers in the list
          </li>
          <li>If no element in the list, return 0</li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Example list [1, 2, 3, 4, 5, 7]
          <br />
          Expected result:
          <br />
          >> 2
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
