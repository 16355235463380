/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof studentName === 'undefined') var studentName;
    if (typeof hasSubmitHomework === 'undefined') var hasSubmitHomework;
    if (typeof myPocketMoney === 'undefined') var myPocketMoney;
    try {returnResult(studentName, hasSubmitHomework, myPocketMoney);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      studentName: number,
      hasSubmitHomework: boolean,
      myPocketMoney: number
    ) => {
      result.studentName = studentName;
      result.hasSubmitHomework = hasSubmitHomework;
      result.myPocketMoney = myPocketMoney;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.studentName === undefined) {
    status.message = "studentname variable not named correctly";
  } else if (result.hasSubmitHomework === undefined) {
    status.message = "hassubmithomework variable not named correctly";
  } else if (result.myPocketMoney === undefined) {
    status.message = "mypocketmoney variable not named correctly";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
