<template>
  <div>
    <b-row no-gutters>
      <b-col class="d-flex flex-column" sm="5" md="4" lg="4">
        <div class="activity-body py-4 px-5">
          <div class="mb-3 d-flex justify-content-between">
            <b-button class="text-muted" variant="link" size="sm">
              <b-icon icon="chevron-left" /> Prev
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="`/course/${this.courseId}`"
            >
              All Lessons
            </b-button>
            <b-button class="text-muted" variant="link" size="sm">
              Next <b-icon icon="chevron-right" />
            </b-button>
          </div>
          <component :is="activityCurriculum.content" :key="activityId" />
        </div>
      </b-col>
      <b-col sm="7" md="8" lg="8">
        <div
          class="activity-actionbar d-flex justify-content-between align-items-center"
        >
          <h4 class="mx-3 mb-0">Editor</h4>
          <div>
            <b-button class="mr-2" variant="danger" @click="onClickReset">
              Reset
            </b-button>
            <b-button class="mr-2" variant="success" @click="onClickRun">
              Run
            </b-button>
          </div>
        </div>
        <b-row no-gutters>
          <b-col md="12">
            <div id="editor-container">
              <div id="editor-tab">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: session == 'html' }"
                      href="#"
                      @click="onClickTab('html')"
                    >
                      index.html
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: session == 'css' }"
                      href="#"
                      @click="onClickTab('css')"
                    >
                      style.css
                    </a>
                  </li>
                </ul>
                <div id="editor"></div>
              </div>

              <div id="editor-console">
                <iframe
                  ref="iframe"
                  :srcdoc="srcdoc"
                  style="width: 100%; height: 100%;"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-model="isResultModalVisible"
      id="result-modal"
      title="Result"
      @ok="onClickResultOk"
      :hide-footer="!isAnswerCorrect"
    >
      <p class="my-4 text-center">
        <span style="font-size: 3rem;">
          {{ isAnswerCorrect ? "✔️" : "❌" }}
        </span>
        <br />
        {{ isAnswerCorrect ? "Great!" : "Try again!" }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import Split from "split.js";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

import * as activityServices from "@/services/acitivity.service";
import curriculum from "@/curriculum/html-foundation";
import { mapGetters } from "vuex";

export default {
  name: "CodingActivityContainer",

  data() {
    return {
      courseId: this.$route.params.courseId,
      activityId: this.$route.params.activityId,
      editor: null,
      consoleText: "",
      srcdoc: "",
      session: "html",
      cssSession: null,
      htmlSession: null,
      activity: {},
      isResultModalVisible: false,
      isAnswerCorrect: false,
      isSplit: false
    };
  },

  watch: {
    $route: "onMounted"
  },

  async mounted() {
    await this.onMounted();
  },

  computed: {
    ...mapGetters({
      isLicensed: "organisation/isLicensed",
      isSubscriptionActive: "organisation/isSubscriptionActive"
    }),

    activityCurriculum() {
      return curriculum.get(this.activityId);
    },

    activityIds() {
      return [...curriculum.keys()];
    },

    currentActivityIndex() {
      return this.activityIds.indexOf(this.activityId);
    }
  },

  methods: {
    async onMounted() {
      this.courseId = this.$route.params.courseId;
      this.activityId = this.$route.params.activityId;

      const loader = this.$loading.show();
      await this.getActivity();
      this.consoleText = "";
      this.initSplit();
      this.initEditor();
      this.initContent();
      loader.hide();
    },

    getActivity() {
      return activityServices
        .getActivity(this.activityId)
        .then(activity => {
          this.activity = activity;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    initEditor() {
      this.editor = ace.edit("editor", {
        mode: "ace/mode/html",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });
      this.htmlSession = new ace.EditSession("", "ace/mode/html");
      this.cssSession = new ace.EditSession("", "ace/mode/css");
      this.htmlSession.setValue(this.activityCurriculum.logics.htmlSeed, 1);
      this.cssSession.setValue(this.activityCurriculum.logics.cssSeed, 1);
      this.editor.setSession(this.htmlSession);
      this.editor.on("change", this.onEditorChange);
    },

    initContent() {
      this.srcdoc =
        `<!DOCTYPE html>
        <html>
          <head>
          <style>` +
        this.cssSession.getValue() +
        `</style>
          </head>` +
        `<body>` +
        this.htmlSession.getValue() +
        `</body></html>`;
    },

    initSplit() {
      if (this.isSplit) {
        return;
      }
      Split(["#editor-tab", "#editor-console"], {
        elementStyle: (dimension, size, gutterSize) => ({
          "flex-basis": `calc(${size}% - ${gutterSize}px)`
        }),
        gutterStyle: (dimension, gutterSize) => ({
          "flex-basis": `${gutterSize}px`
        }),
        direction: "horizontal",
        sizes: [60, 40]
      });
      this.isSplit = true;
    },

    onClickRun() {
      const iframeDoc = this.$refs["iframe"].contentWindow.document;
      try {
        // eslint-disable-next-line
        const self = this;
        self.isAnswerCorrect = this.activityCurriculum.logics.testCorrectness(
          {
            html: this.htmlSession.getValue(),
            css: this.cssSession.getValue(),
            iframe: iframeDoc
          },
          self
        );
        self.isResultModalVisible = true;
      } catch (error) {
        self.isResultModalVisible = true;
        this.isAnswerCorrect = false;
        alert(error);
      }
    },

    onEditorChange() {
      let source = this.htmlSession.getValue();
      source = source.replace(
        '<link rel="stylesheet" href="styles.css">',
        `<style>${this.cssSession.getValue()}</style>`
      );
      this.srcdoc = source;
    },

    onClickResultOk() {
      const nextActivityId = this.activityIds[this.currentActivityIndex + 1];
      this.$router.push(nextActivityId);
    },

    onClickTab(session) {
      this.session = session;
      if (session == "html") {
        this.editor.setSession(this.htmlSession);
      } else {
        this.editor.setSession(this.cssSession);
      }
    },

    onClickReset() {
      if (confirm("Confirm reset code?")) {
        this.htmlSession.setValue(this.activityCurriculum.logics.htmlSeed, 1);
        this.cssSession.setValue(this.activityCurriculum.logics.cssSeed, 1);
        this.initContent();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.activity {
  &-body {
    background-color: #fff;
    height: calc(100vh - 52px);
    overflow: auto;
  }

  &-header {
    height: 52px;
  }

  &-actionbar {
    height: 52px;
  }
}

#editor-container {
  height: calc(100vh - 151px);
  width: 100%;
  display: flex;
  flex-direction: row;
}

#editor {
  height: 100%;
  width: 100%;
  overflow: auto;
}

#editor-console {
  background-color: white;
  white-space: pre-wrap;
  overflow: auto;
  height: calc(100vh - 110px);
  padding: 0px;
}

.editor-tab-wrapper {
  height: 100vh;
}
</style>
