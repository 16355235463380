<template>
  <div>
    <h2>If Statement</h2>

    <p>
      If statement evaluates an given expression and if the expression is
      <code>true</code>, a block of code will be executed.
    </p>

    <p>
      Code within this if statement will execute because 10 > 5 evaluates to
      true
    </p>

    <div id="editor-cond-if" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "code executed"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Write an if statement so that <code>number</code> will be logged if
            it's value is greater than 50.
          </li>
        </ol>
        <br />
        * Use only > operator
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `if (10 > 5) {
  console.log("code executed");
}
`

export default {
  mounted() {
    this.initEditor("editor-cond-if", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
