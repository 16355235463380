<template>
  <div>
    <h2>While Loop</h2>

    <p>
      A while loop iterates through a block of code as long as the given
      condition is true.
    </p>

    <p>
      In example below, as long as <code>counter</code> variable is less than 5,
      the while loop will continue iterate.
    </p>

    <div id="editor-nested" style="height: 110px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          1 <br />
          2 <br />
          3 <br />
          4
        </code>
      </b-card-text>
    </b-card>
    <p class="mt-3">
      In each loop, we add 1 to the counter. When it counter equals to 5, the
      while loop will stop.
    </p>
    <p>
      When using a while loop, if there are no changes in the condition, the
      while loop will loop forever! This is called an infinite loop. When that
      happens, your program will crash!
    </p>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a while loop to push number 6 to 20 in to the variable
            <code>some_numbers</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `counter = 0

while counter < 5:
    print(counter)
    counter += 1`;

export default {
  mounted() {
    this.initEditor("editor-nested", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
