<template>
  <div>
    <h2>Nested Loops</h2>

    <video-player
      poster="/images/loops.png"
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-28-loop-nested.mp4?alt=media&token=0d23778e-0529-4e90-98dd-d90aebe2091a"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Nested loops refer to a loop within a loop. The outer loop always executes
      first, and the inner loop executes inside the outer loop each time the
      outer loop executes once.
    </p>
    <img class="img-fluid" src="@/assets/foundation/loop_nested.png" />
    <p>
      Take note that each loop must use a different variable as counter. Output
      of code above
    </p>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> 1
          <br />
          >> 3
          <br />
          >> "----"
          <br />
          >> 1
          <br />
          >> 4
          <br />
          >> "----"
          <br />
          >> 2
          <br />
          >> 3
          <br />
          >> "----"
          <br />
          >> 2
          <br />
          >> 4
          <br />
          >> "----"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Using nested for loop, print each of the following value in below
            sequence
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 7
          <br />
          >> 8
          <br />
          >> 7
          <br />
          >> 8
          <br />
          >> 7
          <br />
          >> 8
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "AppInventorLoopNested",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
