<template>
  <div>
    <h2>Where Clause</h2>
    <p>
      The <code>WHERE</code> clause is used restrict or filter a query based on
      certain conditions. It can be use with <code>SELECT</code>,
      <code>UPDATE</code>, and <code>DELETE</code> statement which will be
      covered in subsequent exercises. Suppose we have a
      <code>students</code> table:
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            first_name
          </b-th>
          <b-th>
            last_name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Alice</b-td>
          <b-td>Vasilii</b-td>
          <b-td>13</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Fred</b-td>
          <b-td>Davidson</b-td>
          <b-td>14</b-td>
        </b-tr>
        <b-tr>
          <b-td>2</b-td>
          <b-td>Melissa</b-td>
          <b-td>Welch</b-td>
          <b-td>12</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div id="editor-where" style="height: 50px;"></div>

    <p class="mt-3">
      Running <code>SELECT</code> statement above will return all rows where
      <code>age</code> is greater than 13:
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            first_name
          </b-th>
          <b-th>
            last_name
          </b-th>
          <b-th>
            age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>1</b-td>
          <b-td>Fred</b-td>
          <b-td>Davidson</b-td>
          <b-td>14</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <h4>Operators</h4>
    <p>Operator is used to create conditions.</p>

    <b-table-simple class="mt-3" responsive>
      <b-thead>
        <b-tr>
          <b-th>Operator</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>=</b-th>
          <b-td>equal to</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;&gt;</b-th>
          <b-td>not equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>&gt;</b-th>
          <b-td>greater than</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;</b-th>
          <b-td>less than</b-td>
        </b-tr>
        <b-tr>
          <b-th>&gt;=</b-th>
          <b-td>greater than or equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;=</b-th>
          <b-td>less than or equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>BETWEEN ... AND ...</b-th>
          <b-td>between range</b-td>
        </b-tr>
        <b-tr>
          <b-th>LIKE</b-th>
          <b-td>similiar value</b-td>
        </b-tr>
        <b-tr>
          <b-th>IN</b-th>
          <b-td>compare multiple values</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div id="editor-operators" style="height: 100px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Select all data from the <code>devices</code> table with price more
            than 1500
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `SELECT * FROM students WHERE age > 13;`;

const codeAll = `SELECT * FROM students WHERE age BETWEEN 12 AND 13;
SELECT * FROM students WHERE name LIKE 'A%';
SELECT * FROM students WHERE age IN (10, 12, 14);
`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-where", code);
    this.initEditor("editor-operators", codeAll);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
