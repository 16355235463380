<template>
  <div>
    <b-container class="my-5" v-if="!isLoading">
      <b-row class="mb-5">
        <b-col>
          <h1>{{ course.name }}</h1>
          <p>
            {{ course.description }}
          </p>
        </b-col>
        <b-col md="5">
          <b-img :src="course.imageURL" fluid></b-img>
        </b-col>
      </b-row>

      <b-row class="mb-3" v-for="chapter in chapters" :key="chapter.id">
        <b-col>
          <b-card
            header-tag="header"
            footer-tag="footer"
            header-bg-variant="white"
            footer-bg-variant="white"
          >
            <template #header>
              <h4 class="mb-0">{{ chapter.name }}</h4>
            </template>

            <b-card-text
              class="d-flex align-items-center flex-wrap"
              style="row-gap: 15px;"
            >
              <progress-icon
                v-for="activity in chapterActivities(chapter.id)"
                :key="activity.id"
                :activity="activity"
                @click-icon="onClickIcon(activity)"
                class="mr-2"
              ></progress-icon>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  courseServices,
  chapterServices,
  activityServices,
  classroomServices
} from "@/services";
import ProgressIcon from "@/components/ProgressIcon";
import _ from "lodash";

export default {
  name: "Course",

  data() {
    return {
      courseId: this.$route.params.id,
      course: {},
      classroom: {},
      chapters: [],
      activities: [],
      classrooms: [],
      isLoading: true,
      isAssigningClass: false
    };
  },

  components: {
    ProgressIcon
  },

  async created() {
    const loader = this.$loading.show();
    await this.getCourse();
    await this.getChapters();
    await this.getActivities();
    loader.hide();
    this.isLoading = false;
    this.getClassrooms();
  },

  computed: {
    //
  },

  methods: {
    getCourse() {
      return courseServices.getCourse(this.courseId).then(course => {
        this.course = course;
      });
    },

    getChapters() {
      return chapterServices
        .getChapters(this.courseId)
        .then(chapters => {
          if (this.course.type == "user-created") {
            chapters = this.sortChapters(chapters);
          }
          this.chapters = _.sortBy(chapters, ["order"]);
        })
        .catch(error => {
          alert(error);
        });
    },

    sortChapters(chapters) {
      chapters = chapters.map(chapter => {
        return {
          ...chapter,
          order: this.course.chapterOrder[chapter.id]
        };
      });
      return _.orderBy(chapters, ["order"]);
    },

    getClassrooms() {
      return classroomServices
        .getTeacherClassrooms(this.$store.state.user.id)
        .then(classrooms => {
          this.classrooms = classrooms;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getActivities() {
      return activityServices
        .getActivities(this.courseId)
        .then(activities => {
          if (this.course.type == "user-created") {
            activities = this.sortActivities(activities);
          }
          this.activities = activities;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    sortActivities(activities) {
      activities = activities.map(activity => {
        return {
          ...activity,
          order: this.course.activityOrder[activity.id]
        };
      });
      return _.orderBy(activities, ["order"]);
    },

    handleOkAssignClass(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitAssignClass();
    },

    handleSubmitAssignClass() {
      const valid = this.$refs.assignClassForm.checkValidity();
      this.assignClassModalState = valid;
      if (!valid) {
        return;
      }
      this.isAssigningClass = true;

      return classroomServices
        .addCourse(this.selectedClassId, this.courseId)
        .then(() => {
          this.$bvModal.hide("modal-assign-class");
          this.$bvToast.toast(`Course assigned to class`, {
            title: "Assign Class",
            autoHideDelay: 5000,
            variant: "success",
            appendToast: true
          });
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onClickIcon(activity) {
      this.$router.push(`/course/${this.courseId}/activity/${activity.id}`);
    },

    chapterActivities(chapterId) {
      return this.activities.filter(activity => {
        return activity.chapterId == chapterId;
      });
    },

    resetAssignClassModal() {
      this.selectedClassId = "";
    }
  }
};
</script>

<style></style>
