<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-input-group class="mb-3">
          <b-form-select
            v-model="selectedCourseId"
            :options="courseOptions"
          ></b-form-select>
          <b-input-group-append>
            <b-button
              variant="dark"
              @click="onClickCheckProgress"
              :disabled="isCheckingProgress"
            >
              <b-spinner small v-if="isCheckingProgress"></b-spinner>
              {{ isCheckingProgress ? "Loading..." : "Check Progress" }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="12">
        <p>Click on progress status to view activity as student</p>
        <b-table
          responsive
          :items="studentProgress"
          :fields="fields"
          sticky-header="500px"
          head-variant="dark"
        >
          <template #head(name)="data">
            <span class="text-info">{{ data }}</span>
          </template>

          <template #cell(studentName)="data">
            <div class="text-truncate" style="width: 80px;">
              {{ data.item.studentName }}
            </div>
          </template>

          <template #cell()="data">
            <div
              class="clickable"
              @click="
                onClickStudentProgress(data.item.studentId, data.field.key)
              "
            >
              {{ getActivityStatus(data.value) }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { activityServices, classroomServices } from "@/services";
import _ from "lodash";

export default {
  name: "TeacherClassroomProgressTab",

  props: {
    classroom: {
      type: Object,
      default: function() {
        return {};
      }
    },
    courses: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  data() {
    return {
      selectedCourseId: null,
      currentCourseId: null,
      activities: [],
      students: [],
      records: [],
      isCheckingProgress: false
    };
  },

  created() {
    this.getClassroomStudents();
    this.getCourseActivityRecords();
  },

  computed: {
    courseOptions() {
      return this.courses.map(course => {
        return {
          text: course.name,
          value: course.id
        };
      });
    },

    fields() {
      const fields = [
        {
          key: "studentName",
          label: "Name",
          stickyColumn: true,
          isRowHeader: true,
          thStyle: { minWidth: "500px; !important" }
        }
      ];
      const lessonLabels = this.activities.map(activity => {
        return {
          key: activity.id,
          label: activity.name,
          class: "text-center"
        };
      });
      return this.activities.length == 0 ? [] : [...fields, ...lessonLabels];
    },

    studentProgress() {
      const progress = [];
      this.students.forEach(student => {
        if (
          this.records[student.id] != null &&
          this.records[student.id][this.selectedCourseId] != null
        ) {
          progress.push({
            studentName: student.name,
            studentId: student.id,
            ...this.records[student.id][this.selectedCourseId]
          });
        } else {
          progress.push({ studentName: student.name });
        }
      });

      if (this.fields.length == 0) return [];
      return [...progress];
    }
  },

  methods: {
    async onClickCheckProgress() {
      if (this.selectedCourseId == null) {
        return alert("Please select a course");
      }
      this.currentCourseId = this.selectedCourseId;
      this.isCheckingProgress = true;
      await this.getCourseActivities();
      await this.getCourseActivityRecords();
      this.isCheckingProgress = false;
    },

    getClassroomStudents() {
      return classroomServices
        .getClassroomStudents(this.classroom.id)
        .then(students => {
          this.students = students;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getCourseActivityRecords() {
      return activityServices
        .getStudentStatusByClassroom(this.classroom.id)
        .then(records => {
          this.records = records;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getCourseActivities() {
      return activityServices
        .getActivities(this.selectedCourseId)
        .then(activities => {
          this.activities = _.sortBy(activities, ["order"]);
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onClickStudentProgress(studentId, activityId) {
      const routeData = this.$router.resolve(
        `/view/classroom/${this.classroom.id}/student/${studentId}/course/${this.currentCourseId}/activity/${activityId}`
      );
      window.open(routeData.href, "_blank");
    },

    getActivityStatus(status) {
      switch (status) {
        case "done":
          return "✔️";

        case "in-progress":
          return "🚧";

        default:
          return "";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.clickable {
  cursor: pointer;
}
</style>
