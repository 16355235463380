<template>
  <div>
    <h2>Slicing</h2>

    <p>
      We can access a range of elements from a list by using the colon
      <code>:</code> operator.
    </p>

    <div id="editor-list-slice" style="height: 200px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [4, 6, 8]
          <br />
          [2, 4, 6]
          <br />
          [6, 8, 10]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Update / Delete with Slicing
    </h4>
    <p>We can update and delete the selected range</p>
    <div id="editor-list-slice-update" style="height: 180px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [2, 30, 40, 50, 8, 10]
          <br />
          [30, 40, 50, 8, 10]
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Assign <code>some_numbers</code> as the 3rd to 8th elements of
            <code>numbers</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `even_numbers = [2, 4, 6, 8, 10]

# Get 2nd to 4th elements
print(even_numbers[1:4])

# Get 1st to 3rd elements
print(even_numbers[:3])

# Get 3rd to last element
print(even_numbers[2:])`;

const code2 = `even_numbers = [2, 4, 6, 8, 10]

# Replace 2nd to 4th elements
even_numbers[1:3] = [30, 40, 50]
print(even_numbers)

# Delete 1st to 2nd elements
del even_numbers[:1]
print(even_numbers)`;

export default {
  mounted() {
    this.initEditor("editor-list-slice", code);
    this.initEditor("editor-list-slice-update", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
