/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isTrue;
    var isFalse;
    var isFalseTwo;

    try { 
      isTrue = isSquare([6,6,6,6]);
      isFalse = isSquare([7,7,7,5]);
      isFalseTwo = isSquare([7,8,7,5]);
    } catch (err) {
      window.alert(err);
    };

    try {returnResult(isTrue, isFalse, isFalseTwo);} catch (err) { window.alert(err); };
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      isTrue: boolean,
      isFalse: boolean,
      isFalseTwo: boolean
    ) => {
      result.isTrue = isTrue;
      result.isFalse = isFalse;
      result.isFalseTwo = isFalseTwo;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function isSquare")) {
    status.message = "isSquare function is not created";
  } else if (
    result.isTrue != true ||
    result.isFalse != false ||
    result.isFalseTwo != false
  ) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
