<template>
  <div>
    <h2>Conditional Expressions</h2>

    <p>
      A <strong>conditional expression</strong> evaluates to a boolean value of
      either <code>True</code> or <code>False</code>. Comparison operators below
      are use to create conditional expressions.
    </p>

    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Operator</b-th>
          <b-th>Description</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>==</b-th>
          <b-td>equal to</b-td>
        </b-tr>
        <b-tr>
          <b-th>!=</b-th>
          <b-td>not equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>&gt;</b-th>
          <b-td>greater than</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;</b-th>
          <b-td>less than</b-td>
        </b-tr>
        <b-tr>
          <b-th>&gt;=</b-th>
          <b-td>greater than or equal</b-td>
        </b-tr>
        <b-tr>
          <b-th>&lt;=</b-th>
          <b-td>less than or equal</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <p>Comparison operators compare two value and return a boolean</p>

    <div id="editor-expression" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          False
          <br />
          True
          <br />
          True
          <br />
          True
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Change the number 0 so that all the expressions will evaluate to
            True
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = 8
car = "Toyota"

print(number == 5)
print(number > 5)
print(number <= 8)
print(car != "Audi")`;

export default {
  mounted() {
    this.initEditor("editor-expression", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
