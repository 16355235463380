/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myFavouriteFruit === 'undefined') var myFavouriteFruit ;
    try {returnResult(myFavouriteFruit);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myFavouriteFruit: string) => {
      result.myFavouriteFruit = myFavouriteFruit;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");
  if (result.myFavouriteFruit === undefined) {
    status.message = "Variables not created correctly";
  } else if (
    !trimmedCode.includes(
      "if(myFavouriteFruit=='apple'){window.alert('Iloveappletoo!');}"
    )
  ) {
    status.message = "Wrong condition expression or print statement for apple";
  } else if (
    !trimmedCode.includes(
      "elseif(myFavouriteFruit=='banana'){window.alert('YuksIdonotlikebanana!');}"
    )
  ) {
    status.message = "Wrong condition or statement for banana";
  } else if (
    !trimmedCode.includes(
      "elseif(myFavouriteFruit=='carrot'){window.alert('Thatisnotafruit!');}"
    )
  ) {
    status.message = "Wrong condition or statement for carrot";
  } else if (!trimmedCode.includes("else{window.alert('Iguessthatisok');}")) {
    status.message = "Wrong condition or statement for other fruit";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
