<template>
  <div>
    <b-row class="mt-3" v-if="courses.length == 0 && !isLoading">
      <b-col>
        <p>
          😥 Opps.. Looks empty here. Assign
          <router-link to="/courses">courses</router-link> to this classroom to
          get students started!
        </p>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col md="6" class="mb-2" v-for="course in courses" :key="course.id">
        <course-card
          :course="course"
          :showSetting="true"
          @remove-click="onClickRemoveCourse(course)"
          @card-click="onClickCourseCard(course)"
        ></course-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard";
import { classroomServices } from "@/services";

export default {
  name: "TeacherClassroomCourseTab",

  props: {
    classroom: {
      type: Object,
      default: function() {
        return {};
      }
    },
    courses: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  components: {
    CourseCard
  },

  data() {
    return {
      classroomId: this.$route.params.id,
      localClassroom: this.classroom
    };
  },

  methods: {
    onClickCourseCard(course) {
      this.$router.push(`/course/${course.id}`);
    },

    onClickRemoveCourse(course) {
      if (confirm("Confirm remove course from this class?")) {
        classroomServices
          .deleteCourse(this.classroomId, course.id)
          .then(() => {
            this.$emit("update-courses");
          })
          .catch(error => {
            alert(error.message);
          });
      }
    }
  }
};
</script>

<style></style>
