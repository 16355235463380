<template>
  <div>
    <h2>Introduction to Database &amp; SQL</h2>
    <p>
      A <strong>database</strong> is a set of data stored in a computer. A
      <strong>relational database</strong> is a kind of database which stores
      data in tables, kind of like storing data in a spreadsheet software as
      shown below:
    </p>

    <b-table-simple
      class="text-center"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            🔑 Id
          </b-th>
          <b-th>
            First Name
          </b-th>
          <b-th>
            Last Name
          </b-th>
          <b-th>
            Age
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Alice</b-td>
          <b-td>Vasilii</b-td>
          <b-td>13</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Fred</b-td>
          <b-td>Davidson</b-td>
          <b-td>14</b-td>
        </b-tr>
        <b-tr>
          <b-td>2</b-td>
          <b-td>Melissa</b-td>
          <b-td>Welch</b-td>
          <b-td>12</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <p>
      It allows us to access and identify data <em>in relation</em> to another
      data in the database.
    </p>

    <h4 class="mt-3">SQL</h4>
    <p>
      SQL, or Structured Query Language, is a language designed to allow users
      to query, manage and transform relational database. There are many popular
      SQL database such as MySQL, Postgre, Oracle and SQLite. All of them
      support common SQL language.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start your SQL journey! In this course we
            will be working with
            <a href="https://www.sqlite.org/index.html">SQLite</a>, a widely
            adopted serverless database.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
