<template>
  <div>
    <h2>Comment</h2>

    <p>
      Programming in general uses comments to explain codes, makes code more
      readable or used to prevent certain code from execution. In Python, the
      hash tag symbol <code>#</code> is used to indicate start of comment.
    </p>
    <div id="editor-comment" style="height: 80px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Comment out the first print statement
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `# This is a comment
print("Hello World")  # This is another comment`;

export default {
  mounted() {
    this.initEditor("editor-comment", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
