<template>
  <div>
    <b-row no-gutters>
      <b-col class="d-flex flex-column" sm="6" md="5" lg="5">
        <div class="activity-body py-4 px-5">
          <div class="mb-3 d-flex justify-content-between">
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="activityIds[currentActivityIndex - 1]"
              v-if="currentActivityIndex != 0"
            >
              <b-icon icon="chevron-left" /> Prev
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="`/classroom/${this.classroomId}/course/${this.courseId}`"
            >
              All Lessons
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="activityIds[currentActivityIndex + 1]"
            >
              Next <b-icon icon="chevron-right" />
            </b-button>
          </div>
          <div id="editorjs" :key="activityId"></div>
        </div>
      </b-col>
      <b-col sm="6" md="7" lg="7">
        <div
          class="activity-actionbar d-flex justify-content-between align-items-center"
        >
          <h4 class="mx-3 mb-0">Editor</h4>
          <div>
            <span class="mr-2 text-muted" v-if="showSaved">Saved!</span>
            <b-button class="mr-2" variant="secondary" @click="onClickSave">
              <b-spinner small v-if="isSaving"></b-spinner>
              {{ isSaving ? "Saving..." : "Save" }}
            </b-button>
            <b-button class="mr-2" variant="danger" @click="onClickReset">
              Reset
            </b-button>
            <b-button class="mr-2" variant="success" @click="onClickRun">
              Run
            </b-button>
          </div>
        </div>
        <b-row no-gutters>
          <b-col md="12">
            <div id="editor-container">
              <div id="editor"></div>
              <div id="editor-console">
                {{ consoleText }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-model="isResultModalVisible"
      id="result-modal"
      title="Result"
      @ok="onClickResultOk"
      :hide-footer="!isAnswerCorrect"
    >
      <p class="my-4 text-center">
        <span style="font-size: 3rem;">
          {{ isAnswerCorrect ? "✔️" : "❌" }}
        </span>
        <br />
        {{ isAnswerCorrect ? "Great!" : "Try again!" }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import Split from "split.js";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

import * as activityServices from "@/services/acitivity.service";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import CodeTool from "@editorjs/code";
import List from "@editorjs/list";
import InlineCode from "@editorjs/inline-code";
import ImageTool from "@editorjs/image";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import YoutubeEmbed from "editorjs-youtube-embed";

import { runCode } from "@/tests/python";
import { courseServices } from "@/services";

export default {
  name: "ClassroomPythonActivityContainer",

  data() {
    return {
      classroomId: this.$route.params.classroomId,
      courseId: this.$route.params.courseId,
      activityId: this.$route.params.activityId,
      editor: null,
      consoleText: "",
      course: {
        activityOrder: {}
      },
      activity: {
        seed: "",
        content: "",
        tests: {
          seed: [],
          user: []
        }
      },
      isResultModalVisible: false,
      isAnswerCorrect: false,
      isSplit: false,
      isSaving: false,
      showSaved: false
    };
  },

  watch: {
    $route: "onMounted"
  },

  mounted() {
    this.onMounted();
  },

  computed: {
    activityIds() {
      let activities = [];

      for (const key in this.course.activityOrder) {
        if (Object.hasOwnProperty.call(this.course.activityOrder, key)) {
          activities.push({
            id: key,
            order: this.course.activityOrder[key]
          });
        }
      }
      activities = _.orderBy(activities, ["order"]);
      const activityIds = activities.map(activity => {
        return activity.id;
      });

      return activityIds;
    },

    currentActivityIndex() {
      return this.activityIds.indexOf(this.activityId);
    }
  },

  methods: {
    async onMounted() {
      this.classroomId = this.$route.params.classroomId;
      this.courseId = this.$route.params.courseId;
      this.activityId = this.$route.params.activityId;

      const loader = this.$loading.show();
      await this.getCourse();
      await this.getActivity();
      await this.getRecord();

      await this.initEditorJs();
      this.initSplit();
      this.initEditor();

      this.consoleText = "";
      loader.hide();
    },

    initEditorJs() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      const data = this.activity.content;

      this.editorjs = new EditorJS({
        holderId: "editorjs",
        readOnly: true,
        inlineToolbar: true,
        data: data,
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: "Enter a header",
              inlineToolbar: true,
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 1
            }
          },
          code: CodeTool,
          list: {
            class: List,
            inlineToolbar: true
          },
          inlineCode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+M"
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                vimeo: true
              }
            }
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile(file) {
                  return self.uploadActivityFile(file);
                }
              }
            }
          },
          youtubeEmbed: YoutubeEmbed,
          delimiter: Delimiter
        }
      });
      return;
    },

    initEditor() {
      this.editor = ace.edit("editor", {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });

      if (this.record != null) {
        this.editor.setValue(this.record.code, 1);
      } else {
        this.editor.setValue(this.activity.seed || "", 1);
      }
    },

    initSplit() {
      if (this.isSplit) {
        return;
      }
      Split(["#editor", "#editor-console"], {
        elementStyle: (dimension, size, gutterSize) => ({
          "flex-basis": `calc(${size}% - ${gutterSize}px)`
        }),
        gutterStyle: (dimension, gutterSize) => ({
          "flex-basis": `${gutterSize}px`
        }),
        direction: "vertical",
        sizes: [70, 30]
      });
      this.isSplit = true;
    },

    getActivity() {
      return activityServices
        .getActivity(this.activityId)
        .then(activity => {
          this.activity = activity;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getCourse() {
      return courseServices
        .getCourse(this.courseId)
        .then(course => {
          this.course = course;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getRecord() {
      return activityServices
        .getActivityRecord(
          this.$store.state.user.id,
          this.classroomId,
          this.activityId
        )
        .then(record => {
          this.record = record;
        });
    },

    async onClickSave() {
      this.isSaving = true;
      await this.saveWorkspace();
      this.isSaving = false;
      this.showSavedAlert();
    },

    async onClickRun() {
      this.consoleText = "";
      try {
        // eslint-disable-next-line
        const self = this;

        self.isAnswerCorrect = runCode(
          this.editor.getValue(),
          this.activity.seed,
          this.activity.tests,
          self
        );
        self.isResultModalVisible = true;
      } catch (error) {
        self.isResultModalVisible = true;
        this.isAnswerCorrect = false;
        this.consoleText = error;
      }

      // Save workspace and update progress
      if (this.isAnswerCorrect) {
        await this.updateActivityAsDone();
      } else {
        await this.saveWorkspace();
        this.showSavedAlert();
      }
    },

    onClickResultOk() {
      const nextActivityId = this.activityIds[this.currentActivityIndex + 1];
      this.$router.push(nextActivityId);
    },

    onClickReset() {
      if (confirm("Confirm reset code?")) {
        this.editor.setValue(this.activity.seed, 1);
        this.consoleText = "";
      }
    },

    updateActivityAsDone() {
      return activityServices.mergeActivityRecord(this.activityId, {
        classroomId: this.classroomId,
        courseId: this.courseId,
        studentId: this.$store.state.user.id,
        code: this.editor.getValue(),
        status: "done"
      });
    },

    showSavedAlert() {
      this.showSaved = true;
      const interval = setInterval(() => {
        this.showSaved = false;
        clearInterval(interval);
      }, 2000);
    },

    saveWorkspace() {
      return activityServices.mergeActivityRecord(this.activityId, {
        classroomId: this.classroomId,
        courseId: this.courseId,
        studentId: this.$store.state.user.id,
        code: this.editor.getValue()
      });
    }
  }
};
</script>

<style lang="less" scoped>
.activity {
  &-body {
    background-color: #fff;
    height: calc(100vh - 52px);
    overflow: auto;
  }

  &-header {
    height: 52px;
  }

  &-actionbar {
    height: 52px;
  }
}

#editor-container {
  height: calc(100vh - 110px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

#editor {
  height: 100%;
  min-height: 100px;
  overflow: auto;
}

#editor-console {
  min-height: 10px;
  background-color: black;
  white-space: pre-wrap;
  overflow: auto;
}
</style>
