<template>
  <component :is="courseComponent" />
</template>

<script>
import TeacherCourse from "@/views/courses/TeacherCourse.vue";
import StudentCourse from "@/views/courses/StudentCourse.vue";

export default {
  name: "Course",

  computed: {
    courseComponent() {
      let component = null;
      switch (this.$store.state.user.role) {
        case "teacher":
          component = TeacherCourse;
          break;

        case "student":
          component = StudentCourse;
          break;

        default:
          break;
      }
      return component;
    }
  }
};
</script>

<style></style>
