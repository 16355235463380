/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";

export const htmlSeed = `<!DOCTYPE html>
<html>
  <head>
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&amp;display=swap" rel="stylesheet">
    <link rel="stylesheet" href="styles.css">
  </head>
  <body>
    <h1>Welcome!</h1>
    <p>In this course you will learn how to create websites using HTML and CSS!</p>
    <iframe src="https://giphy.com/embed/g9582DNuQppxC" width="300" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
  </body>
</html>`;
export const cssSeed = `html {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  background-color: #000000;
  text-align: center;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23c6a3ff' fill-opacity='0.29'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}`;

export const testCases = "";

export function testCorrectness(code: any, self: any) {
  const html = code.html;
  const css = code.css;
  const iframeDoc = code.iframe;

  const text =
    iframeDoc.querySelector("h1").innerText ||
    iframeDoc.querySelector("h1").textContent;

  assert(
    /Hello World/.test(text),
    "Your h1 element should have the text Hello World"
  );

  return true;
}
