<template>
  <div class="home">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col md="4">
          <div id="content">
            <div id="content-body" class="p-4">
              <h1 class="title">Representing Sound in Binary</h1>
              <p>
                An interactive tool to visualize how sound are represented using
                binary. For the sake of simplicity, first bit of bit depth will
                be used to indicate positve or negative value. In reality, the
                presentation of negative number is more complicated.
              </p>
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Sample Rate"
                label-for="input-sm"
              >
                <b-input-group size="sm" append="hz">
                  <b-form-input
                    min="5"
                    max="1000"
                    type="number"
                    size="sm"
                    v-model.number="sampleRateInput"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Bit Depth"
                label-for="input-sm"
              >
                <b-input-group size="sm">
                  <b-form-input
                    min="2"
                    max="16"
                    type="number"
                    size="sm"
                    v-model.number="bitDepthInput"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <div>
                <div>Audio sample: 1000ms</div>
                <div>Sample interval: {{ samplePeriod }}ms</div>
                <div>Values per sample: {{ sampleValues }}</div>
                <div>Size: {{ audioSize }}</div>
              </div>
              <div class="mt-3">
                <b-button variant="primary" @click="onClickGenerate">
                  Generate sample
                </b-button>
              </div>

              <p class="mt-3"></p>
            </div>
          </div>
        </b-col>

        <b-col md="8">
          <div id="right-body">
            <div id="chart-container">
              <canvas ref="chartCanvas" width="400" height="400"></canvas>
            </div>
            <div class="mt-3">
              <p>Sampled sound data</p>
              <b-table-simple class="text-center" responsive>
                <b-tbody>
                  <b-tr>
                    <b-td variant="primary" sticky-column>Time</b-td>
                    <b-td variant="light" v-for="x in sampleData.x" :key="x">
                      {{ x }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td variant="primary" sticky-column>Amplitude</b-td>
                    <b-td
                      variant="light"
                      v-for="(y, index) in sampleData.ampDec"
                      :key="index"
                    >
                      {{ y.toFixed(2) }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td variant="primary" sticky-column>Amplitude</b-td>
                    <b-td
                      variant="light"
                      v-for="(y, index) in sampleData.ampBin"
                      :key="index"
                    >
                      {{ y }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "Home",

  data() {
    return {
      chartInstance: null,
      amplitude: 10,
      audioLength: 1000,
      sampleRateInput: 20,
      bitDepthInput: 2,
      test: [],
      steps: [],
      sampleData: {
        x: [],
        ampDec: [],
        ampBin: []
      }
    };
  },

  created() {
    //
  },

  mounted() {
    this.onMounted();
  },

  computed: {
    samplePeriod() {
      const stepValue = this.audioLength / (this.sampleRateInput - 1);
      return "≈ " + Math.ceil(stepValue);
    },

    sampleValues() {
      return 2 ** (this.bitDepthInput - 1);
    },

    audioSize() {
      const totalByte = (this.sampleRateInput * this.bitDepthInput) / 8;

      if (totalByte > 1024) {
        return Math.round(totalByte / 1024) + " kilobyte";
      } else {
        return Math.round(totalByte) + " byte";
      }
    }
  },

  methods: {
    onMounted() {
      this.initChart();
    },

    onClickGenerate() {
      if (this.bitDepthInput > 16) {
        return alert("Max bit depth 16 for this simulation");
      }

      if (this.sampleRateInput > 1000) {
        return alert("Max sample rate 1000 for this simulation");
      }

      this.generateChart();
    },

    initChart() {
      this.chartInstance = new Chart(this.$refs["chartCanvas"], {
        type: "line",
        data: {
          labels: this.getX(this.sineData()),
          datasets: this.generateDatasets()
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Time (ms)"
                }
              }
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Amplitude"
                }
              }
            ]
          }
        }
      });
    },

    generateChart() {
      this.chartInstance.data.datasets = this.generateDatasets();
      this.chartInstance.update();
    },

    generateDatasets() {
      return [
        {
          label: "Original Sound Wave",
          borderColor: "rgba(0,0,0,1)",
          backgroundColor: "rgba(0,0,0,0)",
          pointBorderColor: "rgba(0,0,0,0)",
          fill: false,
          data: this.sineData()
        },
        {
          label: "Sampled Sound Wave",
          steppedLine: "before",
          borderColor: "rgba(0, 170, 255,0.7)",
          backgroundColor: "rgba(0,0,0,0)",
          pointBorderColor: "rgba(0,0,0,0)",
          fill: false,
          data: this.generateSampleData()
        }
      ];
    },

    sineData() {
      const data = [];
      for (let index = 0; index <= this.audioLength; index += 1) {
        const x = index;
        const y = this.calcY(x);
        data.push({
          x: x,
          y: y
        });
      }
      return data;
    },

    generateSampleData() {
      const data = [];
      const stepValue = this.audioLength / (this.sampleRateInput - 1);
      const roundedStep = Math.ceil(stepValue);

      const sampledTime = [];
      const sampledAmplitude = [];
      const sampledAmplitudeBin = [];

      this.calcSteps();

      for (let index = 0; index <= this.audioLength; index += roundedStep) {
        const x = index;
        const y = this.calcStepY(x);

        sampledTime.push(x);
        sampledAmplitude.push(y);
        sampledAmplitudeBin.push(this.toBinary(y));

        data.push({
          x: x,
          y: y
        });
      }
      this.sampleData.x = sampledTime;
      this.sampleData.ampDec = sampledAmplitude;
      this.sampleData.ampBin = sampledAmplitudeBin;
      return data;
    },

    toBinary(y) {
      const isPositive = y >= 0;
      const index = this.steps.indexOf(Math.abs(y));
      const indexBin = (index >>> 0)
        .toString(2)
        .padStart(this.bitDepthInput - 1, "0");
      return isPositive ? "0" + indexBin : "1" + indexBin;
    },

    calcY(x) {
      return this.amplitude * Math.sin((x * Math.PI) / 180);
    },

    calcSteps() {
      const stepValue = this.amplitude / (this.sampleValues - 1);
      const steps = [];
      for (let index = 0; index < this.sampleValues; index++) {
        steps.push(index * stepValue);
      }
      this.steps = steps;
    },

    calcStepY(x) {
      const y = this.calcY(x);
      const closest = this.findClosest(Math.abs(y), this.steps);
      return y > 0 ? closest : -closest;
    },

    findClosest(needle, haystack) {
      return haystack.reduce((a, b) => {
        const aDiff = Math.abs(a - needle);
        const bDiff = Math.abs(b - needle);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    },

    getY(data) {
      return data.map(coor => {
        return coor.y;
      });
    },

    getX(data) {
      return data.map(coor => {
        return coor.x;
      });
    }
  }
};
</script>

<style lang="less" scoped>
#content {
  height: calc(100vh - 52px);
  background-color: white;
  border-right: 1px solid rgb(219, 219, 219);
}

#content-body {
  height: calc(100vh - 52px);
  overflow: auto;
}

.title {
  font-size: 2em;
  font-weight: 600;
}

.footer-link {
  color: white;
}

#right-body {
  padding: 20px;
  height: calc(100vh - 52px);
  overflow: auto;
}

#chart-container {
  position: relative;
  height: 50vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
</style>
