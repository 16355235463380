/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    var isEmpty;
    var isZero;
    var isOne;
    var isTwo;
    var isThree;
    try { 
      isEmpty = countEven([]);
      isZero = countEven([3, 7, 5]);
      isOne = countEven([50]);
      isTwo = countEven([2,4,5,11,7]);
      isThree = countEven([0,2,2,17,7,13]);
    } catch (err) {
      window.alert(err);
    };
    try {returnResult(isEmpty, isZero, isOne, isTwo, isThree);} catch (err) { window.alert(err); };
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      isEmpty: number,
      isZero: number,
      isOne: number,
      isTwo: number,
      isThree: number
    ) => {
      result.isEmpty = isEmpty;
      result.isZero = isZero;
      result.isOne = isOne;
      result.isTwo = isTwo;
      result.isThree = isThree;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (!code.includes("function countEven")) {
    status.message = "countEven function is not created";
  } else if (
    result.isEmpty != 0 ||
    result.isZero != 0 ||
    result.isOne != 1 ||
    result.isTwo != 2 ||
    result.isThree != 3
  ) {
    status.message = "Function does not return the correct result";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
