/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let somegoodthings;
let Studentcount;
let Isfunny;`;

export const testCases = `
  chaiAssert.isUndefined(someGoodThings);
  chaiAssert.isUndefined(studentCount);
  chaiAssert.isUndefined(isFunny);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
