<template>
  <div>
    <h2>Insert Into</h2>
    <p>
      The <code>INSERT INTO</code> statement inserts a new row into a table. You
      can use the <code>INSERT INTO</code> statement followed by the table name
      when you want to add new records.
    </p>
    <div id="editor-insert" style="height: 80px;"></div>

    <b-table-simple
      class="text-center mt-3"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            title
          </b-th>
          <b-th>
            release_year
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>1</b-td>
          <b-td>Frozen</b-td>
          <b-td>2013</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <p class="mt-2">
      <code>(id, title, release_year)</code> is the parameter identifying the
      columns that data will be inserted into.
    </p>

    <p>
      <code>VALUES (1, "Frozen", 2013)</code>
      is the value that will be inserted to column <code>id</code>,
      <code>title</code>, and <code>release_year</code>
      respectively
    </p>

    <p>
      If you are adding values for all the columns of the table, you do not need
      to specify the column names in the SQL query.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Insert data into movies table so that the table contain data as
            below
          </li>
        </ol>
      </b-card-text>
    </b-card>
    <b-table-simple
      class="text-center mt-3"
      table-variant="light"
      striped
      small
      caption-top
      responsive
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>
            id
          </b-th>
          <b-th>
            title
          </b-th>
          <b-th>
            director
          </b-th>
          <b-th>
            release_year
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody body-variant="light">
        <b-tr>
          <b-td>0</b-td>
          <b-td>Frozen</b-td>
          <b-td>Chris Buck</b-td>
          <b-td>2013</b-td>
        </b-tr>
        <b-tr>
          <b-td>1</b-td>
          <b-td>Avengers Endgame </b-td>
          <b-td>Anthony Russo</b-td>
          <b-td>2019</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `CREATE TABLE movies (id int, title varChar(255), release_year int)
INSERT INTO movies (id, title, release_year)
VALUES (1, "Frozen", 2013);
`;

export default {
  name: "CreateTable",

  mounted() {
    this.initEditor("editor-insert", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/sql",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
