<template>
  <div>
    <h2>Exercise 38</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function called
            <span class="block block-function">coinFlip</span> that has a
            parameter <span class="block block-variable">number</span>
          </li>
          <li>
            If <span class="block block-variable">number</span> is greater than
            50 the function will return
            <span class="block block-string">head</span> else it will return
            <span class="block block-string">tail</span>
          </li>
          <li>
            Print <span class="block block-function">coinFlip</span> using
            <span class="block block-variable">randomNumber</span> as its
            argument
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
