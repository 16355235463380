// Console format for blockly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function formatter(object: any) {
  switch (typeof object) {
    case "number":
      return object;

    case "boolean":
      return object;

    case "string":
      return `"${object}"`;

    case "object":
      if (object.class == "Array") {
        if (object.properties.length == 0) {
          return "[ ]";
        } else {
          let output = "[";
          const elements = Object.values(object.properties);
          for (let i = 0; i < elements.length; i++) {
            output += formatter(elements[i]);
            if (i < elements.length - 1) {
              output += ", ";
            }
          }
          output += "]";
          return output;
        }
      }
      break;

    default:
      return object;
  }
}
