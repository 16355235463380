/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";

export const seed = `CREATE TABLE games (id int, title varChar(255), genre varChar(255), developer varChar(255), release_year int);
INSERT INTO games VALUES (0, "Among Us", "multiplayer", "InnerSloth LLC", 2018);

-- Only write your code below this line




-- Only write your code above this line
SELECT * FROM games;`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "title", "genre", "developer", "release_year"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [0, "Among Us", "multiplayer", "InnerSloth LLC", 2018],
      [1, "Genshin Impact", "mmorpg", null, null],
      [2, "Pokemon Home", "single player", null, null]
    ],
    "Wrong data inserted"
  );
  return true;
}
