<template>
  <div>
    <h2>While Loop</h2>

    <p>
      A while loop iterates through a block of code as long as the given
      condition is true.
    </p>
    <p>
      In example below, as long as
      <code>counter</code> variable is less than 5, the while loop will continue
      iterate.
    </p>

    <div id="editor-loop-while" style="height: 150px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > [1, 2, 3, 4]
          <br />
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      In each loop, we add 1 to the
      <code>counter</code>. When it <code>counter</code> equals to 5, the while
      loop will stop.
    </p>
    <p>
      When using a while loop, if there are no changes in the condition, the
      while loop will loop forever! This is called an infinite loop. When that
      happens, your program will crash!
    </p>

    <div id="editor-loop-infinite" style="height: 150px;"></div>

    <p class="mt-3">
      In example above, <code>counter</code> will always equal to 1, hence the
      while loop condition will always be true.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a while loop to push number 6 to 20 in to the array
            <code>numbers</code>
          </li>
          <li>
            Use the <code>counter</code> variable to construct your condtional
            expression. You can reassign the value to suit your solution.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let counter = 1;
let numbers = [];

while (counter < 5) {
  numbers.push(counter);
  counter++;
}
console.log(numbers);
`

const codeInfinite = `let counter = 1;
let numbers = [];

// Infinite loop!!
while (counter < 5) {
  numbers.push(counter);
}
`

export default {
  mounted() {
    this.initEditor("editor-loop-while", code);
    this.initEditor("editor-loop-infinite", codeInfinite);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
