/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let car = {
  colour: "red",
  maxSpeed: 300
};

// Only write code below this line
`;

const seedTest = `
  let answer = {
    colour: "red",
    maxSpeed: 300
  }
  chaiAssert.deepEqual(car, answer, "Do not change default code");
`;

export const testCases = `
let answer = {
  colour: "red",
  maxSpeed: 100
};
chaiAssert.deepEqual(car, answer, "maxSpeed of car should be 100");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 4; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(/car\["maxSpeed"\]\s*=\s*100\s*;/.test(code), "use bracket notation");
  window.console = oldConsole;
  return true;
}
