<template>
  <div>
    <h2>Exercise 7</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Using an if...else statement with OR operators, check whether
            <code>animal</code> equals to "dog" or <code>fruit</code> equals to
            "apple". If so, assign <code>result</code> with "Yes", else assign
            "No"
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const codeAnd = `console.log(true && true);
console.log(false && true);
console.log(true && false);
console.log(false && false);
`
const codeOr = `console.log(true || true);
console.log(false || true);
console.log(true || false);
console.log(false || false);
`
const codeNot = `console.log(!true);
console.log(!false);
`

export default {
  mounted() {
    this.initEditor("editor-cond-and", codeAnd);
    this.initEditor("editor-cond-or", codeOr);
    this.initEditor("editor-cond-not", codeNot);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
