/* eslint-disable @typescript-eslint/no-explicit-any */
import { assert } from "chai";
import { deviceTable } from "./devices";

export const preSeed = deviceTable;
export const seed = ``;

export function testCorrectness(code: string, self: any) {
  self.db.exec(preSeed);
  const result = self.db.exec(code);
  self.resultDB = result;

  assert.deepEqual(
    result[0].columns,
    ["id", "name", "type", "price"],
    "Wrong table columns"
  );

  assert.deepEqual(
    result[0].values,
    [
      [15, "Realme", "phone", 399],
      [10, "Pocophone", "phone", 499],
      [11, "Xiaomi Mi 10", "phone", 599],
      [1, "Samsung Galaxy", "phone", 799],
      [8, "Google Pixel", "phone", 799],
      [0, "iPhone X", "phone", 999],
      [6, "iPhone 12", "phone", 1499],
      [7, "Samsung Fold", "phone", 2100]
    ],
    "Wrong data selected"
  );
  return true;
}
