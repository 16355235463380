<template>
  <div class="progress-icon" :style="flexOrder">
    <div
      class="progress-rect"
      :class="{ 'is-completed': isCompleted }"
      @click="onClickIcon"
    >
      {{ activity.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressIcon",

  props: {
    activity: {
      type: Object,
      default: function() {
        return {
          id: "",
          name: "",
          order: 0,
          type: "",
          courseId: "",
          chapterId: ""
        };
      }
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    flexOrder() {
      return {
        order: this.activity.order
      };
    },

    shape() {
      return this.activity.type == "lesson" ? "rect" : "rect";
    }
  },

  methods: {
    onClickIcon() {
      this.$emit("click-icon", this.activity);
    }
  }
};
</script>

<style lang="less" scoped>
.progress-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid #343a40;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}

.progress-rect {
  display: inline-block;
  border: 1px solid #343a40;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  user-select: none;
}

.is-completed {
  background-color: #343a40;
  color: #fff;
}
</style>
