<template>
  <div>
    <h2>Statement</h2>

    <p>
      Instructions that a Python interpreter can execute are called statements.
      For example, <code>a = 1</code> is an assignment statement. In Python, a
      new line is used to indicate a new statement.
    </p>
    <div id="editor-statement" style="height: 80px;"></div>

    <h4 class="mt-3">Multiline Statement</h4>
    <p>
      We can extend a statement over multiple line using the forward slash (/).
      Continuation of statement also implied in <code>()</code> and
      <code>[]</code>.
    </p>
    <div id="editor-statement-multiline" style="height: 100px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Fix the code so that <code>lucky_number</code> is assigned correctly
            across three lines using <code>\</code>.
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `a = 1 + 2
b = 3
print(a)
print(b)
`;

const code2 = `a = 1 + \\
2

b = (3
+ 1)
`;

export default {
  mounted() {
    this.initEditor("editor-statement", code);
    this.initEditor("editor-statement-multiline", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
