<template>
  <div>
    <h2>Exercise 36</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function called
            <span class="block block-function">calculateBMI</span> with two
            parameters - <span class="block block-variable">weight</span> and
            <span class="block block-variable">height</span>
          </li>
          <li>
            The function will calculate and return the BMI value using the
            formula below:
            <img class="img-fluid" src="@/assets/foundation/bmi_formula.png" />
            Where weight is in the unit of kilogram and height is in the unit of
            meter
          </li>

          <li>
            Create a variable
            <span class="block block-variable">bmi</span> and set it as the
            function <span class="block block-function">calculateBMI</span> with
            the arguments of 50 as weight and 1.6 as height
          </li>
          <li>
            Print the value of <span class="block block-variable">bmi</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 19.531249999999
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
