<template>
  <div>
    <h2>Exercise 37</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function called
            <span class="block block-function">oddNumberGenerator</span> that
            has a parameter <span class="block block-variable">n</span>
          </li>
          <li>
            Return the list of all the odd numbers starting before and equal to
            <span class="block block-variable">n</span>
          </li>
          <li>
            Print the output of
            <span class="block block-function">oddNumberGenerator</span> with
            <span class="block block-variable">n</span> as any value greater
            than 0
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          If n is 6, example result:
          <br />
          >> [1,3,5]
          <br /><br />
          If n is 9, example result:
          <br />
          >> [1,3,5,7,9]
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
