/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof favouriteAnimal === 'undefined') var favouriteAnimal;
    if (typeof isCodingFun === 'undefined') var isCodingFun;
    if (typeof year === 'undefined') var year;
    try {returnResult(favouriteAnimal, isCodingFun, year);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (
      favouriteAnimal: string,
      isCodingFun: boolean,
      year: number
    ) => {
      result.favouriteAnimal = favouriteAnimal;
      result.isCodingFun = isCodingFun;
      result.year = year;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.favouriteAnimal == undefined) {
    status.message = "favouriteAnimal variable is not created";
  } else if (result.isCodingFun == undefined) {
    status.message = "isCodingFun variable is not created";
  } else if (result.year == undefined) {
    status.message = "year variable is not created";
  } else if (result.favouriteAnimal != "cat") {
    status.message =
      "favouriteAnimal variable is not assigned with correct value";
  } else if (result.isCodingFun !== true) {
    status.message = "isCodingFun variable is not assigned with correct value";
  } else if (result.year !== 2020) {
    status.message = "year variable is not assigned with correct value";
  } else if (
    !code.includes("alert(favouriteAnimal)") ||
    !code.includes("alert(isCodingFun)") ||
    !code.includes("alert(year)")
  ) {
    status.message = "Variables not printed correctly";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
