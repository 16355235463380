<template>
  <div>
    <h2>Switch Statement</h2>

    <p>
      When you need to executes block of code based on fixed value data, you can
      use
      <code>switch</code> statement instead of multiple if..else if statement.
    </p>

    <div id="editor-cond-switch" style="height: 250px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > "one"
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-2">
      In example above, switch will evaluates <code>a</code> and match the value
      to one of the case. If no matches, the default code block will be
      executed. The <code>break</code> statement ensures the program breaks out
      of <code>switch</code> once the case is matched.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Rewrite the if statement using switch statement
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `let a = 1;
let number;

switch(a) {
  case 0:
    number = "zero";
    break;
    
  case 1:
    number = "one";
    break;

  case 2:
    number = "two";
    break;

  case 3:
    number = "three";
    break;

  default:
    number = "none";
}
console.log(number);
`

export default {
  mounted() {
    this.initEditor("editor-cond-switch", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
