<template>
  <div>
    <h2>Input</h2>

    <p>
      In Python, the built-in function <code>input()</code> allow us to take
      input from the user. The message to be displayed when we ask for user's
      input is put between the <code>()</code>.
    </p>
    <div id="editor-input" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          10
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <code>my_number</code> and ask for user input for
            its value.
          </li>
          <li>Print <code>my_number</code></li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number = input("enter a number")
# input 10
print(number)`;

export default {
  mounted() {
    this.initEditor("editor-input", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
