<template>
  <div>
    <h2>Introduction</h2>

    <p>
      Operators are used to perform operation on numbers.
    </p>
    <ul>
      <li>Addtion +</li>
      <li>Substraction -</li>
      <li>Multiplication *</li>
      <li>Division /</li>
      <li>Remainder %</li>
    </ul>

    <h4 class="mt-3">Remainder %</h4>
    <p>Remainder will return the remainder of dividing two numbers</p>
    <div id="editor-math-intro" style="height: 60px;"></div>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 1
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Click the run button to start your learning about operators!</li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  mounted() {
    this.initEditor("editor-math-intro", `console.log(10 % 3);`);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
