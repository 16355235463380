<template>
  <div class="pb-3">
    <h2>Introduction to MIT App Inventor</h2>

    <video-player
      poster="/images/intro.png"
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-0-course-intro.mp4?alt=media&token=7cd790b6-1ce4-4d55-b65e-e04ee25ea3f6"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Welcome to our programming foundation with MIT App Inventer course!
      Through this course you will learn the core concepts of programming and
      hands-on building mobile applications using MIT App Inventor. Have fun
      learning!
    </p>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "AppInventorIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less"></style>
