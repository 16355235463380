<template>
  <component :is="courseComponent" />
</template>

<script>
import BlocklyActivity from "./TeacherViewBlocklyActivity.vue";
import CodingActivity from "./TeacherViewCodingActivity.vue";
import SqlActivity from "./TeacherViewSqlActivity.vue";
import PythonActivity from "./TeacherViewPythonActivity.vue";
import UserActivity from "./TeacherViewUserActivity.vue";
import { courseServices } from "@/services";

const blocklyCourses = ["bJ01LTYvRsQfT7D8hOnG", "bwWhzZF7dtegYZ6XkXJJ"];
const codingCourses = ["UodGJzr3rrITkKpCi6e4"];
const pythonCourses = ["HCyyNY4H52b9vCXZAYY5"];
const sqlCourses = ["SDSbB5WrKVu50EEKO68Z"];

export default {
  name: "Activity",

  data() {
    return {
      courseId: this.$route.params.courseId,
      course: {}
    };
  },

  async created() {
    await this.getCourse();
  },

  computed: {
    courseComponent() {
      const courseId = this.$route.params.courseId;
      const component = null;

      if (this.course.type == "user-created") {
        return UserActivity;
      }

      if (blocklyCourses.includes(courseId)) {
        return BlocklyActivity;
      } else if (codingCourses.includes(courseId)) {
        return CodingActivity;
      } else if (sqlCourses.includes(courseId)) {
        return SqlActivity;
      } else if (pythonCourses.includes(courseId)) {
        return PythonActivity;
      }
      return component;
    }
  },

  methods: {
    getCourse() {
      return courseServices
        .getCourse(this.courseId)
        .then(course => {
          this.course = course;
        })
        .catch(error => {
          alert(error.message);
        });
    }
  }
};
</script>

<style></style>
