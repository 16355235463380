<template>
  <div>
    <h2>List Methods</h2>

    <p>
      List methods are built-in methods we can use on lists to perform different
      action on a list. <code>append()</code>, <code>pop()</code>, and etc are
      some of the methods we already learned. See all the list methods
      <a
        href="https://docs.python.org/3/tutorial/datastructures.html#more-on-lists"
        target="_blank"
        >here</a
      >.
    </p>

    <h4>Reverse</h4>
    <p>
      Reverses the order of the list
    </p>
    <div id="editor-list-reverse" style="height: 90px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [3, 2, 1]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Sort
    </h4>
    <p>Sorts the list</p>
    <div id="editor-list-sort" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [1, 4, 22, 48]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-4">
      Clear
    </h4>
    <p>Returns the count of the number of items passed as an argument</p>
    <div id="editor-list-count" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          [4, 1, 48, 92]
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-4">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Reverse the <code>numbers</code> list</li>
          <li>
            Use <code>count()</code> method to count the number 2 appears in
            list and assign it to <code>count_two</code>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `number_list = [1, 2, 3]
number_list.reverse()
print(number_list)`;

const code2 = `number_list = [4, 22, 1, 48]
number_list.sort()     
print(number_list)`;

const code3 = `number_list = [4, 22, 1, 48, 92]
number_list.clear()     
print(number_list)`;

export default {
  mounted() {
    this.initEditor("editor-list-reverse", code);
    this.initEditor("editor-list-sort", code2);
    this.initEditor("editor-list-count", code3);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
