<template>
  <div>
    <h2>Introduction</h2>

    <p>
      Python is a simple dynamic typing language (similar to JavaScript) that is
      easy to learn because of its straightforward syntax. It's one of the
      fastest growing language and it is used across many industries and
      applications. Also, it has a rich ecosystem of packages that allow
      developers to develop at a much faster speed.
    </p>
    <p>
      Unlike many other languages which use curly brackets to define scope,
      Python uses indentation and white space to do so. Also, Python uses new
      line to complete a command whereas other languages often use semicolon.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start your Python journey!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

export default {
};
</script>

<style></style>
