/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof favouriteFruit === 'undefined') var favouriteFruit ;
    try {returnResult(favouriteFruit);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (favouriteFruit: string) => {
      result.favouriteFruit = favouriteFruit;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, any>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  const trimmedCode = code.replace(/\s/g, "");
  if (result.favouriteFruit == null) {
    status.message = "favouriteFruit variable is not set correctly";
  } else if (result.favouriteFruit !== "orange") {
    status.message = "favouriteFruit variable not assigned with correct value";
  } else if (
    !trimmedCode.includes(
      "if(favouriteFruit=='apple'){window.alert('Iloveappletoo!')"
    ) &&
    !trimmedCode.includes(
      "if('apple'==favouriteFruit){window.alert('Iloveappletoo!')"
    )
  ) {
    status.message = "If condition is not set correctly";
  } else if (!code.includes("else")) {
    status.message = "Else is not used in the if condition";
  } else if (
    result.favouriteFruit == "apple" &&
    result.printedText != "I love apple too!"
  ) {
    status.message = "Print message is not set correctly";
  } else if (
    result.favouriteFruit != "apple" &&
    result.printedText != "Not my favourite fruit!"
  ) {
    status.message = "Print message is not set correctly";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
