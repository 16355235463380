/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";
import { getArrayElements } from "@/utils/console-utils";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof numbers === 'undefined') var numbers;
    try {returnResult(numbers);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      result.printedText += obj;
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (numbers: number[]) => {
      result.numbers = numbers;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: any) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.numbers === undefined) {
    status.message = "Variable not setup correctly";
  } else if (!code.includes("numbers = [15, 3, 48, 22]")) {
    status.message = "Number variable not assigned correctly";
  } else if (code.includes("numbers = [45, 9, 144, 66]")) {
    status.message = "Use the set block to assign value into the list";
  } else if (!code.includes("for")) {
    status.message = "Use a for loop to reassign each element";
  } else if (
    JSON.stringify(getArrayElements(result.numbers)) !=
    JSON.stringify([45, 9, 144, 66])
  ) {
    status.message = "Final numbers in list not correct";
  } else if (!code.includes("alert(numbers)")) {
    status.message = "numbers not printed";
  } else {
    status.isCorrect = true;
    status.message = "Great! Let move on to the next task.";
  }
  return status;
}
