<template>
  <div>
    <h2>Loops Introduction</h2>

    <p>
      Loops are used to instruct the computer to do something over and over
      again. This is what makes a computer so powerful in carrying out a
      repetitive task. A computer can repeat a line of code a million times in a
      second! There are two main types of loops we can use in most programming
      languages - <strong>For Loop</strong> and <strong>While Loop</strong>
    </p>

    <h4>For Loop</h4>
    <p>
      A for loop will carry out instructions for a given number of times. For
      example in the code below, the code inside for loop will iterate 3 times.
    </p>

    <div id="editor-loop-intro" style="height: 80px;"></div>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          > 1
          <br />
          > 2
          <br />
          > 3
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-2">
      In each for loop, we will need a variable as a counter to keep track of
      the number of loops. In example the above, variable
      <code>i</code> was created as the counter for this loop. You also notice 3
      values in the for loop
    </p>
    <ul>
      <li>from - the initial value of <code>i</code></li>
      <li>to - the condition of <code>i</code> where the for loop will stop</li>
      <li>
        by - how will the variable
        <code>i</code> change after each loop
      </li>
    </ul>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Click the run button to start learning about conditionals!
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */

import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `for (let i = 0; i < 3; i++) {
  console.log(i);
}
`

export default {
  mounted() {
    this.initEditor("editor-loop-intro", code);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/javascript",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
