/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let undefinedVariable; 
let nullVariable = null;
let booleanVariable = true;
let numberVariable = 123;
let stringVariable = "hello";
let objectVariable = {};
let functionVariable = function () {};`;
export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
