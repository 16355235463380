<template>
  <div class="home">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col md="4">
          <div id="content">
            <div class="p-4">
              <h1 class="title">Representing Text in Binary</h1>
              <p>
                An interactive tool to visualize how text are represented using
                binary.
              </p>
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Character Set"
                label-for="input-sm"
              >
                <b-form-select v-model="charSetInput" size="sm">
                  <b-form-select-option value="ascii7">
                    ASCII (7-bit)
                  </b-form-select-option>
                  <b-form-select-option value="unicode">
                    Unicode (UTF-8)
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-size="sm"
                label="Convertion"
                label-for="input-sm"
              >
                <b-form-select v-model="convertToInput" size="sm">
                  <b-form-select-option value="text">
                    Binary to Text
                  </b-form-select-option>
                  <b-form-select-option value="binary">
                    Text to Binary
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <div>
                <div>Size: {{ size }} bit</div>
              </div>
              <div class="mt-3">
                <b-button variant="primary" @click="onClickGenerate">
                  Generate
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="4">
          <div id="ace-editor"></div>
        </b-col>

        <b-col md="4">
          <div id="text-container">
            <h4 class="px-3 py-2 my-0">Result</h4>
            <textarea
              v-model="result"
              id="text-result"
              cols="30"
              rows="10"
            ></textarea>
            <div id="right-content">
              <div v-if="charSetInput != 'unicode'">
                <h4 class="px-3 py-2 my-0">Character set</h4>
                <b-table-simple class="text-center" responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th sticky-column>Number</b-th>
                      <b-th sticky-column>Binary</b-th>
                      <b-th sticky-column>Character</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="char in charSet" :key="char.number">
                      <b-td>{{ char.number }}</b-td>
                      <b-td>{{ char.binary }}</b-td>
                      <b-td>{{ char.character }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>

              <div class="p-4 my-0" v-else>
                <h4>Character set</h4>
                <p>
                  There are 143,859 characters in unicode. You can see the list
                  of unicode
                  <a href="https://unicode-table.com/en/" target="_blank"
                    >here</a
                  >
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

export default {
  name: "Home",

  data() {
    return {
      editor: null,
      convertToInput: "text",
      charSetInput: "ascii7",
      result: "",
      size: 0
    };
  },

  mounted() {
    this.onMounted();
  },

  computed: {
    charSet() {
      const result = [];

      for (let index = 32; index <= 128; index++) {
        const character = {
          number: index,
          character: String.fromCharCode(index),
          binary: index.toString(2).padStart(7, "0")
        };
        result.push(character);
      }

      for (let index = 0; index < 31; index++) {
        const character = {
          number: index,
          character: String.fromCharCode(index),
          binary: index.toString(2).padStart(7, "0")
        };
        result.push(character);
      }

      return result;
    }
  },

  methods: {
    onMounted() {
      this.initEditor();
    },

    initEditor() {
      this.editor = ace.edit("ace-editor", {
        mode: "ace/mode/text",
        selectionStyle: "text",
        theme: "ace/theme/eclipse"
      });
    },

    onClickGenerate() {
      this.resetResult();
      const textInput = this.editor.getValue();

      if (textInput == "") {
        return;
      }

      if (this.charSetInput == "ascii7" && this.convertToInput == "text") {
        this.generateASCIIText(textInput);
      } else if (
        this.charSetInput == "ascii7" &&
        this.convertToInput == "binary"
      ) {
        this.generateASCIIBinary(textInput);
      } else if (
        this.charSetInput == "unicode" &&
        this.convertToInput == "binary"
      ) {
        this.generateUnicodeBinary(textInput);
      } else if (
        this.charSetInput == "unicode" &&
        this.convertToInput == "text"
      ) {
        this.generateUnicodeText(textInput);
      }

      this.getSize();
    },

    generateASCIIText(string) {
      const stringChunks = this.getStringChunks(string, 7);
      let result = "";

      for (let index = 0; index < stringChunks.length; index++) {
        const number = parseInt(stringChunks[index], 2);
        result += String.fromCharCode(number);
      }
      this.result = result;
    },

    generateUnicodeText(string) {
      const stringChunks = this.getStringChunks(string, 8);
      let result = "";

      while (stringChunks.length > 0) {
        const binArray = [];
        const firstChunk = stringChunks[0];

        for (let index = 0; index < firstChunk.length; index++) {
          const bit = firstChunk[index];
          if (bit == "0") {
            break;
          }

          if (stringChunks.length > 0) {
            binArray.push(stringChunks.shift());
          }
        }

        const trimmedBinArray = binArray.map(bin => {
          for (let index = 0; index < bin.length; index++) {
            const bit = bin[index];
            if (bit == "0") {
              bin = bin.slice(index + 1);
              break;
            }
          }
          return bin;
        });

        const charBin = trimmedBinArray.join("");
        const charNumber = parseInt(charBin, 2);
        const char = String.fromCharCode(charNumber);
        result += char;
      }
      this.result = result;
    },

    generateASCIIBinary(string) {
      let result = "";
      for (let index = 0; index < string.length; index++) {
        const charCode = string.charCodeAt(index);
        if (charCode > 128) {
          continue;
        }

        const binary = charCode.toString(2).padStart(7, "0");
        result += binary + " ";
      }
      this.result = result;
    },

    generateUnicodeBinary(string) {
      const utf8 = unescape(encodeURIComponent(string));
      const charArray = [];
      let result = "";
      for (let i = 0; i < utf8.length; i++) {
        charArray.push(utf8.charCodeAt(i));
      }
      const binArray = [];
      for (let index = 0; index < charArray.length; index++) {
        binArray.push(charArray[index].toString(2).padStart(8, "0"));
      }
      binArray.forEach(bin => {
        result += bin + " ";
      });
      this.result = result;
    },

    getStringChunks(string, length) {
      const trimmedString = string.replace(/\s/g, "");
      const re = new RegExp(`.{1,${length}}`, "g");
      const stringChunks = trimmedString.match(re);
      const lastChunk = stringChunks[stringChunks.length - 1];
      if (lastChunk.length < 7) {
        stringChunks.pop();
      }
      return stringChunks;
    },

    getSize() {
      if (this.convertToInput == "text") {
        this.size = this.editor.getValue().length;
      } else if (this.convertToInput == "binary") {
        this.size = this.result.length;
      }
    },

    resetResult() {
      this.result = "";
    }
  }
};
</script>

<style lang="less" scoped>
#content {
  height: calc(100vh - 52px);
  overflow: auto;
  background-color: white;
}

.footer-link {
  color: white;
}

#ace-editor {
  height: calc(100vh - 52px);
  width: 100%;
  overflow: auto;
}

#text-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 52px);
  background-color: rgb(218, 218, 218);
}

#text-result {
  height: 50vh;
  border: 1px solid rgb(199, 199, 199);
}

#right-content {
  height: 50vh;
  background-color: white;
  border-left: 1px solid rgb(199, 199, 199);
  overflow: auto;
}

.title {
  font-size: 2em;
  font-weight: 600;
}
</style>
