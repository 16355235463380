<template>
  <div>
    <b-row no-gutters>
      <b-col
        class="d-flex flex-column"
        :md="isOnlyContent ? 12 : contentSize"
        :lg="isOnlyContent ? 8 : contentSize"
        :offset-lg="isOnlyContent ? 2 : 0"
      >
        <div class="activity-body py-4 px-5">
          <div class="mb-3 d-flex justify-content-between">
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="activityIds[currentActivityIndex - 1]"
              v-if="currentActivityIndex != 0"
            >
              <b-icon icon="chevron-left" /> Prev
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="`/course/${this.courseId}`"
            >
              All Lessons
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              size="sm"
              :to="activityIds[currentActivityIndex + 1]"
            >
              Next <b-icon icon="chevron-right" />
            </b-button>
          </div>
          <component :is="activityCurriculum.content" :key="activityId" />
        </div>
      </b-col>
      <b-col :md="12 - contentSize" v-if="!isOnlyContent">
        <div
          class="activity-actionbar d-flex justify-content-between align-items-center"
        >
          <h4 class="mx-3 mb-0">Workspace</h4>
          <div>
            <b-dropdown text="Code" class="m-md-2">
              <b-dropdown-item @click="viewCode('js')">
                JavaScript
              </b-dropdown-item>
              <b-dropdown-item @click="viewCode('python')">
                Python
              </b-dropdown-item>
              <b-dropdown-item @click="viewCode('dart')">
                Dart
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              class="mr-2"
              variant="danger"
              @click="onClickResetWorkspace"
            >
              Reset
            </b-button>
            <b-button class="mr-2" variant="success" @click="onClickRunCode">
              <b-spinner small v-if="isRunning"></b-spinner>
              {{ isRunning ? "Running..." : "Run" }}
            </b-button>
          </div>
        </div>
        <b-row no-gutters>
          <b-col sm="9">
            <div id="blocklyDiv"></div>
          </b-col>
          <b-col sm="3">
            <console :output="output" :height="'calc(100vh - 110px)'" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-model="isResultModalVisible"
      id="result-modal"
      title="Result"
      @ok="onClickResultOk"
      :hide-footer="!resultStatus.isCorrect"
    >
      <p class="my-4 text-center">
        <span style="font-size: 3rem;">
          {{ resultStatus.isCorrect ? "✔️" : "❌" }}
        </span>
        <br />
        {{ resultStatus.message }}
      </p>
    </b-modal>

    <b-modal :title="generatedCodeLanguage" v-model="viewCodeModal">
      <template>
        <div class="view-code-content">
          {{ generatedCode }}
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Blockly from "blockly";
import Interpreter from "js-interpreter";

import foundationCurriculum from "@/curriculum/foundation";
import appInventorCurriculum from "@/curriculum/app-inventor";
import challengeFoundationCurriculum from "@/curriculum/foundation-challenges";

import Console from "@/components/Console.vue";

import * as activityServices from "@/services/acitivity.service";
import { mapGetters } from "vuex";

export default {
  name: "BlocklyActivity",

  components: {
    Console
  },

  data() {
    return {
      classroomId: this.$route.params.classroomId,
      courseId: this.$route.params.courseId,
      activityId: this.$route.params.activityId,
      workspace: null,
      interpreter: null,
      output: "",
      record: {},
      activity: {},
      result: {
        printedText: ""
      },
      resultStatus: {
        isCorrect: false,
        message: ""
      },
      isResultModalVisible: false,
      intervalTrigger: null,
      isSaving: false,
      isLoading: false,
      showSaved: false,
      isRunning: false,
      generatedCode: "",
      generatedCodeLanguage: "",
      viewCodeModal: false
    };
  },

  watch: {
    $route: "onMounted"
  },

  async mounted() {
    this.onMounted();
  },

  computed: {
    ...mapGetters({
      isLicensed: "organisation/isLicensed",
      isSubscriptionActive: "organisation/isSubscriptionActive"
    }),

    currentCurriculum() {
      let curriculum = null;
      if (this.courseId == "bJ01LTYvRsQfT7D8hOnG") {
        curriculum = foundationCurriculum;
      } else if (this.courseId == "QukHX9bGasbZh0B0UOk8") {
        curriculum = appInventorCurriculum;
      } else {
        curriculum = challengeFoundationCurriculum;
      }
      return curriculum;
    },

    activityCurriculum() {
      return this.currentCurriculum.get(this.activityId);
    },

    activityIds() {
      return [...this.currentCurriculum.keys()];
    },

    content() {
      return this.currentCurriculum.has(this.activityId)
        ? this.currentCurriculum.get(this.activityId).content
        : "";
    },

    isOnlyContent() {
      return this.activity.layout == "content-only";
    },

    contentSize() {
      return this.activity.layout == "video-activity" ? 5 : 4;
    },

    currentActivityIndex() {
      return this.activityIds.indexOf(this.activityId);
    }
  },

  methods: {
    async onMounted() {
      this.courseId = this.$route.params.courseId;
      this.activityId = this.$route.params.activityId;

      const loader = this.$loading.show();
      this.isLoading = true;
      await this.getActivity();
      if (!this.isOnlyContent) {
        this.initPage();
      }
      loader.hide();
      this.isLoading = false;
    },

    getActivity() {
      return activityServices
        .getActivity(this.activityId)
        .then(activity => {
          this.activity = activity;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    getRecord() {
      return activityServices
        .getActivityRecord(
          this.$store.state.user.id,
          this.classroomId,
          this.activityId
        )
        .then(record => {
          this.record = record;
        });
    },

    initPage() {
      this.removeBlocklyInjection();
      this.clearConsole();
      this.injectBlockly();
      this.injectDefaultWorkspace();
    },

    injectBlockly() {
      this.workspace = Blockly.inject(document.getElementById("blocklyDiv"), {
        trashcan: true,
        zoom: {
          controls: true,
          startScale: 1.0,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2
        },
        toolbox: this.activity.blocklyToolbox
      });
    },

    injectRecordWorkspace() {
      const workspaceDom = Blockly.Xml.textToDom(this.record.workspaceText);
      Blockly.Xml.domToWorkspace(workspaceDom, this.workspace);
    },

    injectDefaultWorkspace() {
      if (this.activity.workspaceText != null) {
        const workspaceDom = Blockly.Xml.textToDom(this.activity.workspaceText);
        Blockly.Xml.domToWorkspace(workspaceDom, this.workspace);
      }
    },

    async onClickSave() {
      this.isSaving = true;
      await this.saveWorkspace();
      this.isSaving = false;
      this.showSavedAlert();
    },

    viewCode(language) {
      switch (language) {
        case "js":
          this.generatedCodeLanguage = "JavaScript";
          this.generatedCode = Blockly.JavaScript.workspaceToCode(
            this.workspace
          );
          break;

        case "python":
          this.generatedCodeLanguage = "Python";
          this.generatedCode = Blockly.Python.workspaceToCode(this.workspace);
          break;

        case "dart":
          this.generatedCodeLanguage = "Dart";
          this.generatedCode = Blockly.Dart.workspaceToCode(this.workspace);
          break;

        default:
          break;
      }
      this.viewCodeModal = true;
    },

    showSavedAlert() {
      this.showSaved = true;
      const interval = setInterval(() => {
        this.showSaved = false;
        clearInterval(interval);
      }, 2000);
    },

    onClickResetWorkspace() {
      if (confirm("Are you sure you want to reset this question?")) {
        this.resetWorkspace();
        this.injectDefaultWorkspace();
      }
    },

    async onClickRunCode() {
      this.isRunning = true;
      this.resetResult();
      this.clearConsole();

      // Run code in interpreter
      const workspaceCode = Blockly.JavaScript.workspaceToCode(this.workspace);
      const code = this.appendCode(workspaceCode);
      this.interpreter = new Interpreter(code, this.getInitApi());
      this.stepInterpreter();

      // Run code checking
      this.checkResult(code);

      // Show result modal
      this.showResultModal();
      this.isRunning = false;
    },

    checkResult(code) {
      this.resultStatus = this.activityCurriculum.logics.checkResult(
        code,
        this.result
      );
    },

    updateActivityAsDone() {
      const workspaceDom = Blockly.Xml.workspaceToDom(this.workspace);
      const workspaceText = Blockly.utils.xml.domToText(workspaceDom);
      return activityServices.mergeActivityRecord(this.activityId, {
        classroomId: this.classroomId,
        courseId: this.courseId,
        studentId: this.$store.state.user.id,
        workspaceText: workspaceText,
        status: "done"
      });
    },

    showResultModal() {
      this.isResultModalVisible = true;
    },

    onClickResultOk() {
      const nextActivityId = this.activityIds[this.currentActivityIndex + 1];
      this.$router.push(nextActivityId);
    },

    stepInterpreter() {
      const maxSteps = 100000;
      let count = 0;

      try {
        while (count <= maxSteps) {
          if (!this.interpreter.step()) {
            break;
          }
          count += 1;
        }
        if (count >= maxSteps) {
          this.appendOutput(
            "Your program is terminated because it is taking too long to run!"
          );
        }
      } catch (error) {
        this.appendOutput(error);
        this.isRunning = false;
      }
    },

    appendCode(code) {
      code += this.activityCurriculum.logics.getAppendCode(this.activityId);
      return code;
    },

    getInitApi() {
      return this.activityCurriculum.logics.getInitFunction(
        this.appendOutput,
        this.result
      );
    },

    saveWorkspace() {
      const workspaceDom = Blockly.Xml.workspaceToDom(this.workspace);
      const workspaceText = Blockly.utils.xml.domToText(workspaceDom);
      return activityServices.mergeActivityRecord(this.activityId, {
        classroomId: this.classroomId,
        courseId: this.courseId,
        studentId: this.$store.state.user.id,
        workspaceText: workspaceText
      });
    },

    appendOutput(text) {
      this.output += ">> " + text + "\n";
    },

    onClickClearConsole() {
      this.output = "";
    },

    clearConsole() {
      this.output = "";
    },

    resetResult() {
      this.result = {
        printedText: ""
      };

      this.resultStatus = {
        isCorrect: false,
        message: ""
      };
    },

    resetWorkspace() {
      this.removeBlocklyInjection();
      this.injectBlockly();
    },

    removeBlocklyInjection() {
      document.getElementById("blocklyDiv").innerHTML = "";
    }
  }
};
</script>

<style lang="less" scoped>
.activity {
  &-body {
    background-color: #fff;
    height: calc(100vh - 52px);
    overflow: auto;
  }

  &-header {
    height: 52px;
  }

  &-actionbar {
    height: 52px;
  }
}

#blocklyDiv {
  height: calc(100vh - 110px);
  width: 100%;
}
</style>
