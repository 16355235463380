<template>
  <div class="pb-3">
    <h2>Know Your Tools</h2>

    <video-player
      poster="/images/know-your-tools.png"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-2-tools.mp4?alt=media&token=c3f8e012-3ae6-4597-a9f9-0d8b9454bbe8"
      trackSrc="/setup.vtt"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Palette: Find components here and drag them to the viewer to add them to
      your App
    </p>
    <p>
      Viewer: This is where to add your components and see what your app looks
      like
    </p>
    <p>
      Components: This panel shows the list of components added to Viewer and
      their hierarchy (including hidden components!)
    </p>
    <p>
      Properties: Select a component in Viewer to change its properties (color,
      size, text, etc…)
    </p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "FoundationVariable",

  components: {
    videoPlayer
  },

  data() {
    return {
      player: null,
      options: {
        fluid: true
      }
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
