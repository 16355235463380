<template>
  <div>
    <h2>Variable Naming</h2>

    <p>
      The naming of variable is important in any programming language to ensure
      code readability. Python variable name needs to follow rules as below:
    </p>
    <ul>
      <li>Must start with a letter or the underscore character</li>
      <li>Cannot start with a number</li>
      <li>
        Can only contain alpha-numeric characters and underscores (A-z, 0-9, and
        _ )
      </li>
      <li>Variable names are case-sensitive</li>
    </ul>
    <div id="editor-invalid-variable" style="height: 80px;"></div>

    <h4 class="mt-3">Naming convention</h4>
    <p>
      Technically, you can choose any name for Python variables as long as it
      follows above rules. However, it is good practice to be consistent with
      your naming. The naming convention recommended by
      <a
        href="https://www.python.org/dev/peps/pep-0008/#function-and-variable-names"
        target="_blank"
        >PEP 8 -- Style Guide for Python Code
      </a>
      is to use <strong>snake case</strong>.
    </p>
    <p>
      Using snake case naming convention, each space is replaced by an
      underscore _ character, and the first letter of each word written in
      lowercase
    </p>
    <div id="editor-variable-naming" style="height: 80px;"></div>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Rename all the variables using snake case naming convention
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/webpack-resolver";

const code = `# Invalid names
1stnumber = 15
$text = "hello"`;

const code2 = `some_number = 12
car_brand = "Tesla"
is_correct = True`;

export default {
  mounted() {
    this.initEditor("editor-invalid-variable", code);
    this.initEditor("editor-variable-naming", code2);
  },

  methods: {
    initEditor(id, value) {
      ace.edit(id, {
        mode: "ace/mode/python",
        selectionStyle: "text",
        theme: "ace/theme/eclipse",
        readOnly: true,
        value: value
      });
    }
  }
};
</script>

<style></style>
