/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof meter === 'undefined') var meter;
    if (typeof centimeter === 'undefined') var centimeter;
    try {returnResult(meter, centimeter);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (meter: number, centimeter: number) => {
      result.meter = meter;
      result.centimeter = centimeter;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.meter === undefined) {
    status.message = "meter variable is not created correctly";
  } else if (result.centimeter === undefined) {
    status.message = "centimeter variable is not created correctly";
  } else if (result.meter !== 3.5) {
    status.message = "meter variable is not assigned correctly";
  } else if (result.centimeter !== 350) {
    status.message = "centimeter variable value is not correct";
  } else if (code.includes("centimeter = 350")) {
    status.message = "Use math operator to calculater centimeter value";
  } else if (!code.includes("alert(centimeter)")) {
    status.message = "centimeter not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
