<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js"
      controls
      preload="auto"
      :poster="poster"
    >
      <source :src="src" type="video/mp4" />
      <track
        v-for="track in trackList"
        kind="captions"
        :key="track.src"
        :src="track.src"
        :srclang="track.srclang"
        :label="track.label"
        :default="track.default"
      />
    </video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",

  props: {
    src: {
      type: String
    },

    poster: {
      type: String
    },

    options: {
      type: Object,
      default() {
        return {};
      }
    },

    trackList: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      player: null
    };
  },

  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style></style>
