<template>
  <div class="pb-3">
    <h2>Set up MIT App Inventor</h2>
    <video-player
      class="mt-3"
      poster="/images/setup.png"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-1-intro.mp4?alt=media&token=b02ae1cf-cc57-4a92-9cb7-f269ed145b74"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      Click on the link below to navigate to MIT App Inventor Website
    </p>

    <p>
      <a href="https://appinventor.mit.edu/" target="_blank">
        Link to MIT App Inventor
      </a>
    </p>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "FoundationVariable",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/captions/setup-en.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less">
.video-player-box {
  border-radius: 15px;
}
</style>
