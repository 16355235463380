<template>
  <div>
    <h2>Iterate List Using For Each Loop</h2>
    <video-player
      class="mt-3"
      src="https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/course-media%2FQukHX9bGasbZh0B0UOk8%2Fmit-33-list-foreach.mp4?alt=media&token=a5405986-eb9c-4e29-aa28-398435c9eae1"
      :trackList="trackList"
      :options="{ fluid: true }"
    ></video-player>

    <p class="mt-3">
      For each loop will loops through a block of code for each element in an
      array.
    </p>

    <img class="img-fluid" src="@/assets/foundation/list_for_each_loop.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "apple"
          <br />
          >> "orange"
          <br />
          >> "melon"
          <br />
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      Using for each loop we do not need to specify the starting and ending
      condition for the loop.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Print out each element in
            <span class="block block-variable">colours</span> list using for
            each loop
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "red"
          <br />
          >> "green"
          <br />
          >> "blue"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import videoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "ListIntroduction",

  components: {
    videoPlayer
  },

  data() {
    return {
      trackList: [
        {
          src: "/setup.vtt",
          srclang: "en",
          label: "English",
          default: true
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped></style>
