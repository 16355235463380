/* eslint-disable @typescript-eslint/no-explicit-any */
import formatter from "@/utils/console-formatter";

export function getAppendCode() {
  let appendCode = "";
  appendCode += `
    if (typeof myBooleanString === 'undefined') var myBooleanString;
    if (typeof myBoolean === 'undefined') var myBoolean;
    try {returnResult(myBooleanString, myBoolean);} catch (err) {};
  `;
  return appendCode;
}

export function getInitFunction(
  appendOutput: Function,
  result: Record<string, unknown>
) {
  return (interpreter: any, scope: any) => {
    const alertWrapper = function(obj: any) {
      const formattedText = formatter(obj);
      return appendOutput(formattedText);
    };

    interpreter.setProperty(
      scope,
      "alert",
      interpreter.createNativeFunction(alertWrapper)
    );

    const returnResult = (myBooleanString: string, myBoolean: boolean) => {
      result.myBooleanString = myBooleanString;
      result.myBoolean = myBoolean;
    };

    interpreter.setProperty(
      scope,
      "returnResult",
      interpreter.createNativeFunction(returnResult)
    );
  };
}

export function checkResult(code: string, result: Record<string, unknown>) {
  const status: Record<string, any> = {
    isCorrect: false,
    message: null
  };

  if (result.myBooleanString === undefined) {
    status.message = "myBooleanString variable not created";
  } else if (result.myBoolean === undefined) {
    status.message = "myBoolean variable not created";
  } else if (result.myBooleanString !== "true") {
    status.message = "myBooleanString not assigned with correct value";
  } else if (result.myBoolean !== true) {
    status.message = "myBoolean not assigned with correct value";
  } else if (
    !code.includes("alert(myBooleanString)") ||
    !code.includes("alert(myBoolean)")
  ) {
    status.message = "myBooleanString or myBoolean not printed";
  } else {
    status.message = "Great! Let move on to the next task.";
    status.isCorrect = true;
  }
  return status;
}
